//20180603 WIP
//NOT YET ACCESSABE FROM ANYWHERE, placed in to router as: '/InfoSessionsClassesDemosConsultingSignup'

import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { setUpDefaults,
        userEventsToChooseFrom,
        userEventsSelectedSetState,
        userEventsSelectedSave  //used for change after login, otherwise, used in login action
       } from '../actions';
import InviteCodeValidation from './InviteCodeValidation'


//class SignUpForClassDemoConsulting extends Component{
class SignUpForIntroPresL extends Component{

    constructor (props){
        super(props);
        this.state = {
          previouslyRegisteredUser: '',
          previouslySelectedIntroPres: '',
          selectedIntroPres: '',
          changeButtonClicked: false,
          userErrorMsg: ''
        }

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    //this.handleRenderIntrPresRadioOptions = handleRenderIntrPresRadioOptions.bind(this);
}

    componentDidMount(){
      this.setState({
      previouslyRegisteredUser: this.props.rdcr_user_curr[0].user_id === '' ? false : true,
      previouslySelectedIntroPres: this.props.rdcr_user_events_selected[0].event_id === '' ? false : true,
      selectedIntroPres: this.props.rdcr_user_events_selected[0].event_id
    }, () => {
      console.log('this.state.previouslyRegisteredUser', this.state.previouslyRegisteredUser)
      console.log('this.state.previouslySelectedIntroPres', this.state.previouslyRegisteredUser)
      console.log('this.state.selectedIntroPres', this.state.previouslyRegisteredUser)
    })
      /*
      //https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
      if (Object.keys(this.props.rdcr_user_curr).length === 0 && this.props.rdcr_user_curr.constructor === Object) {
      console.log('this.props.rdcr_user_curr', this.props.rdcr_user_curr)
      this.props.setUpDefaults()
    }
      this.props.userEventsToChooseFrom();
      */
    }

    handleOnSubmit(e){
      e.preventDefault();
      if (this.state.selectedIntroPres === '') {
        this.setState({
          userErrorMsg: 'Please select a intro presentation time.'
        })
      }
      else {
        if (this.props.rdcr_user_curr[0].user_id === '') {
          this.props.userEventsSelectedSetState(this.state.selectedIntroPres)
            //in this case this.props.rdcr_user_curr[0].user_id === '', tf need to register as user
            this.props.history.push('/Register');
        }
        else {
            //in this case this.props.rdcr_user_curr[0].user_id will have user_id (user already logged in)
            this.setState({
              changeButtonClicked: true,
            })
            this.props.userEventsSelectedSave(this.props.rdcr_user_curr[0].user_id, this.state.selectedIntroPres)
          }
        }
    }

    handleOnClickSignIn(e, page_to_open){
      e.preventDefault(); 
      console.log('button clicked:', e.target.name)
      this.props.history.push(`/${page_to_open}`);
    }

    handleIntroPresSelected(e){
      //e.preventDefault()  ////this caused needing to click option twice to be visible as checked
      console.log('e.target.value', e.target.value)
      this.setState({
        selectedIntroPres: e.target.value,
        changeButtonClicked: false
      })
      //this.props.userEventsSelectedSetState(e.target.value);
    }

    handleRenderIntrPresRadioOptions(){
      if (this.props.rdcr_user_events_offering !== undefined) { 
        console.log('if true in handleRenderIntrPresRadioOptions ')
        console.log(this.props.rdcr_user_events_offering);
        if (Array.isArray(this.props.rdcr_user_events_offering)) { 
        //Object.keys(this.props.rdcr_user_events_offering).map((key) => {
          //const item = this.props.rdcr_user_events_offerings[key]
      return this.props.rdcr_user_events_offering.map((item, i) => {
        return <div key={item.id}>
        <label>
            <input type="radio" 
              value={item.id}
              disabled={item.status_attend_pre === 'full'}
              checked={this.state.selectedIntroPres===item.id}
              onChange={(e) => this.handleIntroPresSelected(e)}/>
            {(item.status_attend_pre === 'full' ? 'FULL -- ' : '') + ' ' + item.event_date_time_desc}
        </label>  
          </div>
      })
    }
    }
  }

    handleRenderText01(){
        if (this.state.previouslySelectedIntroPres){
          const msg = "Below is the intro presentation you're currently signed up for. " +
          "Feel free to make changes. We're looking forward to your joining us soon." 
          return msg
        }
        if (!this.state.previouslyRegisteredUser) {
          return <div>
            <p>Welcome! Please join us for one of our free small-group live online intro presentations
            on Relways Relationship Technology and the ComplexAbility appraoch.
            <br /> 
            <br />  
            Select a time that's best for you from these up coming sessions below and continue
            the registration in the next page.
            <br />
            <br />  
            If you have any questions, please don't hesitate to reachout to use at support@complexability.com
            or call 999-999-9999.
            <br />
            <br />  
            We're looking forward to your joining us.
            </p>
            <hr />
          </div>
        }
    }

    handleRenderChangeSavedMsg(){
      if (this.state.changeButtonClicked === true && 
            this.props.rdcr_user_events_selected !== this.state.selectedIntroPres) {
              return <div>
                 <label 
                  className='containerSignUpForIntroPresL__msg_saved_success'>
                  Your change has been saved.
                </label>
                </div>
      }
    }

    handleRender(){
      if (!Array.isArray(this.props.rdcr_user_events_selected)) {  //if array that means defaults in reducer (called by ComponentWillMount, otherwise its empty object
        return <div>
            <label>
              Loading...
            </label>
            </div>
      }
      else {
        if (this.props.rdcr_user_curr[0].login_status_note !== "Login Complete az" && 
              (this.props.rdcr_user_register_invite[0] === undefined || 
                this.props.rdcr_user_register_invite[0].used_dt_tm.includes('issue'))) {
                      console.log('inside if....');
                      return <InviteCodeValidation/>
              }
        else {
          return <div>
          <form onSubmit={this.handleOnSubmit}>
            {this.handleRenderText01()} 
            <label>Up coming small group live presentations:</label>
            <div className='containerSignUpForIntroPresL__OptionList'>
              {this.handleRenderIntrPresRadioOptions()}
            </div>
            <br/>
            <div>
              <label className='ui_msg_label'>{this.state.userErrorMsg}</label>
            </div>
            <div>
            <input hidden={this.props.rdcr_user_curr[0].user_id !== ''} 
              className="btn btn-primary btn-primary-spacing" 
              type="submit" 
              value="Register"
            />
            </div>
            <div>
            {this.handleRenderChangeSavedMsg()}
            </div>
            <div>
            <input hidden={this.props.rdcr_user_curr[0].user_id === ''}
              className="btn btn-primary btn-primary-spacing" 
              type="submit" 
              value="Change"          
            />
            </div>
          
          </form>
          </div>
        }  
      } 
    }
    
    render(){
      return (
        <div className='containerSignUpForIntroPresL'>
          {this.handleRender()}
        </div>
      );
    }
}

//export default LoginUser;

function mapStateToProps(state) {
  return {    
              rdcr_user_curr: state.rdcr_user_curr,
              rdcr_user_events_offering: state.rdcr_user_events_offering,
              rdcr_user_events_selected: state.rdcr_user_events_selected,
              rdcr_user_register_invite: state.rdcr_user_register_invite
          };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    setUpDefaults: setUpDefaults,
    userEventsToChooseFrom: userEventsToChooseFrom,
    userEventsSelectedSetState: userEventsSelectedSetState,
    userEventsSelectedSave: userEventsSelectedSave }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForIntroPresL);