//Persons_rdx
import _ from "lodash";
import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import PersonsTblDataRow from './PersonsTblDataRow'
import {
    UI_CONFIG,
    updateUiConfig, 
    personsDataGet, 
    personsDataFilter,
    personsContextsListDataFilter,
    userContextsDataGet, 
    createNewPersonForUser,
    personContextsDataGet,
    personsDataSort,
    personContextDescRefGet,
    personContextModulesGet,
    personContextsTypeRefGet,
    personContextsCharRefGet,
    contextSuccessRefGet,
    personContextModeRefGet,
    personDataClear,
} from "../actions";

class Persons extends Component{
//https://facebook.github.io/react/docs/forms.html
//az maybe <textarea/> is better here

///*
constructor (props){
    super(props);
    this.state = {
        //person_fn_filter: this.props.rdcr_persons_srch_crit.first_name_crit,
        //person_ln_filter: this.props.rdcr_persons_srch_crit.last_name_crit,
        sort_fn_curr: '',
        sort_ln_curr: '',
        isAdmin: false,
        loading_msg: '',
        descript_of_list: 'dynamic contexts',
        viewEdMaterialTf: false,
    }
    this.handleOnChangeOLD = this.handleOnChangeOLD.bind(this);
    this.handleOnChangeFilter = this.handleOnChangeFilter.bind(this);
    this.handleSortPersons = this.handleSortPersons.bind(this);
}
//*/

//good example for selector(dropdown) setting default: https://stackoverflow.com/questions/21733847/react-jsx-selecting-selected-on-selected-select-option


componentDidMount() {
    console.log('Persons.js componentDidMount');
    console.log('in Persons.js: this.props.rdcr_user_curr[0].user_id', this.props.rdcr_user_curr[0].user_id);
    //20190628 !!!!NEEDS TO BE MANGED BETTER. Currently retrieveing BOTH datasets from DB every time getting back to page!!!
    if (this.props.rdcr_user_curr[0].user_id !== undefined && this.props.rdcr_user_curr[0].user_id !== '') { //20200425az added if bc react hot load was sending null id to db and db crashed -- this fixed
        this.props.personsDataGet(this.props.rdcr_user_curr[0].user_id);
        console.log('under this.props.personsDataGet(this.props.rdcr_user_curr[0].user_id)')
        this.props.userContextsDataGet(this.props.rdcr_user_curr[0].user_id);
        console.log('under2 this.props.personsDataGet(this.props.rdcr_user_curr[0].user_id)')
    };
    window.scrollTo(0,0);
    //get data for down the line:
    this.props.personContextModulesGet();
    this.props.personContextsTypeRefGet();
    this.props.personContextsCharRefGet();
    this.props.personContextModeRefGet();
    console.log('in Persons componentDidMount this.props.rdcr_user_curr::', this.props.rdcr_user_curr)
    console.log('in Persons componentDidMount this.props.rdcr_user_curr[0]::', this.props.rdcr_user_curr[0])
    console.log('in Persons componentDidMount this.props.rdcr_user_curr[0].actions_allowed::', this.props.rdcr_user_curr[0].actions_allowed)
    //20191028zzz dev only: client grp ui:
    console.log('this.props.rdcr_user_curr[0].user_id in Person', this.props.rdcr_user_curr[0].user_id)
    console.log('this.props.rdcr_user_curr[0].usage_grp_id in Person', this.props.rdcr_user_curr[0].usage_grp_id)
        //in dev test for consultativesg user_id ("f8775c95-257c-970a-c59f-534d23516da9"; id_seq 221)
        if (this.props.rdcr_user_curr[0].usage_grp_id) {
            this.props.updateUiConfig(UI_CONFIG, 'ui_metadata', [{component_parent: 'personContext', component_child: 'contextFrameDropdown', grp_name: 'constultativesg_placement_candidate_workflow'}])
            //this.props.personContextDescRefGet('constultativesg_placement_candidate_workflow')
            //this.props.personContextDescRefGet(this.props.rdcr_user_curr[0].usage_grp_id)
            this.props.personContextDescRefGet('Personal')
        }
    this.props.personDataClear();
}

handleOnChangeFilter(e) {
    console.log('handleOnChangeFilter_eee', e.target.name)
    console.log('handleOnChangeFilter_eee', e.target.value)
    this.props.updateUiConfig(UI_CONFIG, 'list_filter_crit', e.target.value);
    switch (this.props.rdcr_ui_config.typeOfBaseListDisplay) {
        case 'name':
            this.props.personsDataFilter(e.target.value);
            break;

        case 'date':
            this.props.personsContextsListDataFilter(e.target.value);
            break;
        default:
            break;
    }
}

handleOnChangeOLD(e) {
    this.props.handlePeopleForUserListFilter(e.target.name, e.target.value)
}

handleGoToPersonContext(e) {
    this.props.personDataGet(e.target.id, () => {
        this.props.history.push('/PeopleContexts');
      }
    );
  }

handleCreateNewPersonCntxtDyns(open_page_level) {
    console.log('in handleCreateNewPersonCntxtDyns, open_page_level', open_page_level);
    //const open_page_level = e.target.value;
    //console.log('in handleCreateNewPersonCntxtDyns, open_page_level', open_page_level);
///**
setTimeout(() => {this.setState({
                    loading_msg: 'Loading new person...'
                    })}, 1000);  //1000 = 1 sec
    /*
    this.setState({
        loading_msg: 'Loading new person...'
    })
    */
    this.props.createNewPersonForUser('person', () => {
        //open diff UI:
        switch (open_page_level) {
            case "new_person_person":
                this.props.history.push('/PersonDetails');
                break;
            case "new_person_context":
                this.props.history.push('/PeopleContexts');
                break;
            case "open_cntxt_dyn":
                /*
                get context data after creating person-context
                same as in PeopleDetail, context button, function handleGoToContext()
                */
                this.props.personContextsDataGet(this.props.rdcr_person_active[0].id, () => {
                console.log('getting new page after getting context data after creating person-context.....');
                this.props.history.push('/DynGrpWtCntxtAndPerson');
                })
                break;
            default:
                break;
        }
      }
    );
  //   */
  }

/*
20180226: above drove me crazy, before putting in a function outside the render -- kept getting messages as though it's not an array
put then after putting in function, moved back into render and worked!
when using _.forEach but also something else was cause issue (couldn't figure out!!!)
got frustrating error:
Uncaught Error: Objects are not valid as a React child (found: object with keys {}). If you meant to render a collection of children, use an array instead or wrap the object using createFragment(object) from the React add-ons. Check the render method of `Persons_rdx`.
*/

//get user contexts list data if needed and set Ui
handleGetUserPersonsOrContexts(e){
        this.props.updateUiConfig(UI_CONFIG, 'typeOfBaseListDisplay', e.target.value)
        this.props.updateUiConfig(UI_CONFIG, 'contextIdsSelected', ['clear']);
        // this.setState({
        //     typeOfBaseListDisplay: e.target.value
        // })
        this.setState({
            descript_of_list: e.target.value === 'name' ? 'people and actions' : 'dynamic contexts'
        })
        switch (e.target.value) {
            case 'name':
                console.log('helloooo from handleGetUserPersonsOrContexts; case --name--')
                this.renderPersonForms();
                break;
            case 'date':
                console.log('helloooo from handleGetUserPersonsOrContexts; case --date--')
                //get user's context data if doesn't exist
                const getUserContextsListData = () => {
                    return new Promise((resolve, reject) => {
                        if (this.props.rdcr_user_contexts_list[0]) {
                            resolve('getUserContextsListData success data already retrieved');
                        }
                        else {
                            console.log('getUserContextsListData/Promise')
                            const rtn = this.props.userContextsDataGet(this.props.rdcr_user_curr[0].user_id);
                            console.log('getUserContextsListData/Promise 2');
                            console.log('rtn rtn rtn rtn', rtn);
                            if (!rtn) {
                                reject('getUserContextsListData failed');
                            }
                            else {
                                resolve('getUserContextsListData success');
                            }    
                        }
                    })
                }
                getUserContextsListData()
                .then(() => this.renderPersonForms())
                .catch(()=> console.log('TEEEEESSSSST catched!'))
            break;
            default:
            break;
        }
}

renderPersonForms() {
    console.log('helloooo renderPersonForms')
    let personsAllOrFiltered = [];
    console.log('this.props.rdcr_ui_config.typeOfBaseListDisplay', this.props.rdcr_ui_config.typeOfBaseListDisplay)
    switch (this.props.rdcr_ui_config.typeOfBaseListDisplay) {
        case 'name':  //for user's persons list
            if (this.props.rdcr_ui_config.list_filter_crit !== '') {
                personsAllOrFiltered = this.props.rdcr_persons_filtered;
            } else {
                personsAllOrFiltered = this.props.rdcr_persons;
            }
        break;

        case 'date':  //for user's contexts list  
            if (this.props.rdcr_ui_config.list_filter_crit !== '') {
            personsAllOrFiltered = this.props.rdcr_contexts_filtered;
        } else {
            personsAllOrFiltered = this.props.rdcr_user_contexts_list;
        }         
        break;

        default:
        break;
        }
    
    return _.map(personsAllOrFiltered, p => {
        //console.log('from PersonQuickForm', p.id);
        //20180506 az issue with router not injuecting history into child componeneet only first (parent) component
        //ANSWER: https://stackoverflow.com/questions/43837212/this-props-history-push-works-in-some-components-and-not-others?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
        let dataToPassForDisplay = '';
        let dataToPassForDisplay02 = '';
        let rowId = ''
        let persId = ''
        let context_disharmony_level = -1000
        switch (this.props.rdcr_ui_config.typeOfBaseListDisplay) {
            case 'name':
                dataToPassForDisplay = (p.first_name ? p.first_name : '') + ' ' + (p.last_name ? p.last_name : '' )
                rowId = p.id; //this is person id
                persId = p.id  //some thing: person id
                break;
            case 'date':
                dataToPassForDisplay = p.entry_dt_tm + ' Context (' + p.context_cat_descript + ') with ' + (p.first_name ? p.first_name : '') + (p.last_name ? ' ' + p.last_name : '' );
                //prev included roadmap type: dataToPassForDisplay = p.entry_dt_tm + ' Context (' + p.context_cat_descript + ' - ' + (p.process_roadmap_mode === 'rapid_roadmap' ? 'RR': 'PR') + ') with ' + (p.first_name ? p.first_name : '') + ' ' + (p.last_name ? p.last_name : '' );
                dataToPassForDisplay02 =  (p.context_goal !== (null && '') ? p.context_goal : ' [???]');
                //prev included context desc (not goal): dataToPassForDisplay02 =  p.context_def !== (null && '') ? p.context_def : ' [description not yet entered]';
                //dataToPassForDisplay02 =  ' -- ' + (p.context_def !== (null || '') ? p.context_def : '[description left blank]')
                rowId = p.cntxtid;  //this is context id
                persId = p.persid;
                context_disharmony_level = p.disharmony_level;
                break;
            default:
                break;
        }
        
        return (
        <PersonsTblDataRow
            data_type={this.props.rdcr_ui_config.typeOfBaseListDisplay}
            dataForDisplay={dataToPassForDisplay}
            dataForDisplay02={dataToPassForDisplay02 ? '. Goal: ' + dataToPassForDisplay02 : ''}
            dataDynStatus={p.dyn_status} 
            key={rowId}
            id={rowId}
            pers_id = {persId}
            pers_first_name = {p.first_name}
            pers_last_name = {p.last_name}
            disharmony_level={context_disharmony_level}
            process_roadmap_mode={p.process_roadmap_mode}
            history={this.props.history}
        />
        )
    });
  }

  handleSortPersons(e){
    e.preventDefault();
    console.log('eeeeeeeeee', e)
    console.log('eeeee.name', e.target.name);
    console.log('eeeee.value', e.target.value);
    let sort_direction = ''
    if (this.state[e.target.name] === 'a>b') {
        sort_direction = 'b>a'
    }
    else {
        sort_direction = 'a>b'
    }
    this.setState(
        {[e.target.name]: sort_direction} 
    )
    this.props.personsDataSort('sort_fn_curr', sort_direction)
  }

  renderUserContextsForm() {
    
    let personsAllOrFiltered = [];
    if (this.props.rdcr_persons_filtered[0]) {
        personsAllOrFiltered = this.props.rdcr_persons_filtered
    } else {
        personsAllOrFiltered = this.props.rdcr_persons
    }
    return _.map(personsAllOrFiltered, p => {
        //console.log('from PersonQuickForm', p.id);
        //20180506 az issue with router not injuecting history into child componeneet only first (parent) component
        //ANSWER: https://stackoverflow.com/questions/43837212/this-props-history-push-works-in-some-components-and-not-others?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
        
        let fullName = (p.first_name ? p.first_name : '') + ' ' + (p.last_name ? p.last_name : '' )
        return (
        <PersonsTblDataRow
            data={fullName} 
            key={p.id}
            id={p.id}
            history={this.props.history}
        />
        )
    });
  }

  handleIsAdmin(){
      if (
        this.props.rdcr_curr_user_admin.permission_type.search('admin') !== -1 ||
        this.props.rdcr_curr_user_admin.permission_type.search('trainer') !== -1
        ) {
            return true
      }
  }

  handleIsEdMaterialVisible () {
      console.log('this.state.viewEdMaterialTf', this.state.viewEdMaterialTf)
      this.setState({
        viewEdMaterialTf: !this.state.viewEdMaterialTf
      }
      )
    }

    render(){
    
        if //(!this.props.rdcr_persons) {  //20180504 pre full redux
        (!this.props.rdcr_user_curr[0].user_id) {
        return <div>Loading...</div>;
        }

        return (
            <div>
                <Container>
                    <Row> {/*noGutters not working like this*/}
                        <Col sm={12} className='m-auto'>
                            <button type="button"
                                className="btn btn-link"
                                name='goBack' value='goBack' onClick={() => this.props.history.push('/UserHome')}>
                                <FontAwesome
                                    name="chevron-left"
                                />
                                menu
                            </button>
                            <input className="container_persons__input_txt_name" 
                                type="text"
                                name='person_name_filter'
                                placeholder="Search"
                                disabled={(this.props.rdcr_ui_config.typeOfBaseListDisplay === 'name') ? '' : ''}
                                //delcand20190731: value={this.state.person_fn_filter}
                                value={this.props.rdcr_ui_config.list_filter_crit}
                                onChange={(e) => this.handleOnChangeFilter(e)}/>  
                            <button
                                className='ml-2 mr-2'
                                disabled={(this.props.rdcr_ui_config.typeOfBaseListDisplay === 'name') ? '' : 'disabled'}
                                onClick={(e) => this.handleSortPersons(e)}>
                                <FontAwesome
                                    name='sort-alpha-up'
                                    className='btn-space'
                                    //size='2x'
                                    style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                    />
                            </button>
                             {/*20200523co for new ui below opening NewPersonOrContext*/}
                            <button
                                hidden={true}
                                onClick={() => this.handleCreateNewPersonCntxtDyns("new_person_person")}
                                >
                                <FontAwesome
                                name='user-plus'
                                className='btn-space'
                                //size='2x'
                                style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                value='new_person_person'
                                />
                            </button>
                            {/*played around with "fas fa-people-arrows" but did look good, pro version maybe better*/}
                            <button hidden={false}
                                onClick={() => this.props.history.push('/NewPersonOrContext')}
                                >
                                <FontAwesome
                                name='user-plus'
                                className='btn-space'
                                //size='2x'
                                style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                value='new_cntxt_dyn_grp'
                                />
                            </button>
                            <button hidden={true}
                                onClick={() => this.props.history.push('/UserLanding')}
                                >
                                <FontAwesome
                                name='fas fa-align-juustify'
                                className='btn-space'
                                //size='2x'
                                style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                value='new_cntxt_dyn_grp'
                                />
                            </button>
                            {/*used for testing:*/}
                            <button hidden={true}
                                onClick={() => this.props.history.push('/NewPersonOrContext')}
                                >
                                <FontAwesome
                                name='fas fa-cube'
                                className='btn-space'
                                //size='2x'
                                style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                value='new_cntxt_dyn_grp'
                                />
                            </button>                            
                            <button hidden={true}
                                onClick={() => this.handleCreateNewPersonCntxtDyns("new_person_context")}
                                >
                                <FontAwesome
                                name="fas fa-arrows-alt"
                                className='btn-space'
                                //size='2x'
                                style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                                value='new_person_context'
                                />
                            </button>
                            <button
                                className='btn btn-outline-secondary btn-sm btn-space'
                                type="button"
                                name='userSettingsUser'
                                value='User SettingsUser'
                                onClick={() => this.props.history.push('/UserSettingsBasics')}>
                                ...
                            </button>
                            <label
                                hidden={true}
                                className='userAppNameDisplayAsLink'
                                type="button"
                                name='userSettingsUser'
                                value='User SettingsUser'
                                onClick={() => this.props.history.push('/UserSettingsBasics')}>
                                {this.props.rdcr_user_curr[0].name_display_in_app.substring(0, 1)}
                            </label>
                            <button
                                hidden={!this.handleIsAdmin()}
                                type="button"
                                className="btn btn-primary btn-sm button_margin_right"
                                name='userSettingsAdmin'
                                value='User SettingsAdmin'
                                onClick={() => this.props.history.push('/UserSettingsAdmin')}>
                                ...
                            </button>
                            <select
                                value={this.props.rdcr_ui_config.typeOfBaseListDisplay}
                                onChange={(e) => this.handleGetUserPersonsOrContexts(e)}>
                                <option value="date">Date -- Person or Task -- Context</option>
                                <option value="name">Person or Task</option>
                            </select>
                            {/* <button type="button" className="btn btn-primary btn-sm" name='' value="Context New"  onClick={() => this.props.personsDataFilter('', '')}>test personsDataFilter</button> */}
                            {/* <button type="button" className="btn btn-primary btn-sm" name='' value="Context New"  onClick={() => this.props.personsDataFilter('', '')}>test personsDataFilter</button> */}
                            {/* <button type="button" className="btn btn-primary btn-sm" name='' value="Context New"  onClick={() => alert(this.props.rdcr_persons_filtered[0].first_name)}>test personsDataFilter</button> */} 
                            {/*20200515 add iframe*/}
                            <span hidden={this.props.rdcr_user_curr[0].location_ed_mat_01 == '' ? true : false} className="btn btn-link" onClick={() => {this.handleIsEdMaterialVisible()}}>{this.state.viewEdMaterialTf ? 'Hide' : 'View'} CA principles {this.state.viewEdMaterialTf ? '-' : '+'}</span><span></span>
                            <div hidden={this.state.viewEdMaterialTf && this.props.rdcr_user_curr[0].location_ed_mat_01 !== '' ? false : true}>
                                <div className='container_for_resp_iframe'>
                                    <iframe className='responsive-iframe' src={this.state.viewEdMaterialTf && this.props.rdcr_user_curr[0].location_ed_mat_01 !== '' ? this.props.rdcr_user_curr[0].location_ed_mat_01 : ''}></iframe>
                                </div>
                                <div hidden>
                                    <a href={this.props.rdcr_user_curr[0].location_ed_mat_01} target="_blank">
                                        View in new window
                                    </a>
                                </div> 
                            </div>
                            <div className='userTypeDisplay'>
                                <label>
                                    {this.handleIsAdmin() ? this.props.rdcr_curr_user_admin.permission_type_display + 
                                        ((this.props.rdcr_curr_user_admin.user_whose_data_being_viewed !== undefined) ? ' viewing ' 
                                            + this.props.rdcr_curr_user_admin.user_whose_data_being_viewed_display_name_in_grp + '\'s dynamic contexts info' : '') 
                                            : ''}
                                </label>
                                <span> </span>
                                <label className='userAppNameDisplay'>{this.props.rdcr_user_curr[0].name_display_in_app}'s {this.state.descript_of_list}</label>
                            </div>
                        </Col>
                        </Row>
                        <Row hidden={this.state.loading_msg === '' ? true : false}>
                            <Col sm={12} md={6} className='m-auto'>
                                {this.state.loading_msg}
                                <br></br>
                            </Col>
                        </Row>
                    <Row hidden={true}>
                            <Col sm={12} md={6} className ='m-auto'>
                                <div>
                                    <iframe src='https://static1.squarespace.com/static/546575e0e4b0468f51ccf752/t/5ea65b479b624666c322e85c/1587960648230/Datagrasp+-+Terms+of+Use%5EJ+4846-1533-8658_1_AZ_01_20200426b_az_FINAL.pdf' width="600" height="600" border="0"></iframe>
                                </div>
                                </Col>
                    </Row>
                    <Row hidden={this.state.loading_msg === '' ? false : true}>
                        <Col>
                            <div>
                                <table className="table table-striped table-hover">
                                    <tbody>           
                                        {this.renderPersonForms()} 
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
        }
    }

function mapStateToProps(state) {
    return {    
                rdcr_ui_config: state.rdcr_ui_config,
                rdcr_user_curr: state.rdcr_user_curr,
                rdcr_curr_user_admin: state.rdcr_curr_user_admin,
                rdcr_persons: state.rdcr_persons,
                rdcr_persons_srch_crit: state.rdcr_persons_srch_crit,
                rdcr_persons_filtered: state.rdcr_persons_filtered,
                rdcr_user_contexts_list: state.rdcr_user_contexts_list,
                rdcr_contexts_filtered: state.rdcr_contexts_filtered,
                rdcr_person_active: state.rdcr_person_active
            };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        updateUiConfig,
        personsDataGet: personsDataGet,
        personsDataFilter: personsDataFilter,
        personsContextsListDataFilter, 
        userContextsDataGet,
        createNewPersonForUser: createNewPersonForUser,
        personContextsDataGet,
        personsDataSort: personsDataSort,
        personContextModulesGet,
        contextSuccessRefGet,
        personContextDescRefGet,
        personContextModeRefGet,
        personContextsTypeRefGet,
        personContextsCharRefGet,
        personDataClear}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(Persons);