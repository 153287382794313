import {USER_PERSONS_DATA__GET } from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_PERSONS_DATA__GET:
      //console.log('rdcr_persons -- USER_PERSONS_DATA__GET -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}