import {ROADMAP_ACTIVE} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case ROADMAP_ACTIVE:
      //console.log('rdcr_roadmap -- ROADMAP_ACTIVE -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
