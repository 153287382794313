import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FontAwesome from 'react-fontawesome';
import {
    UI_CONFIG,
    updateUiConfig, 
    saveOnBlurSingleField, 
    personDataGet, 
    personContextsDataGet, 
    personsDataFilter, 
    personContextModulesGet,
    personContextsTypeRefGet,
    genContextRecs,
    createNewPersonForUser
} from '../actions'

class PersonsTblDataRow extends Component {
//modeled after PersonQuickForm.js

constructor (props){
    super(props);
    this.state = {
        classNameContextDesc01: '',
    }
}

    handleGoToContexts() {
        switch (this.props.data_type) { //passed from Person.js into this component
        case 'name':
            this.props.personDataGet(this.props.id);  //this is parent id
            this.props.personContextsDataGet(this.props.id, () => {
                this.props.history.push('/PeopleContexts');
                });
            break;
        case 'date':
            console.log('handleGoToContexts, case date')
            this.props.personDataGet(this.props.pers_id);  //this is parent id
            this.props.updateUiConfig(UI_CONFIG, 'contextIdsSelected', ['reset', this.props.id]);  //20190712
            this.props.personContextsDataGet(this.props.pers_id, () => {
                this.props.history.push('/PeopleContexts');
                });
            break;
        default:
            break;
        }
    }

    handleGoToRoadmap(){
        //this.props.genContextRecs(this.props.rowId, this.state.disharmony_level, () => this.props.history.push('/ContextRecsAndActions'));
        console.log('handleGoToRoadmap')
        console.log('this.props.process_roadmap_mode', this.props.process_roadmap_mode)
        this.props.personDataGet(this.props.pers_id, () => {
            this.props.updateUiConfig(UI_CONFIG, 'process_roadmap_mode', this.props.process_roadmap_mode)
            this.props.genContextRecs(this.props.id, this.props.disharmony_level !== -1000 ? this.props.disharmony_level : 0, () => this.props.history.push('/ContextRecsAndActions'));
        }) //using the cd that is usually for open page as the call back for more date processing before opening page -- this is bc seems like going to db adn back sometimes errors because not fast enough -- really needs a promise to make this more clear
        
    }

    handleCreateNewContext(){
        switch (this.props.data_type) { //passed from Person.js into this component
        case 'name':
            this.props.personDataGet(this.props.id);  //this is parent id
            this.props.personContextsDataGet(this.props.id, () => {
                this.props.history.push('/PeopleContexts');
                });
            break;
        case 'date':
            console.log('handleCreateNewContext, case date')
            this.props.personDataGet(this.props.pers_id, () => {
                this.props.createNewPersonForUser('context', () => {
                    this.props.history.push('/PeopleContexts');
                    })});
            break;
        default:
            break;
        }
    }

    handleHoverOverContext(action){
        this.setState({
            classNameContextDesc01: (action == 'on' ? 'btn btn-link' : '')
        })
        console.log('handleHoverOverContext')
    }

    render() {
        return (
            <tr>
                <td>
                    {/*
                    <span onMouseOver={()=>this.handleHoverOverContext('on')}
                        onMouseOut={()=>this.handleHoverOverContext('off')}
                        className={this.state.classNameContextDesc01}>
                    */}
                    <span
                        onClick={()=>{this.handleGoToContexts()}}>
                        <span
                            name='person-context' value='person-context' 
                            >
                            {this.props.dataForDisplay}{/*<span>{'.  '}</span>*/}
                        </span>
                        <span></span>{this.props.dataForDisplay02}{this.state.classNameContextDesc01}
                    </span>
                    <span>{'    '}</span>
                    <button
                        hidden={this.props.dataDynStatus !== 'complete'}
                        type="button" className="btn btn-link"
                        name='roadmap' value='roadmap' onClick={()=>{this.handleGoToRoadmap()}}>
                        <FontAwesome
                        name="chevron"
                        />
                        Roadmap
                    </button>
                    {/*experimented with button instead of link style of above*/}
                    <button
                        hidden={true}
                        type="button"
                        className="btn btn-outline-primary"
                        name='roadmap' value='roadmap' onClick={()=>{this.handleGoToRoadmap()}}>
                         <FontAwesome
                            name='map'
                            className='btn-space'
                            style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                            />
                    </button>
                    {/*>>>for creating new context. poss for futer, maybe "..." for more options*/}
                    <button
                        hidden={true}
                        type="button" className="btn btn-link"
                        name='new_context' value='new_context' onClick={()=>{this.handleCreateNewContext()}}>
                        <FontAwesome
                        name="chevron"
                        />
                        Create New Context for {this.props.pers_first_name} {this.props.pers_last_name}
                    </button>
                    {/*playing around with above*/}
                    <button
                        hidden={true}
                        type="button" 
                        className="btn btn-outline-primary"
                        name='new_context' value='new_context' onClick={()=>{this.handleCreateNewContext()}}>
                        <FontAwesome
                        name="plus"
                        className='btn-space'
                        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                        />
                    </button>
                    {/*playing around with above*/}
                    <button
                        hidden={true}
                        type="button"
                        className="btn btn-outline-secondary"
                        name='new_context' value='new_context' onClick={()=>{this.handleCreateNewContext()}}>
                        Add New Context for {this.props.pers_first_name}
                    </button>
                </td>
            </tr>
        )
    }
}

//export default TblDataRow


function mapStateToProps(state) {
    return {
          };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateUiConfig,
        saveOnBlurSingleField: saveOnBlurSingleField, 
        personDataGet: personDataGet, 
        personContextsDataGet: personContextsDataGet, 
        personsDataFilter: personsDataFilter,
        personContextModulesGet,
        personContextsTypeRefGet,
        genContextRecs,
        createNewPersonForUser }, dispatch);
  }
  
  //export default FormPersonRdx;
  export default connect(mapStateToProps, mapDispatchToProps)(PersonsTblDataRow);