import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Container, Card, CardHeader, CardBody} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { UI_CONFIG, saveOnBlurSingleField, updateRoadmapActive, updateContextRecsForAppState, updateUiConfig } from '../actions';
import PopupComponent from '../components/Popup'

class ContextRecAndAction001Unit extends Component {

    constructor (props){
        super(props);
        this.state = {
          new_data: false,
          update_single_field: {
            field_name: '',
            field_data: '',
            assoc_id: this.props.contextRecAndAction.cntnt__id,
            entity: ''
          },
          rec_backcolor_css_class: '',
          description_of_action: '',
          description_of_action_sign_image_file_name: 'logo_ca_20140316.jpg',
          description_of_how: '',
          elem_ref__descript_rec_02: '',
          recmd__rec_action_descript: '',
          recmd__descript_rec_01: '',
          recmd_content: this.props.contextRecAndAction.cntnt__recmd_content === null ? '' :  this.props.contextRecAndAction.cntnt__recmd_content,
          hide_explaination_level_elem: true,
          hide_explaination_level_001: true,
          hide_explaination_level_002: true,
          not_popped_explaination_popup_001: true, 
          not_popped_explaination_popup_002: true,
          action_content_txtarea_visible_unit_level_setting: false,
          roadmap_dispaly_config: 'position_one'
          /*
          type_exec: this.props.contextDynamicUnit['type_exec'],
          subj: this.props.contextDynamicUnit['subj'],
          obj: this.props.contextDynamicUnit['obj'],
          eg: this.props.contextDynamicUnit['eg'],
          rationale: this.props.contextDynamicUnit['rationale'],
          type_elem: this.props.contextDynamicUnit['type_elem']
          */
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);

      } //end constructor

componentDidMount() {

    //setting color + tweekending language of rec desc acton

    /*
    //20180703 az for future reference, language was:
    Stay away from engaging in: evaluation of good/bad and determining value for people and relationships
    Do not emphasize: the practical and pragmatic
    Communicate in the language of: concepts and/or abstract logic
    Deliver conclusion with: new ideas and paradigms

    20180703 new elem lang:
    stating in terms of what's good or bad
    the practical actions or logistics
    analytical thinking
    new ideas
    */

    this.props.updateRoadmapActive();
    let rec_backcolor_class = '';
    let description_of_action = '';
    let description_of_action_sign_image_file_name = '';
    description_of_action = this.props.contextRecAndAction['exec_ref__descript_rec_01'];
    switch (this.props.contextRecAndAction['exec_ref__descript_rec_01']) {
      case 'Avoid':
        //20180728 old: rec_backcolor_class = "recmd_basic_backcolor_stop";
        rec_backcolor_class = "danger" 
        description_of_action_sign_image_file_name = 'stop_sign.PNG';
        break;
      case 'Minimize':
        //20180728 old: rec_backcolor_class = "recmd_basic_backcolor_caution";
        rec_backcolor_class = "warning";
        description_of_action_sign_image_file_name = 'caution_sign.PNG';
        break;
      case 'If comes up, can challenge into rapport':  //from very low, order 0
      case 'If comes up, can go with into rapport':  //from very low, order 1
      case 'For rapport, structure overall communication around':
          //old: rec_backcolor_class = "recmd_basic_backcolor_present";
          rec_backcolor_class = "success";
          description_of_action_sign_image_file_name = 'go_sign.PNG';
          this.setState({
            action_content_txtarea_visible_unit_level_setting: true
          })
          break;
        case 'Conclude with':
        //20180728 old: rec_backcolor_class = "recmd_basic_backcolor_deliver";
        rec_backcolor_class = "primary";
        description_of_action_sign_image_file_name = 'delivery_sign.PNG';
        this.setState({
          action_content_txtarea_visible_unit_level_setting: true
        })
        break;
      default:
        break;
    }
    this.setState({rec_backcolor_css_class: rec_backcolor_class})
    this.setState({description_of_action: description_of_action})
    this.setState({description_of_action_sign_image_file_name});
    this.setState({elem_ref__descript_rec_02: this.props.contextRecAndAction['elem_ref__descript_rec_02']})
    this.setState({recmd__rec_action_descript: this.props.contextRecAndAction['recmd__rec_action_descript']})
    this.setState({recmd__descript_rec_01: this.props.contextRecAndAction['recmd__descript_rec_01']})
    //tweekend rec desc of the how
    let description_of_how = '';
    if (this.props.contextRecAndAction['elem_ref__descript_rec_01'].includes('logic')) {
      //az20190611 description_of_how = 'Conceptualizing analytical thinking';
      description_of_how = 'concepts and analysis';
    }
    if (this.props.contextRecAndAction['elem_ref__descript_rec_01'].includes('practical')) {
      //az20190611 description_of_how = 'Suggesting practical actions and logistics';
      description_of_how = 'practical actions and logistics';
    }
    if (this.props.contextRecAndAction['elem_ref__descript_rec_01'].includes('new ideas')) {
      //az20190611 description_of_how = 'Framing as new ideas';
      description_of_how = 'new ideas';
    }
    if (this.props.contextRecAndAction['elem_ref__descript_rec_01'].includes('evaluation')) {
      //az20190611 description_of_how = 'Stating in terms of good and bad';
      description_of_how = 'evaluation (good or bad)';
    }
    this.setState({description_of_how: description_of_how})
    //20200410 need to variabilize via data, now hard coded based on user grp, make guild buttons visible or not zzz
    if (this.props.rdcr_user_curr[0].user_grp_name === 'think tank 001') {
    //based on nav bar menu logic
    this.props.updateUiConfig(UI_CONFIG, "recmd_content_display_deeper_dive_buttons", true);
    }
  }

handleOnChange(e) {
  //console.log('e.target.value', e.target.value); 
  //console.log('z4', this.state.update_single_field.field_name);
  this.setState({
    new_data: true,
    update_single_field: {
      field_name: e.target.name,
      field_data: e.target.value,
      assoc_id: this.props.contextRecAndAction.cntnt__id,
      entity: 'expr_recmd_grp_item_content'
    },
    [e.target.name]: e.target.value
  })
    //console.log('from handleOnChange -- this.state.new_data', this.state.new_data)
    //console.log('from handleOnChange -- this.state.update_single_field', this.state.update_single_field)
}

handleOnBlur(){
  if (this.state.new_data === true){
    this.props.saveOnBlurSingleField(this.state.update_single_field);
    this.setState({
      new_data: false
    })
    if (this.state.update_single_field.field_name === 'recmd_content') { this.props.updateContextRecsForAppState(this.state.update_single_field) };
  }
}

handleExplainationVisibility(e){
  //issue with fontawesome icon and passing e.target.value -- fixed with e.currentTarget.value
  //making sure that only button has the onClick (not the icon as well)
  //https://stackoverflow.com/questions/38757280/button-with-fontawesome-child-makes-the-button-target-value-undefined
  let hide_t_or_f = !this.state[e.currentTarget.value]
    this.setState({
      [e.currentTarget.value]: hide_t_or_f
    })
  }

  handleExplainationPopup(){
    //from: http://minutemailer.github.io/react-popup/
    /*
    Popup.create({
      title: 'hello title',
      content: 'This popup uses the create method directly to get more control. This popup demonstrates custom buttons.'
    });
    */
    //alert('hello from alert')
    }

    handleUiVisible(uiDesc){
      switch (uiDesc) {
        case 'action_content_txtarea_visible_unit_level_setting':
          this.setState(
            {action_content_txtarea_visible_unit_level_setting: !this.state.action_content_txtarea_visible_unit_level_setting}
          )
          break;
    
        default:
            break;
      }
    }

    render(){

    return (
      <div hidden={(
        (this.props.rdcr_ui_config.recmd_roadmap_display_config === 'position_one' && (this.state.description_of_action === 'Avoid' || this.state.description_of_action === 'Minimize')) ||
                    (this.props.rdcr_ui_config.recmd_roadmap_display_config === 'position_two' && (this.state.description_of_action === 'For rapport, structure overall communication around' || this.state.description_of_action === 'Conclude with'))
                    )}>
      <hr/>
      <Container>
        <Card className={"border-" + this.state.rec_backcolor_css_class}>
          <CardHeader>
            <img style={{float: 'left'}} className="recmd_basic_action_image" src={require('../media/' + this.state.description_of_action_sign_image_file_name)}
              alt='action_sign' width="10%" height="10%"
            />
            <p className={"font-weight-bold text-" + this.state.rec_backcolor_css_class}>{this.state.description_of_action}
            </p>
          </CardHeader> 
          <CardBody>
          {this.props.rdcr_ui_config.recmd_content_visible_only === false ?
            <div>
              <div
                className='recmd_basic_action_text_div'
                onClick={() => this.handleUiVisible('action_content_txtarea_visible_unit_level_setting')}>
                <span className="recmd_basic_action_text">{this.state.description_of_how}</span>
                <span>  </span>  
                <span
                  hidden={this.props.rdcr_ui_config.recmd_content_visible === false ? true : false}
                  className='recmd_basic_action_text_not_bold_for_cancat'>{this.state.action_content_txtarea_visible_unit_level_setting ? '-' : '+'}</span> 
              </div>
          <button
            hidden={!this.props.rdcr_ui_config.recmd_content_display_deeper_dive_buttons}
            type='button'
            className="btn btn-outline-primary containerRecommendationBasic__button_more_desc"
            value='hide_explaination_level_elem' 
            onClick={(e) => this.handleExplainationVisibility(e)}
            >
            {this.state.hide_explaination_level_elem ? 
              <FontAwesome
              name='clipboard'
              className='btn-space'
              style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
              />
              : '...'}
          </button>
          <p
            className='containerRecommendationBasic__rec_text_more_desc_level_elem' 
            hidden={this.state.hide_explaination_level_elem || !this.props.rdcr_ui_config.recmd_content_display_deeper_dive_buttons}>
            {this.state.elem_ref__descript_rec_02}
          </p>
          <button
            hidden={!this.props.rdcr_ui_config.recmd_content_display_deeper_dive_buttons}
            type='button'
            className="btn btn-outline-primary containerRecommendationBasic__button_more_desc"
            value='hide_explaination_level_001' 
            onClick={(e) => this.handleExplainationVisibility(e)}
            >
            {this.state.hide_explaination_level_001 ?
            <FontAwesome
            name='map'
            className='btn-space'
            style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
            />
            : '...'}
          </button>
          <p
            className='containerRecommendationBasic__rec_text_more_desc_level_01' 
            hidden={this.state.hide_explaination_level_001 || !this.props.rdcr_ui_config.recmd_content_display_deeper_dive_buttons}>
            {this.state.recmd__rec_action_descript}
          </p>
          <button
            hidden={true /*took out this level for workshop, used to be:  {!this.props.rdcr_ui_config.recmd_content_display_deeper_dive_buttons} */}
            type='button'
            className="btn btn-outline-primary containerRecommendationBasic__button_more_desc"
            value='hide_explaination_level_002'
            onClick={(e) => this.handleExplainationVisibility(e)}>
            {this.state.hide_explaination_level_002 ? 
              <FontAwesome
              name='book-open'
              className='btn-space'
              style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
              /> : '...'}
          </button>
          
          <p
            className='containerRecommendationBasic__rec_text_more_desc_level_02'
            hidden={this.state.hide_explaination_level_002 || !this.props.rdcr_ui_config.recmd_content_display_deeper_dive_buttons}>
            {this.state.recmd__descript_rec_01}
          </p>
        </div>
        : ''}
        <textarea className="containerRecommendationBasic__recmdActionContentInput" 
          hidden={(this.props.rdcr_ui_config.recmd_content_visible && this.state.action_content_txtarea_visible_unit_level_setting) === false ? true : false}
          type="text"
          name='recmd_content'
          value={this.state.recmd_content}
          onChange={(e) => this.handleOnChange(e)} 
          onBlur={this.handleOnBlur}
          />
        <p hidden>{this.props.contextRecAndAction['recmd__descript_rec_01']}</p>
        <label hidden className="small_label">entry_dt_tm: {this.props.contextRecAndAction['entry_dt_tm']}</label>
        <label hidden className="small_label">id: {this.props.contextRecAndAction['id']}</label>
        <label hidden className="small_label">id_seq: {this.props.contextRecAndAction['id_seq']}</label>
        {this.state.not_popped_explaination_popup_001 ? null : <PopupComponent
                text='Close Me'
            />}
            </CardBody>
            </Card>
            </Container>
      </div>
      );
    }
}

//export default ContextRecAndActionUnit;

function mapStateToProps(state) {
  return {
    rdcr_ui_config: state.rdcr_ui_config,
    rdcr_user_curr: state.rdcr_user_curr,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    saveOnBlurSingleField: saveOnBlurSingleField,
    updateRoadmapActive: updateRoadmapActive,
    updateContextRecsForAppState,
    updateUiConfig}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextRecAndAction001Unit);