import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import { egAct,
          setUpUiConfig, 
          setUpUiConfigDynReset,
          setUpDefaults } from '../actions';
import { Link } from "react-router-dom";

class LoginUser extends Component{

    componentDidMount(){
      this.props.egAct();
      console.log('before setUpUiConfig() in LandingPage');
      this.props.setUpUiConfig();
      this.props.setUpUiConfigDynReset();
      this.props.setUpDefaults();
      if (this.props.location.pathname === '/GoToInterested') {
        this.props.history.push('/SignUpInterested');
      }
      if (this.props.location.pathname === '/GoToLogin') {
        this.props.history.push('/Login');
      }
      console.log('this.props.location', this.props.location)
    }

    handleOnSubmit(e, page_to_open){
      e.preventDefault();
      console.log('button clicked:', e.target.name)
      this.props.history.push(`/${page_to_open}`);
    }

    //https://codeburst.io/4-four-ways-to-style-react-components-ac6f323da822

    render(){

    return (
        <Container>
          <Card>
            <CardHeader hidden><p className='mt-3'>Persuade, influence, sell,<br/>Mentor, collaborate... lead.</p>
            </CardHeader>
        <CardBody>
          <Row className='mb-5 mt-3'>
            <Col>
                <button 
                  className="btn btn-primary"
                  type="button" 
                  name="Interested"
                  value="Interested" 
                  onClick={(e) => {this.handleOnSubmit(e, 'SignUpInterested')}}>
                  I'm interested in learning more
                  </button>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
                <p>This is the beta version of the application. If you have a user account, by logging in you agree to the Terms of Use and Privacy Policy below:</p>
                <div>
                            <Link to='/ToS' target="_blank" name='clicked_link_ToS_dtstamp_UTC' onClick={(e) => this.handleClickLink(e)}>
                                Terms of Service
                            </Link>
                        </div>
                    
                <div>
                    <Link to='/privacy-policy' target="_blank" name='clicked_link_PrivPol_dtstamp_UTC' onClick={(e) => this.handleClickLink(e)}>
                        Privacy Policy
                    </Link>
                </div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
                <button type="button" className="btn btn-primary"
                    name="Login" value="Login" onClick={(e) => {this.handleOnSubmit(e, 'Login')}}>
                    Log in (requires account)&nbsp;
                    </button>
            </Col>
          </Row>
          {/*
          <button type='button' className="btn btn-primary btn-primary-spacing" onClick={(e) => {this.handleOnSubmit(e, 'SignUpInterested')}}>I'm interested in learning more</button>
          <button type='button' className="btn btn-primary btn-primary-spacing" onClick={(e) => {this.handleOnSubmit(e, 'SignUpForIntroPres')}}>Sign up (requires invite code)</button>
          <button type='button' className="btn btn-primary btn-primary-spacing" onClick={(e) => {this.handleOnSubmit(e, 'Login')}}>Log in</button>
          */}
            {/* BUTTON TO GET TO SIGNING UP FOR: PRES, CLASSES, DEMOS, CONSULTING 
            <br/>
            <button type='button' className="btn btn-primary btn-primary-spacing" onClick={(e) => {this.handleOnSubmit(e, 'InfoSessionsClassesDemosConsultingSignup')}}>Info about Info Session, Demos, and Consulting</button>
            */}
        </CardBody>
        <CardFooter hidden>
          <p className='mt-3'>Persuade, influence, sell, mentor and collaborate...lead!</p>
        </CardFooter>
        </Card>
        </Container>
    );

    }
}

//export default LoginUser;

function mapStateToProps(state) {
  return {    
          eg_data: state.eg_data
          };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    egAct: egAct,
    setUpDefaults: setUpDefaults, 
    setUpUiConfig: setUpUiConfig,
    setUpUiConfigDynReset }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginUser);