import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { updateUiConfig,
            verifyUserNameDoesNotExist,
            verifyInviteCodeToRegisterAsUser, 
            userRegisterData, 
            userRegisterDataSave } from "../actions";

class InviteCodeValidation extends Component{

    constructor (props){
        super(props)
        this.state = {
            admin_reg_code: ''
        }
    }  //end of constructor

    handleOnChange(e) {
        this.setState(
            {[e.target.name]: e.target.value}, () => {
                console.log('this.state.[e.target.name]', this.state.admin_reg_code)
                }
        );
        //az? is this ok to do? change the reducer data directly?!?!?!?
        this.props.user_register_data[e.target.name] = e.target.value.replace(/'/g, "''");
        console.log('this.props.user_register_data', this.props.user_register_data);
    }

    handleVerifyRegInviteCode(){
        this.props.updateUiConfig('UI_CONFIG', 'RegNewUser__verify_invite_code_clicked', true, () => {
            this.props.verifyInviteCodeToRegisterAsUser(this.state.admin_reg_code)})
    }
    
    handleRenderInviteCodeMsg(){
        console.log('.............')
        let msg = '';
        if (this.props.rdcr_user_register_invite[0] !== undefined) {
            if (this.props.rdcr_user_register_invite[0].used_dt_tm === 'issue__invite_does_not_exist') {
                msg = "This invite code doesn't seem to exist."
            }
            if (this.props.rdcr_user_register_invite[0].used_dt_tm === 'issue__invite_has_already_been_used') {
                msg = "This invite code has already been used to register."
            }
            return  <div>
                        <label className='containerRegNewUser__ui_msg_label'>{msg}</label>
                    </div>
        }
    }

    render(){
        return (
            <div>
                <div>{this.props.user_register_data.username}
                </div>
                <div>
                <label>To register, please enter your invitation code...</label>
                </div>
                <div>
                <label className="label_leftish">Invitation Code:</label>
                <input type="text" name='admin_reg_code' value={this.state.admin_reg_code} onChange={(e) => this.handleOnChange(e)}/>  
                <input className="btn btn-primary btn-primary-spacing" type="button" value="Submit" onClick={() => this.handleVerifyRegInviteCode()} /> 
                <div>
                    {this.handleRenderInviteCodeMsg()}
                </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {    
        //ui_config: state.ui_config, DELCAND_20190518
        rdcr_user_register_invite: state.rdcr_user_register_invite,
        user_register_data: state.user_register_data,

        };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        updateUiConfig: updateUiConfig,
        verifyUserNameDoesNotExist: verifyUserNameDoesNotExist,
        verifyInviteCodeToRegisterAsUser: verifyInviteCodeToRegisterAsUser,
        userRegisterData: userRegisterData, 
        userRegisterDataSave: userRegisterDataSave}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(InviteCodeValidation);