import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { changePwUser,
        changeUserByAdmin,
        listUsersForAdmin } from "../actions";

class UserSettingChangePwAdmin extends Component{

    constructor (props){
        super(props);
    this.state = {
      admin_code: '1212',
      user_name_selected: ''
  };

    this.handleOnSubmit = this.handleOnSubmit.bind(this);

    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.handleBlur = this.handleBlur.bind(this);
}  //end constructor

componentDidMount(){
  this.props.listUsersForAdmin();
}

handleOnChangeUserSelect(e){
  console.log('e.target.value', e.target.value)
  //zzz user_name_selected
  this.setState({
    user_name_selected: e.target.value
  })
}

handleOnChangeUserNameFreeHand(e){
  console.log('e.target.value', e.target.value)
  //zzz user_name_selected
  this.setState({
    user_name_selected: e.target.value
  })
}
  
handleOnSubmit(e){
  e.preventDefault();
  switch (e.target.name){
    case 'viewAnotherUserData':
      console.log('e.target.user_name.value', e.target.user_name.value)
      this.props.changeUserByAdmin(e.target.user_name.value, this.props.history.goBack)
      break;
    case 'changePw':
      console.log('e.target.admin_code.value', e.target.admin_code.value)
      if (e.target.admin_code.value === this.state.admin_code) {
        console.log('from handleOnSublmit')
        this.props.changePwUser(e)
        //this.props.changePwUser(e, this.props.history.push('Login'))
        console.log('from handleOnSublmit end')
      }
      else {
        console.log('Does not match with admin code, tf cannot change pw.')
      }
      break;
    default:
      break;
  }
}

/**20180615 WIP ADMIND VIEWING ANOTHERS DATA */
handleIsAdminViewingOtherUserData(){
  if (this.props.rdcr_curr_user_admin.permission_type.search('admin') !== -1 &&
        this.props.rdcr_curr_user_admin.curr_viewing_data_of_another === true) {
        return true
  }
}

handleRenderUsersForSelect(){
  if (this.props.rdcr_users_for_admin[0] !== undefined) {
    console.log('from handleRenderUsersForSelect inside if');
    return this.props.rdcr_users_for_admin.map(item => {
      return <option key={item['id']} value={item['un']}>{item['name_display_in_grp'] + ' -- ' + item['un'] + ' -- ' + item['user_group_name'] }</option>
    })}
  }

render(){

return (
  <div>
    <div>
  <form name='viewAnotherUserData' onSubmit={this.handleOnSubmit}>
  <label>
      View People for: <input type="text" name='user_name' value={this.state.user_name_selected}
      onChange={(e)=>this.handleOnChangeUserNameFreeHand(e)}/>
    </label>
    <div>
                    <label htmlFor="un_select">User</label>
                    <select
                    className="form-control"
                    name='un_select' 
                    id='un_select' 
                    value={this.state.user_name_selected} 
                    onChange={(e) => this.handleOnChangeUserSelect(e)} 
                    onBlur={this.handleOnBlur}>
                    <option value=''>Select</option>
                      {this.handleRenderUsersForSelect()}
                  </select>
                  </div>
          
    <input type="submit" value="View user data" />
  </form>
  <hr/>
  </div>
  <div>
      <form name='changePw' onSubmit={this.handleOnSubmit}>
      <label>
          Admin Code: <input type="text" name='admin_code'/>
        </label>
          <label>
          Change Password for user: <input type="text" name='un' />
        </label>
        {/*
        <label>
          Current Password: <input type="text" name='curr_pw' value='not working yet' />
        </label>
        */}
        <label>
          New Password: <input type="password" name='pw'/>
        </label>
        {/*
        <label>
          Confirm New Password: <input type="password" name='pw_confirm' value='not working yet'/>
        </label>
        */}
        <input type="submit" value="Submit" />
      </form>
      </div>
      <button type="button" className="btn btn-default btn-primary-spacing " name='goBack' value='goBack' onClick={() => this.props.history.goBack()}>Back</button>
      </div>
      );
    }
}

//export default UserSettingChangePw;

function mapStateToProps(state) {
  return {    
          rdcr_users_for_admin: state.rdcr_users_for_admin,
          rdcr_user_list_for_admin: state.rdcr_user_list_for_admin
      };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    changePwUser: changePwUser,
    listUsersForAdmin: listUsersForAdmin,
    changeUserByAdmin: changeUserByAdmin}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingChangePwAdmin);