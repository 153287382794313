import {DYN_AREA_REF_FILTERED_DATA_PERSONAL__GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case DYN_AREA_REF_FILTERED_DATA_PERSONAL__GET:
      //console.log('rdcr_dyn_area_ref_pers_filtered -- DYN_AREA_REF_FILTERED_DATA_PERSONAL__GET action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
