import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import EgData from "./reducer_eg_data";
import rdcr_ui_config from "./reducer_ui_config";
import reducer_ui_config_dyn_refine from "./reducer_ui_config_dyn_refine";
import rdcr_signups_for_more_info from "./reducer_signups_for_more_info";
import rdcr_user_events_offering from "./reducer_user_events_offering";
import rdcr_user_events_selected_pre_save from "./reducer_user_events_selected_pre_save"
import rdcr_user_events_selected from "./reducer_user_events_selected"
import rdcr_user_register_invite from "./reducer_user_register_invite";
import rdcr_user_register from "./reducer_user_register";
import reducer_users_for_admin from "./reducer_users_for_admin";
import reducer_usage_grps_for_admin from "./reducer_usage_grps_for_admin";
import UserCurr from "./reducer_user_curr";
import UserCurrStatus from "./reducer_user_curr_status";
import rdcr_curr_user_admin from "./reducer_user_curr_admin";
import rdcr_user_list_for_admin from "./reducer_user_list_for_admin";
import rdcr_user_usage_grp_user from "./reducer_user_usage_grp_user";
import PersonsReducer from "./reducer_persons";
import rdcr_user_contexts_list from "./reducer_contexts_list";
//delcand_20190731: import rdcr_persons_srch_crit from "./reducer_persons_srch_crit";
import rdcr_persons_filtered from "./reducer_persons_filtered";
//delcand_20190731: import rdcr_contexts_srch_crit from "./reducer_contexts_srch_crit";                            //new 20190730
import rdcr_contexts_filtered from "./reducer_contexts_filtered";   //new 20190730
import reducer_active_person from "./reducer_active_person"
import reducer_active_person_contexts from "./reducer_active_person_contexts"
import reducer_active_person_contexts_sub from "./reducer_active_person_contexts_sub";
import reducer_active_person_contexts_selected from "./reducer_active_person_contexts_selected";
import reducer_active_person_contexts_for_display from "./reducer_active_person_contexts_for_display";
import rdcr_persons_context_desc_ref from "./reducer_persons_context_desc_ref"
import rdcr_persons_context_desc_ref_pers from "./reducer_persons_context_desc_ref_pers"
import rdcr_persons_context_desc_ref_prof from "./reducer_persons_context_desc_ref_prof"
import rdcr_persons_context_mode_ref from "./reducer_persons_context_mode_ref"
import rdcr_pers_context_modules from "./reducer_pers_context_modules"
import rdcr_pers_context_type_ref from "./reducer_pers_context_type_ref"
import rdcr_pers_context_char_ref from "./reducer_pers_context_char_ref"
import reducer_context_active from "./reducer_context_active"
import reducer_context_dyns from "./reducer_context_dyns"
import reducer_context_dyns_prev from "./reducer_context_dyns_prev"
import reducer_context_dyns_ui_DELCAND_20181015 from "./reducer_context_dyns_ui_DELCAND_20181015"
import rdcr_context_success_ref from "./reducer_context_success_ref"
import rdcr_context_cat_ref from "./reducer_context_cat_ref"
import reducer_dyn_exec_ref from "./reducer_dyn_exec_ref"
import reducer_dyn_elem_ref from "./reducer_dyn_elem_ref"
import reducer_dyn_area_ref from "./reducer_dyn_area_ref"
import reducer_dyn_area_ref_filtered from "./reducer_dyn_area_ref_filtered"
import reducer_dyn_area_ref_prof from "./reducer_dyn_area_ref_prof"
import reducer_dyn_area_ref_prof_filtered from "./reducer_dyn_area_ref_prof_filtered"
import reducer_dyn_area_ref_pers from "./reducer_dyn_area_ref_pers"
import reducer_dyn_area_ref_pers_filtered from "./reducer_dyn_area_ref_pers_filtered"
import reducer_dyn_obs_target_ref from "./reducer_dyn_obs_target_ref"
import reducer_context_recs from "./reducer_context_recs"
import reducer_context_recs_changed from "./reducer_context_recs_changed"
import rdcr_public_more_info_interest from "./reducer_public_more_info_interest"
import rdcr_dataset from "./reducer_dataset"
import rdcr_single_field_data from "./reducer_single_field_data"
import rdcr_roadmap_active from "./reducer_roadmap_active"

const rootReducer = combineReducers({
  form: formReducer,
  eg_data: EgData,
  rdcr_ui_config,
  rdcr_ui_config_dyn_refine :reducer_ui_config_dyn_refine,
  rdcr_signups_for_more_info: rdcr_signups_for_more_info,
  rdcr_users_for_admin: reducer_users_for_admin,
  rdcr_usage_grps_for_admin: reducer_usage_grps_for_admin,
  rdcr_user_events_offering: rdcr_user_events_offering,
  rdcr_user_events_selected_pre_save: rdcr_user_events_selected_pre_save,
  rdcr_user_events_selected: rdcr_user_events_selected,
  rdcr_user_register_invite,
  rdcr_user_register,
  rdcr_user_curr: UserCurr,
  DELCAND_NOT_USED_20190521_user_curr_status: UserCurrStatus,
  rdcr_curr_user_admin: rdcr_curr_user_admin,
  rdcr_user_list_for_admin: rdcr_user_list_for_admin,
  rdcr_user_usage_grp_user,
  rdcr_persons: PersonsReducer,
  rdcr_user_contexts_list,
  //delcand_20190731: rdcr_persons_srch_crit,
  rdcr_persons_filtered,
  rdcr_person_active: reducer_active_person,
  rdcr_person_active_contexts: reducer_active_person_contexts,
  //delcand_20190731: rdcr_contexts_srch_crit,
  rdcr_contexts_filtered,
  rdcr_active_person_contexts_sub: reducer_active_person_contexts_sub,
  rdcr_active_person_contexts_selected: reducer_active_person_contexts_selected,
  rdcr_active_person_contexts_for_display: reducer_active_person_contexts_for_display,
  rdcr_persons_context_desc_ref,
  rdcr_persons_context_desc_ref_pers,
  rdcr_persons_context_desc_ref_prof,
  rdcr_persons_context_mode_ref,
  rdcr_pers_context_modules,
  rdcr_pers_context_type_ref: rdcr_pers_context_type_ref,
  rdcr_pers_context_char_ref: rdcr_pers_context_char_ref,
  rdcr_context_active: reducer_context_active,
  rdcr_context_dyns: reducer_context_dyns,
  DELCAND_NOT_USED_20190521_context_dyns_prev: reducer_context_dyns_prev,
  rdcr_context_dyns_ui: reducer_context_dyns_ui_DELCAND_20181015,
  rdcr_context_success_ref,
  rdcr_context_cat_ref,
  rdcr_dyn_exec_ref: reducer_dyn_exec_ref,
  rdcr_dyn_elem_ref: reducer_dyn_elem_ref,
  rdcr_dyn_area_ref: reducer_dyn_area_ref,
  rdcr_dyn_area_ref_filtered: reducer_dyn_area_ref_filtered,
  rdcr_dyn_area_ref_prof: reducer_dyn_area_ref_prof,
  rdcr_dyn_area_ref_prof_filtered: reducer_dyn_area_ref_prof_filtered,
  rdcr_dyn_area_ref_pers: reducer_dyn_area_ref_pers,
  rdcr_dyn_area_ref_pers_filtered: reducer_dyn_area_ref_pers_filtered,
  rdcr_dyn_obs_target_ref: reducer_dyn_obs_target_ref,
  rdcr_context_recs: reducer_context_recs,
  rdcr_context_recs_changed: reducer_context_recs_changed,
  rdcr_public_more_info_interest: rdcr_public_more_info_interest,
  rdcr_dataset,
  rdcr_single_field_data,
  rdcr_roadmap_active  
});

export default rootReducer;