import React from 'react';
//import App from './App';

//import LoginUser from "./LoginUser";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//import { BrowserRouter as Router, Route, browserHistory, Switch } from 'react-router-dom';
//import logo from '../Complex-Abillity_H_Black.jpg'
import Logo from '../media/logo_app.png'
import ResponseCard from '../containers/ResponseCard'
import LandingPage from "../containers/LandingPage";
import SignUpInterested from "../containers/SignUpInterested";
import RegNewUserWoAccnt from "../containers/RegNewUserWoAccnt";
import SignUpForIntroPres from "../containers/SignUpForIntroPres";
import SignUpForIntroPresL from "../containers/SignUpForIntroPresL";
import Login from '../containers/LoginUser';
import RegNewUser from '../containers/RegNewUser';
import UserSettingsAdmin from '../containers/UserSettingsAdmin';
import UserSettingsBasics from '../containers/UserSettingsBasics';
import UserHome from '../containers/UserHome';
import UserProgress from '../containers/UserProgress';
import UserTraining from '../containers/UserTraining';
import NewPersonOrContext from "../containers/NewPersonOrContext"; 
import Persons from "../containers/Persons";
import PersonDetails from "../containers/PersonDetails";
import PersonContexts from "../containers/PersonContexts";
import ContextDynGrp from "../containers/ContextDynGrp";
import ContextRecsAndActions from "../containers/ContextRecAndAction001Grp";
import ContextRecAndAction002CpyPst from "../containers/ContextRecAndAction002CpyPst"

//import { browserHistory } from 'react-router';
// http://stackoverflow.com/questions/41023648/routes-on-react-typeerror-cannot-read-property-getcurrentlocation-of-undefin

//OLD: <h3>Relways&trade; application for the ComplexAbility&reg; approach</h3>

const AppRouter = () => {
  return (
    <div>
      <div className="App-header"> 
        <img src={Logo} className="App-logo-static" alt="logo"/>
        <h5 hidden>Relways</h5>
      </div>
    <Router history={Router}>
      <div>
      <Switch>
        {/*<Route path="/" component={App} />*/}
        <Route path='/SignUpInterested' component={SignUpInterested} />
        <Route path='/Status' component={ResponseCard} />
        <Route path='/RegisterForInfo' component={RegNewUserWoAccnt} />
        <Route path='/SignUpForIntroPres' component={SignUpForIntroPres} />
        <Route path='/SignUpForIntroPresL' component={SignUpForIntroPresL} />
        <Route path='/Register' component={RegNewUser} />
        <Route path='/Login' component={Login} />
        <Route path='/ToS' component={() => {window.location = 'https://www.complexability.com/s/DataGrasp-ToU-4418_nk_20180917az-blcw.pdf'; return null}}/>
        <Route path='/privacy-policy' component={() => window.location = 'https://www.complexability.com/s/DataGrasp-PP-4218_nk_20180917az.pdf'}/>
        <Route path='/cai_web_page_mailchimp_signup_form_for_more_info' component={() => window.location = 'https://complexability.com/cai_mc_subscribe'}/>
        <Route path='/cai_web_page_signup_for_intro_presentation' component={() => window.location = 'https://www.complexability.com/free-intro-presentation-signup'}/>
        <Route path='/UserSettingsAdmin' component={UserSettingsAdmin} />
        <Route path='/UserSettingsBasics' component={UserSettingsBasics} />
        <Route path='/UserHome' component={UserHome} />
        <Route path='/PersonDetails' component={PersonDetails} />
        <Route path='/People' component={Persons} />
        <Route path='/NewPersonOrContext' component={NewPersonOrContext} />
        <Route path='/UserProgress' component={UserProgress} />
        <Route path='/UserTraining' component={UserTraining} />
        <Route path='/PeopleContexts' component={PersonContexts} />
        <Route path='/DynGrp' component={ContextDynGrp} />
        <Route path='/ContextRecsAndActions' component={ContextRecsAndActions} />
        <Route path='/ContextRecAndAction002CpyPst' component={ContextRecAndAction002CpyPst} />
        <Route path='/GoToInterested' component={LandingPage} />
        <Route path='/GoToLogin' component={LandingPage} />
        <Route path='/' component={Login} />
        </Switch>
      </div>
    </Router>
    </div>
  );
}

export default AppRouter;
