//20180603 WIP
//NOT YET ACCESSABE FROM ANYWHERE, placed in to router as: '/InfoSessionsClassesDemosConsultingSignup'

import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import FontAwesome from 'react-fontawesome';
import { Container, Row, Col, Form, FormGroup, Card, CardHeader, CardBody, CardFooter, Label, Input } from 'reactstrap';
import { userEventsToChooseFrom, userEventsSelectedPreSave, app_activity_various } from "../actions";
import RegNewUserWoAccnt from './RegNewUserWoAccnt';

class SignUpInterested extends Component{

  constructor (props){
      super(props);
      this.state = {
        isHiddenSelectInterestEvents: true,
        isHiddenRegisterAsUserWoAccount: false,
        msgInterestedInCardHeader: "Let us know what you're interested in and we will be in touch!",
        msgUiIssue: '',
        events_offered: []
      }
      //this.props.userEventsToChooseFrom();
      //this.props.userEventsSelectedPreSave([]);
      this.selectedCheckboxes = new Set();

  this.handleToggleBtForms = this.handleToggleBtForms.bind(this);
  //this.handleRenderIntrPresRadioOptions = handleRenderIntrPresRadioOptions.bind(this);
  }

  componentDidMount(){
    this.props.userEventsToChooseFrom();
    this.props.userEventsSelectedPreSave([]);
    this.selectedCheckboxes = new Set();
    this.props.app_activity_various();
  }

handleRenderInterestedInCheckbox(interestedInEventDesc){
  console.log('this.props.rdcr_user_events_offering', this.props.rdcr_user_events_offering)
  console.log('this.props.rdcr_user_events_offering.isArray', Array.isArray(this.props.rdcr_user_events_offering));
  if (Array.isArray(this.props.rdcr_user_events_offering)) {
  return this.props.rdcr_user_events_offering.map(i => {
    if (i.event_type_desc === interestedInEventDesc) {
      console.log('A MATCH')
      console.log('i.event_type_desc', i.event_type_desc)
      console.log('i.id', i.id)
      return <Input type='checkbox' value={i.id} key={i.id}
        onClick={(e) => this.handleOnClickCheckbox(e)}/>
    }
  })
}
}


handleToggleBtForms(){
  this.setState({
    isHiddenSelectInterestEvents: !this.state.isHiddenSelectInterestEvents,
    isHiddenRegisterAsUserWoAccount: !this.state.isHiddenRegisterAsUserWoAccount,
    msgInterestedInCardHeader: "I'm interested in:"
})
}

handleOnClickCheckbox(e){
  //http://react.tips/checkboxes-in-react/
    if (this.selectedCheckboxes.has(e.target.value)) {
      this.selectedCheckboxes.delete(e.target.value);
    } else {
      this.selectedCheckboxes.add(e.target.value);
    }
    console.log('this.selectedCheckboxes', this.selectedCheckboxes)
  }
  
  handleOnSubmit(e){
        e.preventDefault();
        console.log(e.target);
        console.log('this.selectedCheckboxes.size', this.selectedCheckboxes.size);
        if (this.selectedCheckboxes.size > 0) {
          this.setState({
            msgUiIssue: ''
          })
          //https://stackoverflow.com/questions/20069828/how-to-convert-set-to-array
          const eventsOfInterest = [...this.selectedCheckboxes]
          this.props.userEventsSelectedPreSave(eventsOfInterest)
          this.handleToggleBtForms();
          //this.props.history.push("/RegisterForInfo")
        }
        else {
          this.setState({
            msgUiIssue: 'Please check at least one area of interest.'
          })
        }
      }
       
    render(){
        if (this.props.rdcr_user_events_offering === undefined) { // or !this.props.data.length if your have an empty array initially
            return <div>Loading...</div>; 
        }
        else {
        return (
          <div>
          <div hidden={this.state.isHiddenRegisterAsUserWoAccount}>
            <RegNewUserWoAccnt
              handleToggleBtForms={this.handleToggleBtForms}
              history={this.props.history}/>
          </div>
          <Container hidden={this.state.isHiddenSelectInterestEvents}>
              <Form onSubmit={this.handleOnSubmit.bind(this)}>
          <Card>
            <CardHeader>
              {this.state.msgInterestedInCardHeader ? this.state.msgInterestedInCardHeader : ''}
              {this.state.msgUiIssue === '' ? '' : <p className='mt-2 text-danger'>{this.state.msgUiIssue}</p>}
              </CardHeader>
            <CardBody>
              <FormGroup row>
                  <Col>
                    <p>For individual professionals:</p> 
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col className ='ml-5'>
                  {this.handleRenderInterestedInCheckbox("interest in intro presentation")}
                  <Label>A FREE ONLINE intro presentation</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col className ='ml-5'>
                  {this.handleRenderInterestedInCheckbox("interest in training workshop and application")}
                    <Label>Training workshops and starting to use Relways&trade; technology</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col className ='ml-5'>
                  {this.handleRenderInterestedInCheckbox("interest in one-on-one consulting/coaching using the method and technology")}
                    <Label>Scheduling a consulting/coaching session using the method and technology (first 1/2 hour free to explore fit)</Label>
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Col>
                    <p>For companies, organizations, and groups:</p>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col className ='ml-5'>
                    {this.handleRenderInterestedInCheckbox("interest in organization/company demo of method and technology")}
                    <Label>Getting a demo</Label>
                  </Col>
                </FormGroup>
            </CardBody>
            <CardFooter>
              <FormGroup row>
                  <Col className="col-auto ml-auto">
                      <button className="btn btn-link" type="submit" value="Sign up to get info" name="submit"
                      >
                      next
                      <FontAwesome
                        name="chevron-right"
                      />
                      </button>
                  </Col>
              </FormGroup>
              <FormGroup hidden row>
                  <Col>
                      <button className="btn btn-primary" type="submit" value="Sign up to get info" onClick={() => {}}>Sign up for persentation/workshop/application</button>
                      <p>(If you already have an invite code and want to register for a presentation/workshop and get access to the application or consulting)</p>
                  </Col>
              </FormGroup>
            </CardFooter>
          </Card>
          </Form>  
        </Container>
        </div>
        )
      }          
    }
}


//export default LoginUser;

function mapStateToProps(state) {
  return { 
    rdcr_user_events_offering: state.rdcr_user_events_offering
          };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    userEventsToChooseFrom: userEventsToChooseFrom,
    userEventsSelectedPreSave: userEventsSelectedPreSave,
    app_activity_various
 }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpInterested);