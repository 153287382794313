//20180603 WIP
//NOT YET ACCESSABE FROM ANYWHERE, placed in to router as: '/InfoSessionsClassesDemosConsultingSignup'

import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Container, Row, Col, Form, FormGroup, Card, CardHeader, CardBody, CardFooter, Label, Input } from 'reactstrap';

class ResponseCard extends Component{

  constructor (props){
      super(props);
      this.state = {
      }

  }

  componentDidMount(){
  }

    render(){
        return(
          <Container>
          <Card>
            <CardHeader hidden>
              </CardHeader>
            <CardBody>
              <FormGroup>
                  <h3 className='py-3 mx-auto'>{this.props.rdcr_ui_config.msg_response_gen_01}</h3> 
              </FormGroup>
              <FormGroup>
                  <p>{this.props.rdcr_ui_config.msg_response_gen_02}</p> 
              </FormGroup>
            </CardBody>
            <CardFooter hidden>
            </CardFooter>
          </Card>
        </Container>
        )
      }          
}

//export default LoginUser;

function mapStateToProps(state) {
  return { 
    rdcr_ui_config: state.rdcr_ui_config
          };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
 }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseCard);