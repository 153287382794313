//20180603 WIP
//NOT YET ACCESSABE FROM ANYWHERE, placed in to router as: '/InfoSessionsClassesDemosConsultingSignup'

import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { setUpDefaults,
          setUpUiConfig,
          userEventsToChooseFrom } from '../actions';

//class SignUpForClassDemoConsulting extends Component{
class SignUpForIntroPres extends Component{

    componentDidMount(){
      //https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
      /* Object keys test used when hitting page first:
      if (Object.keys(this.props.rdcr_user_curr).length === 0 && this.props.rdcr_user_curr.constructor === Object)
      */
     if (this.props.rdcr_user_curr[0].user_id === '') {
      console.log('this.props.rdcr_user_curr', this.props.rdcr_user_curr)
      this.props.setUpDefaults();
      this.props.setUpUiConfig()
      this.props.userEventsToChooseFrom(() => this.props.history.push('/SignUpForIntroPresL'))
      //this.props.history.push('/SignUpForIntroPres');
    }
  }
    handleRender(){
      /* isArray test used when hitting page first:
      if (Array.isArray(this.props.rdcr_user_curr) &&
              Array.isArray(this.props.rdcr_user_events_offering)
            ) {  //if array that means defaults in reducer (called by ComponentWillMount, otherwise its empty object
      */
      if (this.props.rdcr_user_curr[0].user_id !== '') {
        // /*
        return <div>
            <label>
              Please click your browser's refresh button to reload...
            </label>
            </div>
        // */
      }
      else {
        return <div>
            <label>
              Loading info presentation dates...
            </label>
            </div>
      } 
    }
    
    render(){

    return (
      <div className='containerSignUpForIntroPres'>
        {this.handleRender()}
      </div>
    );

    }
}

//export default LoginUser;

function mapStateToProps(state) {
  return {    
              rdcr_user_curr: state.rdcr_user_curr,
              rdcr_user_events_offering: state.rdcr_user_events_offering
          };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    setUpDefaults: setUpDefaults,
    setUpUiConfig,
    userEventsToChooseFrom }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForIntroPres);