//Persons_rdx
import _ from "lodash";
import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Container, Row, Col, Label } from 'reactstrap';
import {
    UI_CONFIG,
    updateUiConfig,
    personContextDescRefGet,
    personContextModulesGet,
    personContextsTypeRefGet,
    personContextModeRefGet,
    personsDataGet,
    userUsageGrpUserDataGet,
} from "../actions";

class UserHome extends Component{

constructor (props){
    super(props);
    this.state = {
        //person_fn_filter: this.props.rdcr_persons_srch_crit.first_name_crit,
        //person_ln_filter: this.props.rdcr_persons_srch_crit.last_name_crit,
        sort_fn_curr: '',
        sort_ln_curr: '',
        isAdmin: false,
        loading_msg: '',
        descript_of_list: 'ComplexAbility',
        viewEdMaterialTf: false,
    }
}
//*/

//good example for selector(dropdown) setting default: https://stackoverflow.com/questions/21733847/react-jsx-selecting-selected-on-selected-select-option


componentDidMount() {
    console.log('Persons.js componentDidMount');
    console.log('in Persons.js: this.props.rdcr_user_curr[0].user_id', this.props.rdcr_user_curr[0].user_id);
    //20190628 !!!!NEEDS TO BE MANGED BETTER. Currently retrieveing BOTH datasets from DB every time getting back to page!!!
    if (this.props.rdcr_user_curr[0].user_id !== undefined && this.props.rdcr_user_curr[0].user_id !== '') { //20200425az added if bc react hot load was sending null id to db and db crashed -- this fixed
        this.props.personsDataGet(this.props.rdcr_user_curr[0].user_id);
    };
    window.scrollTo(0,0);
    //get data for down the line:
    this.props.personContextDescRefGet('Personal')
    this.props.personContextModulesGet();
    this.props.personContextsTypeRefGet();
    this.props.personContextModeRefGet();
    console.log('in Persons componentDidMount this.props.rdcr_user_curr::', this.props.rdcr_user_curr)
    console.log('in Persons componentDidMount this.props.rdcr_user_curr[0]::', this.props.rdcr_user_curr[0])
    console.log('in Persons componentDidMount this.props.rdcr_user_curr[0].actions_allowed::', this.props.rdcr_user_curr[0].actions_allowed)
}

  handleIsAdmin(){
      if (
        this.props.rdcr_curr_user_admin.permission_type.search('admin') !== -1 ||
        this.props.rdcr_curr_user_admin.permission_type.search('trainer') !== -1
        ) {
            return true
      }
  }

//for UserLanding:

handleOpenNewContextOrPerson(e){
    this.props.updateUiConfig(UI_CONFIG, 'process_roadmap_mode', e.target.name)
    this.props.history.push('/NewPersonOrContext')
}

render(){

    if //(!this.props.rdcr_persons) {  //20180504 pre full redux
    (!this.props.rdcr_user_curr[0].user_id) {
    return <div>Loading...</div>;
    }

    return (
        <div>
            <Container className='containerLogin'>
                <Row>
                    <Col sm={12} className='m-auto justify-content-md-center'>
                        <label className='text_bold userAppNameDisplay'>ComplexAbility® Leadership</label>
                    </Col>
                </Row>
                <Row> {/*noGutters not working like this*/}
                    <Col sm={6} className='m-auto' hidden='true'>
                            <label className='userTypeDisplay'>
                                {this.handleIsAdmin() ? this.props.rdcr_curr_user_admin.permission_type_display + 
                                    ((this.props.rdcr_curr_user_admin.user_whose_data_being_viewed !== undefined) ? ' viewing ' 
                                        + this.props.rdcr_curr_user_admin.user_whose_data_being_viewed_display_name_in_grp + '\'s dynamic contexts info' : '') 
                                        : ''}
                            </label>
                            <label className='userAppNameDisplay'>{this.props.rdcr_user_curr[0].name_display_in_app}'s {this.state.descript_of_list}</label>
                    </Col>
                    <Col sm={6} className='m-auto'>
                            <div  hidden={!this.props.rdcr_ui_config.isAdmin} className='userTypeDisplayShort'>
                                <label className='label_no_margins'>
                                    {this.props.rdcr_ui_config.isAdmin ? 'Logged in as Admin' : ''}
                                </label>
                            </div>
                    </Col>
                    <Col sm={4} className='m-auto'>
                        <button
                            className='btn btn-outline-secondary btn-sm btn-space'
                            type="button"
                            name='userSettingsUser'
                            value='User SettingsUser'
                            onClick={() => this.props.history.push('/UserSettingsBasics')}>
                            ...
                        </button>
                    <button
                            hidden={!this.handleIsAdmin()}
                            type="button"
                            className="btn btn-primary btn-sm button_margin_right"
                            name='userSettingsAdmin'
                            value='User SettingsAdmin'
                            onClick={() => this.props.history.push('/UserSettingsAdmin')}>
                            ...
                        </button>
                    </Col>
                    <Row>
                    <Col sm={12} className='m-auto'>
                            <label className='userAppNameDisplay'>
                                {this.handleIsAdmin() ? ((this.props.rdcr_curr_user_admin.user_whose_data_being_viewed !== undefined) ? ' viewing ' 
                                        + this.props.rdcr_curr_user_admin.user_whose_data_being_viewed_display_name_in_grp + '\'s ComplexAbility' : '') 
                                        : this.props.rdcr_user_curr[0].name_display_in_app + '\'s ' + this.state.descript_of_list}
                            </label>
                    </Col>
                    </Row>
                    </Row>
                    <Row>
                    <Col sm={12} className='m-auto'>
                        <Label>Create Roadmap for:</Label>
                    </Col>
                    <Col sm={12} className='m-auto'>
                        <button hidden={false}
                            className={'btn btn-primary userLanding_main_buttons'}
                            name='new_communication'  //name='rapid_roadmap'
                            onClick={(e) => this.handleOpenNewContextOrPerson(e)}
                            >Communication
                        </button>
                        <button hidden={false}
                            className={'btn btn-primary userLanding_main_all_buttons'}
                            name='view_all_communications'  //name='rapid_roadmap'
                            onClick={() => this.props.updateUiConfig(UI_CONFIG, 'typeOfBaseListDisplay', 'date', () => this.props.history.push('/People'))}
                            >View All
                        </button>
                        <hr></hr>
                        <div>  
                        <button disabled={true}
                            className={'btn btn-primary userLanding_main_buttons'}
                                name='new_task'  //name='rapid_roadmap'
                                onClick={(e) => this.handleOpenNewContextOrPerson(e)}
                                >Action
                            </button>
                            <button disabled={true}
                                className={'btn btn-primary userLanding_main_all_buttons'}
                                name='view_all_comm_n_actions'  //name='rapid_roadmap'
                                onClick={() => this.props.updateUiConfig(UI_CONFIG, 'typeOfBaseListDisplay', 'date', () => this.props.history.push('/People'))}
                                >View All
                            </button>
                        </div>
                        <hr></hr>
                    </Col>
                    <Col hidden={true} 
                        sm={12} className='m-auto'>
                        <button hidden={false}
                            className={'btn btn-primary userLanding_main_buttons'}
                            name='power_roadmap'
                            onClick={(e) => this.handleOpenNewContextOrPerson(e)}
                            >Power Roadmap
                        </button>
                        <hr></hr>
                    </Col>
            <div hidden={true}>
                    <Col sm={12} className='m-auto'>
                        <Label>View:</Label>
                    </Col>  
                    <Col sm={12} className='m-auto'>
                        <button hidden={false}
                            className={'btn btn-primary userLanding_main_wide_buttons'}
                            onClick={() => this.props.updateUiConfig(UI_CONFIG, 'typeOfBaseListDisplay', 'name', () => this.props.history.push('/People'))}>
                            Actions & Communications
                        </button>
                        <hr></hr>
                    </Col>  
                    <br></br>
                    <br></br>
            </div>
                    <br></br>
                    <Col sm={12} className='m-auto'>
                        <Label>Workshop Training:</Label>
                    </Col>  
                    <Col sm={12} className='m-auto'>
                        <button
                            hidden={false}
                            disabled={true}
                            className={'btn btn-primary userLanding_main_wide_buttons'}
                            onClick={() => this.props.userUsageGrpUserDataGet(this.props.rdcr_user_curr[0].user_id, () => {this.props.history.push('/UserTraining')})}>
                                Principles and Takeaways
                        </button>
                        <hr></hr>
                    </Col>
                    <Col sm={12} className='m-auto'>
                        <button
                            hidden={true}
                            className={'btn btn-primary userLanding_main_wide_buttons'}
                            onClick={() => this.props.userUsageGrpUserDataGet(this.props.rdcr_user_curr[0].user_id, () => {this.props.history.push('/UserProgress')})}>
                                My Goals
                        </button>
                    </Col>
                    </Row>
                    <Row hidden={this.state.loading_msg === '' ? true : false}>
                        <Col sm={12} md={6} className='m-auto'>
                            {this.state.loading_msg}
                            <br></br>
                        </Col>
                    </Row>
                
            </Container>
        </div>
    );
    }
}

function mapStateToProps(state) {
    return {    
                rdcr_ui_config: state.rdcr_ui_config,
                rdcr_user_curr: state.rdcr_user_curr,
                rdcr_curr_user_admin: state.rdcr_curr_user_admin,
            };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        updateUiConfig,
        personContextDescRefGet,
        personContextModulesGet,
        personContextsTypeRefGet,
        personContextModeRefGet,
        personsDataGet,
        userUsageGrpUserDataGet}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(UserHome);