import {EVENTS_SELECTED_BY_USER} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case EVENTS_SELECTED_BY_USER:
      //console.log('rdcr_user_events_selected -- EVENTS_SELECTED_BY_USER -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
