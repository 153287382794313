import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {setUpUiConfig, setUpUiConfigDynReset, setUpDefaults} from '../actions'

import AppRouter from '../components/AppRouter';

class App extends React.Component {

    constructor (props){
        super(props);
        console.log('App constructure')
        this.state = {
            DidMountCounter: 0
            }
    }

    componentDidMount(){
        //from prev LandingPage
        //console.log('DidMountCounter', this.state.DidMountCounter)
        this.setState({
            DidMountCounter: 1},
            () => console.log('DidMountCounter', this.state.DidMountCounter)
        );
        //console.log('componentDidMount in Appz');
        //console.log('componentDidMount in App hello');
        this.props.setUpUiConfig();
        this.props.setUpUiConfigDynReset();
        this.props.setUpDefaults();
    }

    render() {
        return(
            //makes sure that the action setUpDefaults ran with default rdcr_user_curr for page LoginUser to use (otherwise err) 
            this.props.rdcr_user_curr[0] === undefined ? 
                <div>Processing...</div> :
                <AppRouter />
            )
    }
}

function mapStateToProps(state) {
    return {
        rdcr_user_curr: state.rdcr_user_curr    
            };
  }

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
            setUpUiConfig,
            setUpUiConfigDynReset,
            setUpDefaults}, dispatch);
    }
    
export default connect(mapStateToProps, mapDispatchToProps)(App);
