import {CONTEXT_SUCCESS_REF_DATA__GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case CONTEXT_SUCCESS_REF_DATA__GET:
      //console.log('rdcr_context_success_ref -- CONTEXT_SUCCESS_REF_DATA__GET --  action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
