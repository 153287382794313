import {DYN_EXEC_REF_DATA__GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case DYN_EXEC_REF_DATA__GET:
      //console.log('rdcr -- DYN_EXEC_REF_DATA__GET action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
