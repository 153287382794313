import {CONTEXT_DYN_DATA__UI_DELCAND20181015} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case CONTEXT_DYN_DATA__UI_DELCAND20181015:
      //console.log('rdcr -- CONTEXT_DYN_DATA__UI_DELCAND20181015 -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
