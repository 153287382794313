import React, { Component } from 'react';
import assert from 'assert';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Container, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { UI_CONFIG,
          updateUiConfig,
          switchDynAreaRefBasedOnContextCat,
          switchPersonContextDescBasedOnContextCat,
          contextDynsDataGet, 
          DynElemRefGetData, 
          DynExecRefGetData, 
          DynAreaRefGetData,
          DynObsTargetRefGetData,
          saveOnBlurSingleField, 
          personContextsDataGet,
          createNewPersonForUser,
          activeContext,
          genContextRecs, } from '../actions';
import PersonContextSub from './PersonContextSub'

//const UI_CONFIG = 'UI_CONFIG';

class PersonContext extends Component{

    constructor (props){
        super(props);
        this.state = {
          any_change_to_data: false,  //once true stays true while on page -- indicates whether to refresh page data or not upon going to dyns
          new_data: false,
          update_single_field: {
            field_name: '',
            field_data: '',
            assoc_id: '',
            entity: ''
          },
          entry_dt_tm: this.props.personContext['entry_dt_tm'],
          context_type_001_id: this.props.personContext['context_type_001_id'] || '',
          context_def: this.props.personContext['context_def'],
          context_goal: this.props.personContext['context_goal'],
          context_roles: this.props.personContext['context_roles'], //NOT USED AFTER CREATED OTHER'S AND MY ROLE 
          context_role_other: this.props.personContext['context_role_other'], 
          context_role_me: this.props.personContext['context_role_me'], 
          context_actions: this.props.personContext['context_actions'],
          humbot_scale_val: this.props.personContext['humbot_scale_val'],
          conflict_scale_val: this.props.personContext['conflict_scale_val'],
          stakes_scale_val: this.props.personContext['stakes_scale_val'],
          perceived_level_of_perform_by_other_val: this.props.personContext['perceived_level_of_perform_by_other_val'] || '',
          show_cntx_levels_yn: false,
          disharmony_level: (this.props.personContext.disharmony_level) ? this.props.personContext.disharmony_level : 0,
          success_inter_pers_id: (this.props.personContext.success_inter_pers_id) ? this.props.personContext.success_inter_pers_id : '',
          success_inter_pers_note: (this.props.personContext.success_inter_pers_note) ? this.props.personContext.success_inter_pers_note : '',
          success_move_frwd_id: (this.props.personContext.success_move_frwd_id) ? this.props.personContext.success_move_frwd_id : '',
          success_move_frwd_note: (this.props.personContext.success_move_frwd_note) ? this.props.personContext.success_move_frwd_note : '',
          label_desc_01: "Other's role",
          label_desc_04: 'My role',
          label_desc_02: '', //not used ao 202020929, used to be 'Context frame'
          label_desc_03: 'Goal',
          dynsEstablished: true,  //default is true and get checked and poss changed in DidMount
          performance_review_style: false,
          delete_enable: false,
          deleteButtonAction: '',
          whichElementInFocus: '',
          roles_visible: false,
          context_def_visible: false,
          cat_id: this.props.personContext.cat_id ? this.props.personContext.cat_id : '',
          context_cat_title: '',
          desc_id: this.props.personContext.desc_id ? this.props.personContext.desc_id : '',
          mode_id: this.props.personContext.mode_id ? this.props.personContext.mode_id : '',
          view_results_yn: false,
          process_roadmap_mode: this.props.personContext.process_roadmap_mode,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleExpandCollapseCntxLevels = this.handleExpandCollapseCntxLevels.bind(this);
   } //end constructor

componentDidMount() {
  console.log('this.props.rdcr_ui_config.process_roadmap_mode', this.props.rdcr_ui_config.process_roadmap_mode)
  this.props.updateUiConfig(UI_CONFIG, 'activePersName', this.props.rdcr_person_active[0].first_name)
  window.scrollTo(0,0);
  const d = new Date(this.state.entry_dt_tm);
  console.log('!true', !true);
  console.log('dddddd', d.toDateString());
  this.setState({
    entry_dt_tm: d.toDateString()
  })
  this.handleCheckIfDynsEstablished()  //this as been only place ths happens, but needed to also be at the point of opening DynGrp page (added to handleGoToDynamics 20190712)
  console.log('this.state.cat_id z at Mount before handleContextCat', this.state.cat_id);
  //get title of context cat
  this.handleContextCat();
  this.props.updateUiConfig(UI_CONFIG, 'contextFlagCreatingInProgress', false)
  this.props.updateUiConfig(UI_CONFIG, 'process_roadmap_mode', this.props.personContext.process_roadmap_mode)
  if (this.state.context_def !== '' && this.state.context_def !== null) {
    this.setState({
      context_def_visible: true
    })
  }
  else {
    this.setState({
      context_def_visible: false
    })

  }
}

handleCheckIfDynsEstablished() { //quick & dirty check, need to make better
  console.log("this.props.personContext['id']", this.props.personContext['id'])
  this.props.contextDynsDataGet(this.props.personContext['id'], () => {
    /*
    this.props.rdcr_context_dyns.map((dyn) => {
      console.log('dyn.type_area', dyn.type_area)
      if (dyn.type_area === null) {
        this.setState({
          dynsEstablished: false
        }, () => console.log('this.props.dynsEstablished', this.props.dynsEstablished)) 
      }
    })
    this.props.updateUiConfig(UI_CONFIG, 'context_dyns_established', this.state.dynsEstablished)
    */
  let dynCompleteForRoadmapMode = false
  let cntr = 0
  this.props.rdcr_context_dyns.map((dyn) => {
    console.log('dyn.type_area', dyn.type_area)
    if (dyn.type_area === null) {
      cntr++
    }
  })
  console.log('cntr', cntr)
  /*
  //20200728 del cand, not using roadmap type, just cntr
  //experimenting with 3 filled out for both Pow and Rap, if go with this then one statement for both
  if (this.props.rdcr_ui_config.process_roadmap_mode === 'power_roadmap' && cntr  <= 1) {
    dynCompleteForRoadmapMode = true
  }

  if (this.props.rdcr_ui_config.process_roadmap_mode === 'rapid_roadmap' && cntr <= 1) {
    dynCompleteForRoadmapMode = true
  }
  */
 
  if (cntr <= 1) {
    dynCompleteForRoadmapMode = true
  }
  this.setState({
    dynsEstablished: dynCompleteForRoadmapMode
      }, () => {
            console.log('this.props.dynsEstablished', this.props.dynsEstablished)
            this.props.updateUiConfig(UI_CONFIG, 'context_dyns_established', this.state.dynsEstablished)
      })
  })
}

handleOnChange(e) {
  console.log('e.target.name', e.target.name); 
  console.log('e.target.value', e.target.value); 
  const e_target_name = e.target.name;
  this.setState({
    any_change_to_data: true,
    new_data: true,
    update_single_field: {
      field_name: e.target.name,
      field_data: e.target.value,
      assoc_id: this.props.personContext['id'],
      entity: 'pers_expr'
    },
    [e.target.name]: e.target.value
  }, () => {
    console.log('from handleOnChange -- this.state.new_data', this.state.new_data)
    console.log('from handleOnChange -- this.state.update_single_field', this.state.update_single_field)
    switch (e_target_name){
      case 'cat_id':
        console.log('this.state.cat_id before calling handleContextCat from handleOnCHange', this.state.cat_id)
        this.handleContextCat()
        break;
      case 'process_roadmap_mode':
        this.props.updateUiConfig(UI_CONFIG, 'process_roadmap_mode', this.state.process_roadmap_mode)
        break;  
      default:
        break;
    }})
}

handleOnFocus(e){
  this.setState({
    whichElementInFocus: e.target.name
  })
}

handleOnBlur(){
  console.log('handleOnBlur in PersonContext this.state.update_single_field', this.state.update_single_field)
  if (this.state.new_data === true){
    this.props.saveOnBlurSingleField(this.state.update_single_field);
    this.setState({
      new_data: false
    })
  }
}

//20190104 az:

handleOnChangeEnableDeleteText(e){
  if (e.target.value === 'delete') {
    this.setState({
      delete_enable: true,
      deleteButtonAction: 'settingup_delete'
    })
  }
  else {
    this.setState({
      delete_enable: false,
      deleteButtonAction: ''
    })
  }
}

handleOnClickDelete(e){
  switch(this.state.deleteButtonAction){
    case 'settingup_delete':
      this.handleOnChange(e);
      this.setState({
        deleteButtonAction: 'confirming_delete'
      })
      break;

    case 'confirming_delete':
      this.handleOnBlur();
      this.setState({
        deleteButtonAction: ''
      })
      break;

    default:
  }
}

handleExpandCollapseCntxLevels(){
  this.setState({
    show_cntx_levels_yn: !this.state.show_cntx_levels_yn
  })
  console.log('this.state.show_cntx_levels_yn', this.state.show_cntx_levels_yn)
}

handleCreateNewContext(e){
  console.log('from handleCreateNewContext e.target.name', e.target.name);
  switch (e.target.name) {
    case 'createNewContext':
      this.props.createNewPersonForUser ('context')
    case 'createNewContextSub':
    this.props.activeContext(this.props.personContext['id'])
    this.props.createNewPersonForUser ('context_sub')
  }
}

handleGoToPersonDetails(){  //NEED TO DEAL WITH GETTING NEW CONTEXTS IF CHANGES AND GOING TO PERSON (NOW FROM NAVBAR)
  this.props.history.push('/PersonDetailz')
  //if change to data, refresh context from db, 
  //for when return to this page via back from next page
  if (this.state.any_change_to_data) {
    this.props.personContextsDataGet(this.props.rdcr_person_active[0].id) 
  }
}

refreshContextReducerStateDataIfDataChanged(){
  //if change to data, refresh context from db, 
  //for when return to this page via back from next page
  if (this.state.any_change_to_data) {
    console.log('from handleGoToPersonDetails, this.props.rdcr_person_active.id', this.props.rdcr_person_active.id)
    this.props.personContextsDataGet(this.props.rdcr_person_active[0].id) 
  }
}

handleGoToDynamics(){
  
    /*
    // TO LOOK CLOSER RE TO PRIMISES, 20180511
    //https://stackoverflow.com/questions/22519784/how-do-i-convert-an-existing-callback-api-to-promises

    let PromiseDynElemRefGetData = () => {
      return new Promise((resolve, reject) => { //returning promise
        this.props.DynElemRefGetData((err, response) => {
          resolve('success from PromiseDynElemRefGetData'); //promise resolve
          if (err) {
            console.log('failure', err)
            reject('failure from PromiseDynElemRefGetData reject'); //promise reject
          }else{
            console.log('success from PromiseDynElemRefGetData', response)
            resolve('success from PromiseDynElemRefGetData'); //promise resolve
          }
        })
      })
    }
    PromiseDynElemRefGetData()
    .then((r) => console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! success', r))
    .catch((err) => console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! err', err))
    */
  
  this.refreshContextReducerStateDataIfDataChanged()
  this.handleCheckIfDynsEstablished(); /* 201907112 added this check here bc without it,
  /the DynGrp sometime got the wrong status about itself, 
  mixed with another DynGrp in same context. 
  the bug was when another DynGrp was all filled up (dyns all established) 
  then not full DynGrp would have no picklist population bc thinking its already filled out. 
  This function had run just in ComponentDidMount but also needs to be here.
  Bug was encountered and reported by Greer from beta group!
  */
  
  let id = this.props.personContext.id;
  let history = this.props.history
  //failed when not match, tf split in two: const desc = (this.props.rdcr_pers_context_type_ref.find((i) => {return i.id === this.state.context_type_001_id}).type_desc
  const desc_match = this.props.rdcr_pers_context_type_ref.find((i) => {return i.id === this.state.context_type_001_id});
  const desc = desc_match ? desc_match.type_desc : '';
  this.props.updateUiConfig(UI_CONFIG, 'dynGroupTitle', desc);
  //context to be used in dynamics -- either from free hand or picklist
  //20200507co bc when switching from pro to pers caused issues
  /* this.props.updateUiConfig(UI_CONFIG, 'contextDefForDisplay', 
    this.state.desc_id === '' ? 
    this.state.context_def : 
    */
    this.props.updateUiConfig(UI_CONFIG, 'contextDescForDisplay', this.state.desc_id ? this.props.rdcr_persons_context_desc_ref.find(item => item.id === this.state.desc_id).title  : '');
    this.props.updateUiConfig(UI_CONFIG, 'contextDefForDisplay', this.state.context_def);
    //get read for Roadmap as well:
  this.props.updateUiConfig(UI_CONFIG, 'contextGoalForDisplay', this.state.context_goal);
  this.props.updateUiConfig(UI_CONFIG, 'contextDisharmonyLevel', this.state.disharmony_level);
  this.props.DynExecRefGetData(
      () => {
        this.props.DynObsTargetRefGetData();  //20201008 
        this.props.DynElemRefGetData(
          () => this.props.contextDynsDataGet(id, () => {
            console.log('getting new context grps page.....');
            history.push('/DynGrp');
            }
          )
        )
      }
  );
    //above chained via cb's replaced attempt of promise that didn't work
}

testFunction(value){
  if(!this.testFunction.store){
    this.testFunction.store = {};
  }
  this.testFunction.store[value] = true;
}

testFunctionOutput(){
  console.log('this.testFunction.store', this.testFunction.store)
}

handleRenderContextTypePicklist(){
  if (this.props.rdcr_pers_context_type_ref !== undefined) {
    return  <Col sm={12} md={6}>
                  <div>
                    <Label for="context_type_001_id">Performance review skillset</Label>
                    <select
                    className="form-control"
                    name='context_type_001_id' 
                    id='context_type_001_id' 
                    value={this.state.context_type_001_id} 
                    onChange={(e) => this.handleOnChange(e)} 
                    onBlur={this.handleOnBlur}>
                    <option value=''>Select</option>
                    {this.props.rdcr_pers_context_type_ref.map(item => {
                      return <option key={item['id']} value={item['id']}>{item['type_desc']}</option>
                    })}
                  </select>
                  </div>
                </Col>
  }
}

handleRenderContextDescPicklist() {
  return <select
    hidden={false} 
    className="form-control" 
    name='desc_id' 
    value={this.state.desc_id} 
    onChange={(e) => this.handleOnChange(e)} 
    onBlur={this.handleOnBlur}>
    <option value="">Select communication description</option>
      {this.props.rdcr_persons_context_desc_ref.map(item => {
        return <option key={item['id']} value={item['id']}>{item['title']}</option>
      })}
    </select>
}

handleRenderContextCatPicklist(){
  return <div className="dropdownPersonContext">
  <select 
    hidden={false} 
    className="form-control" 
    name='cat_id' 
    id='cat_id'
    value={this.state.cat_id} 
    onChange={(e) => this.handleOnChange(e)} 
    onBlur={() => this.handleOnBlur()}>
    <option value="">Select communication category</option>
      {this.props.rdcr_context_cat_ref.map(item => {
        return <option key={item['id']} value={item['id']}>{item['title']}</option>
      })}
    </select>
    </div>
}

handleRenderContextModePicklist() {
  return <div className="dropdownPersonContext">
  <select 
    hidden={false} 
    className="form-control" 
    name='mode_id' 
    value={this.state.mode_id} 
    onChange={(e) => this.handleOnChange(e)} 
    onBlur={() => this.handleOnBlur()}>
    <option value="">Select communication mode</option>
    {this.props.rdcr_persons_context_mode_ref.map(item => {
      return <option key={item['id']} value={item['id']}>{item['title']}</option>
    })}
    </select>
    </div>
}

handleGetHeadContextSibling(id_head_sibling){
  const create_date = new Date(this.props.rdcr_person_active_contexts.find((c) => c.id === id_head_sibling).entry_dt_tm).toDateString();
  let goal = this.props.rdcr_person_active_contexts.find((c) => c.id === id_head_sibling).context_goal;
  let scenario = this.props.rdcr_person_active_contexts.find((c) => c.id === id_head_sibling).context_def;
  goal = goal ? goal : '[left blank]';
  scenario = scenario ? scenario : '[left blank]';
  return '**' + create_date + '  **Goal: ' + goal
    + ' **Description: ' + scenario;
}

handleRenderSubContextsIdDisplay(){
  if (this.props.rdcr_active_person_contexts_sub[0] !== undefined) {
    return this.props.rdcr_active_person_contexts_sub.map((d) => {
      return <p key={d.id}>{d.id}</p>
    })
  }
}
 
handleRenderSubContexts(){
  if (this.props.rdcr_active_person_contexts_sub[0] !== undefined) {
    return this.props.rdcr_active_person_contexts_sub.map(d => {
            if (d.pers_expr_parent_id === this.props.personContext['id']) {
            return <PersonContextSub
                    personContext={ d }
                    key={d.id}
                    value=''
                    dynsEstablished={this.state.dynsEstablished}
                    history={this.props.history}
                    />
            }
          })
  }        
}

handleGoToRecsAndActions(){
  //context_id passed from parent component
  //DELCAND, bug that gets wrong id: this.props.genContextRecs(this.props.rdcr_context_dyns[0].expr_id, () => this.props.history.push('/ContextRecsAndActions'))
  console.log("this.props.rdcr_context_dyns[0].expr_id", this.props.rdcr_context_dyns[0].expr_id)
  console.log("this.props.personContext['id']", this.props.personContext['id'])
  this.refreshContextReducerStateDataIfDataChanged();
  this.props.updateUiConfig(UI_CONFIG, 'contextGoalForDisplay', this.state.context_goal);
  console.log('this.state.disharmony_level in PersonContext.js func handleGoToRecsAndActions', this.state.disharmony_level);
  this.props.genContextRecs(this.props.personContext['id'], this.state.disharmony_level, () => this.props.history.push('/ContextRecsAndActions'));
}

handleViewResults(){
  this.setState({
    view_results_yn: !this.state.view_results_yn
  }, console.log('this.state.view_results_yn', this.state.view_results_yn));
}

handleUiVisible(uiDesc){
  switch (uiDesc) {
    case 'roles':
      this.setState(
        {roles_visible: !this.state.roles_visible}
      )
      break;
    case 'context_def':
      this.setState({
        context_def_visible: !this.state.context_def_visible
      })
    default:
        break;
  }
}

handleCreateNewContextWtDynsBasedOnThis (NewCntxAsHeadCntxtOrPartOfCurrCnxtGrp = '') {
  console.log('this.props.rdcr_ui_config.contextFlagCreatingInProgress', this.props.rdcr_ui_config.contextFlagCreatingInProgress)
    this.props.updateUiConfig(UI_CONFIG, 'contextFlagCreatingInProgress', true, () => { 
      //context flag in progress get reset to off (false) ComponentDidMount)
      switch (NewCntxAsHeadCntxtOrPartOfCurrCnxtGrp) {
        case '':
          ////////this.props.updateUiConfig(UI_CONFIG, 'newContext', true)
          //create new context for expr based on current, cat + dyns
          //this.props.createNewPersonForUser('context', undefined, {expr_id: this.props.personContext['id'], newCntxAsPartOfCurrCnxtGrp: false})
          this.props.createNewPersonForUser('context', undefined, {expr_id: this.props.personContext['id'], newCntxAsPartOfCurrCnxtGrp: false, cat_id: this.state.cat_id, process_roadmap_mode: this.props.rdcr_ui_config.process_roadmap_mode})
          break;

        case 'CurrentCntxGroupNewMiniCntx':
          //create new context for expr based on current, cat + dyns AND makes this a sibling of curr -- NOT USED YET
          this.props.createNewPersonForUser ('context', undefined, {expr_id: this.props.personContext['id'], newCntxAsPartOfCurrCnxtGrp: true, cat_id: this.state.cat_id, process_roadmap_mode: this.props.rdcr_ui_config.process_roadmap_mode})
          break;
        
        default:
          break;
      }}
    )
}

handleContextCat(action = '') {
    //cat_id,
    //context_cat_title,
    console.log('this.state.cat_id at start of handleContextCat', this.state.cat_id);  
    let context_cat_title = ''
    if (this.state.cat_id === '') {
      //if need to set context cat:
      context_cat_title = this.props.rdcr_ui_config.contextCat !== undefined ? this.props.rdcr_ui_config.contextCat : this.props.rdcr_context_cat_ref[0].title
      console.log("this.state.cat_id === ''")
    }
    else {
      console.log('this.state.cat_id in handleContextCat before context_cat_title var set', this.state.cat_id)
      context_cat_title = this.props.rdcr_context_cat_ref.find(i => i.id === this.state.cat_id).title
      this.props.switchDynAreaRefBasedOnContextCat(context_cat_title);
      this.props.switchPersonContextDescBasedOnContextCat(context_cat_title);
      console.log('context_cat_titlez', context_cat_title)
    }
      if (this.props.rdcr_ui_config.contextCat !== context_cat_title) {
        this.props.updateUiConfig(UI_CONFIG, 'contextCat', context_cat_title);  
      }
    
    switch (action) {
      case '':
        //if (this.props.rdcr_context_cat_ref.find(i => i.id === this.state.cat_id).title !== undefined)
        if (this.state.cat_id !== '')
        {
          this.setState({
            context_cat_title: context_cat_title
          })    
        }
        else
        {
          this.setState({
            context_cat_title: 'Professional or Personal?'
          })
        }
        break;
      case 'onClick':
          let index_cur = this.props.rdcr_context_cat_ref.indexOf(this.props.rdcr_context_cat_ref.find(i => i.id === this.state.cat_id)) || 0
          let index_next = 0
          if (index_cur !== this.props.rdcr_context_cat_ref.length - 1) {
            index_next = index_cur + 1
          }
          console.log('index_cur', index_cur)
          console.log('this.props.rdcr_context_cat_ref[index_next].id', this.props.rdcr_context_cat_ref[index_next].id)
          console.log('this.props.rdcr_context_cat_ref[index_next].id 0', this.props.rdcr_context_cat_ref[0].id)
          const forSaving = {
            target: {
              name: 'cat_id', 
              value: this.props.rdcr_context_cat_ref[index_next].id
            }
          }
          this.handleOnChange(forSaving);
          /* this.setState({
            cat_id: this.props.rdcr_context_cat_ref[index_next].id
          }) */
          this.setState({
            context_cat_title: this.props.rdcr_context_cat_ref[index_next].title
          })
          if (this.props.rdcr_ui_config.contextCat !== this.props.rdcr_context_cat_ref[index_next].title) {
            this.props.updateUiConfig(UI_CONFIG, 'contextCat', this.props.rdcr_context_cat_ref[index_next].title);
            this.props.switchDynAreaRefBasedOnContextCat(this.props.rdcr_context_cat_ref[index_next].title);
            this.props.switchPersonContextDescBasedOnContextCat(this.props.rdcr_context_cat_ref[index_next].title);
          }
          break;
      default:
        break;
    }
}

handleIsContextNew(){
  return this.props.personContext['id'] === this.props.rdcr_ui_config.newContextExprId
}

/* 2020528_DELCAND
handleContextCat(action = '') {
  //cat_id,
  //context_cat_title,
  console.log('this.state.cat_id', this.state.cat_id);  
  let context_cat_title = ''
  if (this.state.cat_id === '') {
    //if need to set context cat:
    context_cat_title = this.props.rdcr_ui_config.contextCat !== undefined ? this.props.rdcr_ui_config.contextCat : this.props.rdcr_context_cat_ref[0].title
  }
  else {
    context_cat_title = this.props.rdcr_context_cat_ref.find(i => i.id === this.state.cat_id).title 
  }
    if (this.props.rdcr_ui_config.contextCat !== context_cat_title) {
      this.props.updateUiConfig(UI_CONFIG, 'contextCat', context_cat_title);
      this.props.switchDynAreaRefBasedOnContextCat(context_cat_title);
      this.props.switchPersonContextDescBasedOnContextCat(context_cat_title);
    }
  
  switch (action) {
    case '':
      //if (this.props.rdcr_context_cat_ref.find(i => i.id === this.state.cat_id).title !== undefined)
      if (this.state.cat_id !== '')
      {
        this.setState({
          context_cat_title: context_cat_title
        })    
      }
      else
      {
        this.setState({
          context_cat_title: 'Professional or Personal?'
        })
      }
      break;
    case 'onClick':
        let index_cur = this.props.rdcr_context_cat_ref.indexOf(this.props.rdcr_context_cat_ref.find(i => i.id === this.state.cat_id)) || 0
        let index_next = 0
        if (index_cur !== this.props.rdcr_context_cat_ref.length - 1) {
          index_next = index_cur + 1
        }
        console.log('index_cur', index_cur)
        console.log('this.props.rdcr_context_cat_ref[index_next].id', this.props.rdcr_context_cat_ref[index_next].id)
        console.log('this.props.rdcr_context_cat_ref[index_next].id 0', this.props.rdcr_context_cat_ref[0].id)
        const forSaving = {
          target: {
            name: 'cat_id', 
            value: this.props.rdcr_context_cat_ref[index_next].id
          }
        }
        this.handleOnChange(forSaving);
        this.setState({
          context_cat_title: this.props.rdcr_context_cat_ref[index_next].title
        })
        if (this.props.rdcr_ui_config.contextCat !== this.props.rdcr_context_cat_ref[index_next].title) {
          this.props.updateUiConfig(UI_CONFIG, 'contextCat', this.props.rdcr_context_cat_ref[index_next].title);
          this.props.switchDynAreaRefBasedOnContextCat(this.props.rdcr_context_cat_ref[index_next].title);
          this.props.switchPersonContextDescBasedOnContextCat(this.props.rdcr_context_cat_ref[index_next].title);
        }
        break;
    default:
      break;
  }
} */

render(){
  /*
    let styleTitleDesc = {
        fontSize: 14,
        float: 'left'
      };
  */

//https://reactstrap.github.io/components/form/
if (1===1) {
return (
      <div>
      <Container className='m-auto'> {/*className='py-3'*/}
        <Form onSubmit={this.handleSubmit}>
          <Card>
            <CardHeader className="d-flex">
              <FormGroup row className='m-0'>
                  <Col sm={12}>
                  <div  hidden={!this.props.rdcr_ui_config.isAdmin} className='userTypeDisplayShort'>
                    <label className='label_no_margins'>
                      {this.props.rdcr_ui_config.isAdmin ? 'Logged in as Admin' : ''}
                    </label>
                  </div>
                  <div>
                      <Label className={(this.state.whichElementInFocus === 'context_def' ? 'font-weight-bold text-primary' : '')}>
                            {this.state.label_desc_02}
                              <span hidden={true}
                                onClick={() => this.handleContextCat('onClick')}> <u>({this.state.context_cat_title})</u>
                              </span>
                      </Label>
                      <span>  </span>
                      <div hidden={true}> {/*DELCAND_20200929 RAPID/POWER ROADMAP CONCEPT*/}
                        <Label className='userTypeDisplay'>
                          {this.state.process_roadmap_mode === 'rapid_roadmap' ? 'RAPID ' : 'POWER '}
                        </Label>
                          <span> </span>
                          <Label>roadmap mode
                            <span>{/*without type='button', weird Hot modual change webpack bug*/}
                              <button type="button" className='btn btn-link' 
                                name={'process_roadmap_mode'}
                                value={this.state.process_roadmap_mode === 'rapid_roadmap' ? 'power_roadmap' : 'rapid_roadmap'}
                                onClick={(e) => this.handleOnChange(e)}
                                onBlur={() => this.handleOnBlur()}  
                                  >(change)
                              </button>
                          </span>
                        </Label>
                      </div>
                  </div>
                  <div>
                    <label className="text_small">
                      Create Date: {this.state.entry_dt_tm} 
                    </label><span>&nbsp; &nbsp; &nbsp;</span>
                    {!this.props.rdcr_ui_config.contextFlagCreatingInProgress ?
                    <span className='text-primary'
                        title="Create new context based on current dynamics."
                        hidden={!this.state.dynsEstablished}
                        onClick={() => this.handleCreateNewContextWtDynsBasedOnThis() }>
                        <FontAwesome
                          name='far fa-copy'
                        />+
                    </span>
                    :
                    <span>
                      <FontAwesome
                        name='fas fa-spinner'
                        />
                    </span>
                    }
                    <Label className='displayFontRed displayFontBold'>{this.handleIsContextNew() ? '-- NEW CONTEXT --' : ''}</Label>
                  </div>
                    
                  
                </Col>
              </FormGroup>  
            </CardHeader>
            <CardBody className="col-12 col-sm-12 pb-0 m-auto">   
            <FormGroup 
                      hidden={this.props.rdcr_ui_config.modulesApplied === 'performance_review' || this.props.rdcr_ui_config.modulesApplied === 'performance_review_only' ? false : true}
                      className='border border-primary'>
                <FormGroup className='m-1' row>
                  {this.props.rdcr_pers_context_type_ref[0] === undefined ? 'UNdefined' : this.handleRenderContextTypePicklist()}
                </FormGroup>
                <FormGroup className='mx-1 pt-3' row>
                    <Col sm={12} md={6}>
                      <label>Level of performance achieved:</label>
                      <select
                        className="form-control"
                        name='perceived_level_of_perform_by_other_val' 
                        id='perceived_level_of_perform_by_other_val' 
                        value={this.state.perceived_level_of_perform_by_other_val} 
                        onChange={(e) => this.handleOnChange(e)} 
                        onBlur={this.handleOnBlur}>
                        <option value=''>Select</option>
                        <option value='1'>Unsatisfactory (1)</option>
                        <option value='2'>Needs Improvement (2)</option>
                        <option value='3'>Satisfactory (3)</option>
                        <option value='4'>Above Satisfactory (4)</option>
                        <option value='5'>Superior (5)</option>  
                      </select>
                    </Col>
                </FormGroup>      
              </FormGroup>
              <div hidden={this.props.rdcr_ui_config.modulesApplied === 'performance_review_only' ? true : false}>
          <div hidden={this.state.view_results_yn}> {/* beg div of context entry or results entry}*/}
              <FormGroup row>
                <Col sm={12}>
                  <div>
                    <p hidden={!this.props.personContext.id_head_sibling}>
                      Attached to context: {this.props.personContext.id_head_sibling ? this.handleGetHeadContextSibling(this.props.personContext.id_head_sibling) : ''};
                    </p>
                    {(this.props.rdcr_user_curr[0].expr_cat_title_default === this.props.rdcr_user_curr[0].expr_cat_title_all && this.props.rdcr_user_curr[0].expr_cat_default_id !== '' && this.props.rdcr_user_curr[0].expr_cat_default_id !== null ? '' : this.handleRenderContextCatPicklist())} {/**displays cat drop down if not preselected via usage_grp **/}
                    {this.props.rdcr_user_curr[0].usage_grp_id === null ? '' : this.handleRenderContextDescPicklist()}
                    {this.handleRenderContextModePicklist()}
                    <p className={(this.state.whichElementInFocus === 'context_def' ? 'font-weight-bold text-primary' : '')}
                    onClick={() => this.handleUiVisible('context_def')}>
                      Short elaboration {this.state.context_def_visible ? '-' : '+'}
                    </p>
                    <Input hidden={!this.state.context_def_visible} className="form-control textAreaPersonContext" 
                      type="textarea" 
                      name='context_def'  
                      placeholder="Brief description of interpersonal situation..."
                      value={this.state.context_def ? this.state.context_def : ''} 
                      onFocus={(e) => this.handleOnFocus(e)}
                      onChange={(e) => this.handleOnChange(e)}
                      onBlur={this.handleOnBlur}/>
                      {/*<Label>Performance Evaluation Feedback Section</Label>*/}
                      {/*placeholder="Brief description of interpersonal situation and goals..."*/}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup hidden={true} row>
                <Col sm={12}>
                    <div> 
                      <Label>Context Frame</Label>
                    </div>
                </Col>
              </FormGroup>
              <div hidden>
                <p onClick={() => this.handleUiVisible('roles')}  
                >Roles {this.state.roles_visible ? '-' : '+'}</p>
              </div>
              <FormGroup row
                hidden={!this.state.roles_visible}>
              <Col sm={12}>
                  <div>
                    <label className={(this.state.whichElementInFocus === 'context_role_me' ? 'font-weight-bold text-primary' : '')}>{this.state.label_desc_04}</label>
                    <Input className="form-control textAreaPersonContextShort"
                        type="textarea"
                        name='context_role_me'
                        placeholder="My role..."
                        value={this.state.context_role_me ? this.state.context_role_me : ''}
                        onFocus={(e) => this.handleOnFocus(e)}
                        onChange={(e) => this.handleOnChange(e)}
                        onBlur={this.handleOnBlur}
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row
                hidden={!this.state.roles_visible}>
                <Col sm={12}>
                  <div>
                    <label className={(this.state.whichElementInFocus === 'context_role_other' ? 'font-weight-bold text-primary' : '')}>{this.state.label_desc_01}</label>
                    <Input className="form-control textAreaPersonContextShort"
                        type="textarea"
                        name='context_role_other'
                        placeholder="Other's role..."
                        value={this.state.context_role_other ? this.state.context_role_other : ''}
                        onFocus={(e) => this.handleOnFocus(e)}
                        onChange={(e) => this.handleOnChange(e)}
                        onBlur={this.handleOnBlur}
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <div>
                    <Label className={(this.state.whichElementInFocus === 'context_goal' ? 'font-weight-bold text-primary' : '')}>{this.state.label_desc_03}</Label>
                    <Input className="form-control textAreaPersonContext" 
                      type="textarea" 
                      name='context_goal'  
                      placeholder="Goal for this interaction..."
                      value={this.state.context_goal ? this.state.context_goal : ''}
                      onFocus={(e) => this.handleOnFocus(e)}
                      onChange={(e) => this.handleOnChange(e)}
                      onBlur={this.handleOnBlur}/>
                      {/*<Label>Performance Evaluation Feedback Section</Label>*/}
                      {/*placeholder="Brief description of interpersonal situation and goals..."*/}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <div>
                  <Label className={(this.state.whichElementInFocus === 'disharmony_level' ? 'font-weight-bold text-primary' : '')}>Charge-intensity level</Label>
                  <select className="form-control"
                        name='disharmony_level'
                        value={this.state.disharmony_level}
                        onFocus={(e) => this.handleOnFocus(e)}
                        onChange={(e) => this.handleOnChange(e)}
                        onBlur={() => this.handleOnBlur()}
                        >
                        <option value="-2">Very Low</option>
                        <option value="-1">Low</option>
                        <option value="0">Medium</option>
                        <option value="1">High</option>
                    </select>
                  </div>
                </Col>
              </FormGroup>
      </div> {/* end div of context entry or results entry}*/}
      <div hidden={!this.state.view_results_yn} name='div_results'>
              <FormGroup row>    
                <Col sm={12}>
                  <div>
                  <div>
                    <Label className='text_bold_blue'>Results</Label>
                      <p className='text_italic display-linebreak'>
                        {(this.state.context_def ? 'Situation:  ' + this.state.context_def : '') + 
                        (this.state.context_goal ? '\nGoal:  ' + this.state.context_goal : '') + 
                        (this.state.context_role_other ? "\nOther:  " + this.state.context_role_other : '') + 
                        (this.state.context_role_me ? "\nMe:  " + this.state.context_role_me : '') +
                        (this.state.disharmony_level ? "\nIntensity:  " + this.state.disharmony_level : '')}
                      </p>
                    
                  </div>
                    <Label className='text_blue'>Interpersonal success level</Label>
                    <select
                        hidden={false} 
                        className="form-control" 
                        name='success_inter_pers_id' 
                        value={this.state.success_inter_pers_id} 
                        onChange={(e) => this.handleOnChange(e)} 
                        onBlur={this.handleOnBlur}>
                        <option value="">Select</option>
                        {this.props.rdcr_context_success_ref.map(item => {
                          return <option key={item['id']} value={item['id']}>{item['title']}</option>
                        })}
                    </select>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <div>
                    <Label className={(this.state.whichElementInFocus === 'success_inter_pers_note' ? 'font-weight-bold text-primary' : '')}>Note</Label>
                    <Input className="form-control textAreaPersonContextShort" 
                      type="textarea" 
                      name='success_inter_pers_note'  
                      placeholder="Interpersonal results note..."
                      value={this.state.success_inter_pers_note ? this.state.success_inter_pers_note : ''}
                      onFocus={(e) => this.handleOnFocus(e)}
                      onChange={(e) => this.handleOnChange(e)}
                      onBlur={this.handleOnBlur}/>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>    
                <Col sm={12}>
                  <div>
                    <Label className='text_blue'>Success level toward goal</Label>
                    <select
                        hidden={false} 
                        className="form-control" 
                        name='success_move_frwd_id' 
                        value={this.state.success_move_frwd_id} 
                        onChange={(e) => this.handleOnChange(e)} 
                        onBlur={this.handleOnBlur}>
                        <option value="">Select</option>
                        {this.props.rdcr_context_success_ref.map(item => {
                          return <option key={item['id']} value={item['id']}>{item['title']}</option>
                        })}
                    </select>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <div>
                    <Label className={(this.state.whichElementInFocus === 'success_move_frwd_note' ? 'font-weight-bold text-primary' : '')}>Note</Label>
                    <Input className="form-control textAreaPersonContextShort" 
                      type="textarea" 
                      name='success_move_frwd_note'  
                      placeholder="Moving forward results note..."
                      value={this.state.success_move_frwd_note ? this.state.success_move_frwd_note : ''}
                      onFocus={(e) => this.handleOnFocus(e)}
                      onChange={(e) => this.handleOnChange(e)}
                      onBlur={this.handleOnBlur}/>
                  </div>
                </Col>
              </FormGroup>
      </div>
              <FormGroup hidden={this.props.rdcr_user_curr[0].actions_allowed === 'subcontexts' ? false : true}
                  row>
                <Col sm={12}>
                {/*{this.handleRenderSubContextsIdDisplay()}*/}
                {this.handleRenderSubContexts()}
                </Col>
              </FormGroup>
              </div>
              <label hidden>Actions</label>
              <textarea hidden type="text" name='context_actions' value={this.state.context_actions ? this.state.context_actions : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
              <button
                  hidden={true}
                  type="button"
                  className="btn btn-secondary"
                  name='btnExpandCallapseCntxLevels'
                  value='btnExpandCallapseCntxLevels'
                  onClick={() => this.handleExpandCollapseCntxLevels()}>
                    Context Levels +/-</button>
            <div hidden={true}>
              <div className='containerPersonContext__container_cntxt_level' style={{ display: (this.state.show_cntx_levels_yn ? 'block' : 'none') }}>
                <div>
                <label className="label_leftish">Level of surprise (0-3)</label>
                <input type="text" name='surprise_scale_val' value={this.state.surprise_scale_val ? this.state.surprise_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
                <div>
                <label className="label_leftish">"Hume-bot" level (0-3)</label>
                <input type="text" name='humbot_scale_val' value={this.state.humbot_scale_val ? this.state.humbot_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
                <br/>
                <div>
                <label className="label_leftish">Contention level</label>
                <input type="text" name='conflict_scale_val' value={this.state.conflict_scale_val ? this.state.conflict_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
                <br/>
                <div>
                <label className="label_leftish">Level of pressure on other</label>
                <input type="text" name='pressure_on_ot_scale_val' value={this.state.pressure_on_ot_scale_val ? this.state.pressure_on_ot_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
                <br/>
                <div>
                <label className="label_leftish">Level of pressure on me</label>
                <input type="text" name='pressure_on_me_scale_val' value={this.state.pressure_on_me_scale_val ? this.state.pressure_on_me_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
              </div>
                <label hidden>id: {this.props.personContext['id']}</label>
                <span hidden> *** </span>
                <label hidden>id_seq: {this.props.personContext['id_seq']}</label>
                <span hidden> *** </span>
                <label hidden>pers_id: {this.props.personContext['pers_id']}</label>
                <span hidden> *** </span>
                <label hidden>entry_dt_tm: {this.props.personContext['entry_dt_tm']}</label>
                <span hidden> *** </span>
                <label hidden>start_dt_tm: {this.props.personContext['start_dt_tm']}</label>
                <span hidden> *** </span>
                <label hidden>end_dt_tm: {this.props.personContext['end_dt_tm']}</label>
            </div>
            </CardBody>
            <CardFooter>
              <Row>
                  <Col className="col-12 col-sm-auto">
                      <button type="button"
                          className="btn btn-link"
                          title="Create interaction in context group based on current dynamics."
                          name='goBack' value='goBack' onClick={() => this.props.history.goBack()}>
                          <FontAwesome
                            name="chevron-left"
                          />
                          back
                      </button>
                  </Col>
                  <Col className="col-12 ml-auto col-sm-auto">
                      <button
                        hidden={true}
                        className="btn btn-outline-secondary mr-2 mb-2 mb-sm-0"
                        type="button"
                        name='createNewContext'
                        value='createNewContext'
                        onClick={(e) => this.handleCreateNewContext(e)}>
                        New Context
                      </button>
                      <button hidden={this.props.rdcr_user_curr[0].actions_allowed === 'subcontexts' ? false : true}
                        className="btn btn-outline-secondary mr-2 mb-2 mb-sm-0"  type="button" name='createNewContextSub' value='createNewContextSub' onClick={(e) => this.handleCreateNewContext(e)}>
                        New Skillset
                      </button>
                      <button hidden={true} className="btn btn-outline-secondary mr-2 mb-2 mb-sm-0"
                          type="button"
                          name='goToPersonDetails'
                          value='goToPersonDetails'
                          onClick={() => this.handleGoToPersonDetails()}>
                          Person
                      </button>
                      <span hidden={true}
                        title='Create new dyanamic based on these within this context.'
                        >
                          <FontAwesome
                            className= "text-primary containerPersonContextButtonCopyContextAsPartOfHeadContextGroup"
                            hidden={!this.state.dynsEstablished}
                            name={!this.state.flagCreatingNewContextInProgress ? 'far fa-copy' : 'fas fa-spinner'}
                            onClick={() => this.handleCreateNewContextWtDynsBasedOnThis('CurrentCntxGroupNewMiniCntx')}
                          />
                      </span>
                      <button className={(this.state.dynsEstablished ? 'btn btn-outline-secondary' : 'btn btn-primary') + ' mr-2 mb-2 mb-sm-0'}
                          type="button"
                          value="Dynamics"
                          onClick={() => this.handleGoToDynamics()}>
                          Dynamics
                      </button>
                      <button
                          hidden={!this.state.dynsEstablished}
                          type="button"
                          className="btn btn-primary mr-2 mb-2 mb-sm-0"
                          name='recsAndActionsButton'
                          value="recsAndActions"
                          onClick={(e) => this.handleGoToRecsAndActions()}
                          >Roadmap
                      </button>
                      <button
                          hidden={!this.state.dynsEstablished}
                          type="button"
                          className="btn btn-primary mr-2 mb-2 mb-sm-0"
                          name='contextResultsButton'
                          value="results"
                          onClick={(e) => this.handleViewResults()}
                          >{this.state.view_results_yn === true ? 'Frame' : 'Results'}
                      </button>
                  </Col>
              </Row>
              <Row hidden={!this.props.rdcr_ui_config.contextDeleteMode}>
                  <Col className="col-12 ml-auto col-sm-auto">
                      <button hidden={!this.state.delete_enable}
                          className='btn btn-outline-secondary mr-2 mb-2 mb-sm-0'
                          type="button"
                          name='delete_dt'
                          value='1/1/2019'
                          onClick={(e) => this.handleOnClickDelete(e)}>
                          {(this.state.deleteButtonAction === 'confirming_delete' ? 'Confirm' : 'Delete')}
                      </button>
                      <input
                        className='inputPersonContextDeleteEnable mr-2 mb-2 mb-sm-0'
                        type="text"
                        name='delete_dt'
                        placeholder='To enable delete, type "delete".'
                        onChange={(e) => this.handleOnChangeEnableDeleteText(e)}     
                      />
                  </Col>
              </Row>  
              </CardFooter>
            </Card>
          </Form>
      </Container>
    </div>
    );
    }  //end return
  }   
}

//export default PersonContext_NEW;

function mapStateToProps(state) {
  return {
          rdcr_user_curr: state.rdcr_user_curr,
          rdcr_ui_config: state.rdcr_ui_config,
          rdcr_person_active: state.rdcr_person_active,
          rdcr_person_active_contexts: state.rdcr_person_active_contexts,
          rdcr_pers_context_type_ref: state.rdcr_pers_context_type_ref,
          rdcr_context_dyns: state.rdcr_context_dyns,
          rdcr_active_person_contexts_sub: state.rdcr_active_person_contexts_sub,
          rdcr_context_success_ref: state.rdcr_context_success_ref,
          rdcr_context_cat_ref: state.rdcr_context_cat_ref,
          rdcr_persons_context_desc_ref: state.rdcr_persons_context_desc_ref,
          rdcr_persons_context_mode_ref: state.rdcr_persons_context_mode_ref,
        };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
      saveOnBlurSingleField: saveOnBlurSingleField,
      updateUiConfig,
      switchDynAreaRefBasedOnContextCat: switchDynAreaRefBasedOnContextCat,
      switchPersonContextDescBasedOnContextCat,
      contextDynsDataGet: contextDynsDataGet,
      DynElemRefGetData: DynElemRefGetData, 
      DynExecRefGetData: DynExecRefGetData, 
      DynAreaRefGetData: DynAreaRefGetData,
      DynObsTargetRefGetData: DynObsTargetRefGetData,
      personContextsDataGet: personContextsDataGet,
      createNewPersonForUser,
      activeContext,
      genContextRecs,}, dispatch);
}

//export default FormPersonRdx;
export default connect(mapStateToProps, mapDispatchToProps)(PersonContext);