import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Container, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { UI_CONFIG,
          personDataGet,
          //
          updateUiConfig,
          switchDynAreaRefBasedOnContextCat,
          switchPersonContextDescBasedOnContextCat,
          contextDynsDataGet, 
          DynElemRefGetData, 
          DynExecRefGetData, 
          DynAreaRefGetData,
          saveOnBlurSingleField, 
          personContextsDataGet,
          createNewPersonForUser,
          activeContext,
          genContextRecs, } from '../actions';

//const UI_CONFIG = 'UI_CONFIG';

class NewPersonOrContext extends Component{
//based on PersonContext.js

    constructor (props){
        super(props);
        this.state = {
          cat_id: '',
          create_type_ui_indicator: '',
          first_name: '',
          last_name: '',
          pers_id: (this.props.rdcr_person_active[0] !== undefined && this.props.rdcr_person_active[0].id !== '') ? this.props.rdcr_person_active[0].id : '',
          ui_msg_cat: '',
          ui_msg_existing_person: '',
          ui_msg_new_person_fn: '',
          single_expr_cat_id: (this.props.rdcr_user_curr[0].expr_cat_title_default === this.props.rdcr_user_curr[0].expr_cat_title_all && this.props.rdcr_user_curr[0].expr_cat_default_id !== '' && this.props.rdcr_user_curr[0].expr_cat_default_id !== null ? this.props.rdcr_user_curr[0].expr_cat_default_id : '')
        }
        this.handleCreateNewPersonCntxtDyns = this.handleCreateNewPersonCntxtDyns.bind(this)
        this.handleCreateNewContextForExistingOther = this.handleCreateNewContextForExistingOther.bind(this)
   } //end constructor

componentDidMount() {
  console.log('single_expr_cat_idz', this.state.single_expr_cat_id)
  if (this.state.single_expr_cat_id !==''){
    this.setState({
      cat_id: this.state.single_expr_cat_id
    },
    () => {console.log('single_expr_cat_id zzz', this.state.single_expr_cat_id)
  console.log('cat_id zzz', this.state.cat_id)})
  }
  //console.log('this.props.rdcr_persons', this.props.rdcr_persons)
  //this.props.updateUiConfig('UI_CONFIG', 'process_roadmap_mode', 'raid_roadmap')
  
}

handleSetCreateTypeUiIndicator(e){
  this.setState({
    create_type_ui_indicator: e.target.value
  })
}

handleInfoForCreating(whichCreate, cb){
  let msg = ''
  //check if category is selected by use only if not preselected when single_expr_cat_id is the id of cat
  //if preselected then set state.cat_id to that preselected id (single_expr_cat_id)
  if (this.state.single_expr_cat_id ===''){
    if (this.state.cat_id === '') {
      msg = 'Context category needs to be selected.'
      this.setState({
        ui_msg_cat: msg
      })
      return
    }
  }
  
  switch (whichCreate){
    case 'forPersonExisting':
      if (this.state.pers_id === '') {
        msg = 'Person needs to be selected.'
        this.setState({
          ui_msg_existing_person: msg
        })
        return
    }
    else
    {
      cb()
    }
    break;
    case 'forPersonNew':  //this can be creating new person or rapid_roadmap
      if (this.state.first_name === '') {
          msg = 'New person needs to have at least first name.'
          this.setState({
            ui_msg_new_person_fn: msg
          })
          return
      }
      else
      {
        cb()
      }
      break;
      case 'forPersonNewWtOutPerson':  //this can be creating new person or rapid_roadmap
        let dt_stamp = new Date()
          let roadmapAsFirstName = ''
          console.log('this.props.rdcr_ui_config.process_roadmap_mode', this.props.rdcr_ui_config.process_roadmap_mode)
          switch(this.props.rdcr_ui_config.process_roadmap_mode){
          /*          
            case 'rapid_roadmap':
              roadmapAsFirstName = 'Rapid Roadmap'
              break;
            case 'power_roadmap':
              roadmapAsFirstName = 'Power Roadmap'
              break;
          */
            case 'rapid_roadmap':
            case 'power_roadmap':
              console.log('NewContextAndPerson forPersonNewWtOutPerson ', this.props.rdcr_ui_config.process_roadmap_mode)
              roadmapAsFirstName = 'Roadmap'
              break;
          }
          this.setState({
            first_name: roadmapAsFirstName,
            last_name: dt_stamp.toDateString() + ' ' + dt_stamp.toTimeString()
          }, () => {
                    //console.log('azazaz', {first_name: this.state.first_name, last_name: this.state.last_name})
                    cb()})
      break;
    default:
      break
    }
    //IS THIS NEEDED?: cb();    
}

handleCreateNewPersonCntxtDyns(open_page_level='new_person_context') {
    setTimeout(() => {this.setState({
                      loading_msg: 'Loading new person...'
                      })}, 1000);  //1000 = 1 sec
    this.props.createNewPersonForUser('person', () => {
        //open diff UI:
        switch (open_page_level) {
            case "new_person_person":
                this.props.history.push('/PersonDetails');
                break;
            case "new_person_context":
                this.props.history.push('/PeopleContexts');
                break;
            case "open_cntxt_dyn":
                /*
                get context data after creating person-context
                same as in PeopleDetail, context button, function handleGoToContext()
                */
                this.props.personContextsDataGet(this.props.rdcr_person_active[0].id, () => {
                console.log('getting new page after getting context data after creating person-context.....');
                this.props.history.push('/DynGrpWtCntxtAndPerson');
                })
                break;
            default:
                break;
        }
      },
      {first_name: this.state.first_name, last_name: this.state.last_name, cat_id: this.state.cat_id, process_roadmap_mode: this.props.rdcr_ui_config.process_roadmap_mode}
    );
}

handleCreateNewContextForExistingOther(){   
    this.props.personDataGet(this.state.pers_id, () => {
        this.props.createNewPersonForUser('context', () => {
            this.props.history.push('/PeopleContexts');
            }, {cat_id: this.state.cat_id, process_roadmap_mode: this.props.rdcr_ui_config.process_roadmap_mode})
          });
}

handleCreateNewMeTaskAndContext(){

}

handleCreateNewContextForExistingMeTask(){
  
}

handleOnChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  })
}
//, () => console.log('state of ' + e.target.name, this.state.pers_id)

render(){

return (
      <div>
      <Container className='col-sm-12 col-xl-6 m-auto'>
          <Card>
            <CardHeader className="d-flex align-items-center">
              <button type="button"
                className="btn btn-link"
                name='goBackButton'
                value='goBack'
                onClick={() => this.props.history.goBack()}>
                <FontAwesome
                    name="chevron-left"
                />
                back
              </button>
              <div>
                <span>Create new:</span>
                <div hidden={true}> {/*DELCAND_20200929 RAPID/POWER ROADMAP CONCEPT*/}
                  <span className='userTypeDisplay'>{this.props.rdcr_ui_config.process_roadmap_mode === 'rapid_roadmap' ? 'RAPID' : 'POWER'}</span>
                  <span> roadmap mode</span>
                  <span><button className='btn btn-link'
                        onClick={() => this.props.updateUiConfig(UI_CONFIG, 'process_roadmap_mode', this.props.rdcr_ui_config.process_roadmap_mode === 'rapid_roadmap' ? 'power_roadmap' : 'rapid_roadmap')}
                        >(change)</button>
                  </span>
                </div>
              </div>
              <div  hidden={!this.props.rdcr_ui_config.isAdmin} className='userTypeDisplayShort'>
                <label className='label_no_margins'>
                  {this.props.rdcr_ui_config.isAdmin ? 'Logged in as Admin' : ''}
                </label>
              </div>
              <div hidden={true}>
                <label>Type</label>
                <select 
                          className="form-control inputPersonDetails"
                          name='cat_id' 
                          id='cat_id' 
                          value={this.state.cat_id === '' ? 'o-comm' : this.state.cat_id} 
                          onChange={(e) => this.handleOnChange(e)} 
                          >
                        <option value=''>Select</option>
                        <option value='o-comm'>Communication</option>
                        <option value='m-task'>Task/Action</option>
                </select>
              </div>
            </CardHeader>
            <CardBody>
            <FormGroup hidden={this.state.single_expr_cat_id !== '' ? true : false} className='border border-primary'>
                <FormGroup className='m-1' row>
                  <Col sm={12}>
                    <label>
                      Context Category<span className='userTypeDisplay'>**</span>:
                    </label>
                    <button
                      hidden={true} 
                      className="btn btn-secondary float-right"
                      onClick={()=> {this.props.updateUiConfig(UI_CONFIG, 'process_roadmap_mode', 'rapid_roadmap')}}>Rapid Roadmap</button> {/*zzz*/}
                  </Col>
                  <label className='containerRegNewUser__ui_msg_label'>
                    {this.state.ui_msg_cat}
                  </label>
                  <Col sm={12}>
                        <select 
                          className="form-control inputPersonDetails"
                          name='cat_id' 
                          id='cat_id' 
                          value={this.state.cat_id} 
                          onChange={(e) => this.handleOnChange(e)} 
                          >
                          <option value=''>Select</option>
                          {this.props.rdcr_context_cat_ref.map(item => {
                            return <option key={item['id']} value={item['id']}>{item['title']}</option>
                          })}
                        </select>
                  </Col>
                </FormGroup>  
              </FormGroup>
              <FormGroup hidden={this.state.create_type_ui_indicator !== ''} row>
                  <Col sm={12}>
                    <button
                      className="btn btn-primary button_margin_right_bottom"
                        value='for_new_person'
                        onClick={(e)=> this.handleSetCreateTypeUiIndicator(e)}>
                      {this.props.rdcr_ui_config.process_roadmap_mode === 'new_task'? 'Task' : 'Person'}
                    </button>
                    <button
                      className="btn btn-primary button_margin_right_bottom"
                        value='for_existing_person'
                        onClick={(e)=> this.handleSetCreateTypeUiIndicator(e)}>
                      {this.props.rdcr_ui_config.process_roadmap_mode === 'new_task'? 'Associated task (for exiting task)' : 'Communication with existing person'}
                    </button>
                    <button
                      className="btn btn-primary button_margin_right_bottom"
                        value='without_person'
                        onClick={()=> this.handleInfoForCreating('forPersonNewWtOutPerson', this.handleCreateNewPersonCntxtDyns)}>
                      Update later
                    </button>
                  </Col>
              </FormGroup>
          </CardBody>
        </Card>
      <Card hidden={this.state.create_type_ui_indicator !== 'for_existing_person'}>
        <CardHeader>
          With person:
        </CardHeader>
        <CardBody>          
            <FormGroup className='border border-primary'>
                <FormGroup className='m-1' row>
                  <label className='containerRegNewUser__ui_msg_label'>
                    {this.state.ui_msg_existing_person}
                  </label>
                    <Col sm={12}>
                      <select 
                        className="form-control inputPersonDetails"
                        name='pers_id' 
                        id='pers_id' 
                        value={this.state.pers_id} 
                        onChange={(e) => this.handleOnChange(e)} 
                        >
                        <option value=''>Select</option>
                        {this.props.rdcr_persons.map(item => {
                          return <option key={item['id']} value={item['id']}>{item['first_name'] + ' ' + item['last_name']}</option>
                        })}
                      </select>
                      <Col sm={12}>
                          <button hidden={true}
                            type="button" className="btn btn-link"
                            name='goBack' value='goBack' onClick={() => this.props.history.goBack()}>
                            <FontAwesome
                              name="chevron-left"
                            />
                            back
                          </button>
                        <button className="btn btn-primary btn-space_right" 
                          onClick={()=> this.handleInfoForCreating('forPersonExisting', this.handleCreateNewContextForExistingOther)}>
                            Create
                        </button>
                        <button
                            className="btn btn-secondary btn-space_right" 
                            value='cancel'
                            onClick={()=> this.setState({create_type_ui_indicator: ''})}>
                          Cancel
                        </button>
                      </Col>
                    </Col>
                </FormGroup>  
              </FormGroup>
              </CardBody>
            </Card>
            <Card hidden={this.state.create_type_ui_indicator !== 'for_new_person'}>
              <CardHeader>
                <Col sm={12}>
                      <label>
                        New Person:
                      </label>
                </Col>
              </CardHeader>
              <CardBody>
                <FormGroup className='border border-primary'>
                  <FormGroup className='m-1' row>
                    <label className='containerRegNewUser__ui_msg_label'>
                      {this.state.ui_msg_new_person_fn}
                    </label>
                    <Col sm={12}>
                      <Label>First Name:</Label>
                      <Input className="inputPersonDetails" type="text" name='first_name' value={this.state.first_name} onChange={(e) => this.handleOnChange(e)}/>
                      <Label>Last Name:</Label>
                      <Input className="inputPersonDetails" type="text" name='last_name' value={this.state.last_name} onChange={(e) => this.handleOnChange(e)} />
                      <Col sm={12}>
                        <button hidden={true}
                              type="button" className="btn btn-link"
                              name='goBack' value='goBack' onClick={() => this.props.history.goBack()}>
                              <FontAwesome
                                name="chevron-left"
                              />
                              back
                        </button>
                        <button className="btn btn-primary btn-space_right" 
                          onClick={()=> this.handleInfoForCreating('forPersonNew', this.handleCreateNewPersonCntxtDyns)}>
                            Create
                        </button>
                        <button
                          className="btn btn-secondary btn-space_right" 
                            value='cancel'
                            onClick={()=> this.setState({create_type_ui_indicator: ''})}>
                          Cancel
                        </button>
                      </Col>
                    </Col>
                  </FormGroup>
                </FormGroup>
              </CardBody>
            </Card>
            <Card>
              <CardFooter>
                <Row>
                  <Col className="col-12 col-sm-auto">
                    <button type="button"
                      className="btn btn-link"
                      name='goBackButton'
                      value='goBack'
                      onClick={() => this.props.history.goBack()}>
                      <FontAwesome
                          name="chevron-left"
                      />
                      back
                    </button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Container>
        </div>
    );
    }  //end return
  }

//export default PersonContext_NEW;

function mapStateToProps(state) {
  return {
          rdcr_persons: state.rdcr_persons,
          rdcr_person_active: state.rdcr_person_active,
          //
          rdcr_user_curr: state.rdcr_user_curr,
          rdcr_ui_config: state.rdcr_ui_config,
          rdcr_person_active: state.rdcr_person_active,
          rdcr_person_active_contexts: state.rdcr_person_active_contexts,
          rdcr_pers_context_type_ref: state.rdcr_pers_context_type_ref,
          rdcr_context_dyns: state.rdcr_context_dyns,
          rdcr_active_person_contexts_sub: state.rdcr_active_person_contexts_sub,
          rdcr_context_success_ref: state.rdcr_context_success_ref,
          rdcr_context_cat_ref: state.rdcr_context_cat_ref,
          rdcr_persons_context_desc_ref: state.rdcr_persons_context_desc_ref,
          rdcr_persons_context_mode_ref: state.rdcr_persons_context_mode_ref,
        };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      personDataGet: personDataGet,
      //
      saveOnBlurSingleField: saveOnBlurSingleField,
      updateUiConfig,
      switchDynAreaRefBasedOnContextCat: switchDynAreaRefBasedOnContextCat,
      switchPersonContextDescBasedOnContextCat,
      contextDynsDataGet: contextDynsDataGet,
      DynElemRefGetData: DynElemRefGetData, 
      DynExecRefGetData: DynExecRefGetData, 
      DynAreaRefGetData: DynAreaRefGetData,
      personContextsDataGet: personContextsDataGet,
      createNewPersonForUser,
      activeContext,
      genContextRecs,}, dispatch);
}

//export default FormPersonRdx;
export default connect(mapStateToProps, mapDispatchToProps)(NewPersonOrContext);