//import _ from "lodash";

import {USER_CONTEXTS_LIST_DATA__GET } from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_CONTEXTS_LIST_DATA__GET:
      //console.log('rdcr_contexts_list -- USER_CONTEXTS_LIST_DATA__GET -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}