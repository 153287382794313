//Persons_rdx
import _ from "lodash";
import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import FontAwesome from 'react-fontawesome';
import { Container, Row, Col, Card, CardHeader, CardBody, Label, Input} from 'reactstrap';
import {
    UI_CONFIG,
    updateUiConfig,
    personContextModulesGet,
    personContextsTypeRefGet,
    personContextModeRefGet,
    personsDataGet,
    userUsageGrpUserDataGet,
    saveOnBlurSingleField,
} from "../actions";

class Principles extends Component{

constructor (props){
    super(props);
    this.state = {
        //person_fn_filter: this.props.rdcr_persons_srch_crit.first_name_crit,
        //person_ln_filter: this.props.rdcr_persons_srch_crit.last_name_crit,
        isAdmin: false,
        loading_msg: '',
        viewEdMaterialTf: true,
        new_data: false,
        update_single_field: {
            field_name: '',
            field_data: '',
            assoc_id: '',
            entity: '',
            sent_from: ''
        },
        wrkshp_beg_wrkshp_goals: this.props.rdcr_user_usage_grp_user[0].wrkshp_beg_wrkshp_goals !== null ? this.props.rdcr_user_usage_grp_user[0].wrkshp_beg_wrkshp_goals : '',
        wrkshp_beg_comm_pain_points: this.props.rdcr_user_usage_grp_user[0].wrkshp_beg_comm_pain_points !== null ? this.props.rdcr_user_usage_grp_user[0].wrkshp_beg_comm_pain_points : '',
        wrkshp_beg_achiev_pain_points: this.props.rdcr_user_usage_grp_user[0].wrkshp_beg_achiev_pain_points !== null ? this.props.rdcr_user_usage_grp_user[0].wrkshp_beg_achiev_pain_points : '',
    }
}
//*/

//good example for selector(dropdown) setting default: https://stackoverflow.com/questions/21733847/react-jsx-selecting-selected-on-selected-select-option

componentDidMount() {
    window.scrollTo(0,0);
}

componentWillUnmount(){
  this.handleOnBlur();  
}


  handleIsAdmin(){
      if (
        this.props.rdcr_curr_user_admin.permission_type.search('admin') !== -1 ||
        this.props.rdcr_curr_user_admin.permission_type.search('trainer') !== -1
        ) {
            return true
      }
  }

//for UserLanding:

handleOpenNewContextOrPerson(e){
    this.props.updateUiConfig(UI_CONFIG, 'process_roadmap_mode', e.target.name)
    this.props.history.push('/NewPersonOrContext')
}

handleOnChange(e) {
    console.log('e.target.value', e.target.value);
  
    this.setState({
      new_data: true,
      update_single_field: {
        field_name: e.target.name,
        field_data: e.target.value,
        assoc_id: this.props.rdcr_user_curr[0].usage_grp_user_id,
        entity: 'usage_grp_user',
        sent_from: 'Principles'
      },
      [e.target.name]: e.target.value,
      any_change_to_data: true
    })
  }
  
  handleOnBlur(){
    if (this.state.new_data === true){
      this.props.saveOnBlurSingleField(this.state.update_single_field);
      //OLD WAY FROM APP.js:  this.props.saveOnBlur(this.state.update_single_field);
      this.setState({
        new_data: false
      })
      console.log("handleOnBlur ran in FormPerson.js>>>>>>>>");
    }
  }

render(){

    if //(!this.props.rdcr_persons) {  //20180504 pre full redux
    (!this.props.rdcr_user_curr[0].user_id) {
    return <div>Loading...</div>;
    }

    return (
        <div>
            <Container>
                <Card>
                    <CardHeader>
                    </CardHeader>
                    <CardBody>
                        <Row hidden={this.state.viewEdMaterialTf && this.props.rdcr_user_curr[0].location_ed_mat_01 !== '' ? false : true}>
                            <Label>Scroll through Principles:</Label>
                            <Col sm={12}>
                                {/*20200515, 20200608 add iframe*/}
                                <div>
                                    <div className='container_for_resp_iframe'>
                                        <iframe className='responsive-iframe' src={this.state.viewEdMaterialTf && this.props.rdcr_user_curr[0].location_ed_mat_01 !== '' ? this.props.rdcr_user_curr[0].location_ed_mat_01 : ''}></iframe>
                                    </div>
                                    <div hidden>
                                        <a href={this.props.rdcr_user_curr[0].location_ed_mat_01} target="_blank">
                                            View in new window
                                        </a>
                                    </div> 
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
    }
}

function mapStateToProps(state) {
    return {    
                rdcr_ui_config: state.rdcr_ui_config,
                rdcr_user_curr: state.rdcr_user_curr,
                rdcr_curr_user_admin: state.rdcr_curr_user_admin,
                rdcr_user_usage_grp_user:state.rdcr_user_usage_grp_user,
            };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        updateUiConfig,
        personContextModulesGet,
        personContextsTypeRefGet,
        personContextModeRefGet,
        personsDataGet,
        userUsageGrpUserDataGet,
        saveOnBlurSingleField, }, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(Principles);