import {USER_CURR__DATA} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_CURR__DATA:
      //console.log('rdcr_user_curr -- USER_CURR__DATA -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
