import {DYN_AREA_REF_DATA__GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case DYN_AREA_REF_DATA__GET:
      //console.log('rdcr_dyn_area_ref -- DYN_AREA_REF_DATA__GET action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
