import {USAGE_GRPS_FOR_ADMIN__DATA} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USAGE_GRPS_FOR_ADMIN__DATA:
      console.log('rdcr_usage_for_admin -- USAGE_GRPS_FOR_ADMIN__DATA -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
