import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import FontAwesome from 'react-fontawesome';
import { Container, Row, Col, Form, FormGroup, Card, CardBody, CardFooter, Label, Input } from 'reactstrap';
import AppNavBar from './AppNavBar';
import { UI_CONFIG,
          updateUiConfig,
          saveOnBlurSingleField,
          personContextsDataGet,
          personDataGet,
          personsDataFilter } from '../actions'

class PersonDetails extends Component{

    constructor (props){
        super(props);
        this.state = {
          new_data: false,
          update_single_field: {
            field_name: '',
            field_data: '',
            assoc_id: '',
            entity: '',
            sent_from: ''
          },
        id: this.props.rdcr_person_active[0].id,
        last_name: this.props.rdcr_person_active[0].last_name !== null ? this.props.rdcr_person_active[0].last_name : '',
        first_name: this.props.rdcr_person_active[0].first_name !== null ? this.props.rdcr_person_active[0].first_name : '',
        position_title: this.props.rdcr_person_active[0].position_title ? this.props.rdcr_person_active[0].position_title : '',
        position_context: this.props.rdcr_person_active[0].position_context ? this.props.rdcr_person_active[0].position_context : '',          
        url_01: this.props.rdcr_person_active[0].url_01 ? this.props.rdcr_person_active[0].url_01 : '',
        delete_enable: false,
        deleteButtonAction: '',
        any_change_to_data: false
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);

   } //end constructor

componentDidMount() {
  this.props.updateUiConfig(UI_CONFIG, 'appSectionActive', 'Person Details');
  window.scrollTo(0,0);
} 

handleOnChange(e) {
  console.log('e.target.value', e.target.value);

  this.setState({
    new_data: true,
    update_single_field: {
      field_name: e.target.name,
      field_data: e.target.value,
      assoc_id: this.props.rdcr_person_active[0].id,
      entity: 'person',
      sent_from: 'PersonDetails'
    },
    [e.target.name]: e.target.value,
    any_change_to_data: true
  })
    console.log('e.target.name', e.target.name);
    console.log('e.target.value', e.target.value);
    console.log('from handleOnChange -- this.state.new_data', this.state.new_data)
    console.log('from handleOnChange -- this.state.update_single_field', this.state.update_single_field)
}

handleOnBlur(){
  if (this.state.new_data === true){
    this.props.saveOnBlurSingleField(this.state.update_single_field);
    //OLD WAY FROM APP.js:  this.props.saveOnBlur(this.state.update_single_field);
    this.setState({
      new_data: false
    })
    console.log("handleOnBlur ran in FormPerson.js>>>>>>>>");
  }
}

handleOnChangeEnableDeleteText(e){
  if (e.target.value === 'delete') {
    this.setState({
      delete_enable: true,
      deleteButtonAction: 'settingup_delete'
    })
  }
  else {
    this.setState({
      delete_enable: false,
      deleteButtonAction: ''
    })
  }
}

handleOnClickDelete(e){
  switch(this.state.deleteButtonAction){
    case 'settingup_delete':
      this.handleOnChange(e);
      this.setState({
        deleteButtonAction: 'confirming_delete'
      })
      break;

    case 'confirming_delete':
      this.handleOnBlur();
      this.setState({
        deleteButtonAction: ''
      })
      this.props.history.push('/People')
      //console.log('this.props.rdcr_ui_config.person_fn_filter', this.props.rdcr_ui_config.person_fn_filter)
      //console.log('this.props.rdcr_ui_config.person_ln_filter', this.props.rdcr_ui_config.person_ln_filter)
      //this.props.personsDataFilter(this.props.rdcr_ui_config.person_fn_filter, this.props.rdcr_ui_config.person_ln_filter)
      break;
    default:
  }
}

handleGoToContext() {
  this.props.personContextsDataGet(this.props.rdcr_person_active[0].id, () => {
      console.log('getting new page.....');
      ////this.props.handleRouteToPage('PersonContexts_NEW_rdx')
      ////console.log('this.props.history', this.props.history);
      this.props.history.push('/PeopleContexts');
      if (this.state.any_change_to_data) {
        this.props.personDataGet(this.props.rdcr_person_active[0].id) //zzz
      }
      
    }
  );
}

    render(){

    if (this.props.rdcr_person_active[0] === undefined) {
      return(
                          <div>
                              <h4>Loading...</h4>
                          </div>
      )}
    else {
      return (
      <Container className='py-3'>
        <button type="button"
          className="btn btn-link"
          name='goBackButton'
          value='goBack'
          onClick={() => this.props.history.goBack()}>
          <FontAwesome
              name="chevron-left"
          />
          back
        </button>
        <AppNavBar brand="Person Details"/> 
        <Form onSubmit={this.handleSubmit}>
          <Card>
            <CardBody className="col-12 col-sm-12 m-auto py-2">
              <FormGroup row>
                <Col sm={12} md={6}>
                  <Label>First Name:</Label>
                  <Input className="form-control inputPersonDetails" type="text" name='first_name' value={this.state.first_name} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                  <Label>Last Name:</Label>
                  <Input className="form-control inputPersonDetails" type="text" name='last_name' value={this.state.last_name} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Label>Position Title:</Label>
                  <Input className="form-control inputPersonDetails" type="text" name='position_title' value={this.state.position_title} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                  <Label>Position Specifics: </Label>
                  <Input className="form-control textAreaPersonDetails"
                        type="textarea"
                        name='position_context'
                        value={this.state.position_context}
                        onChange={(e) => this.handleOnChange(e)}
                        onBlur={() => this.handleOnBlur()}
                  />
                </Col>
                <Col sm={12}>
                  <label hidden={false}>URL {this.state.url_01 !== '' ? <a hidden={false} href={this.state.url_01.startsWith('https://') || this.state.url_01.startsWith('http://') ? this.state.url_01 : 'https://' + this.state.url_01} rel="noopener noreferrer" target="_blank">Go to</a> : ''}{/*az note: interesting import re opening link in new tab or window: https://www.thesitewizard.com/html-tutorial/open-links-in-new-window-or-tab.shtml */}:</label>
                  <input hidden={false}
                    className="inputPersonDetails" type="text" name='url_01' value={this.state.url_01} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </Col>
              </FormGroup>
            <CardFooter>
              <Row>
                  <Col>
                      <button type="button" className="btn btn-link"
                          name='goBack' value='goBack' onClick={() => this.props.history.goBack()}>
                          <FontAwesome
                            name="chevron-left"
                          />
                          back
                          </button>
                  </Col>
                  <Col className="col-auto ml-auto">
                      <button className="btn btn-primary" type="button" value="Context" onClick={() => this.handleGoToContext()}>Contexts</button>
                  </Col>
              </Row>
              <Row hidden={!this.props.rdcr_ui_config.personDeleteMode}>
                  <Col className="col-12 ml-auto col-sm-auto">
                      <button hidden={!this.state.delete_enable}
                          className='btn btn-outline-secondary mr-2 mb-2 mb-sm-0'
                          type="button"
                          name='delete_dt'
                          value='1/1/2019'
                          onClick={(e) => this.handleOnClickDelete(e)}>
                          {(this.state.deleteButtonAction === 'confirming_delete' ? 'Confirm' : 'Delete')}
                      </button>
                      <input
                        className='inputPersonContextDeleteEnable mr-2 mb-2 mb-sm-0'
                        type="text"
                        name='delete_dt'
                        placeholder='To enable delete, type "delete".'
                        onChange={(e) => this.handleOnChangeEnableDeleteText(e)}     
                      />
                  </Col>
              </Row>
              </CardFooter>
            </CardBody>
          </Card>
        </Form>
      </Container>
      );
    }
  }
}

//export default PersonDetails;

function mapStateToProps(state){
  return{
    rdcr_ui_config: state.rdcr_ui_config,
    rdcr_person_active: state.rdcr_person_active
    }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    updateUiConfig,
    saveOnBlurSingleField, 
    personContextsDataGet,
    personDataGet,
    personsDataFilter }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonDetails);