import {PERSON_CONTEXT_MODE_REF } from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case PERSON_CONTEXT_MODE_REF:
      //console.log('rdcr_persons_context_mode_ref -- PERSON_CONTEXT_MODE_REF -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}