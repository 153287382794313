import {CONTEXT_DYN_DATA_PREV} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case CONTEXT_DYN_DATA_PREV:
      //console.log('rdcr_context_dyns_prev -- CONTEXT_DYN_DATA_PREV -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
