import {USER_CURR_ADMIN} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_CURR_ADMIN:
      //console.log('rdcr_curr_user_admin -- USER_CURR_ADMIN -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
