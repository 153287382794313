import {SINGLE_FIELD_DATA} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case SINGLE_FIELD_DATA:
      //console.log('rdcr_single_field_data -- SINGLE_FIELD_DATA -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
