import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Container, Card, CardHeader, CardBody} from 'reactstrap';
import UserSettingsBasicsChangePw from "./UserSettingsBasicsChangePw"
import { updateUiConfig, loginUser} from "../actions";

class UserSettingsBasics extends Component{

    constructor (props){
        super(props);
        this.state = {
        }
//        this.handleOnChange = this.handleOnChange.bind(this);
//        this.handleOnClick = this.handleOnClick.bind(this);
    }  //end of constructor

    componentDidMount(){
        console.log('this.props.rdcr_user_curr', this.props.rdcr_user_curr)
    }
    
    handleOnSubmit(e){
        e.preventDefault();
        console.log('button clicked:', e.target.name)
        switch(e.target.name) {
            case 'changePw':
                console.log('button clicked2:', e.target.name)
            case 'changePin':
                console.log('button clicked3:', e.target.name)
                this.props.updateUiConfig('UI_CONFIG', 'require_login', true, () => {
                    this.props.updateUiConfig('UI_CONFIG', 'require_login_reason', e.target.name, ()=>{
                        this.props.history.push('Login')}
                    )})
                break;
            default:
                break;
        }
        //this.props.history.push(`/${e.target.name}`);
      }

    renderChangePwFrom(){
        return <div>
                    <UserSettingsBasicsChangePw
                        history={this.props.history}
                    />
                </div>
    }

    render(){

    return (
        <div>
            <Container>
            <div hidden={true}>
            <Card hidden={this.props.rdcr_user_curr[0].meeting_url === ''}>
                    <CardHeader>
                        Class Information (join the meeting <a href={this.props.rdcr_user_curr[0].meeting_url} rel="noopener noreferrer" target="_blank">here{this.props.rdcr_user_curr.meeting_url}</a>)
                    </CardHeader>
                    <CardBody className='pt-2'>
                        <div>
                            <span> </span>       
                        </div>
                    </CardBody>
            </Card>
            </div>
                <Card>
                    <CardHeader>
                        <p>Changing password requires logging in {!this.props.rdcr_user_curr[0].chg_pw_required ? 'before and ' : ''}afterwards.</p>
                    </CardHeader>
                    <CardBody className='pt-2'>
                        <div>
                            <button hidden={this.props.rdcr_ui_config.require_login_reason !== 'changePw' && !this.props.rdcr_user_curr[0].chg_pw_required ? false : true} type="button" className="btn btn-outline" name='changePw' value='changePw' onClick={(e) => this.handleOnSubmit(e)}>Change Password</button>
                        </div>
                        <div hidden={true}>
                            <button hidden={this.props.rdcr_ui_config.require_login_reason !== 'changePin' && !this.props.rdcr_user_curr[0].chg_pw_required ? false : true} type="button" className="btn btn-outline" name='changePin' value='changePin' onClick={(e) => this.handleOnSubmit(e)}>Change Pin</button>
                        </div>
                        <div>
                            {((this.props.rdcr_ui_config.require_login_reason === 'changePw' || this.props.rdcr_ui_config.require_login_reason === 'changePin') && this.props.rdcr_ui_config.require_login_success === true)
                                || this.props.rdcr_user_curr[0].chg_pw_required == true ? this.renderChangePwFrom() : ''
                                }
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
    }
}
//export default UserSettings;

function mapStateToProps(state) {
    return {
        rdcr_user_curr: state.rdcr_user_curr,
        rdcr_ui_config: state.rdcr_ui_config,    
        rdcr_users_for_admin: state.rdcr_users_for_admin
        };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        updateUiConfig, 
        loginUser}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsBasics);