import {UI_CONFIG_DYN_REFINE} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case UI_CONFIG_DYN_REFINE:
      //console.log('rdcr_ui_config_dyn_refine -- UI_CONFIG_DYN_REFINE -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
