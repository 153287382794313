//based on RegNewUserWoAccount
import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Container, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { 
            getUsageGrpsForAdmin,
            setUpUiConfig,
            setUpDefaults,
            verifyUserNameDoesNotExist,
            verifyInviteCodeToRegisterAsUser, 
            userRegisterData, 
            userRegisterDataSave } from "../actions";

class RegNewUserByAdmin extends Component{

    constructor (props){
        super(props)
        this.state = {
            msgToUserEmailIsBadAddress: '',
            reg_button_enabled: true
            }
    }  //end of constructor

componentDidMount() {
    this.props.getUsageGrpsForAdmin(); //gets dataset for usage_grp picklist
    this.props.userRegisterData();  //get reducer with defaults
    this.props.rdcr_user_register.with_account = false; //already a default, but just making sure
    if (this.props.rdcr_ui_config.RegNewUser__verified_user_name_does_not_already_exist === undefined) {
        this.props.setUpUiConfig();
        this.props.setUpDefaults();
    }
    window.scrollTo(0,0);
}

handleOnChange(e) {
    //https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react 
    const val = e.target.value.replace(/'/g, "''")
    this.props.userRegisterData(e.target.name, val)
    //this.props.rdcr_user_register[e.target.name] = e.target.value.replace(/'/g, "''");
    console.log('this.props.rdcr_user_register.' + e.target.name, this.props.rdcr_user_register[e.target.name])
}


handleValidateOverallRegDataBeforeCallingSave(){
    return new Promise((resolve, reject) => {

        //VERIFY DATA AGAIN>>> az_note:need to add email not already exit verificaiton after this promise
        
        //20200107 react brwser devtools issue with escape  /[, tf changes :let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
        if ( re.test(this.props.rdcr_user_register.un) ) {
            console.log('re.test(e.target.value)');
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
            this.setState({
                msgToUserEmailIsBadAddress: ''
            })
        }
        else {
            // invalid email, maybe show an error to the user.
            this.setState({
                msgToUserEmailIsBadAddress: "Email address doesn't seem correct or complete."
            })
        }
        
        //<<<<<<DATA VARIFICATION
    if (
        this.state.msgToUserEmailIsBadAddress === '') {//zzz
            this.setState({
                msgToUserGeneralIssue: ''
            })
            //return true        
            resolve(true)
        }
    else {
        this.setState({
            msgToUserGeneralIssue: 'Issue: ' & this.state.msgToUserEmailIsBadAddress
        })
        //return false
        reject(false)
    }
})
}

handleOnSubmitButtonClickNewUserReg(e){
    e.preventDefault();
    console.log('from handleOnSubmitButtonClickNewUserReg, e.target.name: ', e.target.name )
    if (!this.state.reg_button_enabled){  //if submit button is disabled, don't do anything
        return
    }
    console.log('this.props.rdcr_user_register', this.props.rdcr_user_register);
    this.setState({
        reg_button_enabled: false
    })
    this.handleValidateOverallRegDataBeforeCallingSave()
        .then(() => {
            console.log('this.props.rdcr_user_register.agree_yes_click_dtstamp_UTC', this.props.rdcr_user_register.agree_yes_click_dtstamp_UTC);
            console.log('this.props.rdcr_user_register', this.props.rdcr_user_register);
            this.props.userRegisterDataSave('register', () => {this.props.history.push('/Status')})
            //NOT included as cb: , () => {this.props.history.push('/Login')});
        })
        .catch((error) => {
            console.log('catch in handleValidateOverallRegDataBeforeCallingSave, tf data not valid yet and not saved and agreed to check becomes unchecked.');
            this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn = false;
            this.props.userRegisterData('agreed_yes_check_ToS_n_PP_yn', false)
        })
}

handleOnBlur(e){

}

handleRenderUsageGrpsForSelect() {
    if (this.props.rdcr_usage_grps_for_admin[0] !== undefined) {
        return this.props.rdcr_usage_grps_for_admin.map(item => {
          return <option key={item['id']} value={item['id']}>{item['title'] + ' -- ' + item['ab_desc']}</option>
        })}
}

handleGetDateTimeStamp() {
    return new Promise((resolve, reject) => {
        const dt = new Date().toISOString().slice(0, 19).replace('T', ' ')
            if (dt) {
                resolve(dt)
            }
            else {
                reject('error in handleGetDateTimeStamp')
            }
        }) 
        //return resolve(new Date().toISOString().slice(0, 19).replace('T', ' '))
        //reject('issue with handleGetDateTimeStamp promise');
}
    //return new Date().toISOString().slice(0, 19).replace('T', ' ');


//helpful: https://stackoverflow.com/questions/24471636/inline-bootstrap-form-layout-with-labels-above-inputs}*/

renderRegSection(){
return  <Container>
        <Form 
            onSubmit={this.handleOnSubmitButtonClickNewUserReg.bind(this)}>
                <FormGroup>
                        <Label className='mb-0' htmlFor='fn'>Usage Group</Label>
                        <select
                            className="form-control"
                            name='usage_grp_id' 
                            id='usage_grp_id' 
                            onBlur={this.handleOnBlur}
                            onChange={(e) => this.handleOnChange(e)} >
                            <option value=''>Select</option>
                            {this.handleRenderUsageGrpsForSelect()}
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label className='mb-0' htmlFor='fn'>First Name</Label>
                        <Input
                        type="text"
                        id='fn'
                        name='fn'
                        value={this.props.rdcr_user_register.fn ? this.props.rdcr_user_register.fn : ''} 
                        onBlur={(e) => this.handleOnBlur(e)} 
                        onChange={(e) => this.handleOnChange(e)}    
                        />
                    </FormGroup>
                    <FormGroup hidden>
                        <Label htmlFor='mn' className='mb-0'>Middle Name or Initial</Label>
                        <Input type="text"
                            id='mn'
                            name='mn'
                            value={this.props.rdcr_user_register.mn ? this.props.rdcr_user_register.mn : ''} 
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='ln' className='mb-0' >Last Name</Label>
                        <Input type="text"
                            id='ln'
                            name='ln'
                            value={this.props.rdcr_user_register.ln ? this.props.rdcr_user_register.ln : ''} 
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='un' className='mb-0'>Email address</Label>
                        <Input type="text"
                            id='un' 
                            name='un'
                            value={this.props.rdcr_user_register.un ? this.props.rdcr_user_register.un : ''} 
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='pw' className='mb-0'>Password</Label>
                        <Input type="text"
                            id='pw' 
                            name='pw'
                            value={this.props.rdcr_user_register.pw ? this.props.rdcr_user_register.pw : ''} 
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='company_name' className='mb-0'>Company/Organization</Label>
                        <Input type="text"
                            id='company_name'
                            name='company_name'
                            value={this.props.rdcr_user_register.company_name ? this.props.rdcr_user_register.company_name: ''}
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className='mb-0'>Tell us a bit more about yourself...</Label>
                        <Input
                            type="textarea"
                            id='ab_myself_init'
                            name='ab_myself_init'
                            value={this.props.rdcr_user_register.ab_myself_init ? this.props.rdcr_user_register.ab_myself_init : ''}
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                <FormGroup row>
                    <Col className="col-auto ml-auto">
                    <button type='submit'
                        className={this.state.reg_button_enabled ? "btn btn-primary" : "btn btn-primary disabled"} 
                        name='' value='create_new_user' 
                        >
                        Register
                    </button>
                    </Col>
                </FormGroup>
    </Form>
    </Container>
}

    render(){

    return (
            <div>
                {this.renderRegSection()}
            </div>
    );
    }
}

//export default RegNewUser; //pre redux

function mapStateToProps(state) {
    return {    
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_user_register: state.rdcr_user_register,
        rdcr_usage_grps_for_admin: state.rdcr_usage_grps_for_admin,
        };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUsageGrpsForAdmin, 
        setUpUiConfig: setUpUiConfig,
        setUpDefaults: setUpDefaults,
        verifyUserNameDoesNotExist: verifyUserNameDoesNotExist,
        verifyInviteCodeToRegisterAsUser: verifyInviteCodeToRegisterAsUser,
        userRegisterData: userRegisterData, 
        userRegisterDataSave: userRegisterDataSave}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(RegNewUserByAdmin);