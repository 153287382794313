import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateUiConfig, changePwUser, saveOnBlurSingleField} from "../actions";

class UserSettingsBasicsChangePw extends Component{

  constructor (props){
      super(props);
    this.state = {
      pw: '',
      pw_confirm: '',
      agreed_to_ToU_n_PrivPol: false,
      agreed_to_ToU_n_PrivPol_dt: '',
      ui_msg_pw_n_confirm_not_matched: false,
      msg_re_chng_pw: '',
      update_single_field: {
        field_name: '',
        field_data: '',
        assoc_id: '',
        entity: '',
        sent_from: ''
      },
      label_input: '',
      label_input_confirm: ''
    };
  this.handleOnSubmit = this.handleOnSubmit.bind(this);
    
}  //end constructor

//NOTE: this component is used for both change PW and change PIN. If PW, goes to pw redux action. If PIN does regular (simplified) single field data update using state variable 'update_single_field'
//indicator is this.props.rdcr_ui_config.require_login_reason, either changePw or changePin

componentDidMount() {
  switch (this.props.rdcr_ui_config.require_login_reason){
    case 'changePw':
      this.setState({
        label_input: 'New Password',
        label_input_confirm: 'Confirm Password'
      })
      break;
    case 'changePin':
      this.setState({
        label_input: 'New Personal Pin',
        label_input_confirm: 'Confirm Personal Pin'
      })
      break;
  }
} 

handleOnChangePw(e){
  console.log('e.target.value', e.target.value)
  this.setState({
    [e.target.name]: e.target.value
  })
}

handleOnBlurPin(){
  if (this.props.rdcr_ui_config.require_login_reason === 'changePin') {
    this.setState({
      update_single_field: {
        field_name: 'pin_pers',
        field_data: this.state.pw,
        assoc_id: this.props.rdcr_user_curr[0].user_id,
        entity: 'users',
        sent_from: 'UserSettingBasicChangePw'
      }})
  }
}

handleOnChangeAgreeToSnPrivPol(e){
  this.setState({
    agreed_to_ToU_n_PrivPol: !this.state.agreed_to_ToU_n_PrivPol
  })
}

handleMsgReChangePw() {
  const msg = (this.state.ui_msg_pw_n_confirm_not_matched ? 'Entry does not match confirmation entry\r\n' : '') + 
        (!this.state.agreed_to_ToU_n_PrivPol ? 'Agreement to Terms of Use and Privacy Policy required' : '')
    this.setState(
      {msg_re_chng_pw: msg}
  )
  console.log('this.state.msg_re_chng_pw', this.state.msg_re_chng_pw)
}

handleChangeAgree(e){
  this.setState({
    agreed_to_ToU_n_PrivPol: e.target.checked
  })
}  

/**
display = () => {
  console.log('display', this.state)
}
**/

handleOnSubmit(e){

  e.preventDefault();
  console.log('eeeeee.target', e.target)
  //if ((this.state.pw === this.state.pw_confirm) && (this.state.agreed_to_ToU_n_PrivPol === true)) {
  if (this.state.pw.length >= 6 && this.state.pw === this.state.pw_confirm) {
    this.setState({ui_msg_pw_n_confirm_not_matched: false});
    if (this.state.agreed_to_ToU_n_PrivPol) {
      switch (this.props.rdcr_ui_config.require_login_reason) {
        case 'changePw':
          this.props.changePwUser(e, this.props.history.push('Login'))
          break;
        case 'changePin':
          this.props.saveOnBlurSingleField(this.state.update_single_field)
          this.props.history.push('UserHome')

            //need to figure out what this does not work (20200806)
            //testing/playing around
            /**
            this.setState({
              pw: 'helloAZ'},
              this.display   //this.diplay() works works but not AFTER state update -- exact the opposite of what it should be: eg: https://stackoverflow.com/questions/44243487/setstate-callback-is-not-firing-after-state-change
            )
             */

            break;      
      }
      this.props.updateUiConfig('UI_CONFIG', 'require_login', false)
      this.props.updateUiConfig('UI_CONFIG', 'require_login_reason', '')
      this.props.updateUiConfig('UI_CONFIG', 'require_login_success', false)
    }
    else {
      this.handleMsgReChangePw()
    }
  }
  else {
    this.setState({ui_msg_pw_n_confirm_not_matched: true}, ()=>this.handleMsgReChangePw());
      //() => console.log('this.state.ui_msg_pw_n_confirm_not_matched', this.state.ui_msg_pw_n_confirm_not_matched));
  }
}

render(){

return (
  <div>
    <div>
        <p className='ui_msg_label'>{this.state.msg_re_chng_pw}</p>
        <form name='changePw' onSubmit={this.handleOnSubmit}>
        <label className='containerContextDynamicUnit__label_area'>Create {this.state.label_input}</label>
        <p>User Name: {this.props.rdcr_user_curr[0].un}</p>
               
        <input hidden={true} type="text" name='un' value={this.props.rdcr_user_curr[0].un} readOnly/> {/*20200516tookout, but this whole input should be DELCAND: value={this.props.rdcr_user_curr[0].un}*/}
          <label>
          {this.state.label_input}: <input type="password" name='pw' onChange={(e)=>this.handleOnChangePw(e)}  onBlur={()=>this.handleOnBlurPin()}/>
          </label>
          <label>
            {this.state.label_input_confirm}: <input type="password" name='pw_confirm' onChange={(e)=>this.handleOnChangePw(e)}/>
          </label>
          <div>
              <a href={this.props.rdcr_ui_config.urlTos} target="_blank">
                Terms of Service
              </a>
          </div>    
          <div>
              <a href={this.props.rdcr_ui_config.urlPrivPol} target="_blank">
                Privacy Policy
              </a>
          </div>
          <label>By checking the box and clicking the "Submit" button, I heareby agree to the Datagrasp Terms of Use and Privacy Policy: &nbsp;
                    <input type="checkbox"  
                        checked={this.state.agreed_to_ToU_n_PrivPol}
                        onChange={(e) => this.handleChangeAgree(e)}/>
          </label>
          <input type="submit" value="Submit" />
        </form>
    </div>
  </div>
    );
  }
}

//export default UserSettingChangePw;

function mapStateToProps(state) {
  return {
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_user_curr: state.rdcr_user_curr
      };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    updateUiConfig,
    changePwUser,
    saveOnBlurSingleField}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsBasicsChangePw);