import {USER_USAGE_GRP_USER_DATA__GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_USAGE_GRP_USER_DATA__GET:
      console.log('rdcr_user_usage_grp_user -- USER_USAGE_GRP_USER_DATA__GET -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
