import {USER_PERSONS_DATA__FILTERED } from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_PERSONS_DATA__FILTERED:
      //console.log('rdcr_persons_filtered -- USER_PERSONS_DATA__FILTERED -- action.payload', action.payload);
      return action.payload;
    default:
      return state;
  }
}