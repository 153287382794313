import {EG_ACTION__DATA} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case EG_ACTION__DATA:
      //console.log('rdcr_eg_data -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
