import {UI_CONFIG} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case UI_CONFIG:
      //console.log('rdcr_ui_config -- UI_CONFIG -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
