import {USER_CURR__STATUS} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_CURR__STATUS:
      //console.log('rdcr_user_curr_status -- USER_CURR__STATUS -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
