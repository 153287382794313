import React from 'react';
import ReactDOM from 'react-dom';

import App from './containers/App';
import './App.css';
import { Provider } from "react-redux";  //20180216 redux intro
import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import reducers from "./reducers";

import thunk from 'redux-thunk';
//import logger from 'redux-logger';

const createStoreWithMiddleware = applyMiddleware(promise, thunk)(createStore);

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <App />
  </Provider>,
  document.getElementById('root')
);