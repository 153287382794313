import {SIGNUPS_FOR_MORE_INFO} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case SIGNUPS_FOR_MORE_INFO:
      //console.log('rdcr_signups_for_more_info -- SIGNUPS_FOR_MORE_INFO -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
