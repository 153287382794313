import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateUiConfig,
            verifyUserNameDoesNotExist,
            verifyInviteCodeToRegisterAsUser, 
            userRegisterData, 
            userRegisterDataSave } from "../actions";
import InviteCodeValidation from './InviteCodeValidation';

class RegNewUser extends Component{

    constructor (props){
        super(props)
        this.state = {
            admin_reg_code: '',
            un: '',
            un_confirm: '',
            pw: '',
            pw_confirm: '',
            fn: '',
            ln: '',
            zip: '',
            ab_myself_init: '',
            clicked_link_ToS_dtstamp_UTC: '',
            clicked_link_PrivPol_dtstamp_UTC: '',
            agreed_yes_check_ToS_n_PP_yn: false,
            agreed_yes_check_ToS_n_PP_dtstamp_UTC: '',
            agree_yes_click_dtstamp_UTC: '',
            reg_button_enabled: false,
            msgToUserGeneralIssue: '',
            msgToUserEmailIsBadAddress: '',
            msgToUserEmailIsBadConfirm: '',
            msgToUserPwIsBad: '',
            msgToUserPwIsBadConfirm: '',
            msgToUserFnIsBad: '',
            msgToUserLnIsBad: '',
            msgToUserZipIsBad: ''
        }
    }  //end of constructor

componentDidMount() {
    this.props.updateUiConfig('UI_CONFIG', 'RegNewUser__verify_invite_code_clicked', true, () => {
        //this.props.verifyInviteCodeToRegisterAsUser('1001')
        //
    })
    this.props.userRegisterData();
    window.scrollTo(0,0);
}

handleOnChange(e) {
    this.setState(
        {[e.target.name]: e.target.value}, () => {
            console.log('this.state.[e.target.name]', this.state.admin_reg_code)
            }
    );
    //az? is this ok to do? change the reducer data directly?!?!?!?
    this.props.user_register_data[e.target.name] = e.target.value.replace(/'/g, "''");
    console.log('this.props.user_register_data', this.props.user_register_data);
}

handleOnChangeAgreeToToSAndPP(e) {
    //formating date: //https://stackoverflow.com/questions/5129624/convert-js-date-time-to-mysql-datetime
    console.log('e.target.value', e.target.value);
    console.log('e.target.checked', e.target.checked);
    if (e.target.checked === true) {
        //agreed_datestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
        this.props.user_register_data.agreed_yes_check_ToS_n_PP_dtstamp_UTC = this.handleGetDateTimeStamp();
        //this.props.user_register_data.ui.agree_bttn_disabled = false;
        console.log('e.target.checked if true', e.target.checked);
        this.setState({
            agreed_yes_check_ToS_n_PP_yn: e.target.checked
        }, () => this.handleValidateOverallRegDataBeforeCallingSave()
        .then((r) => {
            this.setState({
                    reg_button_enabled: r
                });
            console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrr', r)
            })
        );
    }
    else {
        this.props.user_register_data.agreed_yes_check_ToS_n_PP_dtstamp_UTC = '';
        this.setState({
            agreed_yes_check_ToS_n_PP_yn: e.target.checked
        });
    }
}

handleClickToSLink(e){
    this.setState({
        [e.target.name]: this.handleGetDateTimeStamp() 
    })
    this.props.user_register_data[e.target.name] = this.handleGetDateTimeStamp();
    console.log('this.props.user_register_data[e.target.name]', this.props.user_register_data[e.target.name])
}

handleValidateOverallRegDataBeforeCallingSave(){
    return new Promise((resolve, reject) => {

        //VERIFY DATA AGAIN>>> zzz_note:need to add email not already exit verificaiton after this promise
        
        //let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //new reg exp becaue of warning on start in terminal:
        let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i
        if ( re.test(this.state.un) ) {
            console.log('re.test(e.target.value)');
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
            this.setState({
                msgToUserEmailIsBadAddress: '',
            })
        }
        else {
            // invalid email, maybe show an error to the user.
            this.setState({
                msgToUserEmailIsBadAddress: "Email address doesn't seem correct or complete.",
            })
        }
        if (this.state.un_confirm !== this.state.un) {
            this.setState({
                msgToUserEmailIsBadConfirm: "Confirmed email does not match. Please check email address entered."
            })
        }
        else {
            this.setState({
                msgToUserEmailIsBadConfirm: '',
            })
        }
        if (this.state.pw.length >= 6) {  //password ok: long enough
            this.setState({
                msgToUserPwIsBad: ""
            })
        }
        else {
            // too short of passwork
            this.setState({
                msgToUserPwIsBad: "Password needs to be at least six characters. ",
            })
        }
        if (this.state.un_confirm !== this.state.un) {
            this.setState({
                msgToUserEmailIsBadConfirm: "Confirmed email does not match. Please check email address entered."
            })
        }
        else {
            this.setState({
                msgToUserEmailIsBadConfirm: '',
            })
        }
        if (this.state.fn.length > 0) {
            this.setState({
                msgToUserFnIsBad: ''
            })
        }
        else {
            this.setState({
                msgToUserFnIsBad: 'Please fill in your first name.'
            })
        }
        if (this.state.ln.length > 0) {
            this.setState({
                msgToUserLnIsBad: ''
            })
        }
        else {
            this.setState({
                msgToUserLnIsBad: 'Please fill in your last name.'
            })
        }
        if (this.state.zip.length !== 5 || isNaN(this.state.zip)) { // not ok, does not match with pw
            this.setState({
                msgToUserZipIsBad: "Zip code should be the the simple 5 digit format. "
            })
        }
        else {
            this.setState({
                msgToUserZipIsBad: ''
            })
        }
        //<<<<<<DATA VARIFICATION

    console.log('this.state.un', this.state.un)
    console.log('this.state.un_confirm', this.state.un_confirm)
    console.log('this.state.pw', this.state.pw)
    console.log('this.state.pw_confirm', this.state.pw_confirm)
    console.log('this.state.fn', this.state.fn)
    console.log('this.state.ln', this.state.ln)
    console.log('this.state.zip', this.state.zip)
    console.log('this.state.agree_yes_click_dtstamp_UTC', this.state.agree_yes_click_dtstamp_UTC)
    console.log('this.state.msgToUserEmailIsBadAddress', this.state.msgToUserEmailIsBadAddress)
    console.log('this.state.msgToUserEmailIsBadConfirm', this.state.msgToUserEmailIsBadConfirm)
    console.log('this.state.msgToUserPwIsBad', this.state.msgToUserPwIsBad)
    console.log('this.state.msgToUserPwIsBadConfirm', this.state.msgToUserPwIsBadConfirm)
    console.log('this.state.msgToUserFnIsBad', this.state.msgToUserFnIsBad)
    console.log('this.state.msgToUserLnIsBad', this.state.msgToUserLnIsBad)
    console.log('this.state.msgToUserZipIsBad', this.state.msgToUserZipIsBad)

    if (
        this.state.un !== '' &&
        this.state.un_confirm !== '' &&
        this.state.pw !== '' &&
        this.state.pw_confirm !== '' &&
        this.state.fn !== '' &&
        this.state.ln !== '' &&
        this.state.zip !== '' &&
        this.state.agreed_yes_check_ToS_n_PP_yn === true &&
        //this.state.agree_yes_click_dtstamp_UTC !== '' &&
        this.state.msgToUserEmailIsBadAddress === '' &&
        this.state.msgToUserEmailIsBadConfirm === '' &&
        this.state.msgToUserPwIsBad === '' &&
        this.state.msgToUserPwIsBadConfirm === '' &&
        this.state.msgToUserFnIsBad === '' &&
        this.state.msgToUserLnIsBad ===  '' &&
        this.state.msgToUserZipIsBad === '') {
            this.setState({
                msgToUserGeneralIssue: ''
            })
            //return true        
            resolve(true)
        }
    else {
        this.setState({
            msgToUserGeneralIssue: "All fields need to be filled out to register."
        })
        //bc can easily (intutively) skipped via on blur:
        if( this.state.fn === '') {
            this.setState({
                msgToUserFnIsBad: "Please fill in your first name."
            })
        }
        else if ( this.state.ln === '') {
            this.setState({
                msgToUserLnIsBad: "Please fill in your last name."
            })
        }
        //return false
        reject(false)
    }
})
}

handleSubmitButtonClickNewUserReg(e){
    e.preventDefault();
    console.log('this.props.user_register_data', this.props.user_register_data)
    this.props.user_register_data.agreed_yes_click_dtstamp_UTC = this.handleGetDateTimeStamp();

    this.handleValidateOverallRegDataBeforeCallingSave()
    .then(() => {
        console.log('this.props.user_register_data.agree_yes_click_dtstamp_UTC', this.props.user_register_data.agree_yes_click_dtstamp_UTC);
        console.log('this.props.user_register_data', this.props.user_register_data);
        this.props.userRegisterDataSave(() => {this.props.history.push('/Login')});
    })
    .catch(() => console.log('catch in handleValidateOverallRegDataBeforeCallingSave, tf data not valid yet and not saved.'))
    
    /*20180703 DELCAND: used to be not in promise:
    if (this.handleValidateOverallRegDataBeforeCallingSave()) {
        this.props.user_register_data.agreed_yes_click_dtstamp_UTC = this.handleGetDateTimeStamp();
        console.log('this.props.user_register_data.agree_yes_click_dtstamp_UTC', this.props.user_register_data.agree_yes_click_dtstamp_UTC);
        console.log('this.props.user_register_data', this.props.user_register_data);
        this.props.userRegisterDataSave(() => {this.props.history.push('/Login')});
    */
}
//zzz
handleOnBlur(e){
    console.log('checking email address good format, TOP?');
    switch (e.target.name) {
        case 'un':
            this.props.verifyUserNameDoesNotExist(e.target.value);
            console.log('checking email address good format?');
            console.log('e.target.name', e.target.name);
            console.log('e.target.value', e.target.value);
            //https://stackoverflow.com/questions/39356826/how-to-check-if-it-a-text-input-has-a-valid-email-format-in-reactjs
            //from StackOF comment: don't remember from where i copied this code, but this works.
            //20200107 react brwser devtools issue with escape  /[, tf changes :let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

            if ( re.test(e.target.value) ) {
                console.log('re.test(e.target.value)');
                // this is a valid email address
                // call setState({email: email}) to update the email
                // or update the data in redux store.
                this.setState({
                    msgToUserEmailIsBadAddress: '',
                })
            }
            else {
                // invalid email, maybe show an error to the user.
                this.setState({
                    msgToUserEmailIsBadAddress: "Email address doesn't seem correct or complete.",
                })
            }
            break;
        case 'un_confirm':
            if (e.target.value !== this.state.un) {
                this.setState({
                    msgToUserEmailIsBadConfirm: "Confirmed email does not match. Please check email address entered."
                })
            }
            else {
                this.setState({
                    msgToUserEmailIsBadConfirm: '',
                })
            }
            break;
        case 'pw':
            console.log('e.target.value.length', e.target.value.length);
            if (e.target.value.length >= 6) {  //password ok: long enough
                this.setState({
                    msgToUserPwIsBad: ""
                })
            }
            else {
                // too short of passwork
                this.setState({
                    msgToUserPwIsBad: "Password needs to be at least six characters. ",
                })
            }
            break;

        case 'pw_confirm':
            console.log('this.state.pw', this.state.pw)
            console.log('e.target.value', e.target.value)
            if (e.target.value !== this.state.pw) { // not ok, does not match with pw
                console.log('in if !==')
                this.setState({
                    msgToUserPwIsBadConfirm: 'Confirmed password does not match. Please check and re-enter.'
                }, () => console.log('this.state.msgToUserPwIsBadConfirm', this.state.msgToUserPwIsBadConfirm))
                
            }
            else {
                this.setState({
                    msgToUserPwIsBadConfirm: '',
                })
            }
            break;

        case 'fn':
            if (e.target.value.length > 0) {
                this.setState({
                    msgToUserFnIsBad: ''
                })
            }
            else {
                this.setState({
                    msgToUserFnIsBad: 'Please fill in your first name.'
                })
            }
            break;

        case 'ln':
        if (e.target.value.length > 0) {
            this.setState({
                msgToUserLnIsBad: ''
            })
        }
        else {
            this.setState({
                msgToUserLnIsBad: 'Please fill in your last name.'
            })
        }
            break;

        case 'zip':
            if (e.target.value.length !== 5 || isNaN(e.target.value)) { // not ok, does not match with pw
                this.setState({
                    msgToUserZipIsBad: "Zip code should be the the simple 5 digit format. "
                })
            }
            else {
                this.setState({
                    msgToUserZipIsBad: ''
                })
            }
            break;
        default:
            break;
        }
    }

handleGetDateTimeStamp() {
    //return Date.now();
    return new Date().toISOString().slice(0, 19).replace('T', ' ');
}

//helpful: https://stackoverflow.com/questions/24471636/inline-bootstrap-form-layout-with-labels-above-inputs}*/

renderRegSectionAfterInviteConfirmed(){
    if (this.props.rdcr_user_register_invite[0] === undefined ||
    this.props.rdcr_user_register_invite[0].used_dt_tm.includes('issue')) {
                console.log('inside if....');
                return <InviteCodeValidation/>
        }
        else {
            if (this.props.rdcr_ui_config.RegNewUser__verify_invite_code_clicked === true) {
                if (this.props.rdcr_user_register_invite[0] !== undefined) {        
                    return  <div>
                            <form 
                                onSubmit={this.handleSubmitButtonClickNewUserReg.bind(this)}>
                                <div>
                                    {/*used with "invite reg code:
                                    <p>
                                    {this.props.rdcr_user_register_invite[0].msg_to_user}
                                    </p>
                                    */}
                                </div>
                                <div>
                                <label>REGISTER</label>        
                                </div>
                                <div>
                                    {/*concatenating via + did not trigger a state change, if the first did not change
                                    so needed to put each msg in it's own label element*/}
                                    <label className='containerRegNewUser__ui_msg_label'>
                                        {this.props.rdcr_ui_config.RegNewUser__verify_user_name_does_not_already_exist ? '' : 'This email address already exists in the system -- you may already be registered or you may have a typo in the email address.'}
                                    </label>        
                                    <label className='containerRegNewUser__ui_msg_label'>
                                        {this.state.msgToUserGeneralIssue}
                                    </label>
                                    <label className='containerRegNewUser__ui_msg_label'>
                                        {this.state.msgToUserEmailIsBadAddress}
                                    </label>
                                    <label className='containerRegNewUser__ui_msg_label'>
                                        {this.state.msgToUserEmailIsBadConfirm}
                                    </label>
                                    <label className='containerRegNewUser__ui_msg_label'>
                                        {this.state.msgToUserPwIsBad}
                                    </label>
                                    <label className='containerRegNewUser__ui_msg_label'>
                                        {this.state.msgToUserPwIsBadConfirm}
                                    </label>
                                    <label className='containerRegNewUser__ui_msg_label'>    
                                        {this.state.msgToUserFnIsBad}
                                    </label>
                                    <label className='containerRegNewUser__ui_msg_label'>    
                                        {this.state.msgToUserLnIsBad}
                                    </label>
                                    <label className='containerRegNewUser__ui_msg_label'>    
                                        {this.state.msgToUserZipIsBad}
                                    </label>
                                             
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <label htmlFor='fn' className="label_leftish">First Name:</label>
                                        <input type="text"
                                            id='fn'
                                            name='fn'
                                            value={this.state.fn} 
                                            onBlur={(e) => this.handleOnBlur(e)} 
                                            onChange={(e) => this.handleOnChange(e)}/>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <label htmlFor='ln'className="label_leftish">Last Name:</label>
                                        <input type="text"
                                            id='ln'
                                            name='ln'
                                            value={this.state.ln} 
                                            onBlur={(e) => this.handleOnBlur(e)} 
                                            onChange={(e) => this.handleOnChange(e)}/>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <label htmlFor='un' className="label_leftish">Email address:</label>
                                        <input type="text"
                                            id='un' 
                                            name='un'
                                            value={this.state.un} 
                                            onBlur={(e) => this.handleOnBlur(e)} 
                                            onChange={(e) => this.handleOnChange(e)}/>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <label htmlFor='un_confirm' className="label_leftish">Confirm email:</label>
                                        <input type="text"
                                            id='un_confirm'
                                            name='un_confirm' 
                                            value={this.state.un_confirm} 
                                            onBlur={(e) => this.handleOnBlur(e)} 
                                            onChange={(e) => this.handleOnChange(e)}/>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <label htmlFor='pw' className="label_leftish">New Password:</label>
                                        <input type="password" 
                                            id='pw'
                                            name='pw'
                                            value={this.state.pw} 
                                            onBlur={(e) => this.handleOnBlur(e)} 
                                            onChange={(e) => this.handleOnChange(e)}/>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <label htmlFor='pw_confirm' className="label_leftish">Confirm Password:</label>
                                        <input type="password"
                                            id='pw_confirm'
                                            name='pw_confirm'
                                            value={this.state.pw_confirm}
                                            onBlur={(e) => this.handleOnBlur(e)} 
                                            onChange={(e) => this.handleOnChange(e)}/>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                    <label htmlFor='zip' className="label_leftish">Zip Code:</label>
                                    <input type="text"
                                        id='zip'
                                        name='zip'
                                        value={this.state.zip}
                                        onBlur={(e) => this.handleOnBlur(e)} 
                                        onChange={(e) => this.handleOnChange(e)}/>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <label htmlFor='ab_myself_init'>Tell us a bit about yourself:</label>
                                        <input className='containerRegisterUser__input_tell_ab_yrself'
                                            type="textarea"
                                            id='ab_myself_init'
                                            name='ab_myself_init'
                                            value={this.state.ab_myself_init}
                                            onBlur={(e) => this.handleOnBlur(e)} 
                                            onChange={(e) => this.handleOnChange(e)}/>
                                    </div>    
                                </div>
                                <br />
                                <div>
                                    <a target="_blank" name='clicked_link_ToS_dtstamp_UTC' onClick={(e) => this.handleClickToSLink(e)} href={this.props.rdcr_user_register_invite[0].terms_of_service_url}>Terms of ServiceNEW</a>
                                </div>
                                <div>
                                    /*<Link to='/ToS' target="_blank" name='clicked_link_ToS_dtstamp_UTC' onClick={(e) => this.handleClickToSLink(e)}>ToS
                                    </Link>*/
                                </div>
                                <div>
                                    {/*<Link to='/privacy-policy' target="_blank" name='clicked_link_PrivPol_dtstamp_UTC' onClick={(e) => this.handleClickToSLink(e)}>Privacy Policy
                                    </Link>*/}
                                </div>
                                <br />
                                <div>
                                    <a target="_blank" name='clicked_link_PrivPol_dtstamp_UTC' onClick={(e) => this.handleClickToSLink(e)} href={this.props.rdcr_user_register_invite[0].priv_pol_url}>Privacy PolicyNEW</a>
                                </div>
                                <br />
                                <div>
                                    <label >By checking the box and clicking the register button, I heareby agree to the Datagrasp Terms of Use and Privacy Policy: &nbsp;
                                    <input type="checkbox" 
                                        id="subscribeNews" 
                                        name="agreed_yes_check_ToS_n_PP_dtstamp_UTC" 
                                        value={this.props.user_register_data.agreed_yes_check_ToS_n_PP_yn} 
                                        onChange={(e) => this.handleOnChangeAgreeToToSAndPP(e)}/>
                                    </label>
                                </div>
                                <br />
                                <div>
                                    <button type='submit'
                                        className={this.state.reg_button_enabled ? "btn btn-primary" : "btn btn-primary disabled"} 
                                        name='' value='create_new_user' 
                                        >
                                        Register
                                    </button>
                                    {/*<Link className={this.state.agreed_yes_check_ToS_n_PP_yn === false? "btn btn-primary disabled" : "btn btn-primary"} to='Peoplez' name='' value='create_new_user' onClick={() => this.handleButtonClickNewUserReg()}>Register as New User
                                    </Link>*/}
                                </div>                                                    
                                <div>
                                    <label className="label_leftish"></label>
                                </div>
                        </form>
                        </div>
            }
        }
    } //close of else
}

    render(){

    return (
            <div className='containerRegisterUser'>
                {this.renderRegSectionAfterInviteConfirmed()}
            </div>
    );
    }
}

//export default RegNewUser; //pre redux

function mapStateToProps(state) {
    return {    
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_user_register_invite: state.rdcr_user_register_invite,
        user_register_data: state.user_register_data,

        };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        updateUiConfig: updateUiConfig,
        verifyUserNameDoesNotExist: verifyUserNameDoesNotExist,
        verifyInviteCodeToRegisterAsUser: verifyInviteCodeToRegisterAsUser,
        userRegisterData: userRegisterData, 
        userRegisterDataSave: userRegisterDataSave}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(RegNewUser);