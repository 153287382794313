import {DATASET_GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case DATASET_GET:
      //console.log('rdcr_dataset -- DATASET_GET -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
