import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import FontAwesome from 'react-fontawesome';
import { Container, Col, FormGroup, Card, CardHeader, CardBody } from 'reactstrap';
import { UI_CONFIG_DYN_REFINE,
          saveOnBlurSingleField, 
          DynAreaRefFilteredGetData,
          contextDynsDataGet,
          updateUiConfig,
          contextDynsDataBlankOut} from '../actions'

//const UI_CONFIG_DYN_REFINE = 'UI_CONFIG_DYN_REFINE';

class ContextDynUnit extends Component {

    constructor (props){
        super(props);
        this.state = {
          new_data: false,
          update_single_field: {
            field_name: '',
            field_data: '',
            assoc_id: '',
            entity: ''
          },
          dynUnitIndex: this.props.dynIndex,
          type_exec: this.props.contextDynamicUnit['type_exec']  || '',
          subj: this.props.contextDynamicUnit['subj'],
          obj: this.props.contextDynamicUnit['obj'],
          eg: this.props.contextDynamicUnit['eg'],
          rationale: this.props.contextDynamicUnit['rationale'],
          type_elem: this.props.contextDynamicUnit['type_elem'] || '',
          type_area: this.props.contextDynamicUnit['type_area'] || '',
          type_area_name: this.props.contextDynamicUnit['type_area_name'] || '',
          type_area_name_prev: this.props.contextDynamicUnit['type_area_name'] || '',
          activePickListAreaElemTypeId: this.props.contextDynamicUnit['type_elem'] || '', //???look at what this is pre-redux and if needed
          prev_area_descript: '',   //delcand
          mirror_area_descript: 'start_with', //delcand 
          contextDynamicsRefineTimesLocal: 0, //delcand
          dyn_refining_started: 'false',
          changeAreaButtonHidden: false,
          changePositionActiveStatus: false,
          changeAreadropDownHidden: false,
          obs_target_ref_id: this.props.contextDynamicUnit['obs_target_ref_id'],
          moreInputVisible: false
        }
        this.setStateForField = this.setStateForField.bind(this);
        this.saveData = this.saveData.bind(this);
        this.handleSavingSingleField = this.handleSavingSingleField.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleIncludeBackIntoPicklist = this.handleIncludeBackIntoPicklist.bind(this);
   } //end constructor

componentDidMount(){
  //for refining...
  if (this.props.rdcr_ui_config.dyn_refining === 'true' && this.props.rdcr_ui_config.dyn_refining_init === 'true' && this.state.dyn_refining_started === 'false') {
    this.setState({
      type_area: this.props.contextDynamicUnit['type_area'] || '',
      type_area_name: this.props.contextDynamicUnit['type_area_name'] || ''
    })
  }
}   

setStateForField(fld_name, fld_value){
  return new Promise ((resolve, reject) => {
    this.setState({
      new_data: true,
      update_single_field: {
        field_name: fld_name,
        field_data: fld_value,
        assoc_id: this.props.contextDynamicUnit['id'],
        entity: 'expr_phunk'
      },
      [fld_name]: fld_value
    }, () => {
      //console.log('setStateForField')
      //console.log('this.state.field_name ' + fld_name)
      //console.log('this.state.field_data ' + fld_value)
    })
    resolve('success');
  })
}

saveData(){
  if (this.state.new_data === true){
    this.props.saveOnBlurSingleField(this.state.update_single_field);
    this.setState({
      new_data: false
    })
    //updating reducer for when back button back to this dyn page, from recmds, for instance
    this.props.contextDynsDataGet(this.props.contextId)
    return true;
  }
}

handleSavingSingleField(e_target_name){
  let p = new Promise((resolve, reject) => {
      let success = this.saveData();
      if (success) {
        resolve()
      }
      else {
        reject("Promise reject message: OK, no need for saving new type bc new area was not saved.")
      }
  })
  return p.then(() => {
    //////////////////this.props.handleContextDyanamicsChangedDataFlag();
    //below if up to the end of this "then" all relates only to change of area picklist
    // when area changed, update and save elem_type as well
    if (e_target_name === 'type_area') {
      let area_id = this.state.update_single_field.field_data
      
      let type_elem_id = this.props.rdcr_dyn_area_ref.find(area => area.id === area_id).phunk_elem_id;      
      //20180603, not used, poss dbl check:let type_area_name = this.props.rdcr_dyn_area_ref.find(area => area.id === area_id).name;      

      let p2 = new Promise ((resolve, reject) => {
        this.setStateForField('type_elem', type_elem_id);
        resolve('success');
      });
      return p2.then(() => {
        this.saveData();
      })
    }
  }).catch((msg) => console.log('msg from promise catch:', msg));
}

//zz filter areas -- need fixing
handleOnChange(e) {
  let e_target_name = e.target.name;
  let e_target_value = e.target.value
  this.setStateForField(e_target_name, e_target_value)
  .then((msg) => {
    //for all fields onChange, just run the function (above)
    //specifically for area picklisth onChange: do all the things needed for area change, including filter picklist and save area single field 
    if(e_target_name === 'type_area') {
      let type_elem_id = this.props.rdcr_dyn_area_ref_filtered.find(area => area.id === e_target_value).phunk_elem_id;      
      this.setState({
        type_area: e_target_value,  //azP make sure this works 20181016
        type_area_name: this.props.rdcr_dyn_area_ref_filtered.find(area => area.id === e_target_value).name
        }, () => {
          //takes the dynID out of the array of those being Area reselected (changed)
          //then updates reducer and also the same reducer for changeArea as false
          let changeDynUnitsIds = this.props.rdcr_ui_config_dyn_refine.changeAreaSelectedDynUnitIds;
          //20200109 changed from map to forEach bc warning message re not returning value from map
          changeDynUnitsIds.forEach((id, i) => {
            if (id === this.props.contextDynamicUnit.id) {
              changeDynUnitsIds.splice(i)
            }
          })
          this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'changeAreaSelectedDynUnitIds', changeDynUnitsIds);
          //AZ NOTE: CHECK IF USED:  this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'changeArea', false)
          this.setState({
            changeAreaButtonHidden: false
          })
        }
      )
      //update prev area type:
      if (this.props.rdcr_ui_config.dyn_refining === 'false') {
        this.setState({
          type_area_name_prev: e_target_value
        })
      }
      if (this.props.rdcr_ui_config.dyn_refining_init === 'true') {
        this.setState({
          dyn_refining_started: 'true'
        })
      }
      //20180513 old pre-redux: this.props.handlePhunkAreaOptionsFilter(type_id, 'exclude');
      this.props.DynAreaRefFilteredGetData('filter_out', type_elem_id)
      this.setState({
        activePickListAreaElemTypeId: type_elem_id
      })
      this.handleSavingSingleField(e_target_name);
      }
  })
  .catch(() => {console.log('something went wrong in handleOnChange')})
}

handleIncludeBackIntoPicklist(){
  this.props.handlePhunkAreaOptionsFilter(this.state.activePickListAreaElemTypeId, 'include');
  this.props.handleContextDynamicsConfigUi('redo');
}

handleOnBlur(e){
  let e_target_name = e.target.name;
  this.handleSavingSingleField(e_target_name);
};

handleInitialRefiningVisibleOrNot (){ //POSS REPLACE WITH:  handleInitialAreaSelectHiddenFT
  if ((!this.state.type_area_name) || 
        (this.props.rdcr_ui_config.dyn_refining === 'true' 
          && this.state.dyn_refining_started === 'false')) {
    return false
  }
  else {
    return true
  }
}

handleInitialAreaSelectHiddenFT (){  
  if (!this.state.type_area_name || this.state.changeAreaButtonHidden)
      /*(this.props.rdcr_ui_config_dyn_refine.changeArea === true 
        && this.props.rdcr_ui_config_dyn_refine.changeAreaSelectedDynUnitIds.includes(
          this.props.contextDynamicUnit.id)))*/ {
    return false  //makes visible
  }
  else {
    return true
  }
}

handleRefiningAreaModeButtonHiddenTF(){
  let a = [];
  if (this.props.rdcr_ui_config_dyn_refine.changeAreaSelectedDynUnitIds.includes(this.props.contextDynamicUnit.id)) {
    return true
  }
  else {
      return false
    }
}

handleDynRefiningButtons(e){  //possibly to take out concep to hiding or showing MOVE button, may be ok to be visible all the time
  switch (e.currentTarget.name) {  //using currentTarget instead of Target bc FontAwesome causing issue with where clicked and therefore with target coming from: https://stackoverflow.com/questions/38757280/button-with-fontawesome-child-makes-the-button-target-value-undefined
    case 'refining_postition_from': 
      if (1===2) {
      }
      else {
      this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'movePositionSourceSelectedDynUnitId', this.props.contextDynamicUnit['id']);
      this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'movePositionSourceSelectedDynTypeExec', this.props.contextDynamicUnit['type_exec']);
      }
      this.setState({
        changePositionActiveStatus: !this.state.changePositionActiveStatus
      })
      this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'changeAreaSelectedDynUnitIds', [])
      break;

    case 'refining_postition_execute_switch':  
      // (1) update first DynUnit select to be switched (not this one)
          this.props.saveOnBlurSingleField({
            'field_name': 'type_exec',
            'field_data': this.props.contextDynamicUnit['type_exec'],  //the selected DynUnit gets this DynUnit's type_exec
            'assoc_id': this.props.rdcr_ui_config_dyn_refine.movePositionSourceSelectedDynUnitId,
            'entity': 'expr_phunk',
            'contextId': this.props.contextId});
      // (2) update this DynUnit with type_exec from previously selected DynUnit (to complete the switch)
      this.props.saveOnBlurSingleField({
                        'field_name': 'type_exec',
                        'field_data': this.props.rdcr_ui_config_dyn_refine.movePositionSourceSelectedDynTypeExec,  //the this DynUnit gets the previously selected DynUnit's type_exec
                        'assoc_id': this.props.contextDynamicUnit['id'],
                        'entity': 'expr_phunk',
                        'contextId': this.props.contextId});
      //update current DnyUnit with new type_exec

      this.setState({
        type_exec: this.props.rdcr_ui_config_dyn_refine.movePositionSourceSelectedDynTypeExec
      }, () => console.log('refining_pos_SWITCH_type_exec_SELECTED_SECOND_AFTER_UPDATE'))
      //clearout selected for type_exec move
      this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'movePositionSourceSelectedDynUnitId', '')
      this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'movePositionSourceSelectedDynTypeExec', '')
      break;

    case 'refining_area_select':
      this.props.DynAreaRefFilteredGetData('filter_in', this.state.type_elem);
      this.props.contextDynsDataBlankOut(this.props.contextDynamicUnit.id);
      this.setState({
        type_area: ''
      })
            ///* 
      //20200603 update type_elem_id to null when type_area set to ''
      let p2 = new Promise ((resolve) => {
        this.setStateForField('type_elem', '');
        resolve('success');
      });
      p2.then(() => {
        this.saveData();
      })
 //*/
      this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'movePositionSourceSelectedDynUnitId', '')
      this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'movePositionSourceSelectedDynTypeExec', '')
      let changeDynUnitsIds = this.props.rdcr_ui_config_dyn_refine.changeAreaSelectedDynUnitIds;
      changeDynUnitsIds.push(this.props.contextDynamicUnit.id);
      this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'changeAreaSelectedDynUnitIds', changeDynUnitsIds);
      //AZ TODO: CHCECK IF USED: this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'changeArea', true);
      this.setState({
        changeAreaButtonHidden: true,
        changePositionActiveStatus: false
      })
      break;
    default:
      break;
  }
}

handleDynRefiningAreaHiddenTF(){
  return !this.handleRefiningAreaModeButtonHiddenTF();
}

handleCardBodyDisplay() {
  if (this.props.rdcr_ui_config_dyn_refine.movePositionSourceSelectedDynUnitId === this.props.contextDynamicUnit.id) {
    return "card border-success mb-3" 
  }
  else {
    return "p-0"
  }
} 

handleRenderMovePositionButton(){
  if (!this.state.changePositionActiveStatus || this.props.rdcr_ui_config_dyn_refine.movePositionSourceSelectedDynTypeExec === '') {
    return <FontAwesome
              name='fas fa-arrow-right'
            />
  }
  else {
    return <FontAwesome
              name='fas fa-arrow-left'
            />
  }
}

    render(){
      return (
      <div>
        <Container className='py-1 mb-1'>   
            <Card className='mb-1'>
              <CardHeader className="small">
                <button hidden={this.props.rdcr_ui_config_dyn_refine.movePositionSourceSelectedDynUnitId !== '' && this.props.rdcr_ui_config_dyn_refine.movePositionSourceSelectedDynUnitId !== this.props.contextDynamicUnit['id'] ? false : true}
                      type="button"
                      className="btn btn-outline-secondary mr-3" 
                      name='refining_postition_execute_switch'
                      value=''
                      onClick={(e) => this.handleDynRefiningButtons(e)}>
                      <FontAwesome
                        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                        name='fas fa-arrow-alt-circle-right'
                      />
                </button>
                {this.props.rdcr_dyn_exec_ref.find((item) => item.id === this.props.contextDynamicUnit['type_exec']).descript}
              </CardHeader>
              <CardBody className={this.handleCardBodyDisplay()}>
                <FormGroup row>
                  <Col sm={12}>
                  <select
                    hidden={this.handleInitialAreaSelectHiddenFT()} 
                    className="form-control containerContextDynamicUnit__dropdownDyanmicPhunkArea" 
                    name='type_area' 
                    value={this.props.rdcr_ui_config.dyn_refining === 'true' ? '' : this.state.type_area} 
                    onChange={(e) => this.handleOnChange(e)} 
                    onBlur={this.handleOnBlur}>
                    <option value="">Select</option>
                    {this.props.rdcr_dyn_area_ref_filtered.map(item => {
                      const terms_dt_tm = new Date(item.term_dt_tm !== null ? item.term_dt_tm : '1/1/1900')
                      const now = new Date('4/1/2020')
                      if (terms_dt_tm <= now) {
                        return <option key={item['id']} value={item['id']}>{item['name']}</option>
                      }
                    })}
                  </select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <label hidden={!this.handleDynRefiningAreaHiddenTF()}
                        className="containerContextDynamicUnit__label_area">
                        {this.state.type_area_name}
                    </label>
                    <label hidden={this.handleDynRefiningAreaHiddenTF()}
                        className="containerContextDynamicUnit__label_area_previous">
                        previous:  {this.state.type_area_name_prev}
                    </label>
                    <button hidden={this.state.changeAreaButtonHidden || !this.handleInitialAreaSelectHiddenFT()}
                      type="button"
                      className="btn btn-outline-secondary float-right mr-3"
                      name='refining_postition_from'
                      value=''
                      onClick={(e)=> this.handleDynRefiningButtons(e)}>
                      {this.handleRenderMovePositionButton()}
                  </button>
                  <button hidden={this.state.changeAreaButtonHidden || !this.handleInitialAreaSelectHiddenFT()}
                      type="button"
                      className="btn btn-outline-secondary float-right mr-3"
                      name='refining_area_select'
                      value=''
                      onClick={(e)=> this.handleDynRefiningButtons(e)}>
                      <FontAwesome
                        name='fas fa-edit'
                        className='btn-space'
                      />
                  </button>
                  <button
                      type="button"
                      className="btn btn-outline-secondary float-right mr-3"
                      name='refining_area_select'
                      value=''
                      onClick={()=> this.setState({moreInputVisible: !this.state.moreInputVisible})}>
                      <FontAwesome
                        name='fas fa-ellipsis-h'
                        className='btn-space'
                      />
                  </button>
                  </Col>
                </FormGroup>
            <label hidden className="label_leftish">type_exec:</label>
            <input hidden type="text" name='type_exec' value={this.props.contextDynamicUnit['type_exec'] ? this.props.contextDynamicUnit['type_exec'] : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
            <label hidden className="label_leftish">descript:</label>
            <input hidden type="text" name='type_exec' value={this.props.contextDynamicUnit['descript'] ? this.props.contextDynamicUnit['descript'] : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
            <label hidden className="hidden" >Subjective</label>
            <input hidden className="hidden" type="text" name='subj' value={this.state.subj ? this.state.subj : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
            <label hidden >Description of area</label>
            <textarea hidden className="textAreaContextDynamicUnit" type="text" name='obj' value={this.state.obj ? this.state.obj : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
            <hr hidden className="hr_divider"/>
            <label hidden>Example(s)</label>
            <textarea hidden className="textAreaContextDynamicUnit" type="text" name='eg' value={this.state.eg ? this.state.eg : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
            <hr hidden className="hr_divider"/>
            <FormGroup row>
                  <Col sm={12}>
            <div>
              <textarea hidden={(this.state.rationale == '' || this.state.rationale == null) && !this.state.moreInputVisible}
                className="textAreaContextDynamicUnit" type="text" name='rationale' placeholder="Example as rationale..." value={this.state.rationale ? this.state.rationale : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
              <div hidden={(this.state.obs_target_ref_id == '' || this.state.obs_target_ref_id == null) && !this.state.moreInputVisible}
                >
                <label className="containerContextDynamicUnit__dropdownDyanmicPhunkArea">Observing:</label>
                    <select
                        hidden={false}
                        className="form-control containerContextDynamicUnit__dropdownDyanmicPhunkArea"
                        name='obs_target_ref_id' 
                        value={this.state.obs_target_ref_id == '' || this.state.obs_target_ref_id == null ? this.props.rdcr_ui_config.context_dyns_grp_obs_target_ref_id : this.state.obs_target_ref_id}
                        onChange={(e) => this.handleOnChange(e)}
                        onBlur={() => this.handleSavingSingleField()}>
                        <option value=''>Select</option>  
                        {this.props.rdcr_dyn_obs_target_ref.map(item => {
                            let uiTitle = item['title'] != 'Other' ? item['title'] : (this.props.rdcr_person_active[0].first_name ? this.props.rdcr_person_active[0].first_name : '') +
                                ' ' + (this.props.rdcr_person_active[0].last_name ? this.props.rdcr_person_active[0].last_name : '')
                            return <option key={item['id']} value={item['id']}>{uiTitle}
                        </option>
                        })}
                    </select>
              </div>
            </div>
            </Col>
            </FormGroup>
            <label hidden >orderby_dom</label>
            <input hidden type="text" name='orderby_dom' value={this.props.contextDynamicUnit['orderby_dom'] ? this.props.contextDynamicUnit['orderby_dom'] : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
            <label hidden >type_elem:</label>
            <label hidden >Category:</label>  <select hidden className="dropdownDyanmicPhunkCat"  name='type_elem' value={this.state.type_elem} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}> 
              <option value="">Dynamic Unit Type...</option>
              {this.props.rdcr_dyn_elem_ref.map(item => {
                return <option key={item['id']} value={item['id']}>{item['descript']}</option>
              })}
            </select>
            <label hidden>AZ NOTE: NEED TO MOVE BUTTONS TO NEXT LEVEL UP</label>
            <div hidden className="containerContextDynamicUnitButtons">
              <button type="button" className="btn btn-primary btn-block" name='' value="RecsAndActions" onClick={() => this.props.getContextRecsAndActions(this.props.contextDynamicUnit['expr_id'])}>Recommendation and Actions</button>
              <br />
              <button type="button" className="btn btn-default" name='goBack' value='goBack' onClick={() => this.props.onClickBackButton()}>Back</button>
            </div>
            <label hidden>entry_dt_tm: {this.props.contextDynamicUnit['entry_dt_tm']}</label>
            <span hidden> *** </span>
            <label hidden>id: {this.props.contextDynamicUnit['id']}</label>
            <span hidden> *** </span>
            <label hidden>id_seq: {this.props.contextDynamicUnit['id_seq']}</label>
            <span hidden> *** </span>
            <label hidden>expr_id: {this.props.contextDynamicUnit['user_id']}</label>
            </CardBody>
          </Card>
        </Container>
      </div>
      );
    }
}

//export default ContextDynUnit;

function mapStateToProps(state){
  return{
    rdcr_person_active: state.rdcr_person_active,
    rdcr_dyn_exec_ref: state.rdcr_dyn_exec_ref,
    rdcr_dyn_elem_ref: state.rdcr_dyn_elem_ref,
    rdcr_dyn_area_ref: state.rdcr_dyn_area_ref,
    rdcr_dyn_area_ref_filtered: state.rdcr_dyn_area_ref_filtered,
    rdcr_ui_config: state.rdcr_ui_config,
    //context_dyns: state.context_dyns,
    rdcr_ui_config_dyn_refine: state.rdcr_ui_config_dyn_refine,
    rdcr_dyn_obs_target_ref: state.rdcr_dyn_obs_target_ref
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    saveOnBlurSingleField: saveOnBlurSingleField,
    DynAreaRefFilteredGetData: DynAreaRefFilteredGetData,
    contextDynsDataGet: contextDynsDataGet,
    updateUiConfig,
    contextDynsDataBlankOut }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextDynUnit);