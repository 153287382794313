import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import UserSettingsAdminChangePw from './UserSettingsAdminChangePw'
import RegNewUserByAdmin from './RegNewUserByAdmin'
import AdminCreateConfigUsageGroups from './AdminCreateConfigUsageGroups'
import { getUsersForAdmin,
            getSignUpsForMoreInfo,
            getDataset } from '../actions'

class UserSettingsAdmin extends Component{

    constructor (props){
        super(props);
        this.state = {
            dataset_requested: 'no data requested'
        }
//        this.handleOnChange = this.handleOnChange.bind(this);
//        this.handleOnClick = this.handleOnClick.bind(this);
    }  //end of constructor

componentDidMount(){
    let crit = '';
    switch (this.props.rdcr_curr_user_admin.permission_type) {
        case 'super_admin':
            crit = 'all'
            break;

            case 'trainer':
                crit = this.props.rdcr_curr_user_admin.user_id;
                console.log('this.props.rdcr_curr_user_admin.user_id', this.props.rdcr_curr_user_admin.user_id)
                console.log('this.props.rdcr_curr_user_admin', this.props.rdcr_user_curr)
                break;

        default:
            break;
    }
    console.log('this.props.rdcr_curr_user_admin.permission_type', this.props.rdcr_curr_user_admin.permission_type)
    console.log('crit!!!', crit);
    if (crit !== '') {
        this.props.getUsersForAdmin(crit);    
    }
    //20190214az commented out, no longer needed because this is done via dataset approach via button, action is commented out too:
    //this.props.publicMoreInfoInterest();  // retreives request for me info into reducer, BUT stops there, data not passed anywhere else yet
}

/* //DELCAND_20190213
handleOnSubmit(e){
    e.preventDefault();
    console.log('button clicked:', e.target.name)
    this.props.history.push(`/${e.target.name}`);
    }
*/

//format data for display:
loginHistoryDataForDisplay(){
    let text = 'LoginHistory:\n';
    let i;
    for (i = 0; i < this.props.rdcr_dataset.length; ++i) {
        text = text + this.props.rdcr_dataset[i].un + ' ' +
            this.props.rdcr_dataset[i].first_name + ' ' + 
            this.props.rdcr_dataset[i].last_name + ' ' +
            'type: ' + this.props.rdcr_dataset[i].action_desc + ' ' +
            'dt: ' + this.props.rdcr_dataset[i].rec_create_dt_tm +
            '\n';
    }
    this.setState({
        dataset_requested: text
    });
}

//format data for display:
InterestedSignupDataForDisplay() {
    let text = 'Interested Signup List:\n';
    let i;
    for (i = 0; i < this.props.rdcr_dataset.length; ++i) {
        text = text + this.props.rdcr_dataset[i].email + ' ' +
        this.props.rdcr_dataset[i].first_name + ' ' +
        this.props.rdcr_dataset[i].last_name + ' ' +
            '\n';            
    }
    this.setState({
        dataset_requested: text
    });
}

//format data for display:
UserAggrCountsForDisplay() {
    let text = 'Users Aggregate Counts:\n';
    let i;
    for (i = 0; i < this.props.rdcr_dataset.length; ++i) {
        text = text + this.props.rdcr_dataset[i].un + ' ' +
        this.props.rdcr_dataset[i].people + ' ' +
        this.props.rdcr_dataset[i].context + ' ' +
            '\n';            
    }
    this.setState({
        dataset_requested: text
    });
}
handleAppStats(e){
    const e_target_value = e.target.value; 
    //format data for display:
    
    let type_of_data_to_get = ''
    switch (e.target.value) {
        case 'LoginHistory':
            type_of_data_to_get = 'user_usage'
            break;

        case 'InterestedSignup':
            type_of_data_to_get = 'interested_signup'
            break;

        case 'Count_UserPersonsContext':
            type_of_data_to_get = 'count_user_persons_context'
            break;
            
        default:
            break;
    }
    //console.log('type_of_data_to_get>>>>', type_of_data_to_get)
    //get the dataset data via action and format accordingly:
    this.props.getDataset(type_of_data_to_get, () => {
        switch (e_target_value) {
            case 'LoginHistory':
            this.loginHistoryDataForDisplay();
            break;
    
            case 'InterestedSignup':
            this.InterestedSignupDataForDisplay();
            break;

            case 'Count_UserPersonsContext':
            this.UserAggrCountsForDisplay();
            break;

            default:
            break;
        }
    })   
}

    render(){

    return (
        <div>
            <div>   
                <UserSettingsAdminChangePw
                    history={this.props.history}
                />
                <RegNewUserByAdmin
                />
                <p>Create/Config Usage Groups:</p>
                <AdminCreateConfigUsageGroups
                />
                <button type="button" className="btn btn-outline" name='Login History' value='LoginHistory' onClick={(e) => this.handleAppStats(e)}>Login History</button>
                <button type="button" className="btn btn-outline" name='Interested Signup List' value='InterestedSignup' onClick={(e) => this.handleAppStats(e)}>Interested Signup List</button>
                <button type="button" className="btn btn-outline" name='Count_UserPersonsContext' value='Count_UserPersonsContext' onClick={(e) => this.handleAppStats(e)}>User persons-context counts</button>
                
                {/*DELCAND_20190213<button type="button" className="btn btn-outline" name='Register' value='Register' onClick={(e) => this.handleOnSubmit(e)}>Register New User</button>*/}
                {/*delcand: <button type="button" className="btn btn-primary" name='Admin' value='Admin' onClick={() => this.props.onClickAdminButton(this.props.onClickAdminButtonArgument)}>Admin</button>*/}
                <p className='display-linebreak'>{this.state.dataset_requested}</p>
                {/*above p did not create new lines using \n without creating class in CSS
                as suggested here: https://stackoverflow.com/questions/35351706/how-to-render-a-multi-line-text-string-in-react
                */}
            </div>
        </div>
    );
    }
}

//export default UserSettings;

function mapStateToProps(state) {
    return {
        rdcr_user_curr: state.rdcr_user_curr,
        rdcr_curr_user_admin: state.rdcr_curr_user_admin,    
        rdcr_users_for_admin: state.rdcr_users_for_admin,
        rdcr_dataset: state.rdcr_dataset
        };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        getUsersForAdmin,
        getSignUpsForMoreInfo,
        getDataset}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsAdmin);