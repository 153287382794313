import {USER_CONTEXTS_DATA__FILTERED } from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_CONTEXTS_DATA__FILTERED:
      //console.log('rdcr_contexts_filtered -- USER_CONTEXTS_DATA__FILTERED -- action.payload', action.payload);
      return action.payload;
    default:
      return state;
  }
}