import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { FormGroup, Col} from 'reactstrap';
import { updateUiConfig } from '../actions'
import AppNavBar from './AppNavBar';
//import ContextRecAndAction001Unit from './ContextRecAndAction001Unit'

class Popup extends React.Component {
    render() {
      return (
        <div className='popup'>
          <div className='popup_inner'>
            <button 
                className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                onClick={this.props.closePopup}>OK
            </button>
<div className="container">
  <div className="row">
    <div className="col-sm-12">
    {this.props.action_rapport}
    </div>
    </div>
</div>
            <p className='text-primary'>Copied to clipboard, rapport and conclude with/deliver:</p>
            <span></span>
            <p>{this.props.action_rapport}</p>
            <p>. . .</p>
            <p>{this.props.action_deliver}</p>
            <span></span>
            <span></span>
            <p>{this.props.text1}</p>
            <p>{this.props.text1}</p>

          </div>
        </div>
      );
    }
  }

class ContextRecAndAction002CpyPst extends Component {

  constructor(props) {
    super(props);
    //change order of recs from prop.context_recs to state.context_recs
    let arry = [];
    arry.push(this.props.rdcr_context_recs_changed[0]);
    arry.push(this.props.rdcr_context_recs_changed[1]);
    arry.push(this.props.rdcr_context_recs_changed[3]);
    arry.push(this.props.rdcr_context_recs_changed[2]);
    this.state = {
        context_recs: arry,
        //context_goal: this.props.person_active_contexts.find(cntx => cntx.id === this.props.rdcr_context_active.contextId).context_goal
        context_goal: this.props.rdcr_ui_config.contextGoalForDisplay,
        showPopup: false,
        variable1: ''
    }
    
} //end constructor

componentDidMount(){
    //console.log('from ContextRecAndAction002CpyPst componentDidMount')
    this.props.updateUiConfig('UI_CONFIG', 'appSectionActive', 'Recommendations & Actions, Copy');
    this.setState({
        //variable1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum'
        variable1: ''
    })
  }

  togglePopup() {  //re popup, from: https://codepen.io/bastianalbers/pen/PWBYvz
    this.setState({
      showPopup: !this.state.showPopup
    });
    //copy to clipboard:
    /* doesn't keep the linebreaks bc using input instead of textarea
    //https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
    let rec_actions_text = 'hello' + this.props.rdcr_context_recs_changed[2].cntnt__recmd_content
        + "\n" + ' ... '
        + "\n"  + this.props.rdcr_context_recs_changed[3].cntnt__recmd_content
    //console.log('rec_actions_text', rec_actions_text)
    let copy_actions = document.createElement("input");
    document.body.appendChild(copy_actions);
    copy_actions.setAttribute('value', rec_actions_text);
    copy_actions.select();
    document.execCommand("copy");
    document.body.removeChild(copy_actions);
    */

    //using textarea to retain the line breaks:
    //https://stackoverflow.com/questions/53617043/how-to-copy-text-and-newlines-in-js
    let rec_actions_text = this.props.rdcr_context_recs_changed[2].cntnt__recmd_content
        + "\r\n" + "\r\n" + ' ... '
        + "\r\n"  + "\r\n" + this.props.rdcr_context_recs_changed[3].cntnt__recmd_content
    //console.log('rec_actions_text', rec_actions_text)
    let copy_actions = document.createElement("textarea");
    //document.body.appendChild(copy_actions);
    copy_actions.appendChild(document.createTextNode(rec_actions_text));
    document.body.append(copy_actions)
    copy_actions.select();
    document.execCommand("copy");
    document.body.removeChild(copy_actions);
    
  }  

    handleNavButtons(e){
        switch (e.target.name) {
            case 'recsAndActionsButton':
                //console.log('from inside switch, case:', e.target.name)
                this.props.genContextRecs(this.props.rdcr_context_dyns[0].expr_id, () => this.props.history.push('/ContextRecsAndActions'))
                break;
            case 'goBackButton':
                //console.log('from inside switch, case:', e.target.name)
                this.props.history.goBack()
                break;
            default:
                break;
        }
    }


    render(){

    return (
        <div className='containerRecommendationGroup'>
                <AppNavBar
                    brand="Communication recommendations"
                />
            <div>
                <FormGroup row>
                    <Col className="col-12">
                    <div>
                        <p className='text-primary'>Copied to clipboard, rapport and conclude with/deliver:</p>
                        <p>{this.props.rdcr_context_recs_changed[3].cntnt__recmd_content}</p>
                        <p>. . .</p>
                        <p>{this.props.rdcr_context_recs_changed[2].cntnt__recmd_content}</p>
            </div>
                    </Col>
                </FormGroup>
            </div>
            <div>
                <FormGroup row>
                    <Col className="col-12 col-sm-auto">
                        <button type="button" 
                            className="btn btn-link mt-2"
                            name='goBackButton'
                            value='goBack'
                            onClick={(e) => this.handleNavButtons(e)}
                            >
                            <i className="fas fa-chevron-left"></i>back
                        </button>
                    </Col>
                    <Col className="col-12 ml-auto col-sm-auto">
                        <button 
                            hidden={true /*hidden for workshop*/}
                            type="button" 
                            className="btn btn-primary btn-primary-spacing"
                            name='ActionsOnly'
                            value='ActionsOnly' 
                            onClick={(e) => this.handleMakeRecmntContentVisibleWithoutRecmdElem()}>
                            {this.props.rdcr_ui_config.recmd_content_visible_only === false ? 'View Actions Only' : 'View Dynamics Set'}
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            disabled={this.props.rdcr_ui_config.recmd_content_visible_only}
                            name='ActionsVisible'
                            value='ActionsVisible' 
                            onClick={(e) => this.handleMakeRecmntContentVisible()}>
                            {this.props.rdcr_ui_config.recmd_content_visible === false ? 'View Actions' : 'Hide Actions'}
                        </button>
                        <button 
                            hidden='true'
                            type="button" 
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            disabled={this.props.rdcr_ui_config.recmd_content_visible_only}
                            name='DosCopyNPaste'
                            value='DosCopyNPaste' 
                            onClick={() => alert('hello')}>
                            {this.props.rdcr_ui_config.recmd_content_visible === false ? 'Copy and Paste' : 'Copy and Paste'}
                        </button>
                        <button
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={this.togglePopup.bind(this)}>Copy/Compose</button>
                    </Col>
                </FormGroup>
            </div>
            {this.state.showPopup ? 
          <Popup
            action_rapport={this.props.rdcr_context_recs_changed[3].cntnt__recmd_content}
            action_deliver={this.props.rdcr_context_recs_changed[2].cntnt__recmd_content}
            text1={this.state.variable1}
            closePopup={this.togglePopup.bind(this)}
          />
          : null
        }
        </div>
    );
    }
    
}

//export default ContextRecsAndActionsGroup;

function mapStateToProps(state){
    return {
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_context_recs: state.rdcr_context_recs,
        rdcr_context_dyns: state.rdcr_context_dyns,
        //person_active_contexts: state.person_active_contexts,
        rdcr_context_active: state.rdcr_context_active,
        rdcr_context_recs_changed: state.rdcr_context_recs_changed
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ 
        updateUiConfig: updateUiConfig }, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(ContextRecAndAction002CpyPst);