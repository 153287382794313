import {PERSON_CONTEXT_MODULES} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case PERSON_CONTEXT_MODULES:
      //console.log('rdcr_pers_context_modules -- PERSON_CONTEXT_MODULES action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
