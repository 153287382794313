import {CONTEXT_RECS_DATA__GET} from "../actions";
import {CONTEXT_RECS_DATA__CHANGED} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case CONTEXT_RECS_DATA__GET:
    case CONTEXT_RECS_DATA__CHANGED:
      //console.log('rdcr_context_recs_changed -- CONTEXT_RECS_DATA__GET or CONTEXT_RECS_DATA__CHNAGED -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
