import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Form, FormGroup, Card, CardBody, CardFooter, Label, Input } from 'reactstrap';
import { egAct } from '../actions';
import { setUpUiConfig,
          setUpUiConfigDynReset,
          setUpDefaults,
          loginUser,
          updateUiConfig,
          contextSuccessRefGet,
          contextCatRefGet
} from '../actions';
import { Link } from "react-router-dom";

class LoginUser extends Component{

    constructor (props){
        super(props);
    this.state = {value: ''};
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
}

    componentDidMount(){
      this.props.egAct();
      //from prev LandingPage
      console.log('before setUpUiConfig() in LoginUser');
      if (this.props.rdcr_ui_config.require_login_reason !== 'changePw' && this.props.rdcr_ui_config.require_login_reason !== 'changePin') {  //20191010 az fix
        this.props.setUpUiConfig();
      }
      ////zzz this.props.setUpUiConfigDynReset();
      ////zzz this.props.setUpDefaults();
    }

    handleOnSubmit(e){
      e.preventDefault();
      console.log('from handleOnSublmit in LoginUser')
      this.props.setUpDefaults(); //added this to clear out user access level info, in case back button and logs in as another user
      //two cb's HACK! really should happen in action creator, bup getting history.push to work there directly needs more work
      this.props.loginUser(e, () => {
        let next_page_to_open = '';
        //zzz
        console.log('require_login', this.props.rdcr_ui_config.require_login)
        console.log('require_login_reason', this.props.rdcr_ui_config.require_login_reason)
        console.log('chg_pw_required', this.props.rdcr_ui_config.chg_pw_required)
        console.log('require_login_reason || require_login_reason', this.props.rdcr_ui_config.require_login_reason === 'changePw' || this.props.rdcr_ui_config.require_login_reason === 'changePin')
        console.log('true && undefined', true && undefined)
        console.log('if clause', (this.props.rdcr_ui_config.require_login === true && (this.props.rdcr_ui_config.require_login_reason === 'changePw' || this.props.rdcr_ui_config.require_login_reason === 'changePin'))
                                  || this.props.rdcr_user_curr[0].chg_pw_required == true
                            )               


        if (
              (this.props.rdcr_ui_config.require_login === true && (this.props.rdcr_ui_config.require_login_reason === 'changePw' || this.props.rdcr_ui_config.require_login_reason === 'changePin'))  //force logging in after changePw
                || this.props.rdcr_user_curr[0].chg_pw_required == true //force change pw when indicated in user data, eg new user, first time logging in
              )
          {

            console.log('aaaaa', this.props.rdcr_ui_config.require_login)
            console.log('aaaaa', this.props.require_login_reason)
            console.log('aaaaa', this.props.rdcr_ui_config.chg_pw_required)

            this.props.updateUiConfig('UI_CONFIG', 'require_login_success', true);
            next_page_to_open = '/UserSettingsBasics';
            console.log('next_page_to_open n LoginUser handleOnSubmit', next_page_to_open)
        }
        else {
          //get picklists options for context:
          this.props.contextSuccessRefGet();
          this.props.contextCatRefGet();
          //set up for opening people list page:
          //next_page_to_open = '/People';
          next_page_to_open = '/UserHome';   //started with this instead of People 20200607

        }
        this.props.history.push(next_page_to_open);
      }, () => {
        this.props.history.push('/SignUpForIntroPresL');
      }
    )
      console.log('from handleOnSublmit end')
    }

    handleOnClickSignIn(e, page_to_open){
      e.preventDefault();
      console.log('button clicked:', e.target.name)
      this.props.history.push(`/${page_to_open}`);
    }

    handleLoginStatusMsg(){
      console.log('handleLoginStatusMsg in LoginUser', this.props.rdcr_user_curr[0].login_status_note)
      if (this.props.rdcr_user_curr[0].login_status_note.includes('unsuccess')) {
        let style = {marginTop: '15px'}
      return <div>
                <label style={style}>{this.props.rdcr_user_curr[0].login_status_note}</label>
                <button hidden={true} type='button' className="btn btn-primary btn-primary-spacing" onClick={(e) => {this.handleOnClickSignIn(e, 'SignUp')}}>Sign up</button>
              </div>
      }
    }
    
    render(){

    return (
      <div className='containerLogin'>
        <Form onSubmit={this.handleOnSubmit}>
          <Card>
            <CardBody className='mx-5'>
          <FormGroup row className='mb-0'>
          <div>This is a beta version of the application. If you have a user account, by logging in you agree to the Terms of Use and Privacy Policy below:
                <div>
                    <a href={this.props.rdcr_ui_config.urlTos} target="_blank">
                        Terms of Service
                    </a>
                </div>  
                <div>
                    <a href={this.props.rdcr_ui_config.urlPrivPol} target="_blank">
                        Privacy Policy
                    </a>
                </div>
          </div>
            </FormGroup>
            <FormGroup row className='mb-3'>
                    <Label>User Name:</Label>
                    <Input type="text" name='un' id='un'/> 
            </FormGroup>
            <FormGroup row>
                    <Label>Password:</Label>
                    <Input type="password" name='pw' id='pw'/> 
            </FormGroup>
            </CardBody>
            <CardFooter>
                <FormGroup> 
                  <input
                    className="btn btn-primary btn-primary-spacing"
                    type="submit"
                    value="Login" />
                    {this.handleLoginStatusMsg()}
                  <br></br>
                    <a href={this.props.rdcr_ui_config.urlContactUs} target="_blank">
                      Not yet a user? Get more info.
                    </a>
                  <br></br>
                  <Link
                    hidden={true}
                    className="ml-3"
                    to='/cai_web_page_signup_for_intro_presentation' target="_blank">
                    Or sign up for an intro presentation.
                  </Link>
                </FormGroup>
            </CardFooter>
          </Card>
        </Form>
      </div>
    );

    }
}

//export default LoginUser;

function mapStateToProps(state) {
  return {    
              eg_data: state.eg_data,
              rdcr_ui_config: state.rdcr_ui_config,
              rdcr_user_curr: state.rdcr_user_curr
          };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
                              setUpUiConfig,
                              setUpUiConfigDynReset,
                              loginUser: loginUser,
                              egAct: egAct,
                              setUpDefaults,
                              updateUiConfig,
                              contextSuccessRefGet,
                              contextCatRefGet }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginUser);