import {ACTIVE_CONTEXT} from "../actions";

export default function(state = {}, action) {

//20190219
//payload is fuller dataset from db when already exists
//payload is just id from the new process -- from action createNewPersonForUser

  switch (action.type) {
    case ACTIVE_CONTEXT:
      //console.log('rdcr_context_active -- ACTIVE_CONTEXT -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
