import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import FontAwesome from 'react-fontawesome';
import { Container, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import {    UI_CONFIG,
            updateUiConfig,
            personContextModulesGet, 
            personContextsTypeRefGet, 
            personContextsDataGet } from '../actions';
import AppNavBar from './AppNavBar';
import PersonContext from './PersonContext';
//import PopupTest from '../components/PopupTest'

class PersonContexts extends Component{

    constructor (props){
        super(props);
        this.state = {
            active_context_or_contexts: this.props.rdcr_person_active_contexts,
            indexOfSingleContext: ''
            //active_context_or_contexts: (this.props.rdcr_ui_config.typeOfBaseListDisplay === 'date') ? this.props.rdcr_person_active_contexts.find(cntxt => cntxt.id === this.props.rdcr_ui_config.contextIdsSelected) : this.props.rdcr_person_active_contexts
        }
    }

    componentDidMount() {
        
        this.props.updateUiConfig(UI_CONFIG, 'appSectionActive', 'Contexts');
        console.log('this.props.rdcr_person_active_contexts[0]', this.props.rdcr_person_active_contexts[0])
        //if coming from create person but start with context:
        if (this.props.rdcr_person_active_contexts[0] === undefined) {
            this.props.personContextsDataGet(this.props.rdcr_person_active[0].id)
        }
        console.log('Person Contexts comp did mount, this.props.rdcr_person_active', this.props.rdcr_person_active[0])
        console.log('Person Contexts comp did mount, this.props.rdcr_person_active_contexts', this.props.rdcr_person_active_contexts[0])
        console.log('in Contexts componentDidMount this.props.rdcr_user_curr::', this.props.rdcr_user_curr)
        console.log('in Contexts componentDidMount this.props.rdcr_user_curr[0]::', this.props.rdcr_user_curr[0])
        console.log('in Contexts componentDidMount this.props.rdcr_user_curr[0].actions_allowed::', this.props.rdcr_user_curr[0].actions_allowed)
    }
        
    handleRenderContexts(){
        return this.props.rdcr_active_person_contexts_for_display.map(d => {
                return <PersonContext
                        personContext={ d }
                        key={d.id}
                        value=''
                        history={this.props.history}
                        />
                })
    }

    render(){
               
        if ( this.state.active_context_or_contexts[0] !== undefined && this.props.rdcr_person_active[0] !== undefined) {
            console.log('>>>>>>', 'Contexts for ' + (this.props.rdcr_person_active[0].first_name ? this.props.rdcr_person_active[0].first_name : '') + (this.props.rdcr_person_active[0].last_name?this.props.rdcr_person_active[0].last_name:''))
            return(     <div>
                        <Container>
                            <Card>
                                <CardHeader className='container_generic_background_white'>
                                    <button type="button"
                                        className="btn btn-link"
                                        name='goBackButton'
                                        value='goBack'
                                        onClick={() => this.props.history.goBack()}>
                                        <FontAwesome
                                            name="chevron-left"
                                        />
                                        back
                                    </button>
                                    <AppNavBar brand={'Contexts for ' + (this.props.rdcr_person_active[0].first_name ? this.props.rdcr_person_active[0].first_name : '') +
                                                        ' ' + (this.props.rdcr_person_active[0].last_name ? this.props.rdcr_person_active[0].last_name : '')                            
                                                        }
                                                history={this.props.history}/>
                                    <div >
                                        <div>
                                            <br/>
                                            <p>({this.props.rdcr_active_person_contexts_for_display.length} of {this.props.rdcr_person_active_contexts.length} contexts)</p>
                                        </div>
                                    </div>
                                </CardHeader>
                                {this.handleRenderContexts()}          
                            </Card>
                        </Container>
                        {/*
                        <PopupTest/>
                        */}
                        </div>
                        
            )
        }
            else {
                return(
                    <div>
                        <h4>Loading...</h4>
                    </div>
                )
            }

        /*20180603, not used/unreachable code, DELCAND
        return (
            <div>
                <div><p>hello</p>
                    <p>{this.state.id}</p>
                    <p>{this.props.rdcr_person_active[0] === undefined ? 'not yet' : this.props.rdcr_person_active[0].id}</p>
                    <div>
                    </div>
                <button type="button" className="btn btn-primary btn-sm" name='' value="Context New"  onClick={() => alert(this.props.rdcr_person_active[0].id)}>test act person</button> 
                </div>
            </div>
        );
        */    
    }
}

//export default PersonContexts_NEW_rdx;

function mapStateToProps(state){
    return{
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_user_curr: state.rdcr_user_curr,
        rdcr_person_active: state.rdcr_person_active,
        rdcr_person_active_contexts: state.rdcr_person_active_contexts,
        rdcr_active_person_contexts_selected: state.rdcr_active_person_contexts_selected,
        rdcr_active_person_contexts_for_display: state.rdcr_active_person_contexts_for_display
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateUiConfig,
                                personContextModulesGet,
                                personContextsTypeRefGet: personContextsTypeRefGet,
                                personContextsDataGet: personContextsDataGet,
                                 }, 
                                dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps )(PersonContexts);