import {PERSON_ACTIVE_CONTEXTS_SUB_DATA__GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case PERSON_ACTIVE_CONTEXTS_SUB_DATA__GET:
      //console.log('rdcr_person_active_contexts_sub action -- PERSON_ACTIVE_CONTEXTS_SUB_DATA__GET -- ', action.payload)
      return action.payload;
    default:
      return state;
  }
}
