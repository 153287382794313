import {USER_LIST_FOR_ADMIN} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_LIST_FOR_ADMIN:
      //console.log('rdcr_user_list_for_admin -- USER_LIST_FOR_ADMIN -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
