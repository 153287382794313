import {CONTEXT_RECS_DATA__GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case CONTEXT_RECS_DATA__GET:
      //console.log('rdcr_context_recs -- CONTEXT_RECS_DATA__GET -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
