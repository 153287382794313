import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, Input, Card, CardBody, CardFooter } from 'reactstrap';
import { updateUiConfig,
          contextDynsDataGet, 
          DynElemRefGetData, 
          DynExecRefGetData, 
          DynAreaRefGetData, 
          saveOnBlurSingleField, 
          personContextsDataGet,
          createNewPersonForUser,
          activeContext,
          createNewAddDynsBranch } from '../actions';

class PersonContextSub extends Component{

    constructor (props){
        super(props);
        this.state = {
          any_change_to_data: false,  //once true stays true while on page -- indicates whether to refresh page data or not upon going to dyns
          new_data: false,
          update_single_field: {
            field_name: '',
            field_data: '',
            assoc_id: '',
            entity: ''
          },
          pers_expr_id: this.props.personContext['id'],
          pers_expr_parent_id: this.props.personContext['pers_expr_parent_id'],
          context_type_001_id: this.props.personContext['context_type_001_id'] || '',
          context_def: this.props.personContext['context_def'],
          context_roles: this.props.personContext['context_roles'],
          context_actions: this.props.personContext['context_actions'],
          humbot_scale_val: this.props.personContext['humbot_scale_val'],
          conflict_scale_val: this.props.personContext['conflict_scale_val'],
          stakes_scale_val: this.props.personContext['stakes_scale_val'],
          perceived_level_of_perform_by_other_val: this.props.personContext['perceived_level_of_perform_by_other_val'] || '',
          show_cntx_levels_yn: false,
          label_desc_01: 'Roles:',
          label_desc_02: 'Scenerio and goals:',
          performance_review_style: false
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleExpandCollapseCntxLevels = this.handleExpandCollapseCntxLevels.bind(this);

   } //end constructor

componentDidMount() {
  this.props.updateUiConfig('UI_CONFIG', 'activePersName', this.props.rdcr_person_active[0].first_name)
  window.scrollTo(0,0);
  //20180825 TEMP, spec for perf review 
  //this.props.rdcr_ui_config.modulesApplied === 'performance_review' || this.props.rdcr_ui_config.modulesApplied === 'performance_review_only' ? false : true
  this.setState({
    performance_review_style: this.props.personContext.pers_expr_parent_id === null ? false : true
    }, () => console.log('!this.state.performance_review_style', !this.state.performance_review_style)
  )
}

handleOnChange(e) {
  console.log('e.target.value', e.target.value); 
  this.setState({
    any_change_to_data: true,
    new_data: true,
    update_single_field: {
      field_name: e.target.name,
      field_data: e.target.value,
      assoc_id: this.props.personContext['id'],
      entity: 'pers_expr'
    },
    [e.target.name]: e.target.value
  })
    console.log('from handleOnChange -- this.state.new_data', this.state.new_data)
    console.log('from handleOnChange -- this.state.update_single_field', this.state.update_single_field)
}

handleOnBlur(){
  if (this.state.new_data === true){
    this.props.saveOnBlurSingleField(this.state.update_single_field);
    this.setState({
      new_data: false
    })
  }
}

handleExpandCollapseCntxLevels(){
  this.setState({
    show_cntx_levels_yn: !this.state.show_cntx_levels_yn
  })
  console.log('this.state.show_cntx_levels_yn', this.state.show_cntx_levels_yn)
}

handleCreateNewContext(){
  console.log('inside handleCreateNewContext')
  this.props.activeContext(this.state.pers_expr_parent_id)
  this.props.createNewPersonForUser('context_sub')
}

handleGoToPersonDetails(){
  this.props.history.push('/PersonDetailz')
  //if change to data, refresh context from db, 
  //for when return to this page via back from next page
  if (this.state.any_change_to_data) {
    this.props.personContextsDataGet(this.props.rdcr_person_active[0].id) 
  }
}

handleGoToDynamics(){
  
    /*
    // TO LOOK CLOSER RE TO PRIMISES, 20180511
    //https://stackoverflow.com/questions/22519784/how-do-i-convert-an-existing-callback-api-to-promises

    let PromiseDynElemRefGetData = () => {
      return new Promise((resolve, reject) => { //returning promise
        this.props.DynElemRefGetData((err, response) => {
          resolve('success from PromiseDynElemRefGetData'); //promise resolve
          if (err) {
            console.log('failure', err)
            reject('failure from PromiseDynElemRefGetData reject'); //promise reject
          }else{
            console.log('success from PromiseDynElemRefGetData', response)
            resolve('success from PromiseDynElemRefGetData'); //promise resolve
          }
        })
      })
    }
    PromiseDynElemRefGetData()
    .then((r) => console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! success', r))
    .catch((err) => console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! err', err))
    */
  //if change to data, refresh context from db, 
  //for when return to this page via back from next page
  if (this.state.any_change_to_data) {
    console.log('from handleGoToPersonDetails, this.props.rdcr_person_active.id', this.props.rdcr_person_active.id)
    this.props.personContextsDataGet(this.props.rdcr_person_active[0].id) 
  }
  
  let functionContextDynsDataGet = this.props.contextDynsDataGet;
  let id = this.props.personContext.id;
  let history = this.props.history
  //failed when not match, tf split in two: const desc = (this.props.rdcr_pers_context_type_ref.find((i) => {return i.id === this.state.context_type_001_id}).type_desc
  const desc_match = this.props.rdcr_pers_context_type_ref.find((i) => {return i.id === this.state.context_type_001_id});
  const desc = desc_match ? desc_match.type_desc : '';
  this.props.updateUiConfig('UI_CONFIG', 'dynGroupTitle', desc);
  this.props.DynExecRefGetData(
      () => this.props.DynElemRefGetData(
        () => this.props.DynAreaRefGetData(
          () => functionContextDynsDataGet(id, () => {
        console.log('getting new context grps page.....');
        history.push('/DynGrp');
        }
      )))
  );
    
    //above chained via cb's replaced attempt of promise that didn't work:
    
    /**********
      let p1 = new Promise((resolve, reject) => {
      let hello = this.props.DynElemRefGetData()
      console.log('hello, hello, hello', hello);
      resolve('success DynElemRefGetData');
      if (hello){
        resolve('success DynExecRefGetData')
      }
      else {
        reject('failure DynExecRefGetData')
      }
    });
    
    let p2 = new Promise((resolve, reject) => {
      
      let hello = this.props.DynExecRefGetData()

      console.log('hello, hello, hello', hello);
      resolve('success DynExecRefGetData');
      if (hello){
        resolve('success DynExecRefGetData')
      }
      else {
        reject('failure DynExecRefGetData')
      }
    });

    let p3 = new Promise((resolve, reject) => {
      if (this.props.DynAreaRefGetData()){
        resolve('success DynAreaRefGetData')
      }
      else {
        reject('failure DynAreaRefGetData')
      }
    });
  let functionContextDynsDataGet = this.props.contextDynsDataGet;
  let id = this.props.personContext.id;
  let history = this.props.history

    //from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all
    Promise.all([p1, p2, p3])
    .then(function(values) {  //zzz
      console.log('then of promise.all', values);
      console.log(values[0].search('success'))
      if (values[0].search('success') !== -1 && values[1].search('success') !== -1 && values[2].search('success') !== -1) {
        console.log('success hello!')
      }
      if (values[0].search('success') !== -1 && values[1].search('success') !== -1 && values[2].search('success') !== -1) {
        functionContextDynsDataGet(id, () => {
          console.log('getting new context grps page.....');
          history.push('/DynGrp');
        });
      }
    })
    .catch((values) => console.log('catch of promise.all', values))
    *******/

}

handleGoToRoadMapBasedOnParentContext(){ //zzz
  //button to kicking off this funciton is only enabled when there are phunks in parent context
  console.log('from handleGoToRoadMapBasedOnParentContext')
  this.props.createNewAddDynsBranch(this.state.pers_expr_id, this.state.pers_expr_parent_id, () => this.props.history.push('/ContextRecsAndActions'))

  /**DELCAND 20180827
  this.props.contextDynsDataGet(this.props.personContext['pers_expr_parent_id'], () => {
    if (this.props.rdcr_context_dyns[0].type_elem !== null) {
            console.log('dyns for parent exist')
          }
    else {
      console.log('dyns for parent DOES NOT exist')
    }
  })
   */
}

handleRenderContextTypePicklist(){
  if (this.props.rdcr_pers_context_type_ref !== undefined) {
    console.log('this.props.rdcr_pers_context_type_ref', this.props.rdcr_pers_context_type_ref)
    return  <Col sm={12} md={6}>
                  <div>
                    <Label for="context_type_001_id">Performance review skillset</Label>
                    <select
                    className="form-control"
                    name='context_type_001_id' 
                    id='context_type_001_id' 
                    value={this.state.context_type_001_id} 
                    onChange={(e) => this.handleOnChange(e)} 
                    onBlur={this.handleOnBlur}>
                    <option value=''>Select</option>
                    {this.props.rdcr_pers_context_type_ref.map(item => {
                      return <option key={item['id']} value={item['id']}>{item['type_desc']}</option>
                    })}
                  </select>
                  </div>
                </Col>
  }
}

handleRenderSubContexts(){
  if (this.props.rdcr_active_person_contexts_sub[0] !== undefined) {
    return this.props.rdcr_active_person_contexts_sub.map((d) => {
      return <p key={d.id}>{d.id}</p>
    })
  }
}

render(){

/*     let styleTitleDesc = {
        fontSize: 14,
        float: 'left'
      };
*/

//https://reactstrap.github.io/components/form/

return (
      <div>
      <Container className='py-3'>
        {/*<Form onSubmit={this.handleSubmit}>*/}
          <Card>
            <CardBody className="col-12 col-sm-12 pb-0 m-auto">   
            <FormGroup 
                      hidden={!this.state.performance_review_style}
                      className='border border-primary'>
                <FormGroup className='m-1' row>
                  {this.props.rdcr_pers_context_type_ref[0] === undefined ? 'UNdefinedzzz' : this.handleRenderContextTypePicklist()}
                </FormGroup>
                <FormGroup className='mx-1 pt-3' row>
                    <Col sm={12} md={6}>
                      <label>Level of performance achieved:</label>
                      <select
                        className="form-control"
                        name='perceived_level_of_perform_by_other_val' 
                        id='perceived_level_of_perform_by_other_val' 
                        value={this.state.perceived_level_of_perform_by_other_val} 
                        onChange={(e) => this.handleOnChange(e)} 
                        onBlur={this.handleOnBlur}>
                        <option value=''>Select</option>
                        <option value='1'>Unsatisfactory (1)</option>
                        <option value='2'>Needs Improvement (2)</option>
                        <option value='3'>Satisfactory (3)</option>
                        <option value='4'>Above Satisfactory (4)</option>
                        <option value='5'>Superior (5)</option>  
                      </select>
                    </Col>
                    {/***
                      <Input className="form-control" 
                          type="text" 
                          name='perceived_level_of_perform_by_other_val'
                          id='perceived_level_of_perform_by_other_val'
                          value={this.state.perceived_level_of_perform_by_other_val ? this.state.perceived_level_of_perform_by_other_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                    ***/}
                </FormGroup>      
              </FormGroup>
              <div hidden={this.state.performance_review_style}>
              <FormGroup row>
                <Col sm={12} md={6}>
                  <div>
                    <label>{this.state.label_desc_01}</label>
                    <Input className="form-control textAreaPersonContext"
                        type="textarea"
                        name='context_roles'
                        placeholder="My role and his/her role..."
                        value={this.state.context_roles ? this.state.context_roles : ''}
                        onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12} md={6}>
                  <div>
                    <Label>{this.state.label_desc_02}</Label>
                    <Input className="form-control textAreaPersonContext" 
                      type="textarea" 
                      name='context_def'  
                      placeholder="Brief description of interpersonal situation and goals..."
                      value={this.state.context_def ? this.state.context_def : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                      {/*<Label>Performance Evaluation Feedback Section</Label>*/}
                      {/*placeholder="Brief description of interpersonal situation and goals..."*/}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12} md={6}>
                {this.handleRenderSubContexts()}
                </Col>
              </FormGroup>
              </div>
              <label hidden>Actions</label>
              <textarea hidden type="text" name='context_actions' value={this.state.context_actions ? this.state.context_actions : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
              <button
                  hidden="true"
                  type="button"
                  className="btn btn-secondary"
                  name='btnExpandCallapseCntxLevels'
                  value='btnExpandCallapseCntxLevels'
                  onClick={() => this.handleExpandCollapseCntxLevels()}>
                    Context Levels +/-</button>
            <div hidden='true'>
              <div className='containerPersonContext__container_cntxt_level' style={{ display: (this.state.show_cntx_levels_yn ? 'block' : 'none') }}>
                <div>
                <label className="label_leftish">Level of surprise (0-3)</label>
                <input type="text" name='surprise_scale_val' value={this.state.surprise_scale_val ? this.state.surprise_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
                <div>
                <label className="label_leftish">"Hume-bot" level (0-3)</label>
                <input type="text" name='humbot_scale_val' value={this.state.humbot_scale_val ? this.state.humbot_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
                <br/>
                <div>
                <label className="label_leftish">Contention level</label>
                <input type="text" name='conflict_scale_val' value={this.state.conflict_scale_val ? this.state.conflict_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
                <br/>
                <div>
                <label className="label_leftish">Level of pressure on other</label>
                <input type="text" name='pressure_on_ot_scale_val' value={this.state.pressure_on_ot_scale_val ? this.state.pressure_on_ot_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
                <br/>
                <div>
                <label className="label_leftish">Level of pressure on me</label>
                <input type="text" name='pressure_on_me_scale_val' value={this.state.pressure_on_me_scale_val ? this.state.pressure_on_me_scale_val : ''} onChange={(e) => this.handleOnChange(e)} onBlur={this.handleOnBlur}/>
                </div>
              </div>
                <label hidden>id: {this.props.personContext['id']}</label>
                <span hidden> *** </span>
                <label hidden>id_seq: {this.props.personContext['id_seq']}</label>
                <span hidden> *** </span>
                <label hidden>pers_id: {this.props.personContext['pers_id']}</label>
                <span hidden> *** </span>
                <label hidden>entry_dt_tm: {this.props.personContext['entry_dt_tm']}</label>
                <span hidden> *** </span>
                <label hidden>start_dt_tm: {this.props.personContext['start_dt_tm']}</label>
                <span hidden> *** </span>
                <label hidden>end_dt_tm: {this.props.personContext['end_dt_tm']}</label>
            </div>
            </CardBody>
            <CardFooter>
              <Row>
                  <Col className="col-12 ml-auto col-sm-auto">
                      <button className="btn btn-outline-secondary mr-2 mb-2 mb-sm-0"  type="button" name='createNewContext' value='createNewContext' onClick={() => this.handleCreateNewContext()}>Add Skillset</button>
                      <button hidden='true' className="btn btn-primary mb-2 mb-sm-0" type="button" value="Dynamics" onClick={() => this.handleGoToDynamics()}>Dynamics</button>
                      <button hidden={!this.props.dynsEstablished} className="btn btn-primary mb-2 mb-sm-0" type="button" value="Dynamics" onClick={() => this.handleGoToRoadMapBasedOnParentContext()}>Roadmap</button>
                  </Col>
              </Row>
              </CardFooter>
            </Card>
          {/*</Form>*/}
      </Container>
    </div>
    );
    }
}

//export default PersonContext_NEW;

function mapStateToProps(state) {
  return {
          rdcr_ui_config: state.rdcr_ui_config,
          rdcr_person_active: state.rdcr_person_active,
          rdcr_pers_context_type_ref: state.rdcr_pers_context_type_ref,
          rdcr_active_person_contexts_sub: state.rdcr_active_person_contexts_sub,
          rdcr_context_dyns: state.rdcr_context_dyns
        };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
      saveOnBlurSingleField: saveOnBlurSingleField,
      updateUiConfig,
      contextDynsDataGet: contextDynsDataGet, 
      DynElemRefGetData: DynElemRefGetData, 
      DynExecRefGetData: DynExecRefGetData, 
      DynAreaRefGetData: DynAreaRefGetData,
      personContextsDataGet: personContextsDataGet,
      createNewPersonForUser,
      activeContext,
      createNewAddDynsBranch }, dispatch);
}

//export default FormPersonRdx;
export default connect(mapStateToProps, mapDispatchToProps)(PersonContextSub);