//import { debug } from "util";

import { BreadcrumbItem } from "reactstrap";
//import { CONSOLE_APPENDER } from "karma/lib/constants";

export const EG_ACTION__DATA = 'EG_ACTION__DATA';
export const UI_CONFIG = 'UI_CONFIG';
export const UI_CONFIG_DYN_REFINE = 'UI_CONFIG_DYN_REFINE';
export const TEST_ACTION = 'TEST_REDUCER_ACTION';
export const SET_STATE_CURR_PERS = 'SET_STATE_CURR_PERS';
export const SIGNUPS_FOR_MORE_INFO = 'SIGNUPS_FOR_MORE_INFO';
export const EVENTS_OFFERING__GET = 'EVENTS_OFFERRED';
export const EVENTS_SELECTED_BY_USER_PRE_SAVE = 'EVENTS_SELECTED_BY_USER_PRE_SAVE';
export const EVENTS_SELECTED_BY_USER = 'EVENTS_SELECTED_BY_USER';
export const USER_REGISTER_VERIFICATION_INVITE_CODE = 'USER_REGISTER_VERIFICATION_INVITE_CODE'
export const USER_REGISTER__DATA = 'USER_REGISTER__DATA'; //not used, dbl check
export const USER_REGISTER__SAVE = 'USER_REGISTER__SAVE'; //check if used
export const USERS_FOR_ADMIN__DATA = 'USERS_FOR_ADMIN__DATA';
export const USAGE_GRPS_FOR_ADMIN__DATA = 'USAGE_GRPS_FOR_ADMIN__DATA';
export const USER_CURR__DATA = 'USER_CURR__DATA';
export const USER_CURR__STATUS = 'USER_CURR__STATUS';
export const USER_CURR_ADMIN = 'USER_CURR_ADMIN';
export const USER_LIST_FOR_ADMIN = 'USER_LIST_FOR_ADMIN';
export const USER_DATA__GET = 'USER_DATA__GET';  //az check may not be used
export const USER_USAGE_GRP_USER_DATA__GET = 'USER_USAGE_GRP_USER_DATA__GET';
export const USER_PERSONS_DATA__GET = 'USER_PERSONS_DATA__GET';
export const USER_CONTEXTS_LIST_DATA__GET = 'USER_CONTEXTS_LIST_DATA__GET';
//DELCAND_20190731: export const PERSONS_SRCH_CRIT = 'PERSONS_SRCH_CRIT';
export const USER_PERSONS_DATA__FILTERED = 'USER_PERSONS_DATA__FILTERED';
//DELCAND_20190731: export const CONTEXTS_SRCH_CRIT = 'CONTEXTS_SRCH_CRIT';
export const USER_CONTEXTS_DATA__FILTERED = 'USER_CONTEXTS_DATA__FILTERED';
export const USER_PERSONS_DATA__UPDATED_SINGLE_FIELD = 'USER_PERSONS_DATA__UPDATED_SINGLE_FIELD';
export const PERSON_ACTIVE_DATA_GET = 'PERSON_ACTIVE_DATA_GET';
export const PERSON_ACTIVE_CONTEXTS_DATA__GET = 'PERSON_ACTIVE_CONTEXTS_DATA__GET';
export const PERSON_ACTIVE_CONTEXTS_SELECTED = 'PERSON_ACTIVE_CONTEXTS_SELECTED';
export const PERSON_ACTIVE_CONTEXTS_SUB_DATA__GET = 'PERSON_ACTIVE_CONTEXTS_SUB_DATA__GET';
export const PERSON_ACTIVE_CONTEXTS_FOR_DISPLAY = 'PERSON_ACTIVE_CONTEXTS_FOR_DISPLAY';
export const PERSON_CONTEXT_MODULES = 'PERSON_CONTEXT_MODULES';
export const PERSON_CONTEXT_TYPE_REF = 'PERS_CONTEXT_TYPE_REF'; 
export const PERSON_CONTEXT_CHAR_REF = 'PERSON_CONTEXT_CHAR_REF'; //20201122
export const PERSON_CONTEXT_DESC_REF = 'PERSON_CONTEXT_DESC_REF'; //20191029
export const PERSON_CONTEXT_DESC_REF_PROF = 'PERSON_CONTEXT_DESC_REF_PROF'; //20200405
export const PERSON_CONTEXT_DESC_REF_PERS = 'PERSON_CONTEXT_DESC_REF_PERS'; //20200405
export const PERSON_CONTEXT_MODE_REF = 'PERSON_CONTEXT_MODE_REF'; //20191221
export const ACTIVE_CONTEXT = 'ACTIVE_CONTEXT';
export const CONTEXT_DYN_DATA__GET = 'CONTEXT_DYN_DATA__GET';
export const CONTEXT_DYN_DATA_PREV = 'CONTEXT_DYN_DATA_PREV';
export const CONTEXT_DYN_DATA__UI_DELCAND20181015 = 'CONTEXT_DYN_DATA__UI';
export const CONTEXT_SUCCESS_REF_DATA__GET = 'CONTEXT_SUCCESS_REF_DATA__GET';
export const CONTEXT_CAT_REF_DATA__GET = 'CONTEXT_CAT_REF_DATA__GET';
export const DYN_ELEM_REF_DATA__GET = 'DYN_ELEM_REF_DATA__GET';
export const DYN_EXEC_REF_DATA__GET = 'DYN_EXEC_REF_DATA__GET';
export const DYN_AREA_REF_DATA__GET = 'DYN_AREA_REF_DATA__GET';
export const DYN_AREA_REF_FILTERED_DATA__GET = 'DYN_AREA_REF_FILTERED_DATA__GET';
export const DYN_AREA_REF_DATA_PROFESSIONAL__GET = 'DYN_AREA_REF_DATA_PROFESSIONAL__GET';
export const DYN_AREA_REF_FILTERED_DATA_PROFESSIONAL__GET = 'DYN_AREA_REF_FILTERED_DATA_PROFESSIONAL__GET';
export const DYN_AREA_REF_DATA_PERSONAL__GET = 'DYN_AREA_REF_DATA_PERSONAL__GET';
export const DYN_AREA_REF_FILTERED_DATA_PERSONAL__GET = 'DYN_AREA_REF_FILTERED_DATA_PERSONAL__GET';
export const DYN_OBS_TARGET_REF__GET = 'DYN_OBS_TARGET_REF__GET';
export const CONTEXT_RECS_DATA__GET = 'CONTEXT_RECS_DATA__GET'
export const CONTEXT_RECS_DATA__CHANGED = 'CONTEXT_RECS_DATA__CHANGED'
export const PUBLIC_MORE_INFO_INTEREST__GET = 'PUBLIC_MORE_INFO_INTEREST__GET';
export const DATASET_GET = 'DATASET_GET';
export const SINGLE_FIELD_DATA = 'SINGLE_FIELD_DATA';
export const ROADMAP_ACTIVE = 'ROADMAP_ACTIVE';

function sendActionFromDispatch(type, data) {
  return {
    type: type,
    payload: data
  };
}

export function app_activity_various(json_data = ''){
  return(dispatch) => {
    //date: https://www.toptal.com/software/definitive-guide-to-datetime-manipulation
    const date = new Date();
    const timestamp = date.toLocaleString();
    if (json_data === '') {
      json_data = {"interested_form": {"datetime_open": timestamp}}
    }
    const myheaders = new Headers();
    myheaders.append('Content-Type', 'application/json'); //required for CORS to match with server (in app.js)
    fetch('/routes/api/app_activity_various_save', { 
                  method: 'POST',
                  body: JSON.stringify(json_data),
                  accept: 'application/json',
                  mode: 'cors',
                  headers: myheaders
                }).then(() => console.log("then of fetch in app_activity_various action") )
                .catch((err) => console.log('error fetch app_activity_various hello Giam', err));
  }
}

//20181114 admin get more info
export function getSignUpsForMoreInfo(cb_open_page){
  return(dispatch) => {
  const fetched_data = fetch('/routes/signups_more_info_for_admin', {
    accept: 'application/json',
  }).then(
    (r) => {
        if (r.status === 200) {
            return r.json()
        }
    })
    .catch((err) => console.log('error fetch SIGNUPS_FOR_MORE_INFO', err));
    
    dispatch(sendActionFromDispatch(SIGNUPS_FOR_MORE_INFO, fetched_data))
      .then(() => {
        if (cb_open_page !== undefined) {
          cb_open_page();
        }
      })
  }
} 

export function userEventsToChooseFrom(cb_open_page){
  return(dispatch) => {
  const fetched_data = fetch('/routes/user_events_to_choose_from', {
    accept: 'application/json',
  }).then(
    (r) => {
        if (r.status === 200) {
            return r.json()
        }
    })
    .catch((err) => console.log('error fetch EVENTS_OFFERING__GET', err));
    
    dispatch(sendActionFromDispatch(EVENTS_OFFERING__GET, fetched_data))
      .then(() => {
        if (cb_open_page !== undefined) {
          cb_open_page();
        }
      })
  }
}  

export function verifyInviteCodeToRegisterAsUser(invite_code){
  return(dispatch) => {
    //fetch('/routes/invite_code_verificaiton?i=' + invite_code, {
      fetch('/routes/invite_code_verificaiton/?code=' + invite_code, {
      accept: 'application/json',
    }).then(
      (r) => {
          if (r.status === 200) {
              return r.json()
          }
      })
      .then((r) => {
        dispatch(sendActionFromDispatch(USER_REGISTER_VERIFICATION_INVITE_CODE, r))
      })
      .catch((err) => console.log('error in verifyInviteCodeToRegisterAsUser', err))
  }  
}

export function egAct(){

  const eg_data = {
    text1: 'hello from egAct action/reducter',
    text2: 'hello2'
  }

  return {
    type: EG_ACTION__DATA,
    payload: eg_data
  };
}

export function setUpDefaults() {
  return(dispatch) => {
    let curr_user = [{un: '', user_id: '', login_status_note: '', actions_allowed: ''}]
    dispatch(sendActionFromDispatch(USER_CURR__DATA, curr_user));

    /*DELCAND_20190731
    const crit = {
      first_name_crit: '',
      last_name_crit: ''
    }
    dispatch(sendActionFromDispatch(PERSONS_SRCH_CRIT, crit))
    */

    const adminUser = {
      permission_type: '',
      user_id: '',
      permission_type_display: '',
      curr_viewing_data_of_another: false,
      user_whose_data_being_viewed: '',
      user_whose_data_being_viewed_display_name_in_grp: ''
    }
    dispatch(sendActionFromDispatch(USER_CURR_ADMIN, adminUser));

    const events_to_chose_from = [{
      id: ''
    }]
    dispatch(sendActionFromDispatch(EVENTS_OFFERING__GET, events_to_chose_from));
    
    const user_events_selected = [{
      user_id: '',
      event_id: ''
    }]
    dispatch(sendActionFromDispatch(EVENTS_SELECTED_BY_USER, user_events_selected));

    //bring in all dyns
    dispatch(DynAreaRefGetData())
  }
}

export function setUpUiConfig() {
  let ui_config = {
      ui_metadata: {}, //20191028 for context page
      urlTos: 'https://www.complexability.com/s/Datagrasp-Terms-of-UseJ-4846-1533-8658_1_AZ_01_20200426b_az_FINAL.pdf', //prev: 'https://www.complexability.com/s/DataGrasp-ToU-4418_nk_20180917az-blcw.pdf',
      urlPrivPol: 'https://www.complexability.com/s/Datagrasp-Privacy-PolicyJ-4812-4587-3570_1_AZ_01_20200426b_az_FINAL.pdf', //prev: 'https://www.complexability.com/s/DataGrasp-PP-4218_nk_20180917az.pdf',
      urlContactUs: 'https://www.complexability.com/contact',
      isAdmin: false,
      privacy_mode: true,
      process_roadmap_mode: '',  //'power_roadmap' or 'rapid_roadmap
      userAllowedActions: '',
      typeOfBaseListDisplay: 'date', //'date', //'name',
      person_fn_filter: '',
      person_ln_filter:'',
      list_filter_crit:'',
      list_filter_crit_exec_from_another_function: false,
      activePersName: '',
      newContext: false,
      newContextExprId: '',
      contextAllPerGrpOrSelected: 'all',  // 'selected' -- AZ NOTE NEED TO UPDATE with element 'contextIdsSelected' (below)
      contextIdsSelected: [], //20190712 from list page context mode to pass to context page -- can leverage this more as active context state
      activeContextId: '', //20190712 not yet used
      appSectionActive: '',  //needs to be cleared/changed after use
      modulesApplied: 'default',
      dynGroupTitle: '',
      msg_response_gen_01: '', //needs to be cleared after use
      msg_response_gen_02: '', //needs to be cleared after use
      RegNewUser__verified_user_name_does_not_already_exist: true,
      RegNewUser__verify_invite_code_clicked: false,
      personDeleteMode: false,
      contextDeleteMode: false,
      contextCat: 'Personal',
      //zzzcontextDefVisibleYn: false,
      contextDescForDisplay: '',
      contextDefForDisplay: '',
      contextGoalForDisplay: '',
      contextDisharmonyLevel: '',
      contextFlagCreatingInProgress: false,
      context_dyns_grp_id: '',
      context_dyns_grp_obs_target_ref_id: '',
      context_dyns_established: false,
      context_dyn_unit_dyns_est: {},
      dyn_refining: 'false',
      dyn_refining_init: 'false',
      recmd_roadmap_display_config: 'position_one',
      recmd_content_display_deeper_dive_buttons: false,
      recmd_content_visible: false,
      recmd_content_visible_only: false,
      require_login: false,
      require_login_reason: '',
      require_login_success: false
    }
  
  return {
    type: UI_CONFIG,
    payload: ui_config
  };
}

export function setUpUserUiSettingsDefaults() {
  //20190226

}

export function setUpUiConfigDynReset() {
  
  let ui_config = {
      changeArea: false,
      movePositionSourceSelectedDynUnitId: '',
      movePositionSourceSelectedDynTypeExec: '',
      changeAreaSelectedDynUnitIds: []
    }
  
  return {
    type: UI_CONFIG_DYN_REFINE,
    payload: ui_config
  };
}

export function updateUiConfig(whichUiConfigToUpdate, name_of_item, value, cb_optional){

  return(dispatch, getState) => {

    let uiConfigReducer = [];
    switch (whichUiConfigToUpdate) {
      case UI_CONFIG:
        const { rdcr_ui_config } = getState();
        uiConfigReducer = rdcr_ui_config;
        break;
      case UI_CONFIG_DYN_REFINE:
        const { rdcr_ui_config_dyn_refine } = getState();
        uiConfigReducer = rdcr_ui_config_dyn_refine;
        break;
      default:
        break;
    }

    //20190714 needs special treatmenet like adding to array -- default is simple value to name
    switch (name_of_item) {
      case 'contextIdsSelected':
        switch (value[0]) {
        case 'clear':
            uiConfigReducer[name_of_item] = []
            uiConfigReducer.contextAllPerGrpOrSelected = 'all'
            //empty out selected context(s):
            dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_SELECTED, []))
            break;
        case 'reset':
            uiConfigReducer[name_of_item] = [value[1]]
            uiConfigReducer.contextAllPerGrpOrSelected = 'selected'
            break;
        case 'add':
          //add to ids array
          uiConfigReducer[name_of_item].unshift(value[1]);
          break;
        default:
          break
        }
        break;

      default:
          uiConfigReducer[name_of_item] = value
          break;
      }
    
    //in order not to mutate (mutable) the array, need to recreate copy
    //this will trigger a rendering in react while mutating will not
    //DELCAND20190726: const new_ui_config = Object.create(uiConfigReducer)
    const new_ui_config = Object.assign({}, uiConfigReducer)
    dispatch(sendActionFromDispatch(whichUiConfigToUpdate, new_ui_config))
    if (cb_optional !== undefined) {
      cb_optional();
    }
  }
}

export function getUsersForAdmin (crit){
  //20190929 AZ NEED TO PASS user  TO route
  return (dispatch) => {
    //DELCAND react brwsr devtools warning fetched_data not used: const fetched_data = fetch('/routes/users_for_admin/?crit=' + crit, {
    return fetch('/routes/users_for_admin/?crit=' + crit, {  
      method: 'get',
      accept: 'application/json',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
      },
      credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
    })
    // /*
    .then(
      r => {
        if (r.status === 200) {
            console.log('successfully got users for admin');
            return r.json();
        } 
    })
    .then((r) => dispatch(sendActionFromDispatch(USERS_FOR_ADMIN__DATA, r)))
  }
}

export function getUsageGrpsForAdmin (){
  return (dispatch) => {
    return fetch('/routes/usage_grps_for_admin/', {  
      method: 'get',
      accept: 'application/json',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
      },
      credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
    })
    // /*
    .then(
      r => {
        if (r.status === 200) {
            console.log('successfully got users for admin');
            return r.json();
        }
    })
    .then((r) => dispatch(sendActionFromDispatch(USAGE_GRPS_FOR_ADMIN__DATA, r)))
    .catch((err) => console.log('error action getUsageGrpsForAdmin: ', err))
  }
}


export function userRegisterData(fieldToChangeOptional, newDataOptional = '', PageToHistoryPushOptinal) {
  return(dispatch, getState) => {
  let user_register_data = {
    with_account: false,   //T or F: with (signup for something) or without (just get info), two diff db tbls
    admin_reg_code: '',
    un: '',
    un_confirm: '',
    pw: '',
    pw_confirm: '',
    fn: '',
    mn: '',
    ln: '',
    phone: '',
    zip: '',
    industry: '',
    position_title: '',
    company_name: '',
    ab_myself_init: '',
    clicked_link_ToS_dtstamp_UTC: '',
    clicked_link_PrivPol_dtstamp_UTC: '',
    agreed_yes_check_ToS_n_PP_yn: false,
    agreed_yes_check_ToS_n_PP_dtstamp_UTC: '',
    agreed_yes_click_dtstamp_UTC: '',  //'2018-04-29 21:41:27'
    usage_grp_id: '' //passed to server to be used by pg function that create user--usage_grp row
   }
  let payload_data = {};
  //update from ui
  if (fieldToChangeOptional) {
    const {rdcr_user_register} = getState();
    let new_rdcr_user_register = JSON.parse(JSON.stringify(rdcr_user_register));
    new_rdcr_user_register[fieldToChangeOptional] = newDataOptional;
    payload_data = new_rdcr_user_register;
  }
  else {
    payload_data = user_register_data
  }
  dispatch(sendActionFromDispatch(USER_REGISTER__DATA, payload_data))
  /*
  return {
    type: USER_REGISTER__DATA,
    payload: payload_data
  };
  */
  }
}

export function userRegisterDataSave(typeOfReg, cb_optional_open_page) {
  //typeOfReg either WtAccnt or WoAccnt
  return(dispatch, getState) => {
    const { rdcr_user_register } = getState();
    if (!rdcr_user_register) {
      return;    
    }
    let url_last_segment = '';
    switch (typeOfReg) {
      case 'WtAccnt':
        url_last_segment = 'register'
        break;
      case 'WoAccnt':
        url_last_segment = 'registerWoAccnt'
        break;
      default:
        url_last_segment = 'register'
        break;
    }
  //  /*
      const myheaders = new Headers();
      myheaders.append('Content-Type', 'application/json'); //required for CORS to match with server (in app.js)
                return fetch('/routes/' + url_last_segment, { 
                  method: 'POST',
                  body: JSON.stringify(rdcr_user_register),
                  accept: 'application/json',
                  mode: 'cors',
                  headers: myheaders
                })
                .then((r) => {
                  if (r.status === 200) {
                      return r.json();
                  }
                  else {
                    console.log('http return status:', r.status)
                  }
                })
                .then((r) => {
                  //20180804 DO I NEED THIS? 
                  dispatch(sendActionFromDispatch(USER_REGISTER__DATA, rdcr_user_register))
                  //if user events !== undefined then dispatch: save user event sign up
                  const { rdcr_user_events_selected_pre_save } = getState();
                  const new_rdcr_user_events_selected_pre_save = rdcr_user_events_selected_pre_save.map((i) => {
                    return {user_id: r[0].id, event_id: i};
                  })
     //             if (new_rdcr_user_events_selected_pre_save) {
     //               dispatch(userEventsSelectedSaveMany(new_rdcr_user_events_selected_pre_save))
     //             }
                }
              )
              .then(() => {
                if (cb_optional_open_page !== undefined) {
                  let msg = "Thank you. We'll be in touch!"
                  dispatch(updateUiConfig(UI_CONFIG, 'msg_response_gen_01', msg))
                  msg = "Feel free to email us at info@complexability.com"
                  dispatch(updateUiConfig(UI_CONFIG, 'msg_response_gen_02', msg))
                  cb_optional_open_page()
                } 
              })
              .catch((err) => console.log('error in catch of userRegisterDataSave', err))
  }
}

export function createNewUserUsageGrp(un, usage_grp_id){
  const data = {un, usage_grp_id}
  console.log('createNewUser_zzz', data)
  const myheaders = new Headers();
      myheaders.append('Content-Type', 'application/json'); //required for CORS to match with server (in app.js)
                return fetch('/routes/create_user_usage_grp', { 
                  method: 'POST',
                  body: JSON.stringify(data),
                  accept: 'application/json',
                  mode: 'cors',
                  headers: myheaders
                })
                .then((r) => {
                  if (r.status === 200) {
                      return r.json();
                  }
                  else {
                    console.log('http return status:', r.status)
                  }
                })
}

export function createNewOrConfigUsageGrpByAdmin(data){
  console.log('datazzz', data)
  const myheaders = new Headers();
      myheaders.append('Content-Type', 'application/json'); //required for CORS to match with server (in app.js)
                return fetch('/routes/usage_grp_create_n_config', { 
                  method: 'POST',
                  body: JSON.stringify(data),
                  accept: 'application/json',
                  mode: 'cors',
                  headers: myheaders
                })
                .then((r) => {
                  if (r.status === 200) {
                      return r.json();
                  }
                  else {
                    console.log('http return status:', r.status)
                  }
                })
}

export function createNewPersonForUser (new_item_type, cb_open_new_page, optional_add_data={}) {
  return(dispatch, getState) => {
    const { rdcr_user_curr } = getState();
    const { rdcr_person_active } = getState();
    //DELCAND20200115 const { rdcr_active_person_contexts_for_display } = getState();
    const { rdcr_context_active } = getState(); 
    //20191127 black out selected contexts if exist from another person 20191127 not having this get blacked out cause bug of cannot find id of new context, if a previous person's context were viewed beforehand
    dispatch(updateUiConfig(UI_CONFIG, 'contextIdsSelected', ['clear'], () => {}))
    //blank out existing context first:
    const blankContext = '';
    dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_DATA__GET, blankContext))
    let newItemIndicator = {};
    let extra_instructions = {}
    if ("expr_id" in optional_add_data) {
      extra_instructions = {cat_id: optional_add_data.cat_id, process_roadmap_mode: optional_add_data.process_roadmap_mode, dyns_template_using_context_id: true, expr_id: optional_add_data.expr_id, newCntxAsPartOfCurrCnxtGrp: optional_add_data.newCntxAsPartOfCurrCnxtGrp}
    }
    else
    {
      extra_instructions = {cat_id: optional_add_data.cat_id, process_roadmap_mode: optional_add_data.process_roadmap_mode, dyns_template_using_context_id: false} //cat_id passed from ui form
    }
    //ensure clean optional_add_data
    switch (new_item_type) {
      case 'person':
        newItemIndicator = {new_item: 'person', parent_id: rdcr_user_curr[0].user_id,
            first_name: ("first_name" in optional_add_data ? optional_add_data.first_name : ''),
            last_name: ("last_name" in optional_add_data ? optional_add_data.last_name : ''),
            extra_instructions}
            console.log('extra_instructions in person new_item_type', extra_instructions)
        break;
      case 'context':
        newItemIndicator = {new_item: 'context', parent_id: rdcr_person_active[0].id, extra_instructions}
        console.log('extra_instructions in context new_item_type', extra_instructions)
        break;
      case 'context_sub':
        newItemIndicator = {new_item: 'context_sub', parent_id: rdcr_context_active.contextId, extra_instructions}
        break;
      default:
        break;
    }
        return fetch('/routes/api/v1/person_new_wt_cntxt_n_dyns', {
            method: 'POST',
            body: JSON.stringify(newItemIndicator),
            accept: 'application/json',
            mode: 'cors',
            credentials: 'include',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
          })
          .then(
            r => {
              if (r.status === 200) {
                  console.log('successful create new person');
                  return r.json();
              }
              else {
                console.log('http return status:', r.status)
              }
            })
            .then((r) => {
              switch(new_item_type){
                case 'person':
                  //20190219 added getting the three ids
                  //set state of context id and dyn grp id -- but only need with fast track mode, directly to dyns
                  //directly creating payload as this is only ids from the initial create
                  //no need to go to database to get full data
                  const new_active_context_id = [{id: r[0].id_o_pers_expr}];
                  dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_DATA__GET, new_active_context_id));
                  dispatch(sendActionFromDispatch(ACTIVE_CONTEXT, new_active_context_id));
                  //20200615 last two flipped and the last one got cb -- looks like was causing bug
                  dispatch(personDataGet(r[0].id));
                  dispatch(personContextsDataGet(r[0].id, cb_open_new_page)) //20191125 added line to fix bug of not getting context for display for new person
                  break;
                case 'context':
                  dispatch(updateUiConfig(UI_CONFIG, 'newContextExprId', r[0].id_o_pers_expr));  //20200716 to be used for displaying "New Context" in context section
                   this.personContextsDataGet(r[0].id, cb_open_new_page)
                  
                case 'context_sub':
                  dispatch(updateUiConfig(UI_CONFIG, 'contextIdsSelected', ['reset', r[0].id_o_pers_expr])); //super hack
                  //because resetting selected (above), no need to set as new context: dispatch(updateUiConfig(UI_CONFIG, 'newContext', true))
                  dispatch(personContextsDataGet(rdcr_person_active[0].id))
                  break;
                default:
                  break;
              }
            })
          }
    };


// /*
export function createNewAddDynsBranch (pers_expr_id, pers_expr_parent_id, cb_open_page){
  return(dispatch) => {
  const ids = {pers_expr_id, pers_expr_parent_id}
  return fetch('/routes/api/v1/create_context_dyns_branch_addition', {
    method: 'POST',
    body: JSON.stringify(ids),
    accept: 'application/json',
    mode: 'cors',
    credentials: 'include',
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }
  })
  .then(r => {
      if (r.status === 200) {
          return r.json();
      }
      else {
        console.log('http return status:', r.status)
      }
  })
  .then((r) => {
    dispatch(sendActionFromDispatch(CONTEXT_DYN_DATA__GET, r))
    dispatch(genContextRecs(pers_expr_id, cb_open_page))
  })
}
}
// */

export function verifyUserNameDoesNotExist (user_name){
  return(dispatch, getState) => {
    fetch('/routes/select_user_name?i=' + user_name, {
      accept: 'application/json',
    }).then((r) => {
          if (r.status === 200) {
            //get data back there for user_name already existing  
            return r.json()
          }
          else {
            console.log('r.status in verifyUserNameDoesNotExist', r.status)
          }
      })
      .then((r) => {
        let un_not_exists_yet
        if (r.length === 0) {
          un_not_exists_yet = true
        }
        else if (r.length > 0) {
          un_not_exists_yet = false
        }
        const { ui_config } = getState(); 
        let new_ui_config = Object.create(ui_config);
        new_ui_config.RegNewUser__verified_user_name_does_not_already_exist = un_not_exists_yet;
        dispatch(sendActionFromDispatch(UI_CONFIG, new_ui_config))  
      })
      .catch(err => console.log('catch in verifyUserNameDoesNotExist', err))
    }
}

export function loginUser(e, cb_open_next_page, cb_open_intro_pres_page){
  //cb_open_next_page will most likely be people list page,
  //but can also be others like change password
  e.preventDefault();
  console.log('started loginUser in actions')
  //ref from App, called by: handleAuthLoginForm(e){
    //when changed to const, the whole function execution when haywire!!!
    let user_n_permission = {};
    let user_login_data = {
    username: e.target.un.value,
    password: e.target.pw.value
    } 
    return(dispatch) => {
    fetch('/routes/login', {
      method: 'post',
      body: JSON.stringify(user_login_data),
      accept: 'application/json',
      credentials: 'include',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    // /*
    .then(
      r => {
        if (r.status === 200) {
            console.log('successful user reg');
            return r.json();
        }
        else {
          return [{login_status_note: "Login unsuccessful. Please try again.", 'res status': r.status}]
        }
      })
    .then((r) => {
        dispatch(sendActionFromDispatch(USER_CURR__DATA, r));
        dispatch(userEventsToChooseFrom());
        const status_n_premission = [r[0].login_status_note, {permission_type: r[0].access_level, user_id: r[0].user_id, actions_allowed: r[0].actions_allowed}]
        return status_n_premission;
    })
    .then((status_n_premission) => {
      switch (status_n_premission[0].includes('unsuccessful')) {
        case true:
          break;
        default:
          if ((status_n_premission[1].permission_type === 'super_admin') || 
          (status_n_premission[1].permission_type === 'trainer')) {
            const adminUser = {permission_type: status_n_premission[1].permission_type, 
                                user_id: status_n_premission[1].user_id,
                                permission_type_display: 'ADMIN / INSTRUCTOR'}
            dispatch(sendActionFromDispatch(USER_CURR_ADMIN, adminUser));
            dispatch(updateUiConfig(UI_CONFIG, 'isAdmin', true));
          }
          break;   
      }
      user_n_permission = status_n_premission[1] 
      //return status_n_premission[1]
  }) 
  .then(() => {
      let cb_open_next_page_active
      if (user_n_permission.permission_type === 'intro pres only') {
        //open intro pre signup page
        cb_open_next_page_active = cb_open_intro_pres_page
      }
      else {
        cb_open_next_page_active = cb_open_next_page;
      }
      if (user_n_permission.user_id !== undefined) { //if not user_id, then does dispatch and tf doesn't go to next page
      dispatch(userEventsSelectedGet(user_n_permission.user_id, cb_open_next_page_active)) //had to send cb bc .then did not wait for dipatch to complete and opened page before events were retrieve from server
      }
    })
    .catch((err) => {console.log('err in action UserLogIn', err)})
}}

export function userEventsSelectedGet(user_id, cb_open_page) {
  return(dispatch) => {
    const fetched_data = fetch('/routes/user_events_selected/?i=' + user_id, { 
      method: 'get',
      accept: 'application/json',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
      },
      credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
    })
    // /*
    .then(
      r => {
        if (r.status === 200) {
            return r.json();
        } 
  })
  dispatch(sendActionFromDispatch(EVENTS_SELECTED_BY_USER, fetched_data))
  .then(() => {
    if (cb_open_page !== undefined) {
    cb_open_page()
  }
  })
}
}

export function userEventsSelectedSetState(event_id){ 
  return(dispatch, getState) => {
    const { rdcr_user_events_selected } = getState();
    if (!rdcr_user_events_selected) {
      return;
    }
    let new_rdcr_user_events_selected = rdcr_user_events_selected.slice();
    new_rdcr_user_events_selected[0].event_id = event_id; 
    dispatch(sendActionFromDispatch(EVENTS_SELECTED_BY_USER, new_rdcr_user_events_selected))
  }
}

export function userEventsSelectedPreSave(event_ids){
  return {
    type: EVENTS_SELECTED_BY_USER_PRE_SAVE,
    payload: event_ids
  };
}


export function userEventsSelectedSave(user_id, event_id){ 
  return(dispatch, getState) => {
    if (user_id === undefined) {
      const { rdcr_user_curr } = getState();
      user_id = rdcr_user_curr[0].user_id;
    }
    if (event_id === undefined) {
      const { rdcr_user_events_selected } = getState();
      event_id = rdcr_user_events_selected[0].event_id;
    }
    let user_event_selected_data = {user_id: user_id, event_id: event_id}
      fetch('/routes/sign_up_for_event', { 
        method: 'post',
        body: JSON.stringify(user_event_selected_data),
        accept: 'application/json',
        credentials: 'include',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      // /*
      .then(r => {
          if (r.status === 200) {
              console.log('successful user event sing-up');
              return r.json();
          }
          })
      .catch((err) => console.log('error in catch in userEventsSelectedSave', err))
  }
}


export function userEventsSelectedSaveMany(eventsInterestedByUser){ 
      return fetch('/routes/sign_up_for_event', { 
        method: 'post',
        body: JSON.stringify(eventsInterestedByUser),
        accept: 'application/json',
        credentials: 'include',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      // /*
      .then(r => {
          if (r.status === 200) {
              console.log('successful user event sign up');
              return r.json();
          }
      })
      .then(r => {
        return {
        type: EVENTS_SELECTED_BY_USER,
        payload: r
        }
      })
      .catch((err) => console.log('error in catch in userEventsSelectedSave', err))
  }

export function changePwUser(e, cb_open_page_next){
  e.preventDefault();
    let user_login_data = {
    un: e.target.un.value,
    pw: e.target.pw.value
  }
  return(dispatch) => {
    //20200409azco: return fetch('/routes/changePw', { 
      fetch('/routes/changePw', { 
      method: 'POST',
      body: JSON.stringify(user_login_data),
      accept: 'application/json',
      mode: 'cors',
      credentials: 'include',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(
      r => {
        if (r.status === 200) {
            console.log('successful user reg');
            return r.json();
        }
        else {
          return [{login_status_note: "Login unsuccessful. Please try again or if you haven't created a user account, please sign up.", 'res status': r.status}]
        }
      })
    .then((r) => {
        const pw_change_state = {pw_change_state: 'success', user_curr__status: 'Password changed successfully.'}
        dispatch(sendActionFromDispatch(USER_CURR__STATUS, pw_change_state));
        return 'open_new_page_if_cd_exists'
    })
    .then((status) => {
      switch (status.includes('open_new_page_if_cd_exists')) {
        case true:
          if (cb_open_page_next !== undefined) {
            cb_open_page_next();
          }
          break;
        default:
          break;
      }
  })
  .catch(()=> {console.log('what the helck is going on???')})
}}

//20200627
export function userUsageGrpUserDataGet(user_id, cb_open_next_page) {
  console.log('user_id...console', user_id)
  return(dispatch) => {
    fetch('/routes/api/v1/userUsageGrpUserData?i=' + user_id, {
      accept: 'application/json',
    }).then((r) => {
          if (r.status === 200) {
              return r.json();
          }
      })
      .then((r) => {
        dispatch(sendActionFromDispatch(USER_USAGE_GRP_USER_DATA__GET, r))
        console.log('zzz+m')
        return 'hello'
      })
      .then((r) => {
        //console.log(r)
        console.log('r after cb_open_next_page', r)
        if (cb_open_next_page !== undefined) {
          cb_open_next_page()
        }})
        //console.log('r after cb_open_next_page', r)
      /*
      return {
        type: USER_USAGE_GRP_USER_DATA__GET,
        payload: data_fetched
      };//zzz4
      */
    }
}

export function personsDataGet(user_id) {
  let data_fetched = fetch('/routes/api/v1/person-multi?i=' + user_id, {
    accept: 'application/json',
  }).then(
    r => {
        if (r.status === 200) {
            return r.json();
        }
    })
    return {
      type: USER_PERSONS_DATA__GET,
      payload: data_fetched
    };
} 

export function userContextsDataGet(user_id) {
  console.log('from aaaction userContextsDataGet user_id', user_id)
  let data_fetched = fetch('/routes/api/v1/user_contexts?i=' + user_id, {
    accept: 'application/json',
  }).then(
    r => {
        if (r.status === 200) {
            return r.json();
        }
    })
    return {
      type: USER_CONTEXTS_LIST_DATA__GET,
      payload: data_fetched
    };
} 
  
export function listUsersForAdmin(){
  return(dispatch, getState) => {
  //make sure admin permissions
  const { rdcr_curr_user_admin } = getState();
  if (rdcr_curr_user_admin.permission_type.search('admin') !== -1) {
        let data_fetched = fetch('/routes/api/v1/retrieve_users', {
          accept: 'application/json',
        }).then(
          r => {
              if (r.status === 200) {
                  return r.json();
              }
          })
    return {
      type: USER_LIST_FOR_ADMIN,
      payload: data_fetched
    };    
  }
}
}

export function changeUserByAdmin(user_name, cb_optional){
  return(dispatch, getState) => {
    const { rdcr_curr_user_admin } = getState();
    //dbl change that request from actual Admin and that this user is different for exiting user
      if ((rdcr_curr_user_admin.permission_type.search('admin') !== -1) ||
            (rdcr_curr_user_admin.permission_type.search('trainer') !== -1)) {
        const { rdcr_user_curr } = getState();
        fetch('/routes/api/v1/retrieve_user?i=' + user_name, {
          accept: 'application/json',
        }).then(
          r => {
              if (r.status === 200) {
                  return r.json();
              }
          })
          .then((r)=> {
            //update admin state:
            //const { rdcr_curr_user_admin } = getState();
              let new_rdcr_curr_user_admin = Object.create(rdcr_curr_user_admin);
              let new_rdcr_curr_user_admin_TEST = Object.create(rdcr_curr_user_admin);
              new_rdcr_curr_user_admin.user_whose_data_being_viewed = user_name;
              new_rdcr_curr_user_admin.user_whose_data_being_viewed_display_name_in_grp = (r[0].name_display_in_grp !== null ? r[0].name_display_in_grp: user_name)  //20200421zzz
              new_rdcr_curr_user_admin.curr_viewing_data_of_another = true
              dispatch(sendActionFromDispatch(USER_CURR_ADMIN, new_rdcr_curr_user_admin));
            //change user, which will change people list when Person.js rerenders
              let changed_user_curr = rdcr_user_curr.slice();
              changed_user_curr[0].user_id = r[0].id;
              let p = () => {
                return new Promise((res, rej) => {
                  const x = dispatch(sendActionFromDispatch(USER_CURR__DATA, changed_user_curr))
                  if (x) {
                    res('go')
                  }
                  else
                  {
                    rej('err errz')
                  }
                })
              }
              p()
              .then(() => cb_optional())   
              .catch((msg) => console.log('err msg', msg) )
          })
      }
}
} 

export function personsDataFilter(critValue = ''){ 
    return(dispatch, getState) => {
      const { rdcr_persons } = getState();
      if (!rdcr_persons) {
        return;
      }
    let p = (critValue) => {
      return new Promise((res, rej) => {
        const persons_filtered = rdcr_persons.filter(p =>
          ((p.first_name === null) ? '@#$%' :  p.first_name).toUpperCase().includes(critValue.toUpperCase()) ||
              ((p.last_name === null) ? '@#$%' :  p.last_name).toUpperCase().includes(critValue.toUpperCase())
          );
          const crit = {
            first_name_crit: critValue,
            last_name_crit: critValue
          }
        const x = dispatch(sendActionFromDispatch(USER_PERSONS_DATA__FILTERED, persons_filtered))
        if (x) {
          res(crit)
        }
        else {
          rej('az error in promise')
        }
    })
  }
    return p(critValue).then((crit) => {
      //DELCAND_20190731: dispatch(sendActionFromDispatch(PERSONS_SRCH_CRIT, crit))
      //apply filter to contexts list as well, but only if the search did not originate from contexts filter
      //otherwise will be an infinate loop, each filter kicking off the other, non-stop
      const { rdcr_ui_config } = getState();
      if (rdcr_ui_config.list_filter_crit_exec_from_another_function === false) {
        dispatch(personsContextsListDataFilter(critValue))
      }
      dispatch(updateUiConfig(UI_CONFIG, 'list_filter_crit_exec_from_another_function', !rdcr_ui_config.list_filter_crit_exec_from_another_function))
    })
  }
}

export function personsContextsListDataFilter(critValue = ''){
  
    return(dispatch, getState) => {
      const { rdcr_user_contexts_list } = getState();
    let p = (critValue) => {
      return new Promise((res, rej) => {
        const person_active_contexts_filtered = rdcr_user_contexts_list.filter(c =>
          ((c.first_name === null) ? '@#$%' :  c.first_name).toUpperCase().includes(critValue.toUpperCase()) ||
              ((c.last_name === null) ? '@#$%' :  c.last_name).toUpperCase().includes(critValue.toUpperCase()) || 
              ((c.context_def === null) ? '@#$%' :  c.context_def).toUpperCase().includes(critValue.toUpperCase())
          );
          const crit = {
            context_list_filter_crit: critValue
          }
        const x = dispatch(sendActionFromDispatch(USER_CONTEXTS_DATA__FILTERED, person_active_contexts_filtered))
        if (x) {
          res(crit)
        }
        else {
          rej('az error in promise')
        }
    })
  }
    return p(critValue).then((crit) => {
      //DELCAND_20190731: dispatch(sendActionFromDispatch(CONTEXTS_SRCH_CRIT, crit))
      //apply filter to person list as well, but only if the search did not originate from person filter
      //otherwise will be an infinate loop, each filter kicking off the other, non-stop
      const { rdcr_ui_config } = getState();
      if (rdcr_ui_config.list_filter_crit_exec_from_another_function === false) {
        dispatch(personsDataFilter(critValue))
      }
      dispatch(updateUiConfig(UI_CONFIG, 'list_filter_crit_exec_from_another_function', !rdcr_ui_config.list_filter_crit_exec_from_another_function))
    })
  }
}

//for future use -- sorting Dyns by type_exec
export function reOrderDyns(){
  return(dispatch, getState) => {
    const { rdcr_context_dyns } = getState();
    const new_sort_context_dyns = rdcr_context_dyns.slice().sort((a, b) => {
      if (a.orderby_dom < b.orderby_dom) {
        return 1;
      }
      if (a.orderby_dom > b.orderby_dom) {
        return -1;
      }
      // names must be equal
      return 0;
    })
    dispatch(sendActionFromDispatch(CONTEXT_DYN_DATA__GET, new_sort_context_dyns))
  }
}

//20180302 starting to do save Single Field as app level redux action/reducer
//
/********
function makeASandwichWithSecretSauce(forPerson) {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.
  return function(dispatch) {
    return fetchSecretSauce().then(
      sauce => dispatch(makeASandwich(forPerson, sauce)),
      error => dispatch(apologize('The Sandwich Shop', forPerson, error))
    )
  }
**********/

function saveSingleFieldIntoReducer(type1, payload1){
  //201902ish
  //return new Promise((resolve, reject) => {
   
  //https://redux.js.org/api/applymiddleware

  return dispatch => {
  dispatch({ type: type1, payload: payload1 })
  return Promise.resolve()
}

//resolve("from resolve")

// setTimeout(
//   () => {
//   return dispatch => {
//   dispatch({ type: type1, payload: payload1 })}}
//   , 5000)
// }
}

export function saveSingleField(field_name, field_data, assoc_id, entity, whatTodoWithData) {
  /* based on json in local components
            update_single_field: {
              field_name: '',
              field_data: '',
              assoc_id: '',
              entity: ''
            }
  */
  const update_single_field = {field_name,
                              field_data,
                              assoc_id,
                              entity}

  // const update_single_field = {field_name: 'context_def',
  //                               field_data: 'cntx_hell2',
  //                               assoc_id: '74d60dd8-a15a-84cd-b715-ec7ddfde36b4',
  //                               entity: 'pers_expr'}
                              
  return (dispatch, getState) => dispatch(saveSingleFieldIntoReducer(SINGLE_FIELD_DATA, update_single_field))
  .then(() => {
    if (whatTodoWithData === 'saveToDb') {
      const { rdcr_single_field_data } = getState();
      dispatch(saveOnBlurSingleField(rdcr_single_field_data));
    }
    })
  .catch(() => console.log('errrrrrrr'))
  }
;

//20190302 create a field json as reducer, so now with local in component or in redux state
export function saveOnBlurSingleField(update_single_field = {}, cd_page_page) {
  //20200515co, caused double quotes, postgres param takes care of single quote escape: update_single_field.field_data = update_single_field.field_data.replace(/'/g, "''")
  //modified from in app: this.updateSingleField = (data_for_update, cb) => {

  return(dispatch) => {
    console.log('saveOnBlurSingleField_update_single_field', update_single_field)
    const myheaders = new Headers();
    myheaders.append('Content-Type', 'application/json'); //required for CORS to match with server (in app.js)
    fetch('/routes/api/single_field_update', { 
                  method: 'PUT',
                  body: JSON.stringify(update_single_field),
                  accept: 'application/json',
                  mode: 'cors',
                  headers: myheaders
                })
                .then((r) => {return r.json()})
                .then((r) => {
                  //20190111az is for person, refresh after deleting
                  if (update_single_field.entity === 'person' && update_single_field.field_name === 'delete_dt') {
                    dispatch(personsDataGet(r[0].user_id))  
                    dispatch(personsDataFilter('zt', 'zt'))
                  }
                  //20190105az is for pers_expr, refresh after deleting
                  if (update_single_field.entity === 'pers_expr' && update_single_field.field_name === 'delete_dt') {
                    dispatch(personContextsDataGet(r[0].pers_id))
                  }
                  if (update_single_field.entity === 'expr_phunk' && update_single_field.field_name === 'type_exec') {
                    dispatch(contextDynsDataGet(update_single_field.contextId)) //contextId field only included in object for expr_phunk and type_exec change
                  }
              })
  }
}

export function personDataGet(pers_id, callback_route_to_page) {
  return(dispatch) => {
    fetch('/routes/api/v1/person/?i=' + pers_id, {
      accept: 'application/json',
    }).then(
      (r) => {
          if (r.status === 200) {
              return r.json()
          }
      })
      .then((r) => {
        dispatch(sendActionFromDispatch(PERSON_ACTIVE_DATA_GET, r))
      }) 
      .then(() => {
        if (callback_route_to_page !== undefined) {
          callback_route_to_page();
        }
      })
  }
}

export function personDataClear() {
  return(dispatch) => {
        dispatch(sendActionFromDispatch(PERSON_ACTIVE_DATA_GET, []))
      }
}


export function personsDataSort(field_ref, sort_direction) {
  return(dispatch, getState) => {
    const { rdcr_persons } = getState();
    const { rdcr_persons_filtered } = getState();
    let sort_field = ''
    switch (field_ref) {
      case 'sort_fn_curr':
        sort_field = 'first_name'
        break;
      case 'sort_ln_curr':
        sort_field = 'last_name'
        break;
      default:
        break;
    }

    /**
    //before variabilize field:
    let persons_sorted = persons.slice().sort((a, b) => {
      var x = (a.first_name ? a.first_name : '').toLowerCase();
      var y = (b.first_name ? b.first_name : '').toLowerCase();
     */

    //sort all persons (need to do again below for sort filtered persons, in case on filtered)
     let persons_sorted = rdcr_persons.slice().sort((a, b) => {
      let x = (a[sort_field] ? a[sort_field] : '').toLowerCase();
      let y = (b[sort_field] ? b[sort_field] : '').toLowerCase();
      switch (sort_direction) {
        case 'a>b':
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;
        case 'b>a':
          if (x < y) {return 1;}
          if (x > y) {return -1;}
          return 0;
        default:
          return 0;
      }
    })
    dispatch(sendActionFromDispatch(USER_PERSONS_DATA__GET, persons_sorted))

    //check to see if persons_filtered is not an empty object
    //if not emppty object, will be array wt data
    //help from:  https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
    if (Object.keys(rdcr_persons_filtered).length === 0 && rdcr_persons_filtered.constructor === Object) {
      ///don't do anything
    }
    else {
    let persons_filtered_sorted = rdcr_persons_filtered.slice().sort((a, b) => {
      let x = (a[sort_field] ? a[sort_field] : '').toLowerCase();
      let y = (b[sort_field] ? b[sort_field] : '').toLowerCase();
      switch (sort_direction) {
        case 'a>b':
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;
        case 'b>a':
          if (x < y) {return 1;}
          if (x > y) {return -1;}
          return 0;
        default:
          return 0;
      }
    })
    dispatch(sendActionFromDispatch(USER_PERSONS_DATA__FILTERED, persons_filtered_sorted))
  }
}
}

//20200107 !!!! THIS MAY NEVER BE USED OR DOESN'T do anything, need to verify
export function contextsListDataSort(field_ref, sort_direction) {
  return(dispatch, getState) => {
    }
  }

export function personContextsDataGet(pers_id, cb_open_contexts_page) {
  //HERE GOEST personContext route>>>>>
  return(dispatch, getState) => {
    fetch('/routes/api/person_contexts?id=' + pers_id, {
      accept: 'application/json',
    }).then(
      (r) => {
          if (r.status === 200) {
              return r.json();
          }
      })
    .then((r) => {
        dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_DATA__GET, r))
        dispatch(personContextsSubDataGet(pers_id))
        return r;
    })
    .then((r) => {  
      const { rdcr_ui_config } = getState();
      const { rdcr_person_active_contexts} = getState()
      //when some contexts are filtered out for display
      if (rdcr_ui_config.contextIdsSelected[0] !== undefined) {
        console.log('rdcr_ui_config.contextIdsSelectedzzz', rdcr_ui_config.contextIdsSelected)
        let arrSelectContexts = [];
        rdcr_ui_config.contextIdsSelected.map(selectedContextId => {
          //20200107 react bwsr devtools warning: "Expected to return a value in arrow function             array-callback-return"
          return arrSelectContexts.push(rdcr_person_active_contexts.find(cntxt => cntxt.id === selectedContextId))
        })
        //add new context if this is the situation
        if (rdcr_ui_config.newContext === true) {
          const lastContextAdded = rdcr_person_active_contexts.find(context => Math.max(context.id_seq));
          console.log('lastContextAddedZZZ', lastContextAdded)
          /////////////////////arrSelectContexts.unshift(lastContextAdded)
          console.log('arrSelectContextsZZZ', arrSelectContexts)
          dispatch(updateUiConfig(UI_CONFIG, 'contextIdsSelected', ['add', lastContextAdded.id]));
          dispatch(updateUiConfig(UI_CONFIG, 'newContext', false));
        }
        else {
          //dispatch(updateUiConfig(UI_CONFIG, 'contextMsg', ''));
        }
        dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_SELECTED, arrSelectContexts))
        dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_FOR_DISPLAY, arrSelectContexts))
      }
      ////when contexts are not filtered out for display
      //20191125 seems like not needed -- 20191127 but looks like needed 
      else {
        dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_FOR_DISPLAY, r))
      }
    })
    .then(() => {
      console.log('cb_open_contexts_page', cb_open_contexts_page)
      if (cb_open_contexts_page !== undefined) {
        console.log('page_cd opened...')
        cb_open_contexts_page()
      }})
  }
}

export function personContextSelectedStandAlone(id_head_context){
  return (dispatch, getState) => {
  const { rdcr_ui_config } = getState();
  const { rdcr_person_active_contexts} = getState();
  }
}

export function personContextsSubDataGet(pers_id) {
  return(dispatch) => {
    fetch('/routes/api/person_contexts_sub?id=' + pers_id, {
      accept: 'application/json',
    }).then(
      (r) => {
          if (r.status === 200) {
              return r.json();
          }
      })
    .then((r) => dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_SUB_DATA__GET, r)))
  }
}

export function switchBtGrpCntxtsOrSelected(currModeToSwitchFrom) {
  //ify: place where UiConfig context all or selected: function updateUiConfig
  return(dispatch, getState) => {
    let { rdcr_ui_config } = getState();
    let context_data_for_display;

    switch (rdcr_ui_config.contextAllPerGrpOrSelected) {
      case 'all':
        rdcr_ui_config.contextAllPerGrpOrSelected = 'selected'
        //get only selected
        const { rdcr_active_person_contexts_selected } = getState();
        context_data_for_display = rdcr_active_person_contexts_selected;
        break;
      case 'selected':
        //get all grp's cntexts
        rdcr_ui_config.contextAllPerGrpOrSelected = 'all'
        //get only selected
        const { rdcr_person_active_contexts } = getState();
        context_data_for_display = rdcr_person_active_contexts;
        break; 
      default:
        break;
    }
    const new_ui_config = Object.assign({}, rdcr_ui_config);
    dispatch(sendActionFromDispatch(UI_CONFIG, new_ui_config))
    dispatch(sendActionFromDispatch(PERSON_ACTIVE_CONTEXTS_FOR_DISPLAY, context_data_for_display))
  }
}

export function activeContext(contextId){
  const active_context = {contextId}
  return {
    type: ACTIVE_CONTEXT,
    payload: active_context
  };
}

  /*FOR FUTURE:
    //20180507 note: for future added this layer of Dyn Grp -- context > many grps > each group is made of dyn
    //but for now one context has four dyns
    
    export function contextDynGrpDataGet(context_id, cb_open_dyn_grp_page) {

      // FOR FUTURE
    }
*/

/*PROMISE prominse good eg, need to study, may be good model
https://stackoverflow.com/questions/40946046/wait-for-a-redux-action-to-finish-dispatching?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
*/

//20180811
export function personContextModulesGet() {
  return(dispatch) => {
  fetch('/routes/api/v1/pers_expr_module_ref', {
    method: 'GET',
    accept: 'application/json',
    mode: 'cors',
    credentials: 'include',
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }
  })
  .then(
    (r) => {
        if (r.status === 200) {
            return r.json();
        }
    })
    .then((r) => {
      dispatch(sendActionFromDispatch(PERSON_CONTEXT_MODULES, r));
    })
  .catch((err) => console.log('issue in action personContextModulesGet, error:', err))
  }
}
  
    //20200405 PERS AND PROF
  export function personContextDescRefGet(initCat) {
  
    return(dispatch) => {
      const runFetch = (i, context_cat, reducer_crit_data) => {
        return fetch('/routes/api/v1/pers_expr_desc_ref?i=' + context_cat, {  
          method: 'GET',
          accept: 'application/json',
          mode: 'cors',
          credentials: 'include',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          }
        })
        .then(
          (r) => {
              if (r.status === 200) {
                  return r.json();
              }
          })
          .then((r) => {
            dispatch(sendActionFromDispatch(reducer_crit_data, r));
            //set default for personal
            if (i === 0) {
              dispatch(sendActionFromDispatch(PERSON_CONTEXT_DESC_REF, r));
            }
          })
        .catch((err) => console.log('issue in action personContextDescRefGet, error:', err))
      }
      for (let i = 0; i <= 1; i++){
        switch (i) {
          case 0:
            runFetch(i, 'personal', PERSON_CONTEXT_DESC_REF_PERS);
            break;
          case 1:
            runFetch(i, 'professional', PERSON_CONTEXT_DESC_REF_PROF)
            //.then(() => {if (initCat !== undefined) { dispatch(switchPersonContextDescBasedOnContextCat(initCat))}});
            break;
        } 
      }
    }
  }
  //zzz fix 20200528
  export function switchPersonContextDescBasedOnContextCat (contextCat) {  //20200407 based on DynAreaREf swtich
    return(dispatch, getState) => {
      let context_desc_ref = [];
      switch (contextCat) {
        case 'Personal':
          context_desc_ref = getState().rdcr_persons_context_desc_ref_pers.slice();
          break;
        case 'Professional':
          context_desc_ref = getState().rdcr_persons_context_desc_ref_prof.slice();
          break;  
      }
      dispatch(sendActionFromDispatch(PERSON_CONTEXT_DESC_REF, context_desc_ref))
    }
  }
 

//20191221 (template is above DescRef)
export function personContextModeRefGet() {
  return(dispatch) => {
  fetch('/routes/api/v1/pers_expr_mode_ref', {  
    method: 'GET',
    accept: 'application/json',
    mode: 'cors',
    credentials: 'include',
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }
  })
  .then(
    (r) => {
        if (r.status === 200) {
            return r.json();
        }
    })
    .then((r) => {
      dispatch(sendActionFromDispatch(PERSON_CONTEXT_MODE_REF, r));
    })
  .catch((err) => console.log('issue in action personContextModeRefGet, error:', err))
  }
}

//20201122
  export function personContextsCharRefGet() {
    console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzz14')
    return(dispatch) => {
    fetch('/routes/api/v1/pers_expr_char_ref', {
      method: 'GET',
      accept: 'application/json',
      mode: 'cors',
      credentials: 'include',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      }
    })
    .then(
      (r) => {
          if (r.status === 200) {
              return r.json();
          }
      })
      .then((r) => {
        dispatch(sendActionFromDispatch(PERSON_CONTEXT_CHAR_REF, r));
      })
    .catch((err) => console.log('issue in action personContextsCharRefGet, error:', err))
    }
  }
  

//20180808
//export function personContextsTypeRefGet(module_id = '0d629b03-ba57-c09e-adeb-bd8dd43ac431') {
export function personContextsTypeRefGet(module_id) {
  //client_id -- for future use
  //module_id default is for mentoring, perf review
  return(dispatch) => {
  fetch('/routes/api/v1/pers_expr_type_ref?id=' + module_id, {
    method: 'GET',
    accept: 'application/json',
    mode: 'cors',
    credentials: 'include',
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }
  })
  .then(
    (r) => {
        if (r.status === 200) {
            return r.json();
        }
    })
    .then((r) => {
      dispatch(sendActionFromDispatch(PERSON_CONTEXT_TYPE_REF, r));
    })
  .catch((err) => console.log('issue in action personContextsTypeRefGet, error:', err))
  }
}

export function contextDynsDataBlankOut(dynUnitId) {
  if (dynUnitId === undefined) {
  }
  return(dispatch, getState) => {
    let p = () => {
      return new Promise ((resolve) => {
        resolve('success__from_contextDynsDataBlankOut!!!')
      })
    }
    p(dispatch, getState).then((r) => {
      const { rdcr_context_dyns } = getState();
      const entity_name = 'expr_phunk';
      let field_name = 'type_area';
      let update_single_field_array = [];
      //20200109 changed from map to forEach bc warning message re not returning value from map
      rdcr_context_dyns.forEach((item, i, arr) => {
          if (dynUnitId === undefined) {  //if there is not dynUnitId passed, then all dynUnit will be blanked out, otherwise, just for id passed
            dynUnitId = item.id
          }
          if (item.id === dynUnitId) {
            update_single_field_array.push({
                field_name: field_name,
                field_data: '',
                assoc_id: item.id,
                entity: entity_name
            })
          }
        })
        let context_dyns_updated = [];
        context_dyns_updated = rdcr_context_dyns.slice();
        //20200109 changed from map to forEach bc warning message re not returning value from map
        context_dyns_updated.forEach((item) => {
          if (dynUnitId === undefined) {  //if there is not dynUnitId passed, then all dynUnit will be blanked out, otherwise, just for id passed
            dynUnitId = item.id
          }
          if (item.id === dynUnitId) {
            item.type_area = null;
            item.type_area_name = null;
          }
        })
          return {update_single_field_array: update_single_field_array, context_dyns_updated: context_dyns_updated};
        })
          .then((r) => {
            //to create dispatch as promise, have it in its on "then".
            //Helpful for figuring out: https://stackoverflow.com/questions/40007506/reactjs-redux-how-to-wait-until-dispatch-has-been-completed-before-moving-ont?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
            //20200109 changed from map to forEach bc warning message re not returning value from map
            r.update_single_field_array.forEach((item, i, arr) => {
              //testing: dispatch(sendActionFromDispatch(EG_ACTION__DATA, {msg: item}))      
              dispatch(saveOnBlurSingleField(item))
            })
            dispatch(sendActionFromDispatch(CONTEXT_DYN_DATA__GET, r.context_dyns_updated))        
          })
          .then(() => {
            console.log('last THEN')
          })
        }
  }

export function contextDynsDataGet(context_id, cb_open_dyn_grps_page) {
  
  const fstring = cb_open_dyn_grps_page !== undefined ? cb_open_dyn_grps_page.toString() : ''
  let pg = ''
  if (fstring.includes('/DynGrp')) {
    pg = 'DynGrp'
  }
  //console.log('fstring', fstring)
  //console.log('pg', fstring)

  return(dispatch) => {
    fetch('/routes/api/v1/expr_phunks/?expr_id=' + context_id + '&pg=' + pg, { 
      method: 'get',
      accept: 'application/json',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
      },
      credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
    })
  .then(
    (r) => {
        if (r.status === 200) {
            return r.json();
        }
    })
  .then((r) => {
    const a = Object.create(r);
    dispatch(sendActionFromDispatch(CONTEXT_DYN_DATA__GET, a))
    dispatch(sendActionFromDispatch(CONTEXT_DYN_DATA_PREV, a))
    dispatch(updateUiConfig(UI_CONFIG, 'context_dyns_grp_obs_target_ref_id', a[0].grp_obs_target_ref_id))
    dispatch(updateUiConfig(UI_CONFIG, 'context_dyns_grp_id', a[0].dyns_grp_id))
  })
  .then(() => {
            if (cb_open_dyn_grps_page !== undefined) {
              cb_open_dyn_grps_page()
            }
        })
      }}

export function contextDynsDataUi_DELCAND20181015(context_id, dynId, dynElem, dynType, order) {
  return(dispatch)=> {
    const acton_data = {
      type: 'form actions, contextDynsDataUi!',
      payload: ['test', 'test', 'test']
    };
    dispatch(sendActionFromDispatch(CONTEXT_DYN_DATA__UI_DELCAND20181015, acton_data))
  }
}

export function contextSuccessRefGet(){
  return(dispatch) => {
    fetch('routes/v1/pers_expr_success_ref', {
      method: 'get',
      accept: 'application/json',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
      },
      //credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
      })
      .then(
          (r) => {
              if (r.status === 200) {
                  return r.json();
              }
              else {
                console.log('contextSuccessRefGet r.status NOT good: ', r.status)
              }
          })
        .catch((err) => console.log('contextSuccessRefGet ERROR:', err))
        .then((r) => dispatch(sendActionFromDispatch(CONTEXT_SUCCESS_REF_DATA__GET, r)))
  }
}

export function contextCatRefGet(){ //20191209
  return(dispatch, getState) => {
    const { rdcr_user_curr } = getState();
    fetch('routes/v1/pers_expr_cat_ref/?i=' + rdcr_user_curr[0].usage_grp_id, {
      method: 'get',
      accept: 'application/json',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
      },
      //credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
      })
      .then(
          (r) => {
              if (r.status === 200) {
                  return r.json();
              }
              else {
                console.log('contextCatRefGet r.status NOT good: ', r.status)
              }
          })
        .catch((err) => console.log('contextCatRefGet ERROR:', err))
        .then((r) => dispatch(sendActionFromDispatch(CONTEXT_CAT_REF_DATA__GET, r)))
  }
}

export function DynElemRefGetData(cb_next_action) {
  return(dispatch) => {
    fetch('/routes/api/v1/phunks/dropdown/type/elem', {
      method: 'get',
      accept: 'application/json',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
      },
      credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
      })
      .then(
          (r) => {
              if (r.status === 200) {
                  return r.json();
              }
              else {
                console.log('DynElemRefGetData r.status NOT good: ', r.status)
              }
          })
        .catch((err) => console.log('DynElemRefGetData ERROR:', err))
        .then((r) => dispatch(sendActionFromDispatch(DYN_ELEM_REF_DATA__GET, r)))
        .then(() => {
          if (cb_next_action !== undefined) {
            cb_next_action()
          }
        })
        .then(() => {return 'done_az'})
  };
}  

export function DynExecRefGetData(cb_next_action) {
  return(dispatch) => {
    fetch('/routes/api/v1/phunks/dropdown/type/exec', {
      accept: 'application/json',
      })
      .then(
          (r) => {
              if (r.status === 200) {
                  return r.json();
              }
          })
        .then((r) => dispatch(sendActionFromDispatch(DYN_EXEC_REF_DATA__GET, r)))
        .then(() => {
          if (cb_next_action !== undefined) {
            cb_next_action()
          }
        })
    };
}

export function DynAreaRefGetData(cb_next_action) {  //20200323 new version with both prof and pers
  console.log('getting phunks area in actions DynAreaRefGetData')
  let i = 0;
  let context_cat = '';
  let reducer_crit_data = DYN_AREA_REF_DATA__GET;
  let reducer_crit_data_for_filtered = DYN_AREA_REF_FILTERED_DATA__GET;

  return(dispatch) => {
    const runFetch = (i, context_cat, reducer_crit_data, reducer_crit_data_for_filtered) => {
      return fetch('/routes/api/v1/phunks/dropdown/type/area/?i=' + context_cat, {  //20200322
      accept: 'application/json',
      })
      .then(
          (r) => {
              if (r.status === 200) {
                  return r.json();
              }
          })
        .then((r) => {
          dispatch(sendActionFromDispatch(reducer_crit_data, r));
          dispatch(sendActionFromDispatch(reducer_crit_data_for_filtered, r));
          if (i = 1) {
            dispatch(sendActionFromDispatch(DYN_AREA_REF_DATA__GET, r));
            dispatch(sendActionFromDispatch(DYN_AREA_REF_FILTERED_DATA__GET, r));
          }
        })
        .then(() => {
          if (i = 2 ) {
            if (cb_next_action !== undefined) {
              cb_next_action()
            }
          }
        })
      }
      for (let i = 0; i <= 1; i++) {
        switch (i) {
          case 0:
            context_cat = 'professional';
            reducer_crit_data = DYN_AREA_REF_DATA_PROFESSIONAL__GET;
            reducer_crit_data_for_filtered = DYN_AREA_REF_FILTERED_DATA_PROFESSIONAL__GET;
            break;
          case 1:
            context_cat = 'personal';
            reducer_crit_data = DYN_AREA_REF_DATA_PERSONAL__GET;
            reducer_crit_data_for_filtered = DYN_AREA_REF_FILTERED_DATA_PERSONAL__GET;
            break;
      }
      runFetch(i, context_cat, reducer_crit_data, reducer_crit_data_for_filtered);
    } //close loop
  };
}

export function switchDynAreaRefBasedOnContextCat (contextCat) {
  return(dispatch, getState) => {
    let dyn_area_ref = [];
    switch (contextCat) {
      case 'Personal':
        dyn_area_ref = getState().rdcr_dyn_area_ref_pers.slice();
        break;
      case 'Professional':
        dyn_area_ref = getState().rdcr_dyn_area_ref_prof.slice();
        break;  
    }
    dispatch(sendActionFromDispatch(DYN_AREA_REF_DATA__GET, dyn_area_ref)) 
    dispatch(sendActionFromDispatch(DYN_AREA_REF_FILTERED_DATA__GET, dyn_area_ref)) 
  }
}

export function DynAreaRefFilteredGetData(refresh_or_filter, type_elem_id, cb) {
  return(dispatch, getState) => {
    const { rdcr_dyn_area_ref } = getState();
    const { rdcr_dyn_area_ref_filtered } = getState();
    let new_dyn_area_ref_filtered = [];
    switch (refresh_or_filter){
      case 'refresh':
        dispatch(sendActionFromDispatch(DYN_AREA_REF_FILTERED_DATA__GET, rdcr_dyn_area_ref))    
        break;
      case 'empty_out':
        dispatch(sendActionFromDispatch(DYN_AREA_REF_FILTERED_DATA__GET, new_dyn_area_ref_filtered))
        break;
      case 'filter_out':
        //const { dyn_area_ref_filtered } = getState();
        new_dyn_area_ref_filtered = rdcr_dyn_area_ref_filtered.filter(area => area.phunk_elem_id !== type_elem_id)
        dispatch(sendActionFromDispatch(DYN_AREA_REF_FILTERED_DATA__GET, new_dyn_area_ref_filtered))
        break;
      case 'filter_in':
        //const { rdcr_dyn_area_ref_filtered } = getState();
        //20200109 changed from map to forEach bc warning message re not returning value from map
        rdcr_dyn_area_ref.forEach((area) => {
          if (area.phunk_elem_id === type_elem_id) {
            function areaAlreadyInArray(element){   //using the array prototype some: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
              return element.id === area.id
            }
            if (!rdcr_dyn_area_ref_filtered.some(areaAlreadyInArray)) {
              rdcr_dyn_area_ref_filtered.push(area)
            }
          }
        })
        rdcr_dyn_area_ref_filtered.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        })
        //dyn_area_ref_filtered.push(dyn_area_ref.filter((area) => area.phunk_elem_id === type_elem_id)) 
        new_dyn_area_ref_filtered = rdcr_dyn_area_ref_filtered;
        //new_dyn_area_ref_filtered = rdcr_dyn_area_ref_filtered.sort())
        dispatch(sendActionFromDispatch(DYN_AREA_REF_FILTERED_DATA__GET, new_dyn_area_ref_filtered))
        break;

        case 'filter_in_areas_for_elems_not_established':
          const array_est = type_elem_id.slice() //for this case it's an array
          //let areas_array = [];
          let new_area_array = rdcr_dyn_area_ref.slice(); //starts with all
          //20200109 changed from map to forEach bc warning message re not returning value from map
          array_est.forEach((dyn_est) => {
            rdcr_dyn_area_ref.forEach((phunk_from_base_array, i) => {
              if (dyn_est === phunk_from_base_array.phunk_elem_id) {
                //20200109 changed from map to forEach bc warning message re not returning value from map
                new_area_array.forEach((phunk_from_copy, ii) => {
                  if (phunk_from_copy.phunk_elem_id === phunk_from_base_array.phunk_elem_id) {
                    new_area_array.splice(ii, 1)
                    return    
                  }
                })
              }
            })
          })
          dispatch(sendActionFromDispatch(DYN_AREA_REF_FILTERED_DATA__GET, new_area_array))
          break;
      default:
        break;
    }
  }
}

//20201008
export function DynObsTargetRefGetData() {
  return(dispatch) => {
    fetch('routes/api/v1/phunks/dropdown/type/obs_target', { 
    accept: 'application/json',
    method: 'get',
    accept: 'application/json',
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': 'no-cache'
    },
    credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
    })
    .then(
      (r) => {
        if (r.status === 200) {
            return r.json();
        }
    })
    .then((r) => {
      console.log('DYN_OBS_TARGET_REF__GET')
      dispatch(sendActionFromDispatch(DYN_OBS_TARGET_REF__GET, r));
    })
  }
}

//gen rec
export function genContextRecs(expr_id, disharmony_level, cb_open_new_page){
  console.log('disharmony_level', disharmony_level)
  
  return(dispatch) => {
    fetch('routes/api/v1/expr_immd_act_grp_n_items_n_cntnt_get_or_create/?i=' + expr_id + '&d=' + disharmony_level, { 
    method: 'get',
    accept: 'application/json',
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': 'no-cache'
    },
    credentials: 'include'  //  'include' or 'same-origin'  //http://www.acuriousanimal.com/2016/12/11/fetch-api-and-express-sessions.html
    })
    .then(
      (r) => {
        if (r.status === 200) {
            return r.json();
        }
    })
    .then((r) => {
      console.log('CONTEXT_RECS_DATA__GET')
      dispatch(sendActionFromDispatch(CONTEXT_RECS_DATA__GET, r));
    })
    .then(() => {
      cb_open_new_page()
    })
  }
}

//z1
export function updateContextRecsForAppState(update_single_field) {
  return (dispatch, getState) => {
    const { rdcr_context_recs_changed } = getState();
    let rdcr_context_recs_changed_new = [];

    rdcr_context_recs_changed.forEach(d => {
      if (d.cntnt__id === update_single_field.assoc_id) { d.cntnt__recmd_content = update_single_field.field_data }
      rdcr_context_recs_changed_new.push(d)
    })
    dispatch(sendActionFromDispatch(CONTEXT_RECS_DATA__CHANGED, rdcr_context_recs_changed_new))

  }

}

export function updateRoadmapActive(){
  return(dispatch, getState) => {
    const { rdcr_roadmap_active } = getState()
    let new_roadmap_active = rdcr_roadmap_active + 'hello'
    dispatch(sendActionFromDispatch(ROADMAP_ACTIVE, new_roadmap_active));
  }
}

//20191116
export function getDataset(ds_name, cb){
  const req_body = {ds_name: ds_name}
  return(dispatch) => {
    fetch('/routes/datasets', { 
      method: 'POST',
      body: JSON.stringify(req_body),
      accept: 'application/json',
      mode: 'cors',
      //headers: myheaders
      credentials: 'include',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((r) => {return r.json()})
    .then((r) => {
      dispatch(sendActionFromDispatch(DATASET_GET, r))
      })
    .then(() => {console.log('in action getDataset, in then of CB');
                if (cb !== undefined) {cb()}}
    ) 
  }
}

