import {DYN_OBS_TARGET_REF__GET} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case DYN_OBS_TARGET_REF__GET:
      console.log('rdcr -- DYN_OBS_TARGET_REF__GET action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
