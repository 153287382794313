import {USER_REGISTER_VERIFICATION_INVITE_CODE} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USER_REGISTER_VERIFICATION_INVITE_CODE:
      //console.log('rdcr_user_register_invite -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
