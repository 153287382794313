import {PERSON_CONTEXT_CHAR_REF} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case PERSON_CONTEXT_CHAR_REF:
      console.log('rdcr_pers_context_char_ref -- PERSON_CONTEXT_CHAR_REF action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
