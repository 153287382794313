import {USERS_FOR_ADMIN__DATA} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case USERS_FOR_ADMIN__DATA:
      //console.log('rdcr_users_for_admin -- USERS_FOR_ADMIN__DATA -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
