import {PUBLIC_MORE_INFO_INTEREST__GET } from "../actions";

//NEED TO INTRETRATE INTO reducers/index

export default function(state = {}, action) {

  switch (action.type) {
    case PUBLIC_MORE_INFO_INTEREST__GET:
      //console.log('rdcr_public_more_info_interest -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}