import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { Container, Col, FormGroup, Label, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { genContextRecs, 
            updateUiConfig, 
            DynAreaRefFilteredGetData,
            contextDynsDataBlankOut,
            reOrderDyns,
            saveSingleField,
            saveOnBlurSingleField } from '../actions'
import AppNavBar from './AppNavBar';
import FontAwesome from 'react-fontawesome';
import ContextDynUnit from './ContextDynUnit'

const UI_CONFIG = 'UI_CONFIG';
const UI_CONFIG_DYN_REFINE = 'UI_CONFIG_DYN_REFINE';

class ContextDynGrp extends Component {

    constructor (props){
        super(props);
        this.state = {
            new_data: false,
            update_single_field: {
                field_name: '',
                field_data: '',
                assoc_id: '',
                entity: ''
                },
            obs_target_ref_id: this.props.rdcr_ui_config.context_dyns_grp_obs_target_ref_id !== '' && this.props.rdcr_ui_config.context_dyns_grp_obs_target_ref_id !== null ? this.props.rdcr_ui_config.context_dyns_grp_obs_target_ref_id : '',
            ui_msg: '',
            establishedElems: [],
            rerenderComponent: 1,
            //context_def: this.props.person_active_contexts.find(cntx => cntx.id === this.props.rdcr_context_dyns[0].expr_id).context_def
            context_desc: this.props.rdcr_ui_config.contextDescForDisplay ? this.props.rdcr_ui_config.contextDescForDisplay + '... ' : '' ,
            context_def: this.props.rdcr_ui_config.contextDefForDisplay,
            roadmap_type: this.props.rdcr_ui_config.process_roadmap_mode === 'rapid_roadmap' ? 'RAPID' : 'POWER',
            roadmap_UI_dyn_sel_directions: '',
        }
        this.handleNavButtons = this.handleNavButtons.bind(this)
        this.handleForceRerenderFromChild = this.handleForceRerenderFromChild.bind(this);
   } //end constructor

queueUpDataForChange(e) {
    return new Promise((res, rej) => {
        this.handleOnChange(e)
        res('success');
    })
}

componentDidMount(){
    //if obs_target not est, default it:
    if (this.state.obs_target_ref_id == '') {
        console.log('obs_target_ref_id updating...')
        //fit into handleChange/field save
        let e = {target: {name: 'obs_target_ref_id', value: this.props.rdcr_dyn_obs_target_ref.find(item => item.title = 'Other').id}};
        /* const e = {name: 'obs_target_ref_id',
            value: this.props.rdcr_dyn_obs_target_ref.find(item => item.title = 'Other').id
        } */       
        this.queueUpDataForChange(e)
        .then(() => this.handleSaveData())
    }
    this.setState({
        //roadmap_UI_dyn_sel_directions: this.props.rdcr_ui_config.process_roadmap_mode === 'rapid_roadmap' ? 'roadmap mode (Select at least THREE dynamics -- best if areas in both "Excels in" and "Hardship in" are selected)' : 'roadmap mode (Select ALL FOUR dynamics)'
        roadmap_UI_dyn_sel_directions: 'Select at least THREE dynamics -- best if areas in both "Excels in" and "Hardship in" are selected.'
    })
    this.props.updateUiConfig(UI_CONFIG, 'appSectionActive', 'Dynamics');
    if (this.props.rdcr_ui_config.context_dyns_established) {
        this.props.DynAreaRefFilteredGetData('empty_out')
    }
    else {
        //20200109 changed from map to forEach bc warning message re not returning value from map
        this.props.rdcr_context_dyns.forEach((dyn) => {
            if (dyn.type_area !== null) { 
                this.setState({
                    establishedElems: this.state.establishedElems.push(dyn.type_elem)
                })
            }
        })
        this.props.DynAreaRefFilteredGetData('filter_in_areas_for_elems_not_established', this.state.establishedElems);
  }
}

handleOnChange(e) {
    console.log('e.target.name', e.target.name); 
    console.log('e.target.value', e.target.value); 
    this.setState({
      new_data: true,
      update_single_field: {
        field_name: e.target.name,
        field_data: e.target.value,
        assoc_id: this.props.rdcr_ui_config.context_dyns_grp_id,
        entity: 'expr_phunk_grp'
      },
      [e.target.name]: e.target.value
    })
    //changing rdcr obs target here (instead of onBlur at saving) updates children dyns (units)
    this.props.updateUiConfig(UI_CONFIG, 'context_dyns_grp_obs_target_ref_id', e.target.value);
}

handleSaveData(){
    if (this.state.new_data === true){
      this.props.saveOnBlurSingleField(this.state.update_single_field);
      this.setState({
        new_data: false
      })
    }
  }

handleChangeData(e){

    let whatTodoWithData = '';

    switch (e.type) {
        case 'change':
        this.setState({
            [e.target.name]: e.target.value
        })
        whatTodoWithData = 'updateState'
        break;    
       
        case 'blur':
        whatTodoWithData = 'saveToDb'
        break;
        
        default:
        break;
    }   
    const field_name = e.target.name;
    const field_data = e.target.value;
    const assoc_id = this.props.rdcr_context_dyns[0].expr_id;
    const table_name = 'pers_expr';
    this.props.saveSingleField(field_name, field_data, assoc_id, table_name, whatTodoWithData);
}

handleTest_reOrderDyns(){
    this.props.reOrderDyns()
}

handleRefine_DELCAND20181106() { //replaced by DynUnit-level refining
    
    //refining flag
    if (this.props.rdcr_ui_config.dyn_refining === 'true') {
        this.props.updateUiConfig(UI_CONFIG, 'dyn_refining', 'false')
    }
    else {
        this.props.updateUiConfig(UI_CONFIG, 'dyn_refining', 'true')
    }
    //refining INITIATED flag
    if (this.props.rdcr_ui_config.dyn_refining_init === 'true') {
        this.props.updateUiConfig(UI_CONFIG, 'dyn_refining_init', 'false')
    }
    else {
        this.props.updateUiConfig(UI_CONFIG, 'dyn_refining_init', 'true')
    }
    //update for each phunk in this context, blank out type_area:
    //this.props.contextDynsDataBlankOut();  //zzz commented out 20181016,moved to dynUnit 
    //this.props.DynAreaRefFilteredGetData('refresh');
    //////////////////////////this.props.handleContextDynamicsConfigUi('refine', this.props.contextDynamics[0].expr_id);
    //NEED TO DELETE HIS COMPETELY THROUGHOUT:  this.props.handleContextDynamicsClearAreasFromDynArray();
}

handleNavButtons(e){
    this.props.updateUiConfig(UI_CONFIG, 'dyn_refining', false);
    this.props.updateUiConfig(UI_CONFIG, 'dyn_refining_init', false);
    this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'movePositionSourceSelectedDynUnitId', '');
    this.props.updateUiConfig(UI_CONFIG_DYN_REFINE, 'changeAreaSelectedDynUnitIds', []);

    switch (e.target.name) {
        case 'recsAndActionsButton':
            //only generate recs of all dyns set:
            const cntxt_dyns = this.props.rdcr_context_dyns.slice()
            let count = 0;
                    cntxt_dyns.map((d) => {if (d.type_area) {count++}})
                switch (this.props.rdcr_ui_config.process_roadmap_mode){
                case 'rapid_roadmap':
                    if (count >= 3) {
                        this.props.genContextRecs(this.props.rdcr_context_dyns[0].expr_id, this.props.rdcr_ui_config.contextDisharmonyLevel, 
                                                        () => this.props.history.push('/ContextRecsAndActions'))
                    }
                    else {
                        this.setState({
                            ui_msg: 'At least three dynamics areas need to be selected.'
                        })    
                    }
                    return;
                default:
                    /* if (cntxt_dyns[0].type_area && cntxt_dyns[1].type_area && cntxt_dyns[2].type_area && cntxt_dyns[3].type_area) {
                        this.props.genContextRecs(this.props.rdcr_context_dyns[0].expr_id, this.props.rdcr_ui_config.contextDisharmonyLevel, 
                                                        () => this.props.history.push('/'))
                    } */
                    if (count >= 3) { ///20200613 set to be exactly like rapid -- experiment if only 3 required or back to 4
                        this.props.genContextRecs(this.props.rdcr_context_dyns[0].expr_id, this.props.rdcr_ui_config.contextDisharmonyLevel, 
                            () => this.props.history.push('/ContextRecsAndActions'))
                    }
                    else {
                        //with 4: ui_msg: 'All four dynamic areas need to be selected for POWER roadmap.'
                        this.setState({
                            ui_msg: 'At least three dynamics areas need to be selected.'
                        })    
                    }
                    return;
            }
        case 'goBackButton':
            this.props.history.goBack()
            break;
        default:
            break;
    }
}
//20180513 az need to review what this does and if needed with new redux
handleGoBack(){
    this.props.handleContextDynamicsConfigUi('done', '')
}

handleForceRerenderFromChild(){
    this.forceUpdate();
    this.setState({
        rerenderComponent: this.state.rerenderComponent === 1 ? 2 : 1
    })
}

//BRING IN: brand="Perceptions and intuitions in context"/>

    render(){
    return (
        <div>
        <Container>
        <Card>
            <CardHeader className='container_generic_background_white'>
            <div>
                <button type="button"
                    className="btn btn-link"
                    name='goBackButton'
                    value='goBack'
                    onClick={() => this.props.history.goBack()}>
                    <FontAwesome
                        name="chevron-left"
                    />
                    back
                </button>
                <AppNavBar
                    brand={this.props.rdcr_ui_config.dynGroupTitle !==''? (this.props.rdcr_ui_config.dynGroupTitle + ' (' + this.props.rdcr_ui_config.activePersName + ')') : "Dynamics"} //2020921 changed from "Intuitions and Observations"
                />
                <br/>
                <Label className='containerContextDynamicUnit__dropdownDyanmicPhunkArea'>Observing:</Label>
                <select
                    hidden={false} 
                    className="form-control containerContextDynamicUnit__dropdownDyanmicPhunkArea" 
                    name='obs_target_ref_id' 
                    value={this.state.obs_target_ref_id} 
                    onChange={(e) => this.handleOnChange(e)}
                    onBlur={() => this.handleSaveData()}>
                    {this.props.rdcr_dyn_obs_target_ref.map(item => {
                        let uiTitle = item['title'] != 'Other' ? item['title'] : (this.props.rdcr_person_active[0].first_name ? this.props.rdcr_person_active[0].first_name : '') +
                            ' ' + (this.props.rdcr_person_active[0].last_name ? this.props.rdcr_person_active[0].last_name : '')
                        return <option key={item['id']} value={item['id']}>{uiTitle}
                    </option>
                    })}
                  </select>
                    <Label><span hidden={this.state.obs_target_ref_id == this.props.rdcr_dyn_obs_target_ref.find(e => e.title = 'Other').id} className='font-italic font-bold'>
                        With: {(this.props.rdcr_person_active[0].first_name ? this.props.rdcr_person_active[0].first_name : '') +
                                                ' ' + (this.props.rdcr_person_active[0].last_name ? this.props.rdcr_person_active[0].last_name : '')}
                        </span>
                    </Label>
                  <p><span className='mt-2 mr-2 font-italic font-bold'>In context: </span>{this.state.context_desc}{this.state.context_def}</p>
            </div>
                <div hidden={true}> {/*DELCAND_20200929 RAPID/POWER ROADMAP CONCEPT*/}
                    <Label><span className='userTypeDisplay'>{this.state.roadmap_type} </span>roadmap</Label>                
                    <div>
                        <Label>{this.state.roadmap_UI_dyn_sel_directions}</Label>
                    </div>
                </div>
                {/*
                <Input
                value = {this.state.context_def}
                name = 'context_def'
                onChange={(e) => this.handleChangeData(e)}
                onBlur={(e) => this.handleChangeData(e)}>
                 </Input>
                */}
                </CardHeader>
            <CardBody className='pt-2'>
                <FormGroup row className='mb-0'>
                    <Col sm={12}>
                    { this.props.rdcr_context_dyns.map ((d, index) => {
                        return <ContextDynUnit
                            contextDynamicUnit={ d }
                            key={d.id}
                            contextId={d.expr_id}
                            dynIndex={index}
                            history={this.props.history}
                            handleForceRerenderFromChild={this.handleForceRerenderFromChild}
                            />
                        })
                    }
                    </Col>
                </FormGroup>
                    {/*<button type="button" className="btn btn-primary btn-block" name='' value="RecsAndActions" onClick={() => this.props.genContextRecs(this.props.rdcr_context_dyns[0].expr_id, () => {this.props.history.push('/ContextRecsAndActions')})}>Recommendation and Actions</button>*/}
                    <div hidden={this.state.ui_msg ? false : true}
                        className="alert alert-danger" role="alert">
                        {this.state.ui_msg}
                    </div>
                    <label className='ui_msg_label'>{this.state.ui_msg}</label>
                <CardFooter>
                <FormGroup row>
                                <div className="col">
                                    <button type="button" 
                                        className="btn btn-link"
                                        name='goBackButton'
                                        value='goBack'
                                        onClick={(e) => this.handleNavButtons(e)}
                                        >
                                        <i className="fas fa-chevron-left"></i>back
                                    </button>
                                </div>
                                <div className="col-auto ml-auto">
                                    <button
                                        hidden={true}
                                        type="button"
                                        className="btn btn-outline-secondary mr-2"
                                        disabled={this.props.rdcr_ui_config.dyn_refining === 'true' ? true: false}
                                        name='reFine'
                                        value='reFine'
                                        onClick={() => this.handleTest_reOrderDyns()}
                                        >Sort_UNUSED__NOT_FIN__PREV_GRP_REFINE
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        name='recsAndActionsButton'
                                        value="recsAndActions"
                                        onClick={(e) => this.handleNavButtons(e)}
                                        >Roadmap
                                    </button>
                                </div>
                </FormGroup>
                </CardFooter>
            </CardBody>
            </Card>
        </Container>
      </div>
    );
    }    
}

//export default ContextDynamicGroup_NEW;

function mapStateToProps(state){
    return{
        rdcr_context_dyns: state.rdcr_context_dyns,
        //person_active_contexts: state.person_active_contexts,
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_person_active: state.rdcr_person_active,
        rdcr_dyn_obs_target_ref: state.rdcr_dyn_obs_target_ref,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        genContextRecs,
        updateUiConfig,
        DynAreaRefFilteredGetData,
        contextDynsDataBlankOut,
        reOrderDyns,
        saveSingleField,
        saveOnBlurSingleField }, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(ContextDynGrp);