import {PERSON_CONTEXT_DESC_REF_PERS } from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case PERSON_CONTEXT_DESC_REF_PERS:
      //console.log('rdcr_persons_context_desc_ref_pers -- PERSON_CONTEXT_DESC_REF_PERS -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}