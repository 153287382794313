import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { FormGroup, Container, Col, Label  } from 'reactstrap';
import AppNavBar from './AppNavBar';
import ContextRecAndAction001Unit from './ContextRecAndAction001Unit';
import Clipboard from 'react-clipboard.js';
import FontAwesome from 'react-fontawesome';
import { updateUiConfig, UI_CONFIG } from '../actions'

//import ContextRecAndAction001Utility from './ContextRecAndAction001Utility'

//Popup not used here
class Popup extends React.Component {
    render() {
      return (
        <div className='popup'>
          <div className='popup_inner'>
            <button 
                className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                onClick={this.props.closePopup}>OK
            </button>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        {this.props.action_rapport}
                    </div>
                </div>
            </div>
            <p className= 'text-primary'>Copied to clipboard, rapport and conclude with/deliver:</p>
            <span></span>
            <p>{this.props.action_rapport}</p>
            <p>. . .</p>
            <p>{this.props.action_deliver}</p>
            <span></span>
            <span></span>
            <p>{this.props.text1}</p>
            <p>{this.props.text1}</p>
          </div>
        </div>
      );
    }
  }

  //https://reactjsexample.com/tag/popup/
  //https://react-bootstrap.github.io/components/modal/
  
class ContextRecAndAction001Grp extends Component {

  constructor(props) {
    super(props);
    
    //20190701az: re-did order in table 'aux' third position (2 based 0) and tersh 4th position (3 base 0) 
    // used to be other way around when conceptulaizaiton deliver (tersh) in the language of aux
    //now aux = rapport and tersh = del
    //old os 20190701:
    /*
    //change order of recs from prop.context_recs to state.context_recs
    let arry = [];
    arry.push(this.props.rdcr_context_recs[0]);
    arry.push(this.props.rdcr_context_recs[1]);
    arry.push(this.props.rdcr_context_recs[3]);
    arry.push(this.props.rdcr_context_recs[2]);
    */
    
    this.state = {
        context_recs: this.props.rdcr_context_recs,
        //context_goal: this.props.person_active_contexts.find(cntx => cntx.id === this.props.rdcr_context_active.contextId).context_goal
        context_goal: this.props.rdcr_ui_config.contextGoalForDisplay,
        process_roadmap_mode: this.props.rdcr_ui_config.process_roadmap_mode,
        count_roadmap_mode_switches_for_back_nav: -1,
        count_roadmap_view_switches_for_back_nav: -1,
        curr_roadmap_view: '',
        showPopup: false,
        clipboardMsg: '',
        clipboardType: 'Email'
    }
    
} //end constructor

componentDidMount(){
    this.props.updateUiConfig('UI_CONFIG', 'appSectionActive', 'Recommendations & Actions');
    //resetting roadmap toogle to start with position_one for rapport and deliver
    this.props.updateUiConfig('UI_CONFIG', 'recmd_roadmap_display_config', 'position_one')
  }

  togglePopup() {  //re popup, from: https://codepen.io/bastianalbers/pen/PWBYvz
    this.setState({
      showPopup: !this.state.showPopup
    });
    //copy to clipboard:
    /* doesn't keep the linebreaks bc using input instead of textarea
    //https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
    let rec_actions_text = 'hello' + this.props.rdcr_context_recs_changed[2].cntnt__recmd_content
        + "\n" + ' ... '
        + "\n"  + this.props.rdcr_context_recs_changed[3].cntnt__recmd_content
    console.log('rec_actions_text', rec_actions_text)
    let copy_actions = document.createElement("input");
    document.body.appendChild(copy_actions);
    copy_actions.setAttribute('value', rec_actions_text);
    copy_actions.select();
    document.execCommand("copy");
    document.body.removeChild(copy_actions);
    */
  }
    /*
    copyToClipboard_NOTUSED_BC_NOT_FOR_SAFARI() {
    //using textarea to retain the line breaks:
    //https://stackoverflow.com/questions/53617043/how-to-copy-text-and-newlines-in-js
    let rec_actions_text = this.props.rdcr_context_recs_changed[2].cntnt__recmd_content +
        "\r\n" + "\r\n" + ' ... ' +
        "\r\n"  + "\r\n" + this.props.rdcr_context_recs_changed[3].cntnt__recmd_content
    console.log('rec_actions_text', rec_actions_text)
    let copy_actions = document.createElement("textarea");
    //document.body.appendChild(copy_actions);
    copy_actions.appendChild(document.createTextNode(rec_actions_text));
    document.body.append(copy_actions)
    copy_actions.select();
    document.execCommand("copy");
    document.body.removeChild(copy_actions);
    alert('Copied to clipboard: rapport and conclude with/deliver');
    
    //dealing with iOS safari
    //https://stackoverflow.com/questions/40147676/javascript-copy-to-clipboard-on-safari
  }
  */

  //>>>>>>>>>>>>>>>
  /*
  copyToClipboard_DELETE() {
    //using textarea to retain the line breaks:
    //https://stackoverflow.com/questions/53617043/how-to-copy-text-and-newlines-in-js
    let rec_actions_text = this.props.rdcr_context_recs_changed[2].cntnt__recmd_content
        + "\r\n" + "\r\n" + ' ... '
        + "\r\n"  + "\r\n" + this.props.rdcr_context_recs_changed[3].cntnt__recmd_content
    console.log('rec_actions_text', rec_actions_text)

    //dealing with iOS safari
    //https://stackoverflow.com/questions/40147676/javascript-copy-to-clipboard-on-safari
    let Clipboard = (function(window, document, navigator) {
        var textArea,
            copy;
    
        function isOS() {
            console.log('isOS')
            console.log(navigator.userAgent.match(/ipad|iphone/i))
            return navigator.userAgent.match(/ipad|iphone/i);
        }
    
        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.value = text;
            document.body.appendChild(textArea);
        }
    
        function selectText() {
            var range,
                selection;
    
            if (isOS()) {
                console.log('inside if isOS');
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();  //changed from window.getSelection()
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
                console.log(textArea);
                console.log(textArea.value);
            } else {
                textArea.select();
            }
        }
    
        function copyToClipboard() {        
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
    
        copy = function(text) {
            createTextArea(text);
            selectText();
            copyToClipboard();
        };
    
        return {
            copy: copy
        };
    })(window, document, navigator);
    
    // How to use
    Clipboard.copy(rec_actions_text);
    alert('Copied to clipboard: rapport and conclude with/deliver');
}
*/

    msgBoxCopy(){
        alert('Copied to clipboard: rapport and conclude with/deliver')
    }

    clipboardCopyMsg(){
        this.setState({
            clipboardMsg: 'Copied to clipboard: rapport and conclude with/deliver.'
        })
        setTimeout(() => { this.setState({
            clipboardMsg: ''
        })}, 5000)
    }

  //<<<<<<<<<<<<<<<

    handleNavButtons(e){
        switch (e.target.name) {
            case 'recsAndActionsButton':
                //console.log('from inside switch, case:', e.target.name)
                this.props.genContextRecs(this.props.rdcr_context_dyns[0].expr_id, () => this.props.history.push('/ContextRecsAndActions'))
                break;
            case 'goBackButton':
                //console.log('from inside switch, case:', e.target.name)
                //console.log('this.state.count_roadmap_mode_switches_for_back_nav', this.state.count_roadmap_mode_switches_for_back_nav)
                this.props.history.go(this.state.count_roadmap_mode_switches_for_back_nav)
                break;
            case 'viewInOtherRmMode':
                //console.log('from inside switch, case:', e.target.name)
                this.setState({
                    count_roadmap_mode_switches_for_back_nav: this.state.count_roadmap_mode_switches_for_back_nav - 1,
                    process_roadmap_mode: this.state.process_roadmap_mode === 'rapid_roadmap' ? 'power_roadmap' : 'rapid_roadmap'
                })
                //this.props.updateUiConfig(UI_CONFIG, 'process_roadmap_mode', this.props.rdcr_ui_config.process_roadmap_mode === 'rapid_roadmap' ? 'power_roadmap' : 'rapid_roadmap')
                this.props.history.push('/ContextRecsAndActions')
                break;
            default:
                break;
        }
    }

    //20200117
    handleClipboardTypeChange(){
        switch (this.state.clipboardType) {
            case 'Email':
                this.setState({
                    clipboardType: 'Meeting'
                })
                break;
            case 'Meeting':
                this.setState({
                    clipboardType: 'Email'
                })
                break;
            default:
                break;
        }
    }

    handleDataClipboardText(){
        console.log('this.props.rdcr_context_recs_changedzzz', this.props.rdcr_context_recs_changed)
        switch (this.state.clipboardType) {
            case 'Email':
                return this.props.rdcr_context_recs_changed[0].cntnt__recmd_content + 
                "\r\n\r\n" + 
                " ... " +
                "\r\n\r\n" + this.props.rdcr_context_recs_changed[1].cntnt__recmd_content
            case 'Meeting':
                return "For Goal: " + this.state.context_goal + "\r\n\r\n" +
                "For rapport..." + "\r\n\r\n" +
                //this.props.rdcr_context_recs_changed[2].exec_ref__descript_rec_01 + "\r\n" +
                "   " + this.props.rdcr_context_recs_changed[0].elem_ref__descript_rec_04 + "\r\n\r\n" +
                "   " + "Text/outline:" + "\r\n" +
                "   " + this.props.rdcr_context_recs_changed[0].cntnt__recmd_content + "\r\n\r\n" +
                "--+++++++++++++++++++++++++++++++++++++++++" + "\r\n\r\n" + 
                "Deliver/conclude with..." + "\r\n\r\n" +
                "   " + this.props.rdcr_context_recs_changed[1].elem_ref__descript_rec_04 + "\r\n\r\n" +
                "   " + "Text/outline:" + "\r\n" +
                "   " + this.props.rdcr_context_recs_changed[1].cntnt__recmd_content + "\r\n\r\n" +
                "--+++++++++++++++++++++++++++++++++++++++++" + "\r\n\r\n" + 
                this.props.rdcr_context_recs_changed[2].exec_ref__descript_rec_01 + ":" + "\r\n\r\n" +
                "   " + this.props.rdcr_context_recs_changed[2].elem_ref__descript_rec_04 + "\r\n\r\n" +
                "   " + "Notes:" + "\r\n" +
                "   " + this.props.rdcr_context_recs_changed[2].cntnt__recmd_content + "\r\n\r\n" +
                "--+++++++++++++++++++++++++++++++++++++++++" + "\r\n\r\n" + 
                this.props.rdcr_context_recs_changed[3].exec_ref__descript_rec_01 + ":" + "\r\n\r\n" +
                "   " + this.props.rdcr_context_recs_changed[3].elem_ref__descript_rec_04 + "\r\n\r\n" +
                "   " + "Notes:" + "\r\n" +
                "   " + this.props.rdcr_context_recs_changed[3].cntnt__recmd_content + "\r\n\r\n"
            default:
                break;
        }
    }

    handleMakeRecmntContentVisible(){
        this.props.updateUiConfig('UI_CONFIG', 'recmd_content_visible', !this.props.rdcr_ui_config.recmd_content_visible)
    }

    handleMakeRecmntContentVisibleWithoutRecmdElem(){
        if (!this.props.rdcr_ui_config.recmd_content_visible) {
            this.props.updateUiConfig('UI_CONFIG', 'recmd_content_visible', true)
        }
        this.props.updateUiConfig('UI_CONFIG', 'recmd_content_visible_only', !this.props.rdcr_ui_config.recmd_content_visible_only)
    }

    handleChangeRmDisplay(){
        //20200921
        let rmDisplayConfig;
        switch (this.props.rdcr_ui_config.recmd_roadmap_display_config){
            case 'position_one':
                rmDisplayConfig = 'position_two'
                break;
            default:
                rmDisplayConfig = 'position_one'
        }
        this.props.updateUiConfig('UI_CONFIG', 'recmd_roadmap_display_config', rmDisplayConfig)
    }
    
    render(){
    return (
        <div className='containerRecommendationGroup'>
            <Container>
            <FormGroup row className= 'display-inline-span'>
                <Col className="col-12">
                    <button type="button"
                        className="btn btn-link"
                        name='goBackButton'
                        value='goBack'
                        onClick={(e) => this.handleNavButtons(e)}>
                        <FontAwesome
                            name="chevron-left"
                        />
                        back
                        </button>
                    <AppNavBar
                        brand="Communication Roadmap"
                    />
                </Col>
            </FormGroup>
        
            <div>
                <FormGroup row>
                    <Col className="col-12">
                        <Label><span className='font-italic'>Goal: </span>{this.state.context_goal}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col className="col-12">
                        <p>
                            <span className='font-italic font-bold'>For: </span><span>{(this.props.rdcr_person_active[0].first_name ? this.props.rdcr_person_active[0].first_name : '') +
                                                            ' ' + (this.props.rdcr_person_active[0].last_name ? this.props.rdcr_person_active[0].last_name : '')}
                            </span>
                        </p>
                        <p hidden={true}> {/*DELCAND_20200929 RAPID/POWER ROADMAP CONCEPT*/}
                            <span className='userTypeDisplay'>{this.props.rdcr_ui_config.process_roadmap_mode === 'rapid_roadmap' ? 'RAPID' : 'POWER'} </span>roadmap mode{
                            this.state.process_roadmap_mode !== this.props.rdcr_ui_config.process_roadmap_mode ? (this.state.process_roadmap_mode === 'rapid_roadmap' ? ' in RAPID view' : ' in POWER view') : ''
                            }
                        </p>
                        {/*THESE BUTTONS SAME AS BELOW*/}
                        <button 
                            type="button" 
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            disabled={this.props.rdcr_ui_config.recmd_content_visible_only}
                            name='ActionsVisible'
                            value='ActionsVisible' 
                            onClick={(e) => this.handleMakeRecmntContentVisible()}>
                            {this.props.rdcr_ui_config.recmd_content_visible === false ? 'View Actions' : 'Hide Actions'}
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            name='ChangeRmDisplay'
                            value='ChangeRmDisplay' 
                            onClick={(e) => this.handleChangeRmDisplay()}>
                            Toggle Roadmap Elements
                        </button>
                        <button 
                            hidden={true}
                            type="button" 
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            disabled={this.props.rdcr_ui_config.recmd_content_visible_only}
                            name='DosCopyNPaste'
                            value='DosCopyNPaste' 
                            onClick={() => alert('hello')}>
                            {this.props.rdcr_ui_config.recmd_content_visible === false ? 'Copy and Paste' : 'Copy and Paste'}
                        </button>
                        <button hidden={true}
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={this.togglePopup.bind(this)}>Copy/Compose
                        </button>
                        <button hidden={true}
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={() => this.copyToClipboard()}>Copy/Compose
                        </button>
                        <button hidden={true}
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={() => this.props.history.push('/ContextRecAndAction002CpyPst')}>Copy/Compose
                        </button>
                        <div hidden={true}>
                            <Clipboard
                                className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                                className="text-primary "
                                data-clipboard-text={this.handleDataClipboardText()}
                                onClick={()=>this.clipboardCopyMsg()}>
                                Copy/Compose
                            </Clipboard>
                        </div>
                        {/*az made hidden 20200921>*/}
                        <button hidden={true} 
                            type="button" 
                            className="btn btn-link"
                            name='viewInOtherRmMode'
                            value='' 
                            onClick={(e) => this.handleNavButtons(e)}>
                            Switch rapid/power view
                        </button>
                        {/*az started here 20200720*/}
                        <button hidden={true} 
                            type="button" 
                            className="btn btn-link"
                            name='viewInOtherRmView'
                            value='' 
                            onClick={(e) => this.handleNavButtons(e)}>
                            Change roadmap view
                        </button>
                        <Clipboard className="text-primary bg-white border border-white ml-3 mr-3 mb-2 mt-2 mb-sm-0"
                            button-background-color="bg-gradient-primary"
                            data-clipboard-text={this.handleDataClipboardText()}
                            onClick={()=>this.clipboardCopyMsg()}>
                                <FontAwesome
                                    name='far fa-copy'
                                />
                        </Clipboard>
                        <button hidden={true}
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={() => this.handleClipboardTypeChange()}>{this.state.clipboardType}                        </button>
                        <span className="containerRecommendationGroupToggleText"
                            onClick={() => this.handleClipboardTypeChange()}><u>({this.state.clipboardType})</u>
                        </span>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col className="col-12">         
                        <div>
                            {  this.state.context_recs.map (d => {
                                    return <div
                                            key={d.cntnt__id}
                                            > 
                                        {/*for future use, to place another block within four dyns
                                            (itemCounter === -1) ? <ContextRecAndAction001Utility
                                            key={itemCounter++}
                                        /> : <span></span>*/}
                                        <ContextRecAndAction001Unit
                                            contextRecAndAction={ d }
                                            key={d.cntnt__id}
                                            process_roadmap_mode={this.state.process_roadmap_mode}
                                            />
                                        </div>
                                })
                            }
                        </div>
                    </Col>
                </FormGroup>
            </div>
            <div>
                <FormGroup row>
                    <Col className="col-12 col-sm-auto">
                        <button type="button" 
                            className="btn btn-link mt-2"
                            name='goBackButton'
                            value='goBack'
                            onClick={(e) => this.handleNavButtons(e)}
                            >
                            <i className="fas fa-chevron-left"></i>back
                        </button>
                    </Col>
                    <Col className="col-12 col-sm-auto text-primary">
                        {this.state.clipboardMsg}
                    </Col>
                    <Col className="col-12 ml-auto col-sm-auto">
                    <div hidden={this.state.action_note === '' ? 'true' : false}>
                        <p className='ml-2 mt-2 mr-2 mb-2 text-primary'>{this.state.action_note}</p>
                        </div>
                    </Col>
                    <Col className="col-12 ml-auto col-sm-auto">
                        <button 
                            hidden={true /*hidden for workshop*/}
                            type="button" 
                            className="btn btn-primary btn-primary-spacing"
                            name='ActionsOnly'
                            value='ActionsOnly' 
                            onClick={(e) => this.handleMakeRecmntContentVisibleWithoutRecmdElem()}>
                            {this.props.rdcr_ui_config.recmd_content_visible_only === false ? 'View Actions Only' : 'View Dynamics Set'}
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            disabled={this.props.rdcr_ui_config.recmd_content_visible_only}
                            name='ActionsVisible'
                            value='ActionsVisible' 
                            onClick={(e) => this.handleMakeRecmntContentVisible()}>
                            {this.props.rdcr_ui_config.recmd_content_visible === false ? 'View Actions' : 'Hide Actions'}
                        </button>
                        <button 
                            hidden={true}
                            type="button" 
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            disabled={this.props.rdcr_ui_config.recmd_content_visible_only}
                            name='DosCopyNPaste'
                            value='DosCopyNPaste' 
                            onClick={() => alert('hello')}>
                            {this.props.rdcr_ui_config.recmd_content_visible === false ? 'Copy and Paste' : 'Copy and Paste'}
                        </button>
                        <button hidden={true}
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={this.togglePopup.bind(this)}>Copy/Compose
                        </button>
                        <button hidden={true}
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={() => this.copyToClipboard()}>Copy/Compose
                        </button>
                        <button hidden={true}
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={() => this.props.history.push('/ContextRecAndAction002CpyPst')}>Copy/Compose
                        </button>
                        <div hidden={true}>
                            <Clipboard
                                className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                                className="text-primary "
                                data-clipboard-text={this.handleDataClipboardText()}
                                onClick={()=>this.clipboardCopyMsg()}>
                                Copy/Compose
                            </Clipboard>
                        </div>
                        <button 
                            hidden={true}
                            type="button" 
                            className="btn btn-link"
                            name='viewInOtherRmMode'
                            value='' 
                            onClick={(e) => this.handleNavButtons(e)}>
                            Switch rapid/power view
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            name='ChangeRmDisplay'
                            value='ChangeRmDisplay' 
                            onClick={(e) => this.handleChangeRmDisplay()}>
                            Toggle Roadmap Elements
                        </button>
                            <Clipboard className="text-primary bg-white border border-white ml-3 mr-3 mb-2 mt-2 mb-sm-0"
                                button-background-color="bg-gradient-primary"
                                data-clipboard-text={this.handleDataClipboardText()}
                                onClick={()=>this.clipboardCopyMsg()}>
                                    <FontAwesome
                                        name='far fa-copy'
                                    />
                            </Clipboard>
                        <button hidden={true}
                            className="btn btn-outline mr-2 mb-2 mt-2 mb-sm-0"
                            onClick={() => this.handleClipboardTypeChange()}>{this.state.clipboardType}                        </button>
                        <span className="containerRecommendationGroupToggleText"
                            onClick={() => this.handleClipboardTypeChange()}><u>({this.state.clipboardType})</u>
                        </span>
                    </Col>
                </FormGroup>
            </div>
            {this.state.showPopup ? 
          <Popup
            action_rapport={this.props.rdcr_context_recs_changed[3].cntnt__recmd_content}
            action_deliver={this.props.rdcr_context_recs_changed[2].cntnt__recmd_content}
            msg={'Copied to clipboard: rapport and conclude with/deliver'}
            closePopup={this.togglePopup.bind(this)}
          />
          : null
        }
        </Container>
        </div>
    );
    }
}

//https://www.npmjs.com/package/react-clipboard.js?activeTab=readme

//export default ContextRecsAndActionsGroup;

function mapStateToProps(state){
    return {
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_context_recs: state.rdcr_context_recs,
        rdcr_context_dyns: state.rdcr_context_dyns,
        //person_active_contexts: state.person_active_contexts,
        rdcr_context_active: state.rdcr_context_active,
        rdcr_context_recs_changed:state.rdcr_context_recs_changed,
        rdcr_person_active: state.rdcr_person_active
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ 
        updateUiConfig: updateUiConfig }, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(ContextRecAndAction001Grp);