import {EVENTS_SELECTED_BY_USER_PRE_SAVE} from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case EVENTS_SELECTED_BY_USER_PRE_SAVE:
      //console.log('rdcr_user_events_selected_pre_save -- EVENTS_SELECTED_BY_USER -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}
