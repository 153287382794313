import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Container, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { createNewOrConfigUsageGrpByAdmin, createNewUserUsageGrp } from "../actions";

class AdminCreateConfigUsageGroups extends Component{

    constructor (props){
        super(props)
        this.state = {
            db_action: '',
            id: '',
            title: '',
            ab_desc: '',
            location_ed_mat_01: '',
            meeting_url: '',
            expr_cat_title_default: '',
            expr_cat_title_all: '',
            }
    }  //end of constructor

componentDidMount() {
    window.scrollTo(0,0);
}

//NEWZ
handleOnChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    })
}

handleOnSubmitForm(e){
    e.preventDefault();
    const data = {db_action: 'insert',
            'id': this.state.id,
            title: this.state.title,
            ab_desc: this.state.ab_desc,
            location_ed_mat_01: this.state.location_ed_mat_01,
            meeting_url: this.state.meeting_url,
            expr_cat_title_default: this.state.expr_cat_title_default,
            expr_cat_title_all: this.state.expr_cat_title_all}
    console.log('dataz_from_new_usage_grp', data)
    this.props.createNewOrConfigUsageGrpByAdmin(data)
}

handleOnClickButtonUserGrpCreateTEST(){
    this.props.createNewUserUsageGrp('Jill Waters', '83d8788a-55b2-50a0-6778-a82f02524688')
}

renderRegSection(){
return  <Container>
        <Form onSubmit={this.handleOnSubmitForm.bind(this)}>
            <FormGroup>
                <Label className='mb-0' htmlFor='fn'>New Usage Group Name</Label>
                <Input
                type="text"
                id='title'
                name='title'
                value={this.state.title} 
                onChange={(e) => this.handleOnChange(e)}    
                />
            </FormGroup>
            <FormGroup><button type='submit'
                        name='' value='create_new_user' 
                        >
                        Submit
                    </button></FormGroup>
        </Form>
    </Container>
}

    render(){

    return (
        <div>
            <div>
                <button
                onClick = {() => this.handleOnClickButtonUserGrpCreateTEST()}>
                    TESTING CALLING FUNCTION CREATE USER USAGE GRP
                </button>
            </div>
            <div>
                {this.renderRegSection()}
            </div>
            </div>
    );
    }
}

//export default RegNewUser; //pre redux

function mapStateToProps(state) {
    return {    
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_user_register: state.rdcr_user_register,
        };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        createNewOrConfigUsageGrpByAdmin,
        createNewUserUsageGrp }, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(AdminCreateConfigUsageGroups);