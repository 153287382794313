//based on RegNewUserWoAccount
import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome';
import { Container, Row, Col, Form, FormGroup, Card, CardHeader, CardBody, CardFooter, Label, Input } from 'reactstrap';
import { 
            setUpUiConfig,
            setUpDefaults,
            verifyUserNameDoesNotExist,
            verifyInviteCodeToRegisterAsUser, 
            userRegisterData, 
            userRegisterDataSave } from "../actions";

class RegNewUserWoAccnt extends Component{

    constructor (props){
        super(props)
        this.state = {
                reg_button_enabled: false,
                agreeCheckBox: false,
                msgToUserGeneralIssue: '',
                msgToUserEmailIsBadAddress: '',
                msgToUserEmailIsBadConfirm: '',
                msgToUserPwIsBad: '',
                msgToUserPwIsBadConfirm: '',
                msgToUserFnIsBad: '',
                msgToUserLnIsBad: '',
                msgToUserNeedPhone: '',
                msgToUserZipIsBad: ''
            }
    }  //end of constructor

componentDidMount() {
    this.props.userRegisterData();  //get reducer with defaults
    this.props.rdcr_user_register.with_account = false; //already a default, but just making sure
    if (this.props.rdcr_ui_config.RegNewUser__verified_user_name_does_not_already_exist === undefined) {
        this.props.setUpUiConfig();
        this.props.setUpDefaults();
    }
    window.scrollTo(0,0);
}

handleOnChange(e) {
    //https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react 
    const val = e.target.value.replace(/'/g, "''")
    this.props.userRegisterData(e.target.name, val)
    //this.props.rdcr_user_register[e.target.name] = e.target.value.replace(/'/g, "''");
    console.log('this.props.rdcr_user_register.' + e.target.name, this.props.rdcr_user_register[e.target.name])
}

handleOnChangeAgreeToToSAndPP(e) {
    //formating date: //https://stackoverflow.com/questions/5129624/convert-js-date-time-to-mysql-datetime
    console.log('e.target.value', e.target.value);
    console.log('e.target.checked', e.target.checked);
    if (e.target.checked) {
        this.handleGetDateTimeStamp().then((r => this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_dtstamp_UTC = r))
        const updateCheckBoxStatus = () => {
            return new Promise ((resolve) => {
                this.setState({
                    agreeCheckBox: e.target.checked
                }, () => {
                    this.props.userRegisterData('agreed_yes_check_ToS_n_PP_yn', this.state.agreeCheckBox)
                    resolve('updateCheckBoxStatus success')
                    }
                )
            })
        }
        updateCheckBoxStatus()
        .then(() => this.handleValidateOverallRegDataBeforeCallingSave()
            .then((r) => {
                this.setState({
                        reg_button_enabled: r
                    });
                console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrr', r)
                })
            .catch((error) => {
                console.log('catch in handleValidateOverallRegDataBeforeCallingSave, tf data not valid yet and not saved and agreed to check becomes unchecked.')
                this.props.userRegisterData('agreed_yes_check_ToS_n_PP_yn', false)
            })
        )
    }
    else {
        this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_dtstamp_UTC = '';
        this.props.userRegisterData('agreed_yes_check_ToS_n_PP_yn', false)
        this.setState({
            reg_button_enabled: false,
            agreeCheckBox: false
        })
    }
}

handleClickLink(e){
    console.log('e.target.name', e.target.name)
    const e_target_name = e.target.name
    this.handleGetDateTimeStamp()
    .then((r) => {
        this.props.rdcr_user_register[e_target_name] = r;
    })
    .catch((error) => {
        console.log('error in handleClickLink, catch of handleGetDateTimeStamp', error)
    })
}

handleValidateOverallRegDataBeforeCallingSave(){
    return new Promise((resolve, reject) => {

        //VERIFY DATA AGAIN>>> az_note:need to add email not already exit verificaiton after this promise
        
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ( re.test(this.props.rdcr_user_register.un) ) {
            console.log('re.test(e.target.value)');
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
            this.setState({
                msgToUserEmailIsBadAddress: ''
            })
        }
        else {
            // invalid email, maybe show an error to the user.
            this.setState({
                msgToUserEmailIsBadAddress: "Email address doesn't seem correct or complete."
            })
        }
        if (this.props.rdcr_user_register.un_confirm !== this.props.rdcr_user_register.un) {
            this.setState({
                msgToUserEmailIsBadConfirm: "Confirmed email does not match. Please check email address entered."
            })
        }
        else {
            this.setState({
                msgToUserEmailIsBadConfirm: ''
            })
        }
        /*NOT USING PASSWORD FOR REG WO ACCOUNT
        if (this.state.pw.length >= 6) {  //password ok: long enough
            this.setState({
                msgToUserPwIsBad: ""
            })
        }
        else {
            // too short of passwork
            this.setState({
                msgToUserPwIsBad: "Password needs to be at least six characters. ",
            })
        }
        */
       console.log('this.props.rdcr_user_register.fn', this.props.rdcr_user_register.fn);
       console.log('this.props.rdcr_user_register.fn.length', this.props.rdcr_user_register.fn.length);
        if (this.props.rdcr_user_register.fn.length > 0) {
            this.setState({
                msgToUserFnIsBad: ''
            })
        }
        else {
            this.setState({
                msgToUserFnIsBad: 'Please fill in your first name.'
            })
        }
        if (this.props.rdcr_user_register.ln.length > 0) {
            this.setState({
                msgToUserLnIsBad: ''
            })
        }
        else {
            this.setState({
                msgToUserLnIsBad: 'Please fill in your last name.'
            })
        }
        /*
        //20181119 az took out force valid phone & zip:
        if (this.props.rdcr_user_register.phone.length === 0) {
            this.setState({
                msgToUserNeedPhone: "Phone is missing."
            })    
        }
        else {
            this.setState({
                msgToUserNeedPhone: ''
            })
        }
        if (this.props.rdcr_user_register.zip.length !== 5 || isNaN(this.props.rdcr_user_register.zip)) { // not ok, does not match with pw
            this.setState({
                msgToUserZipIsBad: "Zip code should be the the simple 5 digit format. "
            })
        }
        else {
            this.setState({
                msgToUserZipIsBad: ''
            })
        }
        */
        //<<<<<<DATA VARIFICATION
/*
    console.log('this.props.rdcr_user_register.un', this.props.rdcr_user_register.un)
    console.log('this.props.rdcr_user_register.un_confirm', this.props.rdcr_user_register.un_confirm)
    //console.log('this.props.rdcr_user_register.pw', this.props.rdcr_user_register.pw)
    //console.log('this.props.rdcr_user_register.pw_confirm', this.props.rdcr_user_register.pw_confirm)
    console.log('this.props.rdcr_user_register.fn', this.props.rdcr_user_register.fn)
    console.log('this.props.rdcr_user_register.ln', this.props.rdcr_user_register.ln)
    console.log('this.props.rdcr_user_register.zip', this.props.rdcr_user_register.zip)
    console.log('this.props.rdcr_user_register.agree_yes_click_dtstamp_UTC', this.props.rdcr_user_register.agree_yes_click_dtstamp_UTC)
    console.log('this.state.msgToUserEmailIsBadAddress', this.state.msgToUserEmailIsBadAddress)
    console.log('this.state..msgToUserEmailIsBadConfirm', this.state.msgToUserEmailIsBadConfirm)
    //console.log('this.state.msgToUserPwIsBad', this.state.msgToUserPwIsBad)
    //console.log('this.state.msgToUserPwIsBadConfirm', this.ui.state.msgToUserPwIsBadConfirm)
    console.log('this.state.msgToUserFnIsBad', this.state.msgToUserFnIsBad)
    console.log('this.state.msgToUserLnIsBad', this.state.msgToUserLnIsBad)
    console.log('this.state.msgToUserZipIsBad', this.state.msgToUserZipIsBad)
*/

console.log('this.state.agreeCheckBox -- 1', this.state.agreeCheckBox)
console.log('this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn -- 1', this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn);
    console.log('state!!!', this.state)
    console.log('this.props.rdcr_ui_config.verifyUserNameDoesNotExist', this.props.rdcr_ui_config.verifyUserNameDoesNotExist)
    console.log('props.rdcr_user_register!!!', this.props.rdcr_user_register)
    if (
        this.props.rdcr_ui_config.RegNewUser__verified_user_name_does_not_already_exist === true &&
        this.props.rdcr_user_register.un !== '' &&
        this.props.rdcr_user_register.un_confirm !== '' &&
        //this.state.pw !== '' &&
        //this.state.pw_confirm !== '' &&
        this.props.rdcr_user_register.fn !== '' &&
        this.props.rdcr_user_register.ln !== '' &&
        //20181119 az took out: this.props.rdcr_user_register.zip !== '' &&
        this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn === true &&
        //this.state.agree_yes_click_dtstamp_UTC !== '' &&
        this.state.msgToUserEmailIsBadAddress === '' &&
        this.state.msgToUserEmailIsBadConfirm === '' &&
        this.state.msgToUserPwIsBad === '' &&
        this.state.msgToUserPwIsBadConfirm === '' &&
        this.state.msgToUserFnIsBad === '' &&
        this.state.msgToUserLnIsBad ===  '' &&
        this.state.msgToUserZipIsBad === '') {
            this.setState({
                msgToUserGeneralIssue: ''
            })
            //return true        
            resolve(true)
        }
    else {
        console.log('this.state.agreeCheckBox', this.state.agreeCheckBox)
        console.log('this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn', this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn);
        this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn = false;
        console.log('this.state.agreeCheckBox -- 2', this.state.agreeCheckBox)
        console.log('this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn -- 2', this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn);
        window.scrollTo(0,0);
        this.setState({
            agreeCheckBox: false,
            msgToUserGeneralIssue: "Please fill out the following info..."
        })
        //bc can easily (intutively) skipped via on blur:
        if( this.props.rdcr_user_register.fn === '') {
            this.setState({
                msgToUserFnIsBad: "Please fill in your first name."
            })
        }
        else if ( this.props.rdcr_user_register.ln === '') {
            this.setState({
                msgToUserLnIsBad: "Please fill in your last name."
            })
        }
        //return false
        reject(false)
    }
})
}

handleOnSubmitButtonClickNewUserReg(e){
    e.preventDefault();
    if (!this.state.reg_button_enabled){  //if submit button is disabled, don't do anything
        return
    }
    console.log('this.props.rdcr_user_register', this.props.rdcr_user_register);
    this.setState({
        reg_button_enabled: false
    })
    this.handleGetDateTimeStamp()
    .then((r) => {
        this.props.rdcr_user_register.agreed_yes_click_dtstamp_UTC = r
    })
    .catch((error) => {
        console.log('error in catch of handleOnSubmitButtonClickNewUserReg', error)
    })
    .then(() => {
        this.handleValidateOverallRegDataBeforeCallingSave()
        .then(() => {
            console.log('this.props.rdcr_user_register.agree_yes_click_dtstamp_UTC', this.props.rdcr_user_register.agree_yes_click_dtstamp_UTC);
            console.log('this.props.rdcr_user_register', this.props.rdcr_user_register);
            this.props.userRegisterDataSave('WoAccnt', () => {this.props.history.push('/Status')})
            //NOT included as cb: , () => {this.props.history.push('/Login')});
        })
        .catch((error) => {
            console.log('catch in handleValidateOverallRegDataBeforeCallingSave, tf data not valid yet and not saved and agreed to check becomes unchecked.');
            this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn = false;
            this.props.userRegisterData('agreed_yes_check_ToS_n_PP_yn', false)
        })
    })
}
    //this.props.rdcr_user_register.agreed_yes_click_dtstamp_UTC = this.handleGetDateTimeStamp();
    /*
    this.handleValidateOverallRegDataBeforeCallingSave()
    .then(() => {
        //debugger;
        console.log('this.props.rdcr_user_register.agree_yes_click_dtstamp_UTC', this.props.rdcr_user_register.agree_yes_click_dtstamp_UTC);
        console.log('this.props.rdcr_user_register', this.props.rdcr_user_register);
        this.props.userRegisterDataSave('WoAccnt', () => {this.props.history.push('/Login')});
    })
    .catch(() => console.log('catch in handleValidateOverallRegDataBeforeCallingSave, tf data not valid yet and not saved.'))
    */

handleOnBlur(e){
    console.log('checking email address good format, TOP?');
    switch (e.target.name) {
        case 'un':
            this.props.verifyUserNameDoesNotExist(e.target.value);
            console.log('checking email address good format?');
            console.log('e.target.name', e.target.name);
            console.log('e.target.value', e.target.value);
            //https://stackoverflow.com/questions/39356826/how-to-check-if-it-a-text-input-has-a-valid-email-format-in-reactjs
            //from StackOF comment: don't remember from where i copied this code, but this works.
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( re.test(e.target.value) ) {
                console.log('re.test(e.target.value)');
                // this is a valid email address
                // call setState({email: email}) to update the email
                // or update the data in redux store.
                this.setState({
                    msgToUserEmailIsBadAddress: ''
                })
            }
            else {
                // invalid email, maybe show an error to the user.
                this.setState({
                    msgToUserEmailIsBadAddress: "Email address doesn't seem correct or complete."
                })
            }
            break;
        case 'un_confirm':
            console.log('e.target.value', e.target.value)
            console.log('this.props.rdcr_user_register.un', this.props.rdcr_user_register.un)
            if (e.target.value !== this.props.rdcr_user_register.un) {
                this.setState({
                    msgToUserEmailIsBadConfirm: "Confirmed email does not match. Please check email address entered."
                })
            }
            else {
                this.setState({
                    msgToUserEmailIsBadConfirm: ''
                })
            }
            break;
        /* NOT USING PW FOR REG WO ACCOUNT
            case 'pw':
            console.log('e.target.value.length', e.target.value.length);
            if (e.target.value.length >= 6) {  //password ok: long enough
                this.setState({
                    msgToUserPwIsBad: ""
                })
            }
            else {
                // too short of passwork
                this.setState({
                    msgToUserPwIsBad: "Password needs to be at least six characters. ",
                })
            }
            break;
        case 'pw_confirm':
            console.log('this.state.pw', this.state.pw)
            console.log('e.target.value', e.target.value)
            if (e.target.value !== this.state.pw) { // not ok, does not match with pw
                console.log('in if !==')
                this.setState({
                    msgToUserPwIsBadConfirm: 'Confirmed password does not match. Please check and re-enter.'
                }, () => console.log('this.state.msgToUserPwIsBadConfirm', this.state.msgToUserPwIsBadConfirm))
                
            }
            else {
                this.setState({
                    msgToUserPwIsBadConfirm: '',
                })
            }
            break;
        */
        case 'fn':
            if (e.target.value.length > 0) {
                this.setState({
                    msgToUserFnIsBad: ''
                })
            }
            else {
                this.setState({
                    msgToUserFnIsBad: 'Please fill in your first name.'
                })
            }
            break;

        case 'ln':
            if (e.target.value.length > 0) {
                this.setState({
                    msgToUserLnIsBad: ''
                })
            }
            else {
                this.setState({
                    msgToUserLnIsBad: 'Please fill in your last name.'
                })
            }
            break;
        //20181117 removed:
        /*    
        case 'phone':
            if (e.target.value.length === 0) {
                this.setState({
                    msgToUserNeedPhone: "Phone is missing."
                })    
            }
            else {
                this.setState({
                    msgToUserNeedPhone: ''
                })
            }
            break;

        case 'zip':
            if (e.target.value.length !== 5 || isNaN(e.target.value)) { // not ok, does not match with pw
                this.setState({
                    msgToUserZipIsBad: "Zip code should be the the simple 5 digit format. "
                })
            }
            else {
                this.setState({
                    msgToUserZipIsBad: ''
                })
            }
            break;
        */
        default:
            break;
        }
    }

handleGetDateTimeStamp() {
    return new Promise((resolve, reject) => {
        const dt = new Date().toISOString().slice(0, 19).replace('T', ' ')
            if (dt) {
                resolve(dt)
            }
            else {
                reject('error in handleGetDateTimeStamp')
            }
        }) 
        //return resolve(new Date().toISOString().slice(0, 19).replace('T', ' '))
        //reject('issue with handleGetDateTimeStamp promise');
}
    //return new Date().toISOString().slice(0, 19).replace('T', ' ');


//helpful: https://stackoverflow.com/questions/24471636/inline-bootstrap-form-layout-with-labels-above-inputs}*/

renderRegSection(){
return  <Container>
        <Form 
            onSubmit={this.handleOnSubmitButtonClickNewUserReg.bind(this)}>
            <Card>
                <CardHeader>
                    My contact info:
                    <Label className='containerRegNewUser__ui_msg_label'>
                        {this.props.rdcr_ui_config.RegNewUser__verified_user_name_does_not_already_exist ? '' : 'This email address already exists in the system -- you may already be registered or you may have a typo in the email address.'}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>
                        {this.state.msgToUserGeneralIssue}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>
                        {this.state.msgToUserEmailIsBadAddress}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>
                        {this.state.msgToUserEmailIsBadConfirm}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>
                        {this.state.msgToUserPwIsBad}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>
                        {this.state.msgToUserPwIsBadConfirm}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>    
                        {this.state.msgToUserFnIsBad}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>    
                        {this.state.msgToUserLnIsBad}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>    
                        {this.state.msgToUserZipIsBad}
                    </Label>
                    <Label className='containerRegNewUser__ui_msg_label'>    
                        {this.state.msgToUserNeedPhone}
                    </Label>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label className='mb-0' htmlFor='fn'>First Name</Label>
                        <Input
                        type="text"
                        id='fn'
                        name='fn'
                        value={this.props.rdcr_user_register.fn ? this.props.rdcr_user_register.fn : ''} 
                        onBlur={(e) => this.handleOnBlur(e)} 
                        onChange={(e) => this.handleOnChange(e)}    
                        />
                    </FormGroup>
                    <FormGroup hidden>
                        <Label htmlFor='mn' className='mb-0'>Middle Name or Initial</Label>
                        <Input type="text"
                            id='mn'
                            name='mn'
                            value={this.props.rdcr_user_register.mn ? this.props.rdcr_user_register.mn : ''} 
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='ln' className='mb-0' >Last Name</Label>
                        <Input type="text"
                            id='ln'
                            name='ln'
                            value={this.props.rdcr_user_register.ln ? this.props.rdcr_user_register.ln : ''} 
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='un' className='mb-0'>Email address</Label>
                        <Input type="text"
                            id='un' 
                            name='un'
                            value={this.props.rdcr_user_register.un ? this.props.rdcr_user_register.un : ''} 
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='un_confirm' className='mb-0'>Confirm email</Label>
                        <Input type="text"
                            id='un_confirm'
                            name='un_confirm' 
                            value={this.props.rdcr_user_register.un_confirm ? this.props.rdcr_user_register.un_confirm : ''} 
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup hidden='true'>
                        <Label htmlFor='phone' className='mb-0'>Phone</Label>
                        <Input type="text"
                            id='phone'
                            name='phone'
                            value={this.props.rdcr_user_register.phone ? this.props.rdcr_user_register.phone : ''}
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup hidden='true'>
                        <Label htmlFor='zip' className='mb-0'>Zip Code</Label>
                        <Input type="text"
                            id='zip'
                            name='zip'
                            value={this.props.rdcr_user_register.zip ? this.props.rdcr_user_register.zip : ''}
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='industry' className='mb-0'>Industry (optional)</Label>
                        <Input type="text"
                            id='industry'
                            name='industry'
                            value={this.props.rdcr_user_register.industry ? this.props.rdcr_user_register.industry : ''}
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='position_title' className='mb-0'>Position/Title (optional)</Label>
                        <Input type="text"
                            id='position_title'
                            name='position_title'
                            value={this.props.rdcr_user_register.position_title ? this.props.rdcr_user_register.position_title: ''}
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor='company_name' className='mb-0'>Company/Organization (optional)</Label>
                        <Input type="text"
                            id='company_name'
                            name='company_name'
                            value={this.props.rdcr_user_register.company_name ? this.props.rdcr_user_register.company_name: ''}
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className='mb-0'>Tell us a bit more about yourself and/or how you heard about us (optional)</Label>
                        <Input
                            type="textarea"
                            id='ab_myself_init'
                            name='ab_myself_init'
                            value={this.props.rdcr_user_register.ab_myself_init ? this.props.rdcr_user_register.ab_myself_init : ''}
                            onBlur={(e) => this.handleOnBlur(e)} 
                            onChange={(e) => this.handleOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup row>
                    <Col>
                        <div>
                            {/*<a target="_blank" name='clicked_link_ToS_dtstamp_UTC' onClick={(e) => this.handleClickLink(e)} href={this.props.rdcr_user_register_invite[0].terms_of_service_url}>Terms of ServiceNEW</a>*/}
                        </div>
                        <div>
                            <Link to='/ToS' target="_blank" name='clicked_link_ToS_dtstamp_UTC' onClick={(e) => this.handleClickLink(e)}>
                                Terms of Service
                            </Link>
                        </div>
                    
                <div>
                    <Link to='/privacy-policy' target="_blank" name='clicked_link_PrivPol_dtstamp_UTC' onClick={(e) => this.handleClickLink(e)}>
                        Privacy Policy
                    </Link>
                </div>
                <br />
                <div>
                    {/*<a target="_blank" name='clicked_link_PrivPol_dtstamp_UTC' onClick={(e) => this.handleClickLink(e)} href={this.props.rdcr_user_register_invite[0].priv_pol_url}>Privacy PolicyNEW</a>*/}
                </div>
                </Col>
                </FormGroup>
                <FormGroup row>
                <Col>
                    <Label >By checking the box and clicking the "Interested" button, I heareby agree to the Datagrasp Terms of Use and Privacy Policy: &nbsp;
                    <input type="checkbox" 
                        id="agreed_yes_check_ToS_n_PP_dtstamp_UTC" 
                        name="agreed_yes_check_ToS_n_PP_dtstamp_UTC" 
                        checked={this.state.agreeCheckBox}
                        value={this.props.rdcr_user_register.agreed_yes_check_ToS_n_PP_yn}
                        onChange={(e) => this.handleOnChangeAgreeToToSAndPP(e)}/>
                    </Label>
                </Col>
                </FormGroup>
            </CardBody>
            <CardFooter>
                <FormGroup row>
                    <Col hidden="true">
                        <button type="button" className="btn btn-link"
                            name='goBack' value='goBack' onClick={() => this.props.handleToggleBtForms()}>
                            <FontAwesome
                                name="chevron-left"
                            />
                            back
                            </button>
                    </Col>
                    <Col className="col-auto ml-auto">
                    <button type='submit'
                        className={this.state.reg_button_enabled ? "btn btn-primary" : "btn btn-primary disabled"} 
                        name='' value='create_new_user' 
                        >
                        Interested
                    </button>
                    </Col>
                </FormGroup>
            </CardFooter>
            </Card>
    </Form>
    </Container>
}

    render(){

    return (
            <div>
                {this.renderRegSection()}
            </div>
    );
    }
}

//export default RegNewUser; //pre redux

function mapStateToProps(state) {
    return {    
        rdcr_ui_config: state.rdcr_ui_config,
        rdcr_user_register_invite: state.rdcr_user_register_invite,
        rdcr_user_register: state.rdcr_user_register,
        };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        setUpUiConfig: setUpUiConfig,
        setUpDefaults: setUpDefaults,
        verifyUserNameDoesNotExist: verifyUserNameDoesNotExist,
        verifyInviteCodeToRegisterAsUser: verifyInviteCodeToRegisterAsUser,
        userRegisterData: userRegisterData, 
        userRegisterDataSave: userRegisterDataSave}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(RegNewUserWoAccnt);