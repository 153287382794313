import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { updateUiConfig, createNewPersonForUser, switchBtGrpCntxtsOrSelected } from "../actions";

const UI_CONFIG = 'UI_CONFIG';

class AppNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      isDisplayedRecExplainBttns: false,
      menuTextSelectDisplayRecExplainBttns: "Display guide",
      dynamicMenuItems: {}
    };
  }

  componentDidMount(){
    let menuItems = {};
    //20200109 changed from map to forEach bc warning message re not returning value from map
    this.props.rdcr_pers_context_modules.forEach((i) => {
      //https://stackoverflow.com/questions/26253351/correct-modification-of-state-arrays-in-reactjs
      //https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react
      //https://stackoverflow.com/questions/1168807/how-can-i-add-a-key-value-pair-to-a-javascript-object
      let newItem = {[i.module_desc]: false};
      Object.assign(menuItems, newItem)
      }, this.setState({
        dynamicMenuItems: menuItems   //{'Mentoring, performance review': true, goodbye: false}
        }, () => {
            //console.log('from componentDidMount');
        }
      )
    )
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

handleDeleteModePerson(){
  this.props.updateUiConfig (UI_CONFIG, 'personDeleteMode', !this.props.rdcr_ui_config.personDeleteMode)
}

handleDeleteModeContext(){
  this.props.updateUiConfig (UI_CONFIG, 'contextDeleteMode', !this.props.rdcr_ui_config.contextDeleteMode)
}

handleCreateNewContext(){
  //20200528co, replaced directly creating context from context, but intermediatry page for this. used to be: this.props.createNewPersonForUser ('context')
  this.props.history.push('/NewPersonOrContext')
}

toggleDisplayRecExplainBttns() {
  if (this.state.menuTextSelectDisplayRecExplainBttns === "Display guide") {
    this.setState({
      menuTextSelectDisplayRecExplainBttns: "Do Not Display guide" 
  })
  }
  else {
    this.setState({
      menuTextSelectDisplayRecExplainBttns: "Display guide" 
  })
  }
  this.setState({
    isDisplayedRecExplainBttns: !this.state.isDisplayedRecExplainBttns,
  });
  this.props.updateUiConfig(UI_CONFIG, "recmd_content_display_deeper_dive_buttons", !this.props.rdcr_ui_config.recmd_content_display_deeper_dive_buttons);
}

handleDropdownItemSelection(e){
  //const uiData = 'modulesApplied'
  //const moduleName = 'performance_review'
  let dynamicMenuItems = {...this.state.dynamicMenuItems};
  const e_target_name = e.target.name
  switch (e.target.name) {

    case 'Mentoring, performance review':    
      if (this.props.rdcr_ui_config.modulesApplied === 'default') {
        this.props.updateUiConfig(UI_CONFIG, 'modulesApplied', 'performance_review_only');
        dynamicMenuItems[e_target_name] = !dynamicMenuItems[e_target_name]
        this.setState({
          dynamicMenuItems: {...dynamicMenuItems}
        })
        return;  //needed return and get out of function bc after change of state, the next if condition is met
      }
      
      if (this.props.rdcr_ui_config.modulesApplied === 'performance_review_only') {
        this.props.updateUiConfig(UI_CONFIG, 'modulesApplied', 'default');
        dynamicMenuItems[e_target_name] = !dynamicMenuItems[e_target_name]
        this.setState({
          dynamicMenuItems: {...dynamicMenuItems}
        })
        return;
      }
      break;
    default:
      break;
  }
  
}

handleGoToPersonDetails(){
  this.props.history.push('/PersonDetailz')
}

handleRenderDropdownMenus(){
  //const menus = ['Contexts', 'Dyanamics'];
  //return menus.map((i)=> {
    return  <DropdownMenu right
            key={'key'}>
            {this.handleRenderDropdownItem()}
          </DropdownMenu>
  //})
}

handleRenderDropdownItem(appSectionOptional=''){
  const appSection = (appSectionOptional === '' ? this.props.rdcr_ui_config.appSectionActive : appSectionOptional)
  switch (appSection){
    case 'Person Details':
      return <div>
              <DropdownItem className="w-auto"
                key={'deleteContext'}
                name={'deleteContext'}
                onClick={(e) => this.handleDeleteModePerson()}>
                Delete Person Mode
              </DropdownItem>  
            </div>
    case 'Contexts':
      return <div>
            <DropdownItem hidden={true}
              className="w-auto"
              key={'sort'}
              name={'sort'}
              onClick={(e) => this.handleDropdownItemSelection(e)}>
              Sort/Re-sort by Entry Date
            </DropdownItem>
            <DropdownItem hidden={this.props.rdcr_ui_config.contextIdsSelected[0] === undefined}
              className="w-auto"
              key={'swithcAllContextsOrSelected'}
              name={'swithcAllContextsOrSelected'}
              onClick={() => this.props.switchBtGrpCntxtsOrSelected()}>
              {(this.props.rdcr_ui_config.contextAllPerGrpOrSelected === 'selected') ? 'View All Contexts' : 'View Selected Contexts (and newly created)'}
            </DropdownItem>
             <DropdownItem className="w-auto"
              key={'createNewContext'}
              name={'createNewContext'}
              onClick={(e) => this.handleCreateNewContext()}>
              New Context
          </DropdownItem>
          <DropdownItem className="w-auto"
              key={'deleteContext'}
              name={'deleteContext'}
              onClick={(e) => this.handleDeleteModeContext()}>
              Delete Contexts Mode
          </DropdownItem>
        </div>
    case 'Recommendations & Actions':
      return  <DropdownItem 
                className="w-auto"
                onClick={() => this.toggleDisplayRecExplainBttns()}>
                {this.state.menuTextSelectDisplayRecExplainBttns}
              </DropdownItem>
    default:
        break;
  }
}

  //using tag and Link found here: https://github.com/reactstrap/reactstrap/issues/298

/*
<NavbarBrand tag={Link} to="/Peoplez">reactstrap</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
*/
//DECAND_20180811: brand

handleRenderNavItem(NavItemName=''){
  if (NavItemName === 'PersonDetails' && this.props.rdcr_ui_config.appSectionActive !== 'Person Details') {
    return  <NavItem>
              <NavLink 
              tag={Link} to="/PersonDetails">Person Details
            </NavLink>
            </NavItem>
  }
  else {
    return  <NavItem>
            </NavItem>
  }
}

  render() {
    return (
      <div>
        <Container className="navbar-expand-lg">
        <Navbar color="white" light>
        <NavbarBrand className="menu_brand_text">{this.props.brand}</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink tag={Link} to="/People">People List</NavLink>
              </NavItem>
              {this.handleRenderNavItem('PersonDetails')}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {this.props.rdcr_ui_config.appSectionActive + ' '}Options
                </DropdownToggle>
                {this.handleRenderDropdownMenus()}
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state){
  return{
      rdcr_ui_config: state.rdcr_ui_config,
      rdcr_pers_context_modules: state.rdcr_pers_context_modules
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      updateUiConfig: updateUiConfig,
      createNewPersonForUser,
      switchBtGrpCntxtsOrSelected}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppNavBar);

//20200220: changed from <Navbar color="white" light expand="lg"> this expands menu items horizonally on initial line