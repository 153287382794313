import {PERSON_CONTEXT_DESC_REF_PROF } from "../actions";

export default function(state = {}, action) {

  switch (action.type) {
    case PERSON_CONTEXT_DESC_REF_PROF:
      //console.log('rdcr_persons_context_desc_ref_prof -- PERSON_CONTEXT_DESC_REF_PROF -- action.payload', action.payload)
      return action.payload;
    default:
      return state;
  }
}